import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";

const Forgotpassmsg = () => {
  const { t } = useTranslation();
  return (
    <div className=" pt-20 xl:pt-0 h-screen flex items-center justify-center rounded-md py-40 min-h-screen container xl:px-96">
      <div className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium  text-center">
        <div>
          <div className="flex place-content-center mb-4 h3">
            <BsCheckCircleFill className="text-green-600 text-6xl" />
          </div>
          <div>{t("Reset_Password_Message")}</div>
          <div className="mt-4 flex gap-2 w-full text-center justify-center">
            <Link
              to="/public-contact"
              className=" px-4 py-2 mt-2 rounded-3xl bg-pr hover:bg-sr text-white"
            >
              {t("Footer_Contact_Us")}
            </Link>
            <Link
              to="/"
              className="px-4 py-2 mt-2 rounded-3xl bg-pr hover:bg-sr text-white"
            >
              {t("Back_To_Home")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassmsg;
