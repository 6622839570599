import React, { useState, useContext, useEffect } from "react";
import { MainContent } from "../../../App";
import { url, errorHandler } from "../../../api/Api";
import ProfileNavbar from "../../../layout/ProfileNavbar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";

const ContactReceipt = () => {
  const { t } = useTranslation();
  const { language, isAuthenticated } = useContext(MainContent);
  const [contactReceipt, setContactReceipt] = useState(null);

  const getContactReceipt = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=40&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })

      .then((result) => setContactReceipt(result.data));
  };
  useEffect(() => {
    getContactReceipt();
  }, [language]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <div className=" min-h-screen">
      <div className=" w-full lg:mt-20 pt-2 ">
        <div className="container">
          <div
            className={isAuthenticated ? "mb-2 px-2 mt-3" : "mb-2 px-2 mt-24 lg:mt-4"}
          >
            {isAuthenticated && <ProfileNavbar />}
          </div>
          <div className="ml-2 mr-2 ">
            <div className="items-center justify-center text-center mt-2 mb-8 bg-white  py-5 px-5 w-full shadow-md rounded-lg font-medium ">
              <div className="w-full h-full flex items-center justify-center mb-4">
                <BsCheckCircleFill className="text-green-600 text-6xl" />
              </div>
              {contactReceipt !== null && (
                <>
                  <div
                    dangerouslySetInnerHTML={{ __html: contactReceipt.text }}
                  ></div>

                  <div className="text-center pt-5">
                    <Link
                      to="/"
                      className="px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white"
                    >
                      {t("Back_To_Home")}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactReceipt;
