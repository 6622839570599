import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { url, errorHandler } from "../api/Api";
import { MainContent } from "../App";
import { useTranslation } from "react-i18next";
const GDPR = () => {
  const { setShow, language } = useContext(MainContent);
  const { t } = useTranslation();
  const [gdpr, setGdpr] = useState("");
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=37&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setGdpr(result.data.text);
        } else {
          setGdpr("");
        }
      });
  }, [language]);
  return (
    <div style={{ width: "100%" }} className=" relative">
      <div
        style={{ width: "100%", zIndex: "9999999999" }}
        className=" fixed bottom-0"
      >
        <div className="container bg-gray-300 p-3 shadow-lg rounded-tl-lg rounded-tr-lg">
          <div dangerouslySetInnerHTML={{ __html: gdpr }}></div>
          <div className="w-full flex items-center gap-3 justify-end">
            <button
              onClick={() => {
                Cookies.set("decline", true);
                setShow(false);
              }}
              className="bg-pr hover:bg-sr text-sm py-1 text-white px-2 rounded-md"
            >
              {t("Remind_Me_Later")}
            </button>
            <button
              onClick={() => {
                Cookies.set("accept", true, { expires: 365 });
                setShow(false);
              }}
              className="bg-pr hover:bg-sr text-sm py-1 text-white px-2 rounded-md"
            >
              {t("GDPR_Accept_All")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPR;
