import React, { useContext, useState, useEffect, useRef } from "react";
import Recaptcha from "react-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import { MainContent } from "../App";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    right: 0,
    transformOrigin: "top right",
  },
  shrink: {
    transformOrigin: "top right!important",
  },
}));

const Forgetpassword = ({ setLoginshow }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const { language, checkleft, setOpenReg } = useContext(MainContent);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState("");
  const recaptchaRef = useRef(null);

  const classes = useStyles();

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  const handleValidation = () => {
    let isValid = true;

    if (!email) {
      setEmailError(t("emailErrorMessage"));
      isValid = false;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError(t("emailErrorInvalidMessage"));
      isValid = false;
    } else {
      setEmailError("");
    }
    return isValid;
  };

  const handleForgetPasswordForm = (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      email: email,
      langCode: language,
      recaptchaToken: recaptchaToken,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}identity/forgotpassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setLoginshow(false);
          setOpenReg(false);
          swalController(
            t("Password_Reset_Email_Sent_Successfully"),
            t("Forget_Password_Message_Success"),
            "success"
          );
        } else {
          setIsSubmitting(false);
          resetRecaptcha();
          swalController(result.errors[0], "", "warning");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const swalController = (title, message, icon) => {
    Swal.fire({
      title: title,
      text: message,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: icon,
      timer: 10000,
    });
  };

  const changeLanguagrToArabic = () => {
    const rootElement = document.getElementById("forgot_password_form");
    rootElement.style.cssText = `
    direction: rtl;
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-size: 15px;
  `;
  };

  useEffect(() => {
    if (language === "ar") {
      changeLanguagrToArabic();
    }
  }, []);

  // create a reset function
  const resetRecaptcha = () => {
    setVerified(false);

    if (recaptchaRef.current !== null) {
      recaptchaRef.current.reset();
    }

    setIsSubmitting(false);
  };

  return (
    <div className="pt-20 xl:pt-0  flex items-center justify-center">
      <ToastContainer />

      <div className="bg-white text-sr relative z-50 p-4 rounded w-96">
        <div className="flex items-center justify-center">
          <h1 className="border-b-2 w-60 border-pr cursor-pointer flex items-center justify-center py-2 text-pr font-medium text-xl">
            {t("Forgot_Password")}
          </h1>
        </div>
        <form
          onSubmit={(e) => handleForgetPasswordForm(e)}
          id="forgot_password_form"
        >
          <div className="w-full mt-3">
            <TextField
              onInvalid={(e) =>
                e.target.setCustomValidity(t("Enter_A_valid_Email_Address"))
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
              style={
                checkleft
                  ? { width: "100%" }
                  : {
                      width: "100%",
                      direction: "ltr",
                      transformOrigin: "top right",
                    }
              }
              type="email"
              autoFocus
              InputLabelProps={{
                style: {
                  direction: language === "ar" ? "rtl" : "ltr", // Label direction based on language
                },
                classes:
                  language === "ar"
                    ? {
                        root: classes.labelRoot,
                        shrink: classes.shrink,
                      }
                    : {},
              }}
              label={t("Email")}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              color="success"
              error={!!emailError}
              helperText={
                <p className={`text-${language === "ar" ? "right" : "left"}`}>
                  {emailError}
                </p>
              }
            />
          </div>
          <div className="mt-3 flex items-center">
            <Recaptcha
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPCHA_KEY}
              render="explicit"
              elementID="forgot_password"
              hl={language}
              verifyCallback={(token) => {
                setVerified(true);
                handleRecaptchaVerify(token);
              }}
              onErrored={() => {
                resetRecaptcha();
              }}
            />
          </div>
          <div className="mt-3 w-full">
            {verified ? (
              <button
                type="submit"
                className="bg-pr px-2   text-sr py-2 text-sm rounded"
                disabled={isSubmitting}
              >
                {t("Send_Password")}
              </button>
            ) : (
              <button
                type="button"
                disabled
                className="border px-2 hover:bg-pr text-sm text-sr py-2 rounded"
                style={{ cursor: "not-allowed" }}
              >
                {t("Send_Password")}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgetpassword;
