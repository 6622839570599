import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { BiBlock } from "react-icons/bi";
import { FaTrashRestore } from "react-icons/fa";
import {
  RiInboxUnarchiveFill,
  RiInboxArchiveFill,
  RiChatDeleteFill,
} from "react-icons/ri";

import { useTimeFunction } from "../../utils/useTimeFunction";
import SectionSeparator from "../SectionSeparator";

const UserInformation = ({
  isChatSelected,
  inboxes,
  activeChat,
  chatState,
  setMobilePopup,
  available,
  handleConfirmationController,
  profileNotActiveOrNotApprovedHandler,
  blockUser,
  restoreMessages,
  removeInbox,
  unArchiveChat,
  moveToArchive,
  isCurrentProfileApproved,
  isCurrentProfileAvailable,
  isMarkedForDeletion,
}) => {
  const { t } = useTranslation();
  const time_ago = useTimeFunction();

  return (
    <div className="right_side">
      {isChatSelected && (
        <>
          <div className="online_status">
            <span
              style={
                inboxes[activeChat]?.isonline && available
                  ? { background: "#1dbf73" }
                  : { background: "#736d6d" }
              }
            ></span>
            {
              <h3>
                {!available ? (
                  <span className="text-gray-500 bg-white font-bold">
                    {t("NotAvailable")}
                  </span>
                ) : inboxes[activeChat]?.isonline ? (
                  t("Online")
                ) : (
                  t("Offline")
                )}
              </h3>
            }
          </div>
          <div className="user_details">
            <div className="item">
              <h3 className="font-bold">{t("Member_Since")}</h3>
              <h4>{inboxes[activeChat]?.membersince?.slice(0, 10)}</h4>
            </div>
            <div className="item">
              <h3>{t("Last_Seen")}</h3>
              <h4
                style={
                  inboxes[activeChat]?.isonline && available
                    ? { color: "#1dbf73", fontWeight: "Bold" }
                    : { color: "#736d6d" }
                }
              >
                {!available
                  ? t("NotAvailable")
                  : inboxes[activeChat]?.isonline
                  ? t("Online")
                  : time_ago(inboxes[activeChat]?.lastactivitydate)}
              </h4>
            </div>
            <div className="item">
              <h3>{t("Age")}</h3>
              <h4>{inboxes[activeChat]?.age}</h4>
            </div>
            <div className="item">
              <h3>{t("Gender")}</h3>
              <h4>{inboxes[activeChat]?.gender ? t("Male") : t("Female")}</h4>
            </div>
            <div className="item">
              <h3>{t("Location")}</h3>
              <h4>
                {inboxes[activeChat]?.location !== null
                  ? inboxes[activeChat]?.location
                  : t("No_Data")}
              </h4>
            </div>
            <div className="item">
              <h3>{t("Seeking")}</h3>
              <h4>
                {inboxes[activeChat]?.seekingminage}-
                {inboxes[activeChat]?.seekingmaxage}
              </h4>
            </div>
          </div>

          <div className="footer">
            {inboxes[activeChat]?.isblocked ? (
              ""
            ) : (
              <span
                style={{ fontSize: "2rem" }}
                title={t("Block_User")}
                // onClick={() => {
                //   handleConfirmationController(
                //     t("Confirm_Block_User"),
                //     t("Block"),
                //     blockUser
                //   );
                // }}
                onClick={() => {
                  if (
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion
                  ) {
                    handleConfirmationController(
                      t("Confirm_Block_User"),
                      t("Block"),
                      blockUser
                    );
                    //setMobilePopup(false);
                  } else {
                    profileNotActiveOrNotApprovedHandler();
                  }
                }}
              >
                <BiBlock />
              </span>
            )}
            {!inboxes[activeChat]?.archived && chatState !== "Deleted" ? (
              <span
                // onClick={() => {
                //   handleConfirmationController(
                //     t("Confirm_Archive_Chat"),
                //     t("Archive"),
                //     moveToArchive
                //   );
                // }}
                onClick={() => {
                  if (
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion
                  ) {
                    handleConfirmationController(
                      t("Confirm_Archive_Chat"),
                      t("Archive"),
                      moveToArchive
                    );
                    // setMobilePopup(false);
                  } else {
                    profileNotActiveOrNotApprovedHandler();
                  }
                }}
                style={{ fontSize: "2rem" }}
                title={t("Move_To_Archive")}
              >
                <RiInboxArchiveFill />
              </span>
            ) : chatState !== "Deleted" ? (
              <span
                // onClick={() => {
                //   handleConfirmationController(
                //     t("Confirm_UnArchive_Chat"),
                //     t("UnArchive"),
                //     unArchiveChat
                //   );
                // }}
                onClick={() => {
                  if (
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion
                  ) {
                    handleConfirmationController(
                      t("Confirm_UnArchive_Chat"),
                      t("UnArchive"),
                      unArchiveChat
                    );
                    // setMobilePopup(false);
                  } else {
                    profileNotActiveOrNotApprovedHandler();
                  }
                }}
                style={{ fontSize: "2rem" }}
                title={t("UnArchive_Chat")}
              >
                <RiInboxUnarchiveFill />
              </span>
            ) : (
              ""
            )}
            {chatState !== "Deleted" ? (
              <span
                // onClick={() => {
                //   handleConfirmationController(
                //     t("Confirm_Remove_Chat"),
                //     t("Remove_User_Chat"),
                //     removeInbox
                //   );
                // }}
                onClick={() => {
                  if (
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion
                  ) {
                    handleConfirmationController(
                      t("Confirm_Remove_Chat"),
                      t("Remove_User_Chat"),
                      removeInbox
                    );
                    // setMobilePopup(false);
                  } else {
                    profileNotActiveOrNotApprovedHandler();
                  }
                }}
                style={{ fontSize: "2rem" }}
                title={t("Remove_User_Chat")}
              >
                <RiChatDeleteFill />
              </span>
            ) : (
              ""
            )}

            {inboxes[activeChat]?.hascurrentprofilehistoricalmessages ||
              (chatState === "Deleted" && (
                <span
                  // onClick={() => {
                  //   handleConfirmationController(
                  //     t("Confirm_Restore_Chat_Messages"),
                  //     t("Restore_Messages"),
                  //     restoreMessages
                  //   );
                  //   setMobilePopup(false);
                  // }}
                  onClick={() => {
                    if (
                      isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion
                    ) {
                      handleConfirmationController(
                        t("Confirm_Restore_Chat_Messages"),
                        t("Restore_Messages"),
                        restoreMessages
                      );
                      setMobilePopup(false);
                    } else {
                      profileNotActiveOrNotApprovedHandler();
                    }
                  }}
                  style={{ fontSize: "1.4rem" }}
                  title={t("Restore_Messages")}
                >
                  <FaTrashRestore />
                </span>
              ))}
          </div>
          <SectionSeparator
            Section_Title={t("Important_Notice")}
            paddingTop={"mt-12"}
            paddingBotton={"mb-0"}
          ></SectionSeparator>
          <div className="text-center text-gray-400 pb-5 text-sm">
            {t("Old_Messages_Will_Be_Deleted")}
          </div>
        </>
      )}
    </div>
  );
};
export default memo(UserInformation);
