import Cookies from "js-cookie";
import React, { useContext, useEffect, useState, useRef } from "react";
import Recaptcha from "react-recaptcha";
import { makeStyles } from "@mui/styles";
import { toast, ToastContainer } from "react-toastify";
import { MainContent } from "../App";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { errorHandler, url } from "../api/Api";
//import TextField from "@mui/material/TextField";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { customToasterStyle } from "../utils/constants";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    right: 0,
    transformOrigin: "top right",
  },
  shrink: {
    transformOrigin: "top right!important",
  },
}));

const Login = ({ setForgetPass, setLoginOrRegistration }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    setisAuthenticated,
    setLanguage,
    checkleft,
    language,
    changeDirection,
    changeDirectionsame,
    setUpdateMailbadge,
    isAuthenticated,
  } = useContext(MainContent);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);
  const history = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [holdToShow, setHoldToShow] = useState(false);
  const recaptchaRef = useRef(null);

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  const getOwnData = () => {
    const getheders = new Headers();
    getheders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const getRequestOptions = {
      method: "GET",
      headers: getheders,
      redirect: "follow",
    };
    fetch(`${url}profile/own`, getRequestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setTimeout(() => {
          if (result.data.profileattributes) {
            setLanguage(result.data.profileattributes.defaultlanguage);
            localStorage.removeItem("i18nextLng");
            localStorage.setItem(
              "i18nextLng",
              result.data.profileattributes.defaultlanguage
            );

            changeDirection(result.data.profileattributes.defaultlanguage);
            changeDirectionsame(result.data.profileattributes.defaultlanguage);
          }
        }, 1000);
      });
  };

  const handleValidation = () => {
    let isValid = true;

    if (!email) {
      setEmailError(t("emailErrorMessage"));
      isValid = false;
    } else if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setEmailError(t("emailErrorInvalidMessage"));
      isValid = false;
    } else {
      setEmailError("");
    }
    return isValid;
  };

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      email: email,
      password: password,
      recaptchaToken: recaptchaToken,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}identity/login`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.succeeded) {
          // Cookies.set("accessToken", result.token);
          // Cookies.set("refreshToken", result.refreshtoken);
          // Cookies.set("isAuthenticated", result.succeeded);
          const oneMonthFromNow = new Date();
          oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

          Cookies.set("accessToken", result.token, {
            expires: oneMonthFromNow,
          });
          Cookies.set("refreshToken", result.refreshtoken, {
            expires: oneMonthFromNow,
          });
          Cookies.set("isAuthenticated", result.succeeded, {
            expires: oneMonthFromNow,
          });

          
          // give a message
          // toast.success(t("Toast_Login_Successfull"), {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   style: customToasterStyle(language),
          // });

          if (Cookies.get("location")) {
            const mylocation = Cookies.get("location");
            Cookies.remove("location");
            setisAuthenticated(result.succeeded);
            // localStorage.setItem("isAuthenticated", true);
            history(mylocation);
          } else {
            setisAuthenticated(result.succeeded);
            // localStorage.setItem("isAuthenticated", false);
            getOwnData();
            //TODO SEND USER TO WELCOME PAGE OR SIMILAR
            history("/");
          }
        } else {
          setIsSubmitting(false);
          resetRecaptcha();
          swalController(result.errors[0], "", "info");
        }
      })
      .catch((error) => {
        swalController("Oops...", error, "error"); //'Something went wrong!',
        setIsSubmitting(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    let timeoutId = null;
    if (document.getElementById("focus")) {
      timeoutId = setTimeout(() => {
        document.getElementById("focus").focus();
      }, 10);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const swalController = (title, message, icon) => {
    Swal.fire({
      title: title,
      text: message,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: icon,
      timer: 10000,
    });
  };

  const changeLanguagrToArabic = () => {
    const rootElement = document.getElementById("LoginForm");
    rootElement.style.cssText = `
    direction: rtl;
    font-family: 'Noto Kufi Arabic', sans-serif;
    font-size: 15px;
  `;
  };

  useEffect(() => {
    if (language === "ar") {
      changeLanguagrToArabic();
    }
  }, []);

  // create a reset function
  const resetRecaptcha = () => {
    setVerified(false);

    if (recaptchaRef.current !== null) {
      recaptchaRef.current.reset();
    }

    setIsSubmitting(false);
  };

  const handleMouseDown = () => {
    setHoldToShow(true);
    setShowPassword(true);
  };

  const handleMouseUp = () => {
    setHoldToShow(false);
    setShowPassword(false);
  };

  return (
    <div className="flex items-center flex-col justify-center">
      <ToastContainer />
      <div className=" text-sr p-4 rounded w-96">
        <form
          onSubmit={(e) => handleLoginFormSubmit(e)}
          autoComplete="off"
          dir={checkleft ? "ltr" : "rtl"}
          id="LoginForm"
        >
          <div className="mt-3">
            <TextField
              onInvalid={(e) =>
                e.target.setCustomValidity(t("Enter_A_valid_Email_Address"))
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
              style={
                checkleft
                  ? { width: "100%" }
                  : {
                      width: "100%",
                      direction: "ltr",
                      transformOrigin: "top right",
                    }
              }
              type="email"
              id="focus"
              InputLabelProps={{
                style: {
                  direction: language === "ar" ? "rtl" : "ltr", // Label direction based on language
                },
                classes:
                  language === "ar"
                    ? {
                        root: classes.labelRoot,
                        shrink: classes.shrink,
                      }
                    : {},
              }}
              label={t("Email")}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              color="success"
              error={!!emailError}
              helperText={
                <p className={`text-${language === "ar" ? "right" : "left"}`}>
                  {emailError}
                </p>
              }
            />
          </div>
          <div className="mt-3">
            <TextField
              onInvalid={(e) =>
                e.target.setCustomValidity(t("Enter_Your_Password"))
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
              //type="password"
              type={showPassword ? "text" : "password"}
              InputLabelProps={{
                style: {
                  direction: language === "ar" ? "rtl" : "ltr", // Label direction based on language
                },
                classes:
                  language === "ar"
                    ? {
                        root: classes.labelRoot,
                        shrink: classes.shrink,
                      }
                    : {},
              }}
              style={
                checkleft
                  ? { width: "100%" }
                  : { width: "100%", direction: "ltr" }
              }
              label={t("Password")}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              color="success"
              InputProps={{
                endAdornment: password && (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onTouchStart={handleMouseDown}
                      onTouchEnd={handleMouseUp}
                    >
                      {showPassword || holdToShow ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="mt-3 flex items-center">
            <Recaptcha
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPCHA_KEY}
              render="explicit"
              elementID="login"
              hl={language}
              style={{ border: "none" }}
              verifyCallback={(token) => {
                setVerified(true);
                handleRecaptchaVerify(token);
              }}
              onErrored={() => {
                resetRecaptcha();
              }}
            />
          </div>
          <div className="mt-3 w-full flex items-center justify-between">
            {verified ? (
              <button
                type="submit"
                className=" border bg-pr px-3  text-sr  py-2 text-sm font-medium rounded"
                disabled={isSubmitting}
              >
                {isSubmitting ? t("Authenticating") : t("Login")}
              </button>
            ) : (
              <button
                type="button"
                disabled
                className=" border px-3 text-sr hover:bg-pr py-2 text-sm font-medium rounded"
              >
                {t("Login")}
              </button>
            )}
            <div className="mt-2">
              <div
                onClick={() => {
                  setForgetPass(true);
                  setLoginOrRegistration(3);
                }}
                className=" border-b text-sm border-white cursor-pointer hover:text-pr text-sr"
              >
                {t("Forgotten_Password")}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
