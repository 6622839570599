import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { VscThreeBars } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Spinner from "../../../components/Spinner";
import Cart from "../card/Cart";
import { useTranslation } from "react-i18next";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { errorHandler } from "../../../api/Api";
import { ToastContainer } from "react-toastify";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";
import { Link } from "react-router-dom";
import {
  AiOutlineUsergroupAdd,
  AiOutlineDoubleRight,
  AiOutlineDoubleLeft,
} from "react-icons/ai";

const MyMatches = () => {
  const { t } = useTranslation();
  const { grid, setGrid, changnow, language } = useContext(MainContent);
  const [users, setUsers] = useState([]);
  const [load, setLoad] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      const getDataFromServer = async () => {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${Cookies.get("accessToken")}`
        );
        myHeaders.append("Accept-Language", language || "en");

        let requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        fetch(
          `${process.env.REACT_APP_URI}profilematch/matchresult`,
          requestOptions
        )
          .then((response) => {
            errorHandler(response);
            return response.json();
          })
          .then((response) => {
            if (response.data.length !== 0) {
              setUsers(response.data);
              saveProfileIds(response.data);
              setLoad(false);
            } else {
              setUsers(null);
              saveProfileIds(null);
            }
          });
      };
      getDataFromServer();
    } catch (error) {
      return error;
    }
  }, [changnow, load]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("My_Matches")}
        language={language}
      />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-9 text-sr font-medium sm:text-xl  md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {t("My_Matches")}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      {users !== null ? (
        <div className=" container">
          {users.length !== 0 ? (
            <div className="my-3">
              <div className="flex items-center justify-end gap-3 ">
                <div>
                  <BsFillGrid3X3GapFill
                    onClick={() => setGrid(true)}
                    // className="cursor-pointer text-3xl text-sr"
                    className={`cursor-pointer text-3xl  ${
                      grid ? "text-gray-400" : "text-sr"
                    }`}
                  />
                </div>
                <div>
                  <VscThreeBars
                    onClick={() => setGrid(false)}
                    // className="cursor-pointer text-4xl text-sr"
                    className={`cursor-pointer text-3xl  ${
                      !grid ? "text-gray-400" : "text-sr"
                    }`}
                  />
                </div>
              </div>
              <div
                className={
                  grid
                    ? "my-8 grid grid-cols-1 items-center md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-5 gap-2 "
                    : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                }
              >
                {users.map((item, ind) => (
                  <Cart key={ind} data={item} setLoad={setLoad} />
                ))}
              </div>
            </div>
          ) : (
         <Spinner/>
          )}
        </div>
      ) : (
        <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
          <p className="mb-10">{t("No_Matches_Found")}</p>
          <Link
            to="/matches/matches-setting"
            className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
          >
            <AiOutlineUsergroupAdd
              className={`h-5 w-5 ${language === "ar" ? "ml-1" : "mr-1"} `}
            />
            {t("Matches_Settings")}{" "}
            {language === "ar" ? (
              <AiOutlineDoubleLeft className="icon font-bold text-2xl" />
            ) : (
              <AiOutlineDoubleRight className="icon bold  text-2xl" />
            )}
          </Link>
        </div>
      )}
    </>
  );
};

export default MyMatches;
