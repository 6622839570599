import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { url, errorHandler } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import FixedHeader from "../../layout/FixedHeader";

import { useTranslation } from "react-i18next";
const SuccessStoryPage = () => {
  const { t } = useTranslation();
  const { language, isAuthenticated } = useContext(MainContent);
  const [text, setText] = useState(null);
  const [succStories, setSuccStories] = useState([]);
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStories = (crl) => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== null) {
          setSuccStories(result.data);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const getnotificationPage = () => {
      const myHeaders = new Headers();
      myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept-Language", language || "en");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };

      fetch(
        `${url}sitepage/pagetype?PageTypeId=8&LangCode=${language}`,
        requestOptions
      )
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((result) => {
          if (result.data) {
            setText(result.data.text);
          }
        });
    };

    getnotificationPage();
    getStories(`${url}SuccessStory/approved/all?PageNumber=1&PageSize=10`);
  }, [language]);

  return (
    <div
      //className="pt-1 container min-h-screen bg-blue-50 sm:mt-1 md:mt-1 lg:mt-24 xl:mt-24 2xl:mt-24">
      className={`pt-1 container  min-h-screen bg-blue-50  ${
        !isAuthenticated ? "mt-20" : "lg:mt-24"
      }`}
    >
      {isAuthenticated && <ProfileNavbar />}
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Success_Stories")}
        language={language}
      />
      <div className=" w-full flex items-center mb-0 justify-center ">
        <h1 className="text-center mt-9  text-sr font-medium  sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {t("Success_Stories")}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <div className="py-3">
        {text !== null && (
          <div
            className="bg-white py-4 px-5 w-full shadow-md rounded-md font-normal "
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        )}
        {succStories.length !== 0 &&
          succStories.map((item, ind) => (
            <div key={ind} className="p-3 my-2 bg-gray-200">
              <h1 className="text-2xl">{item.title}</h1>
              <p className="py-2 text-sm">{item.text}</p>
            </div>
          ))}
        {prvurl !== null || nxturl !== null ? (
          <div className="flex w-full items-center mt-2 mb-4 justify-end gap-2">
            {prvurl !== null ? (
              <button
                onClick={() => getStories(prvurl)}
                className="text-white bg-pr hover:bg-sr rounded-sm px-2 py-2 text-sm"
              >
                {t("Previous")}
              </button>
            ) : (
              <button
                disabled
                className="text-white bg-pr cursor-not-allowed rounded-sm px-2 py-2 text-sm"
              >
                {t("Previous")}
              </button>
            )}
            {succStories.length >= 12 ? (
              <button
                onClick={() => getStories(nxturl)}
                className="text-white bg-pr hover:bg-sr rounded-sm px-2 py-2 text-sm"
              >
                {t("Next")}
              </button>
            ) : (
              <button
                disabled
                className="text-white bg-pr cursor-not-allowed rounded-sm px-2 py-2 text-sm"
              >
                {t("Next")}
              </button>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SuccessStoryPage;
