import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { errorHandler, url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaRegEye } from "react-icons/fa";
import {
  AiOutlineDelete,
  AiFillCloseCircle,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineCheckSquare,
  AiFillCheckSquare,
} from "react-icons/ai";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";

const Abusement = () => {
  const { changnow, language } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState(null);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  
  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setAllwaitinglist(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    getAllTemplates(`${url}abusement/all`);
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}abusement/delete?AbusementId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}abusement?AbusementId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setText(result.data.text);
      });
  };
  const markashandled = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let raw = JSON.stringify({
      abusementid: id,
    });

    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}abusement/markashandled`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };
  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Abusement
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />

      <>
        {allwaitinglist.length !== 0 ? (
          <Table bordered striped hover responsive="md">
            <thead>
              <tr>
                <th>Date</th>
                <th>ReporterId</th>
                <th>AbuserId</th>
                <th>Type</th>
                <th>Handled</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <>
                {allwaitinglist.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item.createddate.slice(0, 10)}</td>

                    <td>
                      <Link to={"/profile/" + item.reportedbyprofileid}>
                        {item.reportedbyprofileid}
                      </Link>
                    </td>
                    <td>
                      <Link to={"/profile/" + item.abuserprofileid}>
                        {item.abuserprofileid}
                      </Link>
                    </td>
                    <td>{item.abusementtypename}</td>
                    <td>
                      {item.ishandled ? (
                        <AiFillCheckSquare className="h-6 w-6 text-pr cursor-pointer" />
                      ) : (
                        <AiOutlineCheckSquare
                          onClick={() => markashandled(item.abusementid)}
                          className="h-6 w-6 text-pr cursor-pointer"
                        />
                      )}
                    </td>

                    <td className="flex  gap-2">
                      <AiOutlineDelete
                        onClick={() => deleteTemplate(item.abusementid)}
                        className="h-6 w-6 text-pr cursor-pointer"
                      />
                      <FaRegEye
                        onClick={() => {
                          setEdit(true);
                          readTemplate(item.abusementid);
                        }}
                        className="h-6 w-6 text-pr cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </Table>
        ) : (
          <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
            No Abusement Found
          </div>
        )}
        {allwaitinglist.length !== 0 && (
          <div className="float-right flex items-center gap-4 mb-3">
            {prvurl === null ? (
              <button
                disabled
                className="bg-pr cursor-not-allowed flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
              >
                <AiOutlineLeft />
                Previous
              </button>
            ) : (
              <button
                onClick={() => getAllTemplates(prvurl)}
                className="bg-pr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
              >
                <AiOutlineLeft />
                Previous
              </button>
            )}
            {allwaitinglist.length !== 10 ? (
              <button
                disabled
                className="bg-pr cursor-not-allowed  flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
              >
                Next <AiOutlineRight />
              </button>
            ) : (
              <button
                onClick={() => {
                  getAllTemplates(nxturl);
                }}
                className="bg-pr   flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
              >
                Next <AiOutlineRight />
              </button>
            )}
          </div>
        )}
      </>
      {edit && (
        <div className="mb-4 mt-3">
          <div className="w-full flex items-center justify-end mb-3 mt-4">
            <AiFillCloseCircle
              onClick={() => {
                setEdit(false);
              }}
              className="h-8 w-8 text-pr cursor-pointer"
            />
          </div>
          <div className="w-full p-3 mt-3 h-96 overflow-y-scroll border bg-white shadow-md text-gray-600">
            {text}
          </div>
        </div>
      )}
    </div>
  );
};

export default Abusement;
