import React, { useState, useEffect } from "react";
import { BiCurrentLocation } from "react-icons/bi";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
const Location = ({
  setLongitude,
  setLatitude,
  setCityName,
  savedAddress,
  useColors,
}) => {
  const { t } = useTranslation();
  const [adress, setAdress] = useState(savedAddress);

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setLatitude(ll.lat);
    setLongitude(ll.lng);
    setAdress(value);
    setCityName(value);
  };

  useEffect(() => {
    setAdress(savedAddress);
  }, [savedAddress]);

  const clearSearch = () => {
    setAdress("");
    setCityName("");
    setLatitude("");
    setLongitude("");
  };

  return (
    <div>
      <PlacesAutocomplete
        value={adress || ""}
        onChange={setAdress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <div className="input-group">
              <input
                type="text"
                aria-describedby="basic-addon1"
                {...getInputProps({
                  placeholder: `${t("Search_Places")}`,
                  className:
                    " form-control w-80 location-search-input py-2 " +
                    (useColors
                      ? adress !== ""
                        ? " bg-green-100"
                        : " bg-red-100"
                      : ""),
                })}
              />
              <div className="input-group-append">
                <span
                  style={{ padding: "12px " }}
                  className="input-group-text  bg-sr text-white"
                  id="basic-addon1"
                >
                  <BiCurrentLocation className="w-5 h-4" />{" "}
                  {adress !== "" && (
                    <button className="text-red-500" onClick={clearSearch}>
                      X
                    </button>
                  )}
                </span>
              </div>
            </div>

            {suggestions.length !== 0 && (
              <div className="autocomplete-dropdown-container absolute z-10 p-2 bg-pr">
                {loading && <div>{t("Loading")}</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#1482C4",
                        color: "white",
                        padding: "2px 4px",
                        cursor: "pointer",
                      }
                    : { backgroundColor: "#E7B93A", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={suggestion.placeId}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default Location;
