export const getPreviousProfileId = (currentId) => {
  // Retrieve profile IDs from local storage
  const storedProfileIds = localStorage.getItem("profileIds");
  const profileIds = storedProfileIds ? JSON.parse(storedProfileIds) : [];
  const currentIndex = profileIds.indexOf(currentId);
  let profileid = currentIndex > 0 ? profileIds[currentIndex - 1] : null;

  if (profileid !== null && profileid !== undefined) {
    return String(profileid);
  }
  return String(currentId);
};

export const getNextProfileId = (currentId) => {
  // Retrieve profile IDs from local storage
  const storedProfileIds = localStorage.getItem("profileIds");
  const profileIds = storedProfileIds ? JSON.parse(storedProfileIds) : [];
  const currentIndex = profileIds.indexOf(currentId);
  let profileid =
    currentIndex < profileIds.length - 1 ? profileIds[currentIndex + 1] : null;

  if (profileid !== null && profileid !== undefined) {
    return String(profileid);
  }
  return String(currentId);
};
