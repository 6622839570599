import React from "react";
import { useTranslation } from "react-i18next";

const MakeRow = ({ title, attribute, mydata, description }) => {
  const { t } = useTranslation();

  if (attribute !== null && mydata) {
    const filterdata = mydata.filter((item) => item.translistid === attribute);
    return (
      <tr className=" w-full grid grid-cols-2">
        <td>
          <p className="text-yellow-500 font-medium">{title}</p>
        </td>
        <td className=" font-medium text-sr">
          {filterdata.length !== 0 && filterdata[0].name} {description}
        </td>
      </tr>
    );
  } else {
    return (
      <tr className=" w-full grid grid-cols-2">
        <td>
          <p className="text-yellow-500 font-bold">{title}</p>
        </td>
        <td className=" font-medium text-sr">
          {attribute === true ? (
            t("Yes")
          ) : attribute === false ? (
            t("No")
          ) : (
            <span className="text-gray-300"> {t("No_Answer")}</span>
          )}
        </td>
      </tr>
    );
  }
};

export default MakeRow;
