import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { url, errorHandler } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import FixedHeader from "../../layout/FixedHeader";

const BlogList = () => {
  const { t } = useTranslation();
  const { language, isAuthenticated } = useContext(MainContent);
  const [blogs, setBlogs] = useState([]);
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getBlogs = async (crl) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept-Language", language || "en");

      const response = await fetch(crl, {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      });
      errorHandler(response);
      const result = await response.json();
      //setBlogs(result.data ? result.data.text : t("NoDataAvailable"));
      setBlogs(result.data);
      setPrvurl(result.previouspage);
      setNxturl(result.nextpage);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogs(
      `${url}sitepage/Published/sitepages?PageType=Blog&SiteId=1&LangCode=${language}`
    );
  }, [language]);
  return (
    <div
      //className="pt-1 container min-h-screen bg-blue-50 sm:mt-1 md:mt-1 lg:mt-24 xl:mt-24 2xl:mt-24">
      className={`pt-1 container min-h-screen bg-blue-50 ${
        !isAuthenticated ? "mt-20" : "lg:mt-24"
      }`}
    >
      {isAuthenticated && <ProfileNavbar />}
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Blogs")}
        language={language}
      />
      <div className=" w-full flex items-center mb-0 justify-center ">
        <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {t("Blogs")}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      {blogs?.length !== 0 && (
        <div className="mt-4">
          {blogs?.map((item, ind) => (
            <div key={ind} className="p-3 my-2 bg-white shadow-md rounded-md">
              <Link to={"/blog/" + item.sitepageid} className="text-md">
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      )}
      {blogs?.length === 10 && (
        <div className="flex w-full items-center mt-2 mb-4 justify-end gap-2">
          {prvurl !== null ? (
            <button
              onClick={() => getBlogs(prvurl)}
              className="text-white bg-pr hover:bg-sr rounded-sm px-2 py-2 text-sm"
            >
              {t("Previous")}
            </button>
          ) : (
            <button
              disabled
              className="text-white bg-pr cursor-not-allowed rounded-sm px-2 py-2 text-sm"
            >
              {t("Previous")}
            </button>
          )}
          {blogs?.length >= 10 ? (
            <button
              onClick={() => getBlogs(nxturl)}
              className="text-white bg-pr hover:bg-sr rounded-sm px-2 py-2 text-sm"
            >
              {t("Next")}
            </button>
          ) : (
            <button
              disabled
              className="text-white bg-pr cursor-not-allowed rounded-sm px-2 py-2 text-sm"
            >
              {t("Next")}
            </button>
          )}
        </div>
      )}
      {!blogs || blogs.length === 0 ? (
        <div className="bg-white rounded-xl shadow-md text-black flex py-5 mt-8">
          <div className={`py-3 ${language === "ar" ? "mr-8" : "ml-8"}`}>
            {t("NoDataAvailable")}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BlogList;
