// utils/constants.js
export const customToasterStyle = (language) => {
  return language === "ar"
    ? {
        textAlign: "right",
        direction: "rtl",
        fontSize: "16px", // Set the desired font size
        fontFamily: "'Noto Kufi Arabic', sans-serif", // Set the desired font type
      }
    : {
        textAlign: "left",
        direction: "ltr",
        fontSize: "16px", // Set the desired font size
        fontFamily: "Arial, sans-serif", // Set the desired font type
      };
};
