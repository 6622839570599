import { errorHandler } from "./Api";
export const GetInboxMessageRequest = async (inboxParams, accessToken) => {
  const res = await fetch(
    `${process.env.REACT_APP_URI}inbox/messages?InboxId=${inboxParams}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  );
  errorHandler(res);
  const data = await res.json();
  return data;
};
