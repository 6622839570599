import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";
import EditItem from "../EditItem";

function Appearance({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload } = useContext(MainContent);
  const gender = myown_data?.data?.profileattributes?.gender;
  const { bodyshapedescription } = myown_data?.data || {};
  const profileID = myown_data?.data?.profileid;
  const [haircolor, setHaircolor] = useState(bodyshapedescription?.haircolorid);
  const [hairtype, setHairtype] = useState(bodyshapedescription?.hairtypeid);
  const [eyewear, setEyewear] = useState(bodyshapedescription?.eyewearid);
  const [eyecolor, setEyecolor] = useState(
    bodyshapedescription?.eyescolortypeid
  );
  const [skintype, setSkintype] = useState(bodyshapedescription?.skintypeid);
  const [bodytype, setBodytype] = useState(bodyshapedescription?.bodytypeid);
  const [bodylook, setBodylook] = useState(bodyshapedescription?.bodylookid);
  const [facialhair, setFacialhair] = useState(
    bodyshapedescription?.facialhairid
  );
  const [bodyweight, setBodyweight] = useState(
    bodyshapedescription?.bodyweightid
  );
  const [ethencity, setEthencity] = useState(bodyshapedescription?.ethnicityid);
  const [disabilitie, setDisabilitie] = useState(
    bodyshapedescription?.disabilityid
  );
  const [height, setHeight] = useState(bodyshapedescription?.bodyheightid);

  const handleSaveBodyShape = () => {
    const body = JSON.stringify({
      bodyShapeDescriptionId: profileID,
      hairTypeId: hairtype,
      bodyLookId: bodylook,
      hairColorId: haircolor,
      eyesColorTypeId: eyecolor,
      bodyTypeId: bodytype,
      bodyWeightId: bodyweight,
      skinTypeId: skintype,
      eyeWearId: eyewear,
      disabilityId: disabilitie,
      facialHairId: facialhair,
      ethnicityId: ethencity,
      bodyHeightId: height,
    });
    HANDLE_API_REQUEST.POST(endpoints.SAVE_BODY_SHAPE, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="3">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium  pb-2 flex items-center w-full">
          {t("Your_Appearance")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        <div className="pt-2 ">
          <EditItem
            value={haircolor}
            title={t("Hair_Color")}
            handleSelect={(value) => setHaircolor(value)}
            data={
              gender === ("false" || false)
                ? profileData?.haircolor
                : profileData?.haircolor?.filter((item) => item?.gender !== "F")
            }
          />
          <EditItem
            value={hairtype}
            title={t("Hair_Type")}
            handleSelect={(value) => setHairtype(value)}
            data={profileData?.hairtype}
          />
          <EditItem
            value={eyewear}
            title={t("Eye_Wear")}
            handleSelect={(value) => setEyewear(value)}
            data={profileData?.eyewear}
          />
          <EditItem
            value={eyecolor}
            title={t("Eye_Color")}
            handleSelect={(value) => setEyecolor(value)}
            data={profileData?.eyescolortype}
          />
          <EditItem
            value={skintype}
            title={t("Skin_Type")}
            handleSelect={(value) => setSkintype(value)}
            data={profileData?.skintype}
          />
          <EditItem
            value={bodytype}
            title={t("Body_Type")}
            handleSelect={(value) => setBodytype(value)}
            data={profileData?.bodytype}
          />
          <EditItem
            value={bodylook}
            title={t("Body_Look")}
            handleSelect={(value) => setBodylook(value)}
            data={profileData?.bodylook}
          />
          <EditItem
            value={facialhair}
            title={t("Facial_Hair")}
            handleSelect={(value) => setFacialhair(value)}
            data={profileData?.facialhair}
          />

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
            <div className="pt-2">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("Body_Weight")}
              </h2>
              <select
                // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  bodyweight === "" ||
                  bodyweight === undefined ||
                  bodyweight === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={bodyweight || t("Select_Weight")}
                onChange={(e) => setBodyweight(e.target.value || null)}
              >
                <option value="">{t("Select_Weight")}</option>
                {profileData?.bodyweight.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name} ({t("kg")})
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-2">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("Body_Height")}
              </h2>
              <select
                //  className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  height === "" || height === undefined || height === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={height || t("Select_Height_centimeter")}
                onChange={(e) => setHeight(e.target.value || null)}
              >
                <option value="">{t("Select_Height_centimeter")}</option>
                {profileData?.bodyheight?.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item?.name} ({t("cm")})
                  </option>
                ))}
              </select>
            </div>
          </div>
          <EditItem
            value={ethencity}
            title={t("Your_Ethnicity_Is_Mostly")}
            data={profileData?.ethnicitie}
            handleSelect={(value) => setEthencity(value)}
          />
          <EditItem
            value={disabilitie}
            handleSelect={(value) => setDisabilitie(value)}
            title={t("Disabilities")}
            data={profileData?.disabilitie}
          />
        </div>
        <div className="w-full items-end flex justify-end">
          <button
            type="submit"
            onClick={handleSaveBodyShape}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default Appearance;
