import React from "react";

const SectionSeparator = ({ Section_Title, paddingBotton, paddingTop }) => {
  return (
    <div
      className={`relative flex ${paddingTop ? paddingTop : " pt-5 "} ${
        paddingBotton ? paddingBotton : " pb-5 "
      } items-center`}
    >
      <div className="flex-grow border-t border-gray-400"></div>
      <span className="flex-shrink mx-4 text-gray-400">{Section_Title}</span>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  );
};

export default SectionSeparator;
