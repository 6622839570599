import React, { useContext, useEffect } from "react";
import { MainContent } from "../../App";
import { useNavigate } from "react-router";
import { signalrWebsocket } from "../../utils/socketUtils";

const StartPage = () => {
  const { myown_data, language } = useContext(MainContent);
  const history = useNavigate();

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (language !== "ar") {
      rootElement.style.direction = "ltr";
      rootElement.style.fontFamily = "Roboto, sans-serif";
      rootElement.style.fontSize = "15px";
    } else {
      rootElement.style.cssText = `direction: rtl; font-family: 'Noto Kufi Arabic', sans-serif; font-size: 15px`;
    }
    localStorage.setItem("i18nextLng", language);
    let data = myown_data.data.profileattributes;

    // if (data) {
    //   if (data.defaultstartuppage === 1) {
    //     history("/dashboard");
    //   }
    //   if (data.defaultstartuppage === 2) {
    //     history("/chat/active");
    //   }
    //   if (data.defaultstartuppage === 3) {
    //     history("/matches/mymatches");
    //   }
    //   if (data.defaultstartuppage === 4) {
    //     history("/matches/filter");
    //   }
    //   if (data.defaultstartuppage === 5) {
    //     history("/search/top10");
    //   }
    //   if (data.defaultstartuppage === 6) {
    //     history("/search/latest");
    //   }
    // } else {
    //   history("/dashboard");
    // }
    if (data) {
      if (data.iswelcomeviewed !== true) {
        history("/welcomepage");
      } else {
        switch (data.defaultstartuppage) {
          case 1:
            history("/dashboard");
            break;
          case 2:
            history("/chat/active");
            break;
          case 3:
            history("/matches/mymatches");
            break;
          case 4:
            history("/matches/filter");
            break;
          case 5:
            history("/search/top10");
            break;
          case 6:
            history("/search/latest");
            break;
          default:
            history("/dashboard");
        }
      }
    } else {
      history("/dashboard");
    }
  }, [language]);

  useEffect(() => {
    let data = myown_data.data.profileattributes;
    const handlePushNotification = async (message) => {
      //  console.log(message); // Show push notification messages in the UI
    };

    const connectToSocket = () => {
      if (data?.profileattributesid !== "") {
        if (signalrWebsocket?.state === "Connected") {
          let eventName =
            "ProfilePushNotification_" + data?.profileattributesid;
          signalrWebsocket.on(eventName.toString(), handlePushNotification);
        }
      }
    };

    if (data?.profileattributesid) {
      connectToSocket();
    }
  }, []);

  return <div></div>;
};

export default StartPage;
