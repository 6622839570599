import Cookies from "js-cookie";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { SocketEvent } from "../utils/SocketEvent";

export let signalrWebsocket = null;

export const socketConnection = async () => {
  try {
    if (signalrWebsocket?.state?.connected) {
      return signalrWebsocket;
    }
    const profileId = Cookies.get("userId");
    signalrWebsocket = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_CHATHUB_URI, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          return profileId;
        },
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    signalrWebsocket.on("dummyEvent", () => {});
    await signalrWebsocket?.start();
    // Register the user if userId is provided
    if (profileId) {
      await signalrWebsocket.invoke("RegisterConnection", profileId);
      console.log("RegisterConnection: " + profileId);
    }

    signalrWebsocket.off("dummyEvent");

    return signalrWebsocket;
  } catch (e) {
    console.log(e);
  }
};

export function joinChat(inboxid, currentProfileId, userName) {
  try {
    if (signalrWebsocket?.state !== "Connected") {
      signalrWebsocket = socketConnection();
      if (signalrWebsocket?.state === "Connected") {
        signalrWebsocket?.invoke("JoinInboxChat", {
          InboxId: inboxid.toString(),
          CurrentProfileId: currentProfileId.toString(),
          UserName: userName.toString(),
        });
      }
      //  console.log("User " + userName + " has joined the chat " + inboxid);
    } else {
      signalrWebsocket?.invoke("JoinInboxChat", {
        InboxId: inboxid.toString(),
        CurrentProfileId: currentProfileId.toString(),
        UserName: userName.toString(),
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function newMessageEvent({
  inboxid,
  currentProfileId,
  userName,
  activeProfileId,
}) {
  try {
    if (signalrWebsocket?.state === "Connected") {
      signalrWebsocket?.invoke(SocketEvent.MESSAGE_SEND, {
        InboxId: inboxid.toString(),
        CurrentProfileId: currentProfileId.toString(),
        UserName: userName.toString(),
        TargetProfileId: activeProfileId,
      });
    } else {
      signalrWebsocket = socketConnection();
      signalrWebsocket?.invoke(SocketEvent.MESSAGE_SEND, {
        InboxId: inboxid.toString(),
        CurrentProfileId: currentProfileId.toString(),
        UserName: userName.toString(),
        TargetProfileId: activeProfileId,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function sendTypingEvent(inboxid, currentProfileId, userName) {
  try {
    if (signalrWebsocket?.state !== "Connected") {
      signalrWebsocket = socketConnection();
      console.log("Trying to connect to typing event");
      signalrWebsocket?.invoke(SocketEvent.TYPING, {
        InboxId: inboxid.toString(),
        CurrentProfileId: currentProfileId.toString(),
        UserName: userName.toString(),
      });
      //  console.log("User typing");
    } else {
      if (signalrWebsocket?.state === "Connected") {
        signalrWebsocket?.invoke(SocketEvent.TYPING, {
          InboxId: inboxid.toString(),
          CurrentProfileId: currentProfileId.toString(),
          UserName: userName.toString(),
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function sendRemoveSocketConnectionFromChatEvent(
  inboxid,
  currentProfileId,
  userName
) {
  try {
    if (signalrWebsocket?.state === "Connected") {
      signalrWebsocket?.invoke(SocketEvent.REMOVE_FROM_CHAT, {
        InboxId: inboxid.toString(),
        CurrentProfileId: currentProfileId.toString(),
        UserName: userName.toString(),
      });
      //   console.log("User " + userName + " has left the chat " + inboxid);
    } else {
      signalrWebsocket = socketConnection();
      if (signalrWebsocket?.state === "Connected") {
        signalrWebsocket?.invoke(SocketEvent.REMOVE_FROM_CHAT, {
          InboxId: inboxid.toString(),
          CurrentProfileId: currentProfileId.toString(),
          UserName: userName.toString(),
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}
