import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";
import EditItem from "../EditItem";

function BackgroundAndCulturalValues({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload } = useContext(MainContent);
  const {
    nationalityid,
    faithid,
    faithattitudeid,
    convertid,
    religionservicesid,
    readquranid,
    polygamyid,
    profilecreatorid,
    educationid,
    familyvaluesid,
  } = myown_data?.data?.backgroundandculturalvalues || {};

  const profileID = myown_data?.data?.profileid;
  const gender = myown_data?.data?.profileattributes?.gender;

  const [faith, setFaith] = useState(faithid);
  const [religiousvalues, setReligiousvalues] = useState(faithattitudeid);
  const [born, setBorn] = useState(convertid);
  const [attendReligious, setAttendReligious] = useState(religionservicesid);
  const [readQuran, setReadQuran] = useState(readquranid);
  const [polygamy, setPolygamy] = useState(polygamyid);
  const [familyValue, setFamilyValue] = useState(familyvaluesid);
  const [profileCreator, setProfileCreator] = useState(profilecreatorid);

  const [education, setEducation] = useState(educationid);
  const [nationality, setNationality] = useState(nationalityid);

  const handleSaveBackgroundCulture = () => {
    const body = JSON.stringify({
      backgroundAndCulturalValuesId: profileID,
      nationalityId: nationality,
      educationId: education,
      faithAttitudeId: religiousvalues,
      faithId: faith,
      convertId: born,
      religionServicesId: attendReligious,
      readQuranId: readQuran,
      profileCreatorId: profileCreator,
      familyValuesId: familyValue,
      polygamyId: polygamy,
    });

    HANDLE_API_REQUEST.POST(endpoints.SAVE_BACKGROUND_CULTURE, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="7">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center  w-full">
          {t("Your_Background_Cultural_Values")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        <div className="pt-2 ">
          <h6 className=" text-sr font-medium text-lg w-full pb-2 mb-2 border-b">
            {t("Nationality")}
          </h6>
          <select
            className={`px-2 py-2 border w-full text-sr font-medium rounded-md ${
              nationality === "" ||
              nationality === undefined ||
              nationality === null
                ? "bg-red-100"
                : "bg-green-100 "
            }`}
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
            aria-label=".form-select-lg example"
            id="nationality"
            //className="px-2 py-2 border w-full text-sr font-medium"
          >
            <option value="">{t("Select_Nationality")}</option>
            {profileData.nationality.map((item, ind) => (
              <option key={ind} value={item.translistid}>
                {item.name}
              </option>
            ))}
          </select>
          <EditItem
            value={faith}
            title={t("Faith")}
            data={
              gender === "true" || gender === true
                ? profileData.faith.filter((item) =>
                    [1, 2].includes(item.translistid)
                  )
                : profileData.faith
            }
            handleSelect={(value) => setFaith(value)}
          />
          <EditItem
            value={religiousvalues}
            title={t("Religious_Values")}
            data={profileData.faithattitude}
            handleSelect={(value) => setReligiousvalues(value)}
          />
          <EditItem
            value={born}
            title={t("Born_Reverted")}
            data={
              gender === ("false" || false)
                ? profileData.convert
                : profileData.convert.filter((item) => item.gender !== "F")
            }
            handleSelect={(value) => setBorn(value)}
          />
          <EditItem
            value={attendReligious}
            title={t("Attend_Religious_Services")}
            data={profileData.religionservice}
            handleSelect={(value) => setAttendReligious(value)}
          />
          <EditItem
            value={readQuran}
            title={t("Read_Quran")}
            handleSelect={(value) => setReadQuran(value)}
            data={profileData.readquran}
          />
          <EditItem
            value={polygamy}
            title={t("Polygamy")}
            handleSelect={(value) => setPolygamy(value)}
            data={profileData.polygamy}
          />
          <EditItem
            value={education}
            title={t("Education")}
            handleSelect={(value) => setEducation(value)}
            data={profileData.education}
          />
          <EditItem
            value={familyValue}
            title={t("Family_Values")}
            handleSelect={(value) => setFamilyValue(value)}
            data={profileData.familyvalue}
          />
          <EditItem
            value={profileCreator}
            title={t("Profile_Creator")}
            data={profileData.profilecreator}
            icon={true}
            handleSelect={(value) => setProfileCreator(value)}
          />
        </div>
        <div className="w-full items-end flex justify-end">
          <button
            type="button"
            onClick={handleSaveBackgroundCulture}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default BackgroundAndCulturalValues;
