import endpoints, { getApiKeyConfig } from "../api/EndPoints";
import useFetch from "./useFetch";

export function useGetAllHobbies(language) {
  return useFetch(endpoints.GET_ALL_HOBBIES, language);
}
export function useGetProfileData(language) {
  return useFetch(endpoints.GET_PROFILE_DATA, language);
}
export function useGetAge(language) {
  return useFetch(endpoints.GET_USER_AGE, language);
}
export function useGetSubscriptionData(language) {
  return useFetch(endpoints.SUBSCRIPTION_DATA(language), language);
}
export function useGetPaymentAmount(language, currencyId) {
  return useFetch(endpoints.PAYMENT_AMOUNT(language, currencyId), language);
}
export function useGetBasicData(language) {
  return useFetch(endpoints.BASIC_DATA(language), language, getApiKeyConfig);
}
export function useGetGOldData(language) {
  return useFetch(endpoints.GOLD_DATA(language), language, getApiKeyConfig);
}
export function useGetPlatinumData(language) {
  return useFetch(endpoints.PLATINUM_DATA(language), language, getApiKeyConfig);
}
export function useGetPaymentData(language) {
  return useFetch(endpoints.PAYMENT_DATA, language);
}
export function useGetNotificationScheduler(language) {
  return useFetch(endpoints.NOTIFICATIONS_SCHEDULER, language);
}
export function useGetNotifications(language) {
  return useFetch(endpoints.NOTIFICATIONS, language);
}
