import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProfileNavbar from "../../../layout/ProfileNavbar";
import EmailChange from "./EmailChange";
import Notifications from "./Notifications";
import PasswordChange from "./PasswordChange";
import UserSite from "./UserSite";
import PrivateContact from "./PrivateContact";

const MembersSettings = () => {
  const { page } = useParams();
  const history = useNavigate();

  // Function to render the component based on the page parameter
  const renderPage = () => {
    switch (page) {
      case "email":
        return <EmailChange />;
      case "notification":
        return <Notifications />;
      case "password":
        return <PasswordChange />;
      case "contact":
        return <PrivateContact />;
      case "site-settings":
        return <UserSite />;
      default:
        history("/404");
        return null;
    }
  };

  return (
    <div className="container mt-24 bg-blue-50 lg:mt-16 min-h-screen lg:pt-10 h-full rounded">
      <div className="w-full px-2">
        <ProfileNavbar />
        <div className="mt-4">{renderPage()}</div>
      </div>
    </div>
  );
};

export default MembersSettings;
