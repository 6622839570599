import React, { useEffect, useState, useContext } from "react";
import { url, errorHandler } from "../../api/Api";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { MainContent } from "../../App";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsExclamationCircleFill } from "react-icons/bs";
import FixedHeader from "../../layout/FixedHeader";
const PaymentWrong = () => {
  const { t } = useTranslation();
  const [hmtl, setHmtl] = useState(null);
  const { language } = useContext(MainContent);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=26&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setHmtl(result.data.text));
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-16 lg:pt-10 min-h-screen pb-5">
      <div className="container">
        <div className="mb-2">
          <ProfileNavbar />
        </div>
        <FixedHeader
          scrollPosition={scrollPosition}
          headerText={t("PaymentFailed")}
          language={language}
        />

        <div className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium ">
          <div className="flex place-content-center mb-4 h3">
            <BsExclamationCircleFill className="text-red-600 text-6xl" />
          </div>
          <div className="text-center  mt-4">
            {hmtl !== null && (
              <div dangerouslySetInnerHTML={{ __html: hmtl }}></div>
            )}
          </div>
          <div className="text-center  mt-4">
            <Link
              to="/"
              className="px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white"
            >
              {t("Back_To_Home")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentWrong;
