import Cookies from "js-cookie";
import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { Form, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { errorHandler, url } from "../../../api/Api";
import { MainContent } from "../../../App";
const Contact = ({ setSupport }) => {
  const { t } = useTranslation();
  const [metadata, setMetadata] = useState([]);
  const { changnow, language } = useContext(MainContent);
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [sdata, setSdata] = useState([]);
  const [nxturl, setNxturl] = useState(null);
  const [prvurl, setPrvurl] = useState(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [sinFaq, setSinFaq] = useState(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getMeatadata = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", language || "en");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=FAQCategory`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setMetadata(result.faqcategory);
      });
  };
  const search = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result?.data?.length !== 0) {
          setSdata(result.data);
        } else {
          setSdata([]);
        }
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      });
  };
  const getFaq = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setShow(true);
    fetch(`${url}faq?FAQId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setSinFaq(result.data);
        setShow(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    search(`${url}faq/search?FreeTextSearch=${text}&FaqCategoryId=${category}`);
    getMeatadata();
  }, [changnow, language]);

  return (
    <div className="h-full w-full lg:w-96">
      <ToastContainer />
      <div className="bg-white relative h-full rounded-md p-2 w-full pt-4">
        <div className="flex mb-3">
          <h1 className="text-center w-full text-2xl text-pr">
            {t("Help_Title")}
          </h1>

          <div className="flex items-center  justify-end pt-2 mb-2">
            <AiFillCloseCircle
              onClick={() => setSupport(false)}
              className="text-gray-800 w-6 h-6 cursor-pointer"
            />
          </div>
        </div>

        <div className="my-3 grid grid-cols-1 items-center">
          <input
            type="text"
            autoFocus
            onChange={(e) => {
              if (category !== "true") {
                search(
                  `${url}faq/search?FreeTextSearch=${`${e.target.value}`}&FaqCategoryId=${category}`
                );
              } else {
                search(
                  `${url}faq/search?FreeTextSearch=${`${e.target.value}`}`
                );
              }
              setText(e.target.value);
            }}
            className="py-1 px-2 border outline-none my-1 rounded-md bg-white text-black placeholder-gray-300  w-full"
            placeholder={t("Help_Search")}
          />
          <Form.Select
            onChange={(e) => {
              if (e.target.value !== "true") {
                search(
                  `${url}faq/search?FreeTextSearch=${text}&FaqCategoryId=${e.target.value}`
                );
              } else {
                search(`${url}faq/search?FreeTextSearch=${text}`);
              }
              setCategory(e.target.value || null);
            }}
            className="select rounded-md"
            size="md"
          >
            <option selected value>
              {" "}
              {t("Select_FAQ_Topic")}
            </option>
            {metadata?.length !== 0 &&
              metadata.map((item, ind) => (
                <option key={ind} value={item.translistid}>
                  {item.name}{" "}
                </option>
              ))}
          </Form.Select>
        </div>

        {sdata.length !== 0 ? (
          <div className="h-full relative">
            <div className="h-2/4 bg-white rounded-md grid grid-cols-1 overflow-y-scroll divide-y">
              <Accordion defaultActiveKey="0">
                {sdata.map((item, ind) => (
                  <Accordion.Item
                    eventKey={ind}
                    key={ind}
                    onClick={() => {
                      getFaq(item.faqid);
                      setShow(true);
                      setTitle(item.title);
                    }}
                  >
                    <Accordion.Header>
                      <div
                        // className="font-medium text-sm text-right cursor-pointer hover:text-sr hover:bg-gray-200 text-gray-600 w-full"
                        className={`font-medium text-sm ${
                          language === "ar" ? "text-right" : "text-left"
                        } cursor-pointer hover:text-sr hover:bg-gray-200 text-gray-600 w-full`}
                      >
                        <details className="open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10 open:shadow-lg p-3 rounded-lg">
                          <summary
                            className={`text-sm flex ${
                              language === "ar"
                                ? "justify-right"
                                : "justify-left"
                            } leading-6 text-slate-900 dark:text-white font-semibold select-none`}
                          >
                            {item.title}
                          </summary>
                        </details>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      {show ? (
                        <p>{t("Loading")}</p>
                      ) : (
                        <>
                          {sdata.length !== 0 ? (
                            <>{sinFaq !== null && <p>{sinFaq.text}</p>}</>
                          ) : (
                            <p className="text-white py-3">{t("No_Data")}</p>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>

            <div className="flex items-center justify-end gap-2 mt-3 pr-3">
              {prvurl === null ? (
                <BsFillArrowLeftCircleFill className="text-gray-600 cursor-not-allowed hover:text-sr" />
              ) : (
                <BsFillArrowLeftCircleFill
                  onClick={() => search(prvurl)}
                  className="text-back hover:text-sr cursor-pointer"
                />
              )}
              {nxturl === null ? (
                <BsFillArrowRightCircleFill className="text-gray-600 hover:text-sr cursor-not-allowed" />
              ) : (
                <BsFillArrowRightCircleFill
                  onClick={() => search(nxturl)}
                  className="text-gray-600 hover:text-sr cursor-pointer"
                />
              )}
            </div>
          </div>
        ) : (
          <p className="text-gray-600 py-3">{t("No_Data")}</p>
        )}
        {
          <div className="mt-3 w-full absolute bottom-12 left-0 flex items-center justify-around">
            <p
              //   onClick={() => {
              //     localStorage.removeItem("cid");
              //     localStorage.removeItem("contact");
              //   }}
              //   to="/memberssettings/contact"
              className="text-slate-900 text-xs text-gray-500 font-bold"
            >
              {t("Can_Find_Your_Answer_Contact_Us")}
            </p>
            <Link
              onClick={() => {
                localStorage.removeItem("cid");
                localStorage.removeItem("contact");
              }}
              to="/memberssettings/contact"
              className="text-slate-900  px-2 py-1 rounded-md bg-gray-200 cursor-pointer hover:bg-sr hover:text-white text-xs"
            >
              {t("Help_ContactUS")}
            </Link>
          </div>
        }
      </div>
    </div>
  );
};

export default Contact;
