import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { RiEditBoxLine } from "react-icons/ri";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineClose,
  AiOutlineCheck,
  AiOutlineDelete,
  AiFillCloseCircle,
} from "react-icons/ai";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import PaginationButton from "../Helpers/PaginationButton";

const Promotion = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [promoId, setpromoId] = useState(1);
  const [body, setBody] = useState("");
  const [langCode, setLangCode] = useState("en");
  const [promocode, setPromocode] = useState("");
  const [percent, setPercent] = useState(null);
  const [active, setActive] = useState(false);
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [metadata, setMetadata] = useState([]);
  const [promoType, setPromoType] = useState();
  const op = ["Percentage", "One Week", "Two Weeks"];
  const [key, setKey] = useState("all");
  const [endDateError, setEndDateError] = useState("");
  const [startDateError, setStartDateError] = useState("");

  const handleEndDateChange = (selectedDate) => {
    setEndDate(selectedDate);
    if (!selectedDate) {
      setEndDateError("Please select an end date.");
    } else {
      setEndDateError("");
    }
  };

  const handleStartDateChange = (selectedDate) => {
    setStartDate(selectedDate);
    if (!selectedDate) {
      setStartDateError("Please select a start date.");
    } else {
      setStartDateError("");
    }
  };

  useEffect(() => {
    if (key === "create") setEdit(false);
    setPromocode("");
    setTitle("");
    setBody("");
    setPercent();
  }, [key]);

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setAllwaitinglist(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      });
  };
  const getMetadata = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=PromotionType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setMetadata(result.promotiontype);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    getMetadata();
    if (langCode !== "") {
      getAllTemplates(`${url}promotion/all?LangCode=${langCode || "en"}`);
    }
  }, [changnow, load, langCode]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const ErrorToast = (errors) => {
    toast.error(errors[0].message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const createTemplate = (e) => {
    e.preventDefault();
    if (!startDate) {
      setStartDateError("Please select a start date.");
      return;
    }
    if (!endDate) {
      setEndDateError("Please select an end date.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      promoCode: promocode,
      title: title,
      text: body,
      //promoPercent: percent,
      promoPercent:
        (promoType === 1 || promoType === "1") && percent > 0 && percent < 100
          ? percent
          : 0,
      langCode: langCode,
      active: active,
      promotionTypeId: promoType,
      promotiontypename: op[promoType - 1],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}promotion/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setPromocode("");
          setPercent(0);
          setTitle("");
          setBody("");
        }
        return response.json();
      })
      .then((result) => {
        if (result.errors) {
          ErrorToast(result.errors);
        }
        showToast(result);
      })
      .catch((error) => ErrorToast(error));
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      promotionid: promoId,
      startDate: startDate,
      endDate: endDate,
      promoCode: promocode,
      title: title,
      text: body,
      promoPercent:
        (promoType === 1 || promoType === "1") && percent > 0 && percent < 100
          ? percent
          : 0,
      langCode: langCode || "en",
      active: active,
      promotionTypeId: promoType,
      promotiontypename: op[promoType - 1],
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}promotion/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.errors) {
          ErrorToast(result.errors);
        } else {
          showToast(result);
          getAllTemplates(`${url}promotion/all?LangCode=${langCode || "en"}`);
          setEdit(false);
        }
      });
  };
  // delete
  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      promotionid: id,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}promotion/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        getAllTemplates(`${url}promotion/all?LangCode=${langCode}`);
        showToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}promotion?PromotionId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEndDate(result.enddate);
        setStartDate(result.startdate);
        setTitle(result.title);
        setBody(result.text);
        setPercent(result.promopercent);
        setPromocode(result.promocode);
        setActive(result.active);
        setpromoId(result.promotionid);
        setPromoType(result.promotiontypeid);
      });
  };

  const enableDisable = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      promotionid: id,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}promotion/activateordeactivate`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        getAllTemplates(`${url}promotion/all?LangCode=${langCode}`);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setEdit(false);
    if (key === "create") {
      setLangCode("en");
      setPromoType("");
      setTitle("");
      setBody("");
      setStartDate(null);
      setEndDate(null);
    }
    if (key === "all") {
      setLangCode("en");
    }
  }, [key]);

  return (
    <div className="px-4 ">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Promotion
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        <Tab eventKey="create" title="Create">
          <>
            <form onSubmit={(e) => createTemplate(e)}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-3">
                <Form.Select
                  required
                  size="md"
                  value={langCode}
                  onChange={(e) => setLangCode(e.target.value)}
                >
                  <option value="">Select Language</option>
                  <option value="en">English </option>
                  <option value="de">German </option>
                  <option value="fr">French</option>
                  <option value="it">Italien</option>
                  <option value="no">Norwegian</option>
                  <option value="ar">عربي</option>
                  <option value="sv">Swedish</option>
                  <option value="da">Danish</option>
                  <option value="tr">Turkish</option>
                  <option value="nl">Dutch</option>
                  <option value="es">Spanish</option>
                </Form.Select>
                <Form.Select
                  required
                  size="md"
                  value={promoType}
                  onChange={(e) => {
                    setPromoType(e.target.value);
                  }}
                >
                  <option value="">Select promotion type</option>
                  {metadata.length > 0 &&
                    metadata.map((item, ind) => (
                      <option key={item.translistid} value={item.translistid}>
                        {item.name}{" "}
                      </option>
                    ))}
                </Form.Select>
              </div>
              <div className="flex items-center gap-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    required
                    defaultValue={dayjs(startDate)}
                    id="startdate"
                    label="Start Date"
                    selected={startDate}
                    minDate={dayjs()}
                    onChange={handleStartDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    slotProps={{
                      textField: {
                        placeholder: "Start Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                        error: !!startDateError,
                        helperText: startDateError,
                      },
                    }}
                  />
                  <DateTimePicker
                    required
                    label="End Date"
                    defaultValue={dayjs(endDate)}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    minDate={dayjs()}
                    slotProps={{
                      textField: {
                        placeholder: "End Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                        error: !!endDateError,
                        helperText: endDateError,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <Form.Group className="my-3" controlId="formGridAddress2">
                <Form.Label>Promo Code</Form.Label>
                <Form.Control
                  autoFocus
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                />
              </Form.Group>
              {promoType == 1 && (
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Promo Percent</Form.Label>
                  <Form.Control
                    required
                    value={percent}
                    type="number"
                    className="w-32"
                    onChange={(e) => setPercent(e.target.value)}
                  />
                </Form.Group>
              )}
              <Form.Group className="my-3" controlId="formGridAddress2">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="my-3" controlId="formGridState">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  required
                  value={body}
                  as="textarea"
                  onChange={(e) => setBody(e.target.value)}
                  rows={3}
                />
              </Form.Group>
              <div className="flex items-center justify-between">
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                    type="checkbox"
                    label="Active"
                  />
                </Form.Group>
                <button
                  className="mt-2 px-3 py-2 rounded-md bg-sr hover:bg-pr text-white text-sm"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </>
        </Tab>
        <Tab eventKey="all" title="Edit">
          <div className="flex items-center gap-3 w-1/2 mb-3">
            <Form.Select
              size="md"
              value={langCode}
              onChange={(e) => setLangCode(e.target.value)}
            >
              <option value="">Select Language </option>
              <option value="en">English </option>
              <option value="de">German </option>
              <option value="fr">French</option>
              <option value="it">Italien</option>
              <option value="no">Norwegian</option>
              <option value="ar">عربي</option>
              <option value="sv">Swedish</option>
              <option value="da">Danish</option>
              <option value="tr">Turkish</option>
              <option value="nl">Dutch</option>
              <option value="es">Spanish</option>
            </Form.Select>
          </div>
          {allwaitinglist?.length > 0 ? (
            <Table bordered striped hover responsive="md">
              <thead>
                <tr>
                  <th>Promo Id</th>
                  <th>Language</th>
                  <th>Title</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Type</th>
                  <th>Promo code</th>
                  <th>Percent(%)</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {allwaitinglist.map((item, ind) => (
                    <tr key={item.promotionid}>
                      <td>{item.promotionid}</td>
                      <td>{item.langcode}</td>
                      <td>{item.title}</td>
                      <td>{item.startdate.slice(0, 10)}</td>
                      <td>{item.enddate.slice(0, 10)}</td>
                      <td>{item.promotiontypename}</td>
                      <td>{item.promocode}</td>
                      <td>
                        {item.promopercent}
                        {item.promopercent ? " % off" : ""}
                      </td>
                      <td>
                        <span
                          className={
                            item.active
                              ? "text-green-600 font-bold"
                              : "text-red-600 font-bold"
                          }
                        >
                          {item.active ? "Yes" : "No"}
                        </span>
                      </td>

                      <td className="flex items-center justify-center gap-2">
                        {!item.active ? (
                          <AiOutlineCheck
                            onClick={() => enableDisable(item.promotionid)}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        ) : (
                          <AiOutlineClose
                            onClick={() => enableDisable(item.promotionid)}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        )}
                        <AiOutlineDelete
                          onClick={() => deleteTemplate(item.promotionid)}
                          className="h-6 w-6 text-pr cursor-pointer"
                        />
                        <RiEditBoxLine
                          onClick={() => {
                            setEdit(true);
                            readTemplate(item.promotionid);
                          }}
                          className="h-6 w-6 text-pr cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </Table>
          ) : (
            langCode && (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                No promotion found
              </div>
            )
          )}

          {allwaitinglist?.length > 0 &&
            (prvurl !== null || nxturl !== null) && (
              <div className="float-right flex items-center gap-4 mb-3">
                <PaginationButton
                  onClick={() => getAllTemplates(prvurl)}
                  disabled={prvurl === null}
                  text="Previous"
                  icon={<AiOutlineLeft />}
                />
                <PaginationButton
                  onClick={() => getAllTemplates(nxturl)}
                  disabled={nxturl === null}
                  text="Next"
                  icon={<AiOutlineRight />}
                />
              </div>
            )}

          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <div className="my-3 flex items-center gap-10">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    required
                    defaultValue={dayjs(startDate)}
                    id="startdate"
                    label="Start Date"
                    selected={dayjs(startDate)}
                    value={dayjs(startDate)}
                    minDate={dayjs()}
                    onChange={handleStartDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    slotProps={{
                      textField: {
                        placeholder: "Start Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                        error: !!startDateError,
                        helperText: startDateError,
                      },
                    }}
                  />

                  <DateTimePicker
                    required
                    label="End Date"
                    defaultValue={dayjs(endDate)}
                    selected={dayjs(endDate)}
                    value={dayjs(endDate)}
                    onChange={handleEndDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    minDate={dayjs()}
                    slotProps={{
                      textField: {
                        placeholder: "End Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                        error: !!endDateError,
                        helperText: endDateError,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <Form.Select
                  size="sm"
                  value={promoType}
                  onChange={(e) => setPromoType(e.target.value)}
                >
                  {metadata.length !== 0 &&
                    metadata.map((item, ind) => (
                      <option key={item.translistid} value={item.translistid}>
                        {item.name}{" "}
                      </option>
                    ))}
                </Form.Select>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Promo Code</Form.Label>
                  <Form.Control
                    value={promocode}
                    onChange={(e) => setPromocode(e.target.value)}
                  />
                </Form.Group>
                {promoType == 1 && (
                  <Form.Group className="my-3" controlId="formGridAddress2">
                    <Form.Label>Promo Percent</Form.Label>
                    <Form.Control
                      type="number"
                      value={percent}
                      className="w-32"
                      onChange={(e) => setPercent(e.target.value)}
                    />
                  </Form.Group>
                )}
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Text</Form.Label>
                  <Form.Control
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>

                <div className="flex items-center justify-between">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      checked={active}
                      onChange={(e) => setActive(e.target.checked)}
                      type="checkbox"
                      label="Active"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="mt-2 bg-sr hover:bg-pr"
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Promotion;
