import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProfileNavbar from "./../layout/ProfileNavbar";
import { MainContent } from "./../App";
import FixedHeader from "./../layout/FixedHeader";

const Notfound = () => {
  const { isAuthenticated, language } = useContext(MainContent);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" min-h-screen">
      <div className=" w-full lg:mt-20 pt-2 ">
        <div className="container">
          <div
            className={
              isAuthenticated ? "mb-2 px-2 mt-3" : "mb-2 px-2 mt-24 lg:mt-4"
            }
          >
            {isAuthenticated && (
              <div className="mb-2 ">
                <ProfileNavbar />
              </div>
            )}
            <FixedHeader
              scrollPosition={scrollPosition}
              headerText={t("PageNotFound")}
              language={language}
            />
            <div className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium ">
              <h1 className=" text-9xl text-sr text-center">
                4<span className=" text-pr">0</span>4
              </h1>
              <div className="bg-white py-3 px-0 w-full rounded-lg font-medium ">
                <div className="px-2 lg:px-5 text-sr font-medium text-center w-full">
                  {t("Page_Not_Found")}
                </div>
                <div className="text-center  mt-8">
                  <Link
                    to="/"
                    className=" px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white "
                  >
                    {t("Back_To_Home")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notfound;
