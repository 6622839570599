import React, { useEffect, useState, useContext } from "react";
import { errorHandler, url } from "../../../api/Api";
import { MainContent } from "../../../App";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { customToasterStyle } from "../../../utils/constants";
import FixedHeader from "../../../layout/FixedHeader";

const UserSite = () => {
  const { t } = useTranslation();
  const {
    language,
    changnow,
    myown_data,
    handleSetSiteSettings,
    setFreshReload,
    setLanguage,
  } = useContext(MainContent);
  const [text, setText] = useState(null);
  const [langcode, setLangcode] = useState(language);
  const [crData, setCrData] = useState([]);
  const [currency, setCurrency] = useState(1);
  const [siteMeta, setSiteMeta] = useState([]);
  const [site, setSite] = useState(1);
  const [relaod, setRelaod] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getOwnData = () => {
    if (myown_data.data.profileattributes) {
      setLangcode(myown_data.data.profileattributes.defaultlanguage);
      setSite(myown_data.data.profileattributes.defaultstartuppage);
      setCurrency(myown_data.data.profileattributes.defaultcurrency);
    }
  };

  function getCustomHeaders() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", langcode || "en");

    return myHeaders;
  }
  const getsite = () => {
    let myHeaders = getCustomHeaders();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=32&LangCode=${language}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          setText(result.data.text);
        }
      });
  };
  const getmetaCurrecny = () => {
    const requestOptions = {
      method: "GET",
      headers: getCustomHeaders(),
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=CurrencyType`,
      requestOptions
    ).then((response) => {
      errorHandler(response);
      response.json().then((result) => {
        setCrData(result.currencytype);
      });
    });
  };
  const getmetasite = () => {
    const requestOptions = {
      method: "GET",
      headers: getCustomHeaders(),
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=StartupPage`,
      requestOptions
    ).then((response) => {
      errorHandler(response);
      response.json().then((result) => {
        setSiteMeta(result.startuppage);
      });
    });
  };
  const showToast = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };
  const crateUserSetting = () => {
    setLanguage(langcode);
    const raw = JSON.stringify({
      langcode: langcode,
      preferredcurrencyid: currency,
      startuppageid: site,
    });

    const requestOptions = {
      method: "POST",
      headers: getCustomHeaders(),
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}UserSiteSetting/addorupdate`, requestOptions).then(
      (response) => {
        errorHandler(response);
        response.json().then((result) => {
          setFreshReload(true);
          localStorage.setItem("i18nextLng", langcode);

          handleSetSiteSettings({
            defaultlanguage: langcode,
            defaultstartuppage: site,
            defaultcurrency: currency,
          });

          setRelaod(true);
          showToast(result);
        });
      }
    );
  };
  const resetUserSiteSettings = () => {
    const raw = JSON.stringify({
      langcode: "en",
      preferredcurrencyid: 2,
      startuppageid: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: getCustomHeaders(),
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}UserSiteSetting/addorupdate`, requestOptions).then(
      (response) => {
        errorHandler(response);
        response.json().then((result) => {
          if (result.success) {
            setLanguage("en");
            setFreshReload(true);
            localStorage.setItem("i18nextLng", "en");

            handleSetSiteSettings({
              defaultlanguage: "en",
              defaultstartuppage: site,
              defaultcurrency: currency,
            });

            setRelaod(true);
            swalController(t("Toast_Reset_Succesfully"), "", "success");
          } else {
            swalController(t("No_Changes_Have_Been_Made"), "", "warning");
          }
        });
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOwnData();
    getsite();
    getmetaCurrecny();
    getmetasite();
    setRelaod(false);
  }, [changnow, language, relaod, myown_data]);

  const confirmShowResetHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      text: t("Reset_Site_Setting_Confirm_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Confirm_Reset"),
      cancelButtonText: t("Cancel"),
      onOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resetUserSiteSettings();
      }
    });
  };
  const swalController = (title, message, icon) => {
    Swal.fire({
      title: title,
      text: message,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: icon,
      timer: 4000,
    });
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Site_Settings")}
        language={language}
      />
      <div>
        <div className=" w-full flex items-center mb-4 justify-center ">
          <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Site_Settings")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        {text !== null && (
          <div className="bg-white py-4 px-5 w-full shadow-md rounded-lg font-medium">
            <div
              className="py-4 "
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-1 mt-6 ">
          <div className="w-full flex flex-col gap-2 bg-white p-3 shadow-md rounded-md ">
            <div className="pb-2">
              <p className="pb-1 text-sm text-sr font-medium">
                {t("Select_Your_Preferred_Language")}
              </p>

              <Form.Select
                size="sm"
                value={langcode}
                className="text-sr font-medium w-full"
                onChange={(e) => {
                  setLangcode(e.target.value);
                }}
              >
                <option value="en">{t("Language_English")}</option>
                <option value="de">{t("Language_German")}</option>
                <option value="fr">{t("Language_French")}</option>
                <option value="it">{t("Language_Italien")}</option>
                <option value="no">{t("Language_Norwegian")}</option>
                <option value="ar">{t("Language_Arabic")}</option>
                <option value="sv">{t("Language_Swedish")}</option>
                <option value="es">{t("Language_Spanish")}</option>
                <option value="da">{t("Language_Danish")}</option>
                <option value="tr">{t("Language_Turkish")}</option>
                <option value="nl">{t("Language_Dutch")}</option>
              </Form.Select>
            </div>
            <div className="pb-2">
              <p className="pb-1 text-sm text-sr font-medium">
                {t("Select_Your_Preferred_Currency")}
              </p>
              <Form.Select
                value={currency}
                className="text-sr font-medium"
                onChange={(e) => setCurrency(e.target.value)}
                size="sm"
              >
                {crData.map((item, ind) => (
                  <option key={ind} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="pb-2">
              <p className="pb-1 text-sm text-sr font-medium">
                {t("Select_Your_Startup_Page")}
              </p>
              <Form.Select
                className="text-sr font-medium"
                value={site}
                onChange={(e) => setSite(e.target.value)}
                size="sm"
              >
                {siteMeta.map((item, ind) => (
                  <option key={ind} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="w-full flex justify-end gap-3">
              <button
                onClick={confirmShowResetHandler}
                className="bg-sr text-white rounded-md px-2 text-md py-2 hover:bg-pr text-md font-bold"
              >
                {t("Reset_Form_SiteSetting")}
              </button>
              <button
                onClick={crateUserSetting}
                className="bg-sr text-white rounded-md px-2 text-md py-2 hover:bg-pr text-md font-bold"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSite;
