import React, { memo, useContext, useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { MainContent } from "../../../../App";
import { handleNotificationState } from "../../../../api/methods";

const Notification = ({ notification, schedulers }) => {
  const { language } = useContext(MainContent);
  const [active, setActive] = useState(notification?.active);
  const [schdulerIdentifier, setSchdulerIdentifier] = useState(
    notification?.schedulerid
  );

  function ChangeNotificationSatus() {
    if (active) {
      setActive(false);
      setSchdulerIdentifier(0);
      handleNotificationState(
        notification.notificationstypeid,
        schdulerIdentifier,
        false,
        language
      ).then((res) => {});
    }
  }

  const handleChange = (schdulerIdentifier) => {
    setSchdulerIdentifier(schdulerIdentifier);
    handleNotificationState(
      notification.notificationstypeid,
      schdulerIdentifier,
      !!schdulerIdentifier,
      language
    ).then((res) => {
      setActive(!!schdulerIdentifier);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full py-2">
      <div className="grid grid-cols-1 bg-white shadow-md py-3 px-5 rounded-md items-center md:grid-cols-3 lg:grid-cols-6 gap-8">
        <div className="col-span-2 text-sr font-medium">
          {notification?.name}
        </div>
        {schedulers?.map((item, index) => {
          return (
            <div key={index}>
              <input
                type="radio"
                checked={schdulerIdentifier === item?.translistid}
                name={notification.notificationstypeid}
                value={item?.translistid}
                id={item?.translistid}
                onChange={(e) => handleChange(parseInt(e.target.value))}
              />
              <label
                htmlFor={item}
                className={`text-sr font-medium ml-4 ${
                  language === "ar" ? "mr-2" : ""
                }`}
              >
                {item.name}
              </label>
            </div>
          );
        })}
        <div>
          <Switch
            checked={active}
            onChange={ChangeNotificationSatus}
            disabled={!active}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Notification);
