import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { Accordion } from "react-bootstrap";
import { MainContent } from "../../../../App";
import EditItem from "../EditItem";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { errorHandler } from "../../../../api/Api";
import Redio from "../Radio";
import { FaLanguage } from "react-icons/fa";

function LifeStyles({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload } = useContext(MainContent);
  const {
    alcoholattitudeid,
    smokeattitudeid,
    animalsattitudeid,
    foodattitudeid,
    maritalstatusattitudeid,
    childrenhaveattitudeid,
    childrenattitudeid,
    childrennumberattitudeid,
    childrenoldestattitudeid,
    childrenyoungesttattitudeidid,
    hometypeid,
    employmentattitudeid,
    annualincomeid,
    residenceid,
    relocateid,
    residencystatusid,
    livingsituationid,
    clothingattitudeid,
    gamblingattitudeid,
    haveacar,
    haveamotorcycle,
  } = myown_data?.data?.lifestylesandattitudes || {};

  const profileID = myown_data?.data?.profileid;

  const [drink, setDrink] = useState(alcoholattitudeid);
  const [smoke, setSmoke] = useState(smokeattitudeid);
  const [animal, setAnimal] = useState(animalsattitudeid);
  const [eatinghabit, setEatinghabit] = useState(foodattitudeid);
  const [marital, setMarital] = useState(maritalstatusattitudeid);
  const [havechildren, setHavechildren] = useState(childrenhaveattitudeid);
  const [wantchildren, setWantchildren] = useState(childrenattitudeid);
  const [howmanaychildren, setHowmanaychildren] = useState(
    childrennumberattitudeid
  );
  const [oldestchildren, setOldestchildren] = useState(
    childrenoldestattitudeid
  );
  const [youngestchilren, setYoungestchilren] = useState(
    childrenyoungesttattitudeidid
  );
  const [ocupation, setOcupation] = useState(employmentattitudeid);
  const [anualincome, setAnualincome] = useState(annualincomeid);
  const [hometype, setHometype] = useState(hometypeid);
  const [residence, setResidence] = useState(residenceid);
  const [relocate, setRelocate] = useState(relocateid);
  const [residenceStatus, setResidenceStatus] = useState(residencystatusid);
  const [livingsituations, setLivingsituations] = useState(livingsituationid);
  const [clothing, setClothing] = useState(clothingattitudeid);

  const [gambling, setGambling] = useState(gamblingattitudeid);
  const [car, setCar] = useState(haveacar);
  const [motorcycle, setMotorcycle] = useState(haveamotorcycle);
  const [sports] = useState(myown_data?.data?.profilesports || []);
  const [traveling] = useState(
    myown_data?.data.profiletravlingattitudes.length !== 0
      ? myown_data?.data.profiletravlingattitudes[0].travlingattitudeid
      : null
  );
  const gender = myown_data?.data?.profileattributes?.gender;

  const handleSaveLifeStyle = () => {
    const body = JSON.stringify({
      lifestylesAndAttitudesId: profileID,
      smokeAttitudeId: smoke,
      foodAttitudeId: eatinghabit,
      alcoholAttitudeId: drink,
      gamblingAttitudeId: gambling,
      travlingAttitudeId: traveling,
      clothingAttitudeId: clothing,
      childrenAttitudeId: wantchildren,
      employmentAttitudeId: ocupation,
      animalsAttitudeId: animal,
      annualIncomeId: anualincome,
      residenceId: residence,
      childrenHaveAttitudeId: havechildren,
      childrenNumberAttitudeId: howmanaychildren,
      childrenOldestAttitudeId: oldestchildren,
      childrenYoungesttAttitudeIdId: youngestchilren,
      residencyStatusId: residenceStatus,
      relocateId: relocate,
      homeTypeId: hometype,
      maritalStatusAttitudeId: marital,
      livingSituationId: livingsituations,
      sportAttitudeId: sports,
      haveACar: car,
      haveAMotorcycle: motorcycle,
    });

    HANDLE_API_REQUEST.POST(endpoints.SAVE_LIFE_STYLE, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      });
  };

  useEffect(() => {
    if (
      havechildren === "1" ||
      havechildren === "" ||
      havechildren === undefined ||
      havechildren === null
    ) {
      setOldestchildren("");
      setYoungestchilren("");
      setHowmanaychildren("");
    }
  }, [havechildren]);

  const doesntHaveChildren = React.useMemo(() => {
    return havechildren === "1" || !havechildren;
  }, [havechildren]);

  // function doesntHaveChildren() {
  //   return (
  //     havechildren === "1" ||
  //     havechildren === "" ||
  //     havechildren === undefined ||
  //     havechildren === null
  //   );
  // }

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="6">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center  w-full">
          {t("Your_LifeStyle")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        <div className="pt-2 ">
          <EditItem
            value={drink}
            title={t("Do_You_Drink")}
            data={profileData.alcoholattitude}
            handleSelect={(value) => setDrink(value)}
          />
          <EditItem
            value={smoke}
            title={t("Do_You_Smoke")}
            data={profileData.smokeattitude}
            handleSelect={(value) => setSmoke(value)}
          />
          <EditItem
            value={animal}
            title={t("Loving_Animal")}
            data={profileData.animalsattitude}
            handleSelect={(value) => setAnimal(value)}
          />

          <EditItem
            value={eatinghabit}
            title={t("Eating_Habits")}
            data={profileData.foodattitude}
            handleSelect={(value) => setEatinghabit(value)}
          />
          <EditItem
            value={gambling}
            title={t("Gambling_Attitude")}
            data={profileData.gamblingattitude}
            handleSelect={(value) => setGambling(value)}
          />
          <EditItem
            value={marital}
            title={t("Marital_Status")}
            data={
              gender === "true"
                ? profileData.maritalstatusattitude.filter(
                    (item) => item.gender !== "F"
                  )
                : gender === true
                ? profileData.maritalstatusattitude.filter(
                    (item) => item.gender !== "F"
                  )
                : profileData.maritalstatusattitude.filter(
                    (item) => item.gender !== "M"
                  )
            }
            handleSelect={(value) => setMarital(value)}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            <div className="pt-4">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("Do_You_Have_Children")}
              </h2>
              <select
                //className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  havechildren === "" ||
                  havechildren === undefined ||
                  havechildren === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={havechildren || t("Select_Options")}
                onChange={(e) => setHavechildren(e.target.value || null)}
              >
                <option value="">{t("Select_Options")}</option>
                {profileData.childrenhaveattitude.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-4">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("Do_You_Want_Children")}
              </h2>
              <select
                //className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  wantchildren === "" ||
                  wantchildren === undefined ||
                  wantchildren === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={wantchildren || t("Select_Options")}
                onChange={(e) => setWantchildren(e.target.value || null)}
              >
                <option value="">{t("Select_Options")}</option>
                {profileData.childrenattitude.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-4">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("How_Many_Children_Do_You_Have")}
              </h2>
              <select
                disabled={doesntHaveChildren}
                //className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  howmanaychildren === "" ||
                  howmanaychildren === undefined ||
                  howmanaychildren === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={howmanaychildren || t("Select_Options")}
                onChange={(e) => setHowmanaychildren(e.target.value || null)}
              >
                <option value="">{t("Select_Options")}</option>
                {profileData.childrennumberattitude.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-4">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("Oldest_Child")}
              </h2>
              <select
                disabled={doesntHaveChildren}
                //className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  oldestchildren === "" ||
                  oldestchildren === undefined ||
                  oldestchildren === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={oldestchildren || t("Select_Options")}
                onChange={(e) => setOldestchildren(e.target.value || null)}
              >
                <option value="">{t("Select_Options")}</option>
                {profileData.childrenoldestattitude.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-4">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                {t("Youngest_Child")}
              </h2>
              <select
                disabled={doesntHaveChildren}
                // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                  youngestchilren === "" ||
                  youngestchilren === undefined ||
                  youngestchilren === null
                    ? "bg-red-100"
                    : "bg-green-100 "
                }`}
                aria-label=".form-select-lg example"
                id="im"
                value={youngestchilren || t("Select_Options")}
                onChange={(e) => setYoungestchilren(e.target.value || null)}
              >
                <option value="">{t("Select_Options")}</option>
                {profileData.childrenyoungesttattitude.map((item) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-4">
              <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                &nbsp;
              </h2>
            </div>
          </div>
          <EditItem
            value={ocupation}
            title={t("Ocupation")}
            data={profileData.employmentattitude}
            handleSelect={(value) => setOcupation(value)}
          />
          <EditItem
            value={anualincome}
            title={t("Annual_Income")}
            data={profileData.annualincome}
            handleSelect={(value) => setAnualincome(value)}
          />
          <EditItem
            value={hometype}
            title={t("Home_Type")}
            handleSelect={(value) => setHometype(value)}
            data={profileData.hometype}
          />
          <EditItem
            value={residenceStatus}
            title={t("Residency_Status")}
            data={profileData.residencystatus}
            handleSelect={(value) => setResidenceStatus(value)}
          />
          <EditItem
            value={residence}
            title={t("Residence")}
            handleSelect={(value) => setResidence(value)}
            data={profileData.residence}
          />
          <EditItem
            value={livingsituations}
            title={t("Living_Situation")}
            handleSelect={(value) => setLivingsituations(value)}
            data={profileData.livingsituation}
          />
          <EditItem
            value={relocate}
            title={t("Willing_To_Relocate")}
            handleSelect={(value) => setRelocate(value)}
            data={profileData.relocate}
          />
          <EditItem
            value={clothing}
            title={t("Clothing_Attitude")}
            data={profileData.clothingattitude}
            handleSelect={(value) => setClothing(value)}
          />

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
            <div className="pt-2">
              <h2 className="text-sr text-sm font-medium pb-1 border-b">
                {t("Have_Any_Car")}
              </h2>

              <FormControl component="fieldset">
                <RadioGroup
                  className={`rounded-md border-2 mb-3 mt-2 py-2 w-52 ${
                    car === "" || car === undefined || car === null
                      ? "bg-red-100"
                      : "bg-green-100 "
                  } `}
                  row
                  aria-label="car"
                  name="row-radio-buttons-group"
                  value={car}
                  onChange={(e) => setCar(e.target.value)}
                >
                  <FormControlLabel
                    control={<Redio val={true} />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    control={<Redio val={false} />}
                    label={t("No")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="pt-2">
              <h2 className="text-sr text-sm font-medium pb-1 border-b">
                {t("Have_A_Motorcycle")}
              </h2>

              <FormControl component="fieldset">
                <RadioGroup
                  className={` rounded-md border-2 mb-3 mt-2 py-2 w-52 ${
                    motorcycle === "" ||
                    motorcycle === undefined ||
                    motorcycle === null
                      ? "bg-red-100"
                      : "bg-green-100 "
                  }`}
                  row
                  aria-label="motorcycle"
                  name="row-radio-buttons-group"
                  value={motorcycle}
                  onChange={(e) => setMotorcycle(e.target.value)}
                >
                  <FormControlLabel
                    control={<Redio val={true} />}
                    label={t("Yes")}
                  />
                  <FormControlLabel
                    control={<Redio val={false} />}
                    label={t("No")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full items-end flex justify-end">
          <button
            type="button"
            onClick={handleSaveLifeStyle}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default LifeStyles;
