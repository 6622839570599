import React from "react";

const PaginationButton = ({ onClick, disabled, text, icon }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`bg-pr ${
      disabled ? "cursor-not-allowed" : ""
    } flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 bg-sr hover:bg-pr`}
  >
    {text} {icon}
  </button>
);

export default PaginationButton;
