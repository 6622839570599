import React, { useContext, useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";
import { FaInfoCircle } from "react-icons/fa";

function ProfileInformation() {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload, language } =
    useContext(MainContent);

  const [aboutme, setAboutme] = useState(
    myown_data?.data?.profilecontentdraft?.aboutmetext
      ? myown_data?.data?.profilecontentdraft?.aboutmetext
      : myown_data?.data?.profilecontent?.aboutmetext
  );
  const profileID = myown_data?.data?.profileid;
  const [profiletitle, setProfiletitle] = useState(
    myown_data?.data?.profilecontentdraft?.title
      ? myown_data?.data?.profilecontentdraft?.title
      : myown_data?.data?.profilecontent?.title
  );
  const [seeking, setSeeking] = useState(
    myown_data?.data?.profilecontentdraft?.lookingfortext
      ? myown_data?.data?.profilecontentdraft?.lookingfortext
      : myown_data?.data?.profilecontent?.lookingfortext
  );

  const [titleError, setTitleError] = useState("");
  const [aboutMeError, setAboutMeError] = useState("");
  const [aboutMyPartnerError, setAboutMyPartnerError] = useState("");

  const handleSubmit = () => {
    let isValid = true;

    if (!profiletitle || profiletitle.trim() === "") {
      setTitleError(t("profileTitleRequiredMessage"));
      isValid = false;
    } else {
      setTitleError("");
    }

    if (!aboutme || aboutme.trim() === "") {
      setAboutMeError(t("profileAboutMeRequiredMessage"));
      isValid = false;
    } else {
      setAboutMeError("");
    }

    if (!seeking || seeking.trim() === "") {
      setAboutMyPartnerError(t("profileSeekingRequiredMessage"));
      isValid = false;
    } else {
      setAboutMyPartnerError("");
    }

    return isValid;
  };

  const savetoContentDraft = (e) => {
    e.preventDefault();
    if (!handleSubmit()) {
      return;
    }
    const body = JSON.stringify({
      profileContentDraftId: profileID,
      title: profiletitle,
      aboutMeText: aboutme,
      lookingForText: seeking,
    });

    HANDLE_API_REQUEST.POST(endpoints.SAVE_CONTENT_DRAFT, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        result.success && setFreshReload(true);
        showtoast(
          result,
          "Toast_Waiting_For_Approval",
          "Toast_Saving_Unsuccessful"
        );
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    // if (profiletitle !== null && profiletitle.trim() !== "") {
    //   setTitleError("");
    // }

    if (
      profiletitle !== null &&
      typeof profiletitle !== "undefined" &&
      profiletitle.trim() !== ""
    ) {
      setTitleError("");
    }

    if (
      aboutme !== null &&
      typeof aboutme !== "undefined" &&
      aboutme.trim() !== ""
    ) {
      setAboutMeError("");
    }

    // if (aboutme !== null && aboutme.trim() !== "") {
    //   setAboutMeError("");
    // }

    if (
      seeking !== null &&
      typeof seeking !== "undefined" &&
      seeking.trim() !== ""
    ) {
      setAboutMyPartnerError("");
    }

    // if (seeking !== null && seeking.trim() !== "") {
    //   setAboutMyPartnerError("");
    // }
  }, [profiletitle, aboutme, seeking]);

  return (
    <Accordion.Item
      className="my-2 bg-white rounded-md overflow-hidden shadow-md "
      eventKey="1"
    >
      <Accordion.Header>
        <h1 className="text-xl text-sr font-medium  pb-2 flex items-center w-full">
          {t("Your_Info")}{" "}
        </h1>
        <span
          className={`rounded-full ${
            myown_data?.data?.profilecontentdraft !== null &&
            myown_data?.data?.profilecontentdraft !== undefined &&
            !myown_data?.data?.profilecontentdraft.rejected
              ? "bg-yellow-400 text-gray-900 "
              : myown_data?.data?.profilecontentdraft !== null &&
                myown_data?.data?.profilecontentdraft !== undefined &&
                myown_data?.data?.profilecontentdraft.rejected
              ? "bg-red-400 text-gray-900 "
              : myown_data?.data?.profilecontent !== null &&
                myown_data?.data?.profilecontent !== undefined &&
                (myown_data?.data?.profilecontentdraft === null ||
                  myown_data?.data?.profilecontentdraft === undefined)
              ? "bg-green-200 text-gray-900 "
              : ""
          }  px-4 py-2 font-bold shadow-2xl inline-block ml-auto text-center`}
        >
          {myown_data?.data?.profilecontentdraft !== null &&
          myown_data?.data?.profilecontentdraft !== undefined &&
          !myown_data?.data?.profilecontentdraft.rejected
            ? t("Pending_Review_And_Approval")
            : myown_data?.data?.profilecontentdraft !== null &&
              myown_data?.data?.profilecontentdraft !== undefined &&
              myown_data?.data?.profilecontentdraft.rejected
            ? t("Content_Has_Been_Rejected")
            : myown_data?.data?.profilecontent !== null &&
              myown_data?.data?.profilecontent !== undefined &&
              (myown_data?.data?.profilecontentdraft === null ||
                myown_data?.data?.profilecontentdraft === undefined)
            ? t("Content_Has_Been_Approved")
            : ""}
        </span>
      </Accordion.Header>
      <Accordion.Body>
        <form onSubmit={(e) => savetoContentDraft(e)} noValidate="noValidate">
          <div className="pt-4 flex items-center justify-center lg:justify-start gap-0 flex-wrap">
            <div className=" flex items-center w-full justify-between">
              <label
                htmlFor="title"
                className="form-label text-lg font-bold text-sr"
              >
                {t("Profile_Title")}
              </label>{" "}
            </div>

            <span className="text-sm text-gray-500 mb-1 flex gap-2">
              <FaInfoCircle className="text-yellow-600" />
              <i>{t("PersonalInformationIsProhibited")}</i>
            </span>
            <input
              className="form-control"
              placeholder={t("Your_Profile_Title")}
              htmlFor="title"
              type="text"
              autoFocus
              id="title"
              maxLength="250"
              required
              value={profiletitle}
              onChange={(e) => setProfiletitle(e.target.value)}
            ></input>

            <p className=" text-xs text-green-500 font-bold pt-0 w-full ">
              {profiletitle?.length || 0} / 250
              <span
                className={`text-red-500 text-xs italic  ${
                  language === "ar" ? "text-right mr-10" : "text-left ml-10"
                }`}
              >
                {titleError}
              </span>
            </p>
          </div>

          <div className="pt-4 flex items-center justify-center lg:justify-start gap-0 flex-wrap">
            <div className=" flex items-center w-full justify-between">
              <label
                htmlFor="about"
                className="form-label text-lg font-bold flex items-center text-sr"
              >
                {t("Profile_About_Me")}
              </label>
            </div>
            <span className="text-sm text-gray-500 mb-1 flex gap-2">
              <FaInfoCircle className="text-yellow-600" />
              <i>{t("PersonalInformationIsProhibited")}</i>
            </span>
            <div className=" w-full">
              <textarea
                className="form-control"
                rows="3"
                htmlFor="about"
                required
                maxLength="4000"
                placeholder={t("PlaceHolderAboutMe")}
                value={aboutme}
                onChange={(e) => setAboutme(e.target.value)}
              ></textarea>
              <p className=" text-xs text-green-500 font-bold pt-2">
                {aboutme?.length || 0} / 4000
                <span
                  className={`text-red-500 text-xs italic  ${
                    language === "ar" ? "text-right mr-10" : "text-left ml-10"
                  }`}
                >
                  {aboutMeError}
                </span>
              </p>
            </div>
          </div>
          <div className="pt-4 flex items-center justify-center lg:justify-start gap-0 flex-wrap">
            <div className=" w-full flex items-center justify-between">
              <label
                htmlFor="seeking"
                className="form-label flex items-center text-lg font-bold text-sr"
              >
                {t("Seeking_Partner_Details")}
              </label>
            </div>
            <span className="text-sm text-gray-500 mb-1 flex gap-2">
              <FaInfoCircle className="text-yellow-600" />
              <i>{t("PersonalInformationIsProhibited")}</i>
            </span>
            <div className=" w-full">
              <textarea
                className="form-control"
                rows="3"
                maxLength="4000"
                required
                htmlFor="seeking"
                placeholder={t("PlaceHolderSeeking")}
                value={seeking}
                onChange={(e) => setSeeking(e.target.value)}
              ></textarea>
              <p className=" text-xs text-green-500 font-bold pt-2">
                {seeking?.length || 0} / 4000
                <span
                  className={`text-red-500 text-xs italic  ${
                    language === "ar" ? "text-right mr-10" : "text-left ml-10"
                  }`}
                >
                  {aboutMyPartnerError}
                </span>
              </p>
            </div>
          </div>
          <div className="w-full items-end flex justify-end">
            <button
              type="submit"
              className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default ProfileInformation;
