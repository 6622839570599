import React from "react";
import ProfileNavbar from "../../layout/ProfileNavbar";
import MatchFilter from "./MatchFilter/MatchFilter";
import MatchSetting from "./matchSetting/MatchSetting";
import MyMatches from "./myMatches/MyMatches";
import { useParams, useNavigate } from "react-router-dom";

const Matches = () => {
  const { page } = useParams();
  const history = useNavigate();

  return (
    <div className=" bg-blue-50 w-full mt-24 bg lg:mt-16 lg:pt-10 min-h-screen">
      <div className="px-4 container">
        <ProfileNavbar />
        <div>
          <div className="pt-4">
            <>
              {page === "mymatches" && <MyMatches />}
              {page === "matches-setting" && <MatchSetting />}
              {page === "filter" && <MatchFilter />}
              {["mymatches", "matches-setting", "filter"].indexOf(page) ===
                -1 && history("/404")}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Matches;
