import React from "react";
import Favourite from "./peopleActivity/Favourite";
import Liked from "./peopleActivity/Liked";
import Viewers from "./peopleActivity/Viewers";
import { useParams, useNavigate } from "react-router-dom";
import ProfileNavbar from "../../layout/ProfileNavbar";
import Mylikes from "./myActivity/Mylikes";
import Myfavourite from "./myActivity/Myfavorite";
import Myviewed from "./myActivity/Myviewed";
import Blocklist from "./myActivity/Blocklist";

const Activity = () => {
  const { page } = useParams();
  const history = useNavigate();

  return (
    <div className="container bg-blue-50 mt-24 lg:mt-16 lg:pt-10 min-h-screen">
      <div className="container">
        <ProfileNavbar />
        <div>
          <div className="pt-4">
            {page === "likedme" && <Liked />}
            {page === "favoriteme" && <Favourite />}
            {page === "myviewers" && <Viewers />}
            {page === "mylikes" && <Mylikes />}
            {page === "myfavorite" && <Myfavourite />}
            {page === "myviewed" && <Myviewed />}
            {page === "blocklist" && <Blocklist />}
            {[
              "likedme",
              "favoriteme",
              "myviewers",
              "mylikes",
              "myfavorite",
              "myviewed",
              "blocklist",
            ].indexOf(page) === -1 && history("/404")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
