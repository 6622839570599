import Select from "react-select";
import { useTranslation } from "react-i18next";

const AutocompleteSelect = ({ countryorigin, setCountryorigin, formdata }) => {
  const { t } = useTranslation();

  const options = formdata?.country?.map((item, ind) => ({
    value: item.translistid,
    label: item.name,
    key: ind,
  }));

  return (
    <Select
      className={` mb-3 font-medium text-sr text-sm ${
        countryorigin === "" ||
        countryorigin === undefined ||
        countryorigin === null ||
        countryorigin === 0
          ? "bg-red-100"
          : "bg-green-100 "
      }`}
      placeholder={t("Select")}
      options={options}
      value={options?.find((option) => option?.value === countryorigin)}
      onChange={(selectedOption) => setCountryorigin(selectedOption?.value)}
      isClearable
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: `${
            countryorigin === "" ||
            countryorigin === undefined ||
            countryorigin === null ||
            countryorigin === 0
              ? "rgba(254, 226, 226, var(--tw-bg-opacity))"
              : "rgba(209, 250, 229, var(--tw-bg-opacity))"
          }`,
        }),
      }}
    />
  );
};

export default AutocompleteSelect;
