import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillEyeFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { url } from "../../../api/Api";
import fetchHandler from "../../../api/fetchHandler";
import { MainContent } from "../../../App";
import Spinner from "../../../components/Spinner";
import PeopleCard from "./PeopleCard";
import { ToastContainer } from "react-toastify";
import ActivityNoteMessage from "../../ActivityNoteMessage";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Viewers = () => {
  const {changenow, language } = useContext(MainContent);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [nexturl, setNexturl] = useState();
  const [response, setResponse] = useState(null);
  const [prvurl, setPrvurl] = useState();

  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getviewedmelist = (crl) => {
    fetchHandler(crl, "GET", undefined).then((result) => {
      setResponse(result);
      setData(result.data);
      saveProfileIds(result.data);
      setPrvurl(result.previouspage);
      setNexturl(result.nextpage);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getviewedmelist(`${url}profile/getviewedmelist`);
  }, [changenow, language]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Activity_Viewed_My_Profile")}
        language={language}
      />
      {response !== null ? (
        <>
          <div className=" w-full flex items-center justify-center ">
            <h1 className="text-center my-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Activity_Viewed_My_Profile")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <ToastContainer />
          {data.length !== 0 ? (
            <>
              {/* <div className="flex flex-wrap gap-2 justify-center items-center p-4"> */}
              <div className="my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto">
                {data.map((item, ind) => (
                  <PeopleCard
                    key={ind}
                    item={item}
                    cardProfileId={item.peoplecardprofileid}
                  >
                    <div className="flex items-center justify-evenly text-pr text-xl mt-4">
                      <Link to={"/profile/" + item.peoplecardprofileid}>
                        <BsFillEyeFill className="h-5 cursor-pointer w-5" />
                      </Link>
                    </div>
                  </PeopleCard>
                ))}
              </div>
              <div className="pb-20">
                <div
                  className={
                    language !== "ar"
                      ? "float-right flex items-center gap-4"
                      : "float-left flex items-center gap-4"
                  }
                >
                  {prvurl !== null || nexturl !== null ? (
                    <>
                      {prvurl !== null ? (
                        <button
                          onClick={() => getviewedmelist(prvurl)}
                          className="bg-sr text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                        >
                          {t("Previous")}
                        </button>
                      ) : (
                        <button
                          disabled
                          className="bg-sr text-xs px-3 cursor-not-allowed py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                        >
                          {t("Previous")}
                        </button>
                      )}
                      {nexturl !== null ? (
                        <button
                          onClick={() => getviewedmelist(nexturl)}
                          className="bg-sr text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                        >
                          {t("Next")}
                        </button>
                      ) : (
                        <button
                          disabled
                          className="bg-sr text-xs px-3 py-2 cursor-not-allowed rounded text-white transition ease-linear duration-150 hover:bg-pr"
                        >
                          {t("Next")}
                        </button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="bg-white rounded-xl shadow-md text-black flex py-5">
              {/* <h1 className=" ml-8"> {t("No_Data_Found")}</h1> */}
              <h1 className={language === "ar" ? "mr-8" : "ml-8"}>
                {" "}
                {t("ViewedMyProfile_NoData")}
              </h1>
            </div>
          )}
        </>
      ) : (
      <Spinner/>
      )}
      <ActivityNoteMessage
        Section_Title={t("Note")}
        paddingBotton={" pb-2"}
      ></ActivityNoteMessage>
    </>
  );
};

export default Viewers;
