import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { url, errorHandler } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionSeparator from "../SectionSeparator";
import FixedHeader from "../../layout/FixedHeader";
const Blog = () => {
  const { t } = useTranslation();
  const { siteid } = useParams();
  const { language, isAuthenticated } = useContext(MainContent);
  const [blog, setBlog] = useState({});
  const [otherBlogs, setOtherBlogs] = useState([]);
  const [change, setChange] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const myHeaders = new Headers({ ApiKey: process.env.REACT_APP_API_KEY });
      const requestOptions = { method: "GET", headers: myHeaders };
      const response = await fetch(
        `${url}sitepage/Published/sitepages?PageType=Blog&SiteId=1&LangCode=${language}`,
        requestOptions
      );
      errorHandler(response);
      const result = await response.json();
      if (result?.data?.length !== null) {
        const filteredData = result.data.filter(
          (item) => item.sitepageid === parseInt(siteid)
        );

        setBlog(filteredData[0]);
        setOtherBlogs(
          result.data.filter((item) => item.sitepageid !== parseInt(siteid))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setChange(false);
    fetchData();
  }, [language, change]);

  return (
    // <div
    //   className={`pt-1 container min-h-screen bg-blue-50 sm:mt-1 md:mt-1 lg:mt-24 xl:mt-24 2xl:mt-24 ${
    //     !isAuthenticated ? "mt-20" : ""
    //   }`}
    // >
    <div
      className={`pt-1 container min-h-screen bg-blue-50 ${
        !isAuthenticated ? "mt-20" : "lg:mt-24"
      }`}
    >
      {isAuthenticated && <ProfileNavbar />}
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Blogs")}
        language={language}
      />
      <div className=" w-full flex items-center mb-0 justify-center ">
        <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {blog?.title ? blog?.title : t("Blog_ID_not_found_Header")}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      {blog?.text ? (
        <div className="mt-2 lg:p-3 bg-white shadow-md p-3 text-gray-700 rounded-lg">
          {blog?.length !== 0 && (
            <div dangerouslySetInnerHTML={{ __html: blog?.text }}></div>
          )}
        </div>
      ) : (
        <div className="mt-2 lg:p-3 bg-white shadow-md p-3 text-gray-700 rounded-lg">
          <p>{t("Blog_ID_not_found_Message")}</p>
        </div>
      )}
      {otherBlogs?.length !== 0 && (
        <div>
          {/* <h1 className="text-xl font-medium pt-4 pb-2"> */}
          <SectionSeparator
            Section_Title={t("Related_Articles")}
            paddingBotton={"pb-2"}
          ></SectionSeparator>
          {/* </h1> */}
          {otherBlogs?.map((item, ind) => (
            <div key={ind} className="p-3 my-2 bg-white  shadow-md rounded-lg">
              <Link
                onClick={() => setChange(true)}
                to={"/blog/" + item.sitepageid}
                className="text-2xl"
              >
                {item.title}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;
