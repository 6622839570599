import Cookies from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import { url, errorHandler } from "../../api/Api";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { Link, useNavigate } from "react-router-dom";
import { MainContent } from "../../App";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";
import CountdownTimer from "../CountdownTimer";
import FixedHeader from "../../layout/FixedHeader";

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [hmtl, setHmtl] = useState(null);
  const { language, isAuthenticated, setisAuthenticated, setMyown_data } =
    useContext(MainContent);
  
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=25&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setHmtl(result.data.text));
  }, [language]);

  const removeTokensAndlogOut = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const raw = JSON.stringify({
      refreshToken: `${Cookies.get("refreshToken")}`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}identity/signout`, requestOptions);
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isAuthenticated");
    setisAuthenticated(false);
   // localStorage.setItem("isAuthenticated", false);
    history("/");
    Cookies.remove("location");
  };

  // useEffect(() => {
  //   let timeoutId;
  //   window.scrollTo(0, 0);
  //   if (isAuthenticated) {
  //     timeoutId = setTimeout(() => {
  //       removeTokensAndlogOut();
  //       setMyown_data(null);
  //     }, 10000);
  //   }
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  const handleTimeout = () => {
    // Handle the timeout event, e.g., log the user out
    if (isAuthenticated) {
      removeTokensAndlogOut();
      setMyown_data(null);
    }
  };

     useEffect(() => {
       window.scrollTo(0, 0);
     }, []);
  
  return (
    <div className="container mt-16 lg:pt-10 min-h-screen pb-5">
      <div className="container">
        <div className="mb-2 ">
          <ProfileNavbar />
        </div>
        <FixedHeader
          scrollPosition={scrollPosition}
          headerText={t("PaymentCompleted")}
          language={language}
        />
        <div className="bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium ">
          <div className="flex place-content-center mb-4 h3">
            <BsCheckCircleFill className="text-green-600 text-6xl" />
          </div>
          <div className="text-center">
            {hmtl !== null && (
              <div dangerouslySetInnerHTML={{ __html: hmtl }}></div>
            )}
          </div>
          <div>
            <CountdownTimer timeInSeconds={15} onTimeout={handleTimeout} />
          </div>
          <div className="text-center  mt-4">
            <Link
              to="#"
              onClick={handleTimeout}
              className="px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white"
            >
              {t("Back_To_Home")}
            </Link>
            {/* <Link
              to="/"
              className="px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white"
            >
              {t("Back_To_Home")}
            </Link> */}
          </div>
        </div>
        {/* <div className="text-center  mt-4">
          <Link
            to="/"
            className="px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white"
          >
            {t("Back_To_Home")}
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentSuccess;
