import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";
import { FormatNewLanguage } from "../../../../utils/helpers";

function SpokenLanguages({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload, language } =
    useContext(MainContent);
  const { spokenlanguages } = myown_data?.data || {};
  const profileID = myown_data?.data?.profileid;
  const [selectlanguage, setSelectlanguage] = useState(spokenlanguages || []);

  const handleSaveLanguage = () => {
    // if (selectlanguage.length !== 0) {
    // const ln = selectlanguage.map((item) =>
    //   FormatNewLanguage(profileID, item.translistid)
    // );

    const body = JSON.stringify(selectlanguage);

    HANDLE_API_REQUEST.POST(endpoints.SAVE_LANGUAGE, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      })
      .catch((error) => console.log("error", error));
    //}
    // else {
    //   toast.error(t("Toast_Please_Select_A_Language"), {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };

  let filter3 = [];

  if (selectlanguage?.length !== 0 && profileData !== null) {
    selectlanguage.forEach((item) => {
      let newarr = profileData?.language?.filter(
        (ind) => ind.translistid === item.languageid
      );
      filter3 = [...filter3, newarr[0]];
    });
  }

  const saveLanguages = (arr) => {
    let newarr = [];
    arr.map((item) => newarr.push({ languageid: item.translistid }));
    setSelectlanguage(newarr);
  };

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="5">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center  w-full">
          {t("Your_Languages")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        {filter3.length !== 0 ? (
          <Multiselect
            className={`form-select form-select-md mb-3 mt-2 py-2 ${
              language === "ar" ? "text-right " : ""
            }${filter3.length === 0 ? "bg-red-100" : "bg-green-100 "}`}
            required
            options={profileData.language}
            selectionLimit="5"
            selectedValues={filter3}
            displayValue="name"
            placeholder={t("Select")}
            onSelect={(e) => saveLanguages(e)}
            onRemove={(e) => saveLanguages(e)}
          />
        ) : (
          <Multiselect
            className={`form-select form-select-md mb-3 mt-2 py-2 ${
              language === "ar" ? "text-right " : ""
            }${selectlanguage.length === 0 ? "bg-red-100" : "bg-green-100 "}`}
            required
            options={profileData.language}
            selectionLimit="5"
            displayValue="name"
            placeholder={t("Select")}
            onSelect={(e) => saveLanguages(e)}
            onRemove={(e) => saveLanguages(e)}
          />
        )}
        <div className="w-full items-end flex justify-end">
          <button
            onClick={handleSaveLanguage}
            type="submit"
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default SpokenLanguages;
