import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";

const SystemLogs = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [langcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);

  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [lettertype, setLettertype] = useState([]);
  const [message, setMessage] = useState("");
  const [messagePropmt, setMessagePropmt] = useState("");
  const [exception, setException] = useState("");
  const [properties, setProperties] = useState("");
  const [letterid] = useState("1");

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };

  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=LogLevels`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setLettertype(result.loglevels));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    getlettertype();
    getAllTemplates(`${url}log/all`);
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      sitesettingsid: data.sitesettingsid,
      defaultlangcode: langcode,
      defaultcurrencytypeid: letterid,
      isenabled: data.isenabled,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}sitesetting/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      });
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    
    let raw = JSON.stringify({
      id: id,
    });

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}log/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        showToast(result);
      });
  };
  const alldelete = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${url}log/delete/all`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      });
  };

  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${url}log?Id=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setMessage(result.data.message);
        setException(result.data.exception);
        setProperties(result.data.properties);
        setMessagePropmt(result.data.messagetemplate);
      });
  };

  return (
    <div className="px-4">
      <ToastContainer />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          System Logs
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="System Logs">
          <div className="mb-2">
            <Form.Select
              size="sm"
              onChange={(e) =>
                e.target.value !== "" &&
                getAllTemplates(
                  `${url}log/level?LogLevelOptions=${e.target.value}`
                )
              }
            >
              <option value="">Select LogLevelOptions </option>
              {lettertype.length !== 0 && (
                <>
                  {lettertype.map((item, ind) => (
                    <option key={ind} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </>
              )}
            </Form.Select>
          </div>
          <>
            {allwaitinglist.length !== 0 && lettertype.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>TimeStamp</th>
                      <th>Message</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.timestamp}</td>
                        <td>{item.message.slice(0, 50)}...</td>
                        <td className="flex items-center justify-center gap-1">
                          <AiOutlineDelete
                            onClick={() => deleteTemplate(item.id)}
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.id);
                            }}
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-white w-full bg-sr h-24 flex items-center justify-center text-xl font-medium">
                No Search Result
              </div>
            )}
            {allwaitinglist.length !== 0 && (
              <div className=" w-full justify-between flex items-center gap-4 mb-3">
                <div>
                  <button
                    onClick={alldelete}
                    className="bg-sr hover:bg-pr text-white px-3 py-2 rounded-md cursor-pointer"
                  >
                    Delete All
                  </button>
                </div>
                <div className="flex items-center gap-2">
                  {prvurl === null ? (
                    <button
                      disabled
                      className="bg-sr text-xs cursor-not-allowed flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                    >
                      <AiOutlineLeft />
                      Previous
                    </button>
                  ) : (
                    <button
                      onClick={() => getAllTemplates(prvurl)}
                      className="bg-sr text-xs flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                    >
                      <AiOutlineLeft />
                      Previous
                    </button>
                  )}
                  {allwaitinglist.length !== 12 ? (
                    <button
                      disabled
                      className="bg-sr text-xs cursor-not-allowed  flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                    >
                      Next <AiOutlineRight />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        getAllTemplates(nxturl);
                      }}
                      className="bg-sr text-xs   flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                    >
                      Next <AiOutlineRight />
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-sr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className="grid w-full grid-cols-1">
                  <h2 className="mt-3 mb-1">Message</h2>
                  <div className="w-full h-36 p-2 bg-gray-200 text-gray-500 text-sm overflow-y-scroll">
                    {message}
                  </div>
                  <h2 className="mt-3 mb-1">Message Prompt</h2>
                  <div className="w-full h-36 p-2 bg-gray-200 text-gray-500 text-sm overflow-y-scroll">
                    {messagePropmt}
                  </div>
                  <h2 className="mt-3 mb-1">Properties</h2>
                  <div className="w-full h-36 p-2 bg-gray-200 text-gray-500 text-sm overflow-y-scroll">
                    {properties}
                  </div>
                  <h2 className="mt-3 mb-1">Exception</h2>
                  <div className="w-full h-44 p-2 bg-yellow-100 text-gray-500 text-sm overflow-y-scroll">
                    {exception}
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default SystemLogs;
