import { errorHandler } from "./Api";
export const searchInboxRequest = async (
  searchInboxValue,
  state,
  accessToken
) => {
  const res = await fetch(
    `${process.env.REACT_APP_URI}inbox/search?SearchText=${searchInboxValue}&status=${state}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    }
  );
  errorHandler(res);
  const data = await res.json();
  return data;
};
