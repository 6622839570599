import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";
import EditItem from "../EditItem";

function Persolnality({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload } = useContext(MainContent);
  const { personalitydescription } = myown_data?.data || {};
  const profileID = myown_data?.data?.profileid;

  const [romance, setRomance] = useState(personalitydescription?.romancetypeid);
  const [characteristic, setcharacteristic] = useState(personalitydescription?.characteristicsid);

  const handleSavePersonality = () => {
    const body = JSON.stringify({
      personalityDescriptionId: profileID,
      romanceTypeId: romance,
      characteristicsId: characteristic,
    });

    HANDLE_API_REQUEST.POST(endpoints.SAVE_PERSONALITY, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      });
  };

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="4">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center  w-full">
          {t("Your_Personality")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        <EditItem
          value={romance}
          title={t("Romance_Type")}
          data={profileData.romancetype}
          handleSelect={(value) => setRomance(value)}
        />
        <EditItem
          title={t("Characteristics")}
          data={profileData?.characteristics}
          handleSelect={(value) => setcharacteristic(value)}
          value={characteristic}
        />
        <div className="w-full items-end flex justify-end">
          <button
            type="submit"
            onClick={handleSavePersonality}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default Persolnality;
