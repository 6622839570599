import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const TravelingSelect = ({ formdata, setTraveling, traveling }) => {
  const { t } = useTranslation();

  return (
    <Select
      placeholder={t("Select")}
      options={formdata?.travlingattitude.map((item, ind) => ({
        value: item.translistid,
        label: item.name,
        key: ind,
      }))}
      onChange={(selectedOption) => {
        setTraveling([
          {
            travlingattitudeid: selectedOption?.value,
          },
        ]);
      }}
      onSelect={(e) => setTraveling(e)}
      onRemove={(e) => setTraveling(e)}
      isClearable
      //className="px-2 py-2 border w-full"
      className={`px-2 py-2 border w-full ${
        traveling && traveling.length ? "bg-green-100" : "bg-red-100"
      }`}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor:
            traveling && traveling.length
              ? "rgba(209, 250, 229, var(--tw-bg-opacity))"
              : "rgba(254, 226, 226, var(--tw-bg-opacity))",
        }),
      }}
    />
  );
};

export default TravelingSelect;
