import { useState, useEffect } from "react";
import { errorHandler } from "../api/Api";
import { HANDLE_API_REQUEST } from "../api/EndPoints";

function useFetch(endpoint, language, config = null) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!endpoint) return;
    setLoading(true);
    setData(null);
    setError(null);

    const controller = new AbortController();
    const signal = controller.signal;

    HANDLE_API_REQUEST.GET(endpoint, language, config, signal)
      .then((res) => {
        errorHandler(res);
        return res.json();
      })
      .then((data) => {
        setLoading(false);
        data && setData(data);
      })
      .catch((err) => {
        setLoading(false);
        setError("An error occurred...");
      });

    return () => {
      controller.abort();
    };

  }, [endpoint, language, config]);

  return { data, loading, error };
}

export default useFetch;
