import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const SuccesStories = () => {
  const { t } = useTranslation();
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [lettertype, setLettertype] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchtext, setSearchtext] = useState("");
  const [show, setShow] = useState(false);

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };
  const getSearchStories = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchList(result.data);
        } else {
          setSearchList([]);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };

  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=PageType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setLettertype(result.pagetype));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    getlettertype();
    getAllTemplates(`${url}SuccessStory/approved/waiting?LangCode=en`);
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0].message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      successstoriesid: id,
    });

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}SuccessStory/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        showToast(result);
      });
  };
  const publish = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      successstoriesid: data.successstoriesid,
    });

    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}SuccessStory/approve`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}SuccessStory?SuccessStoriesId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setTitle(result.data.title);
        setBody(result.data.text);
      });
  };

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Success Stories
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="Waiting for approval">
          <>
            {allwaitinglist.length !== 0 && lettertype.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Created Date</th>
                      <th>LangCode</th>
                      <th>Title</th>
                      <th>Approved</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.createddate.slice(0, 10)}</td>
                        <td>{item.langcode}</td>

                        <td>{item.title}</td>

                        <td>{item.approved ? "Yes" : "No"}</td>

                        <td className="flex items-center justify-center gap-2">
                          <AiOutlineDelete
                            onClick={() =>
                              deleteTemplate(item.successstoriesid)
                            }
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.successstoriesid);
                            }}
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-white w-full bg-sr h-24 flex items-center justify-center text-xl font-medium">
                {t("No Search Result")}
              </div>
            )}
            {allwaitinglist.length !== 0 && (
              <div className="float-right flex items-center gap-4 mb-3">
                {prvurl === null ? (
                  <button
                    disabled
                    className="bg-pr cursor-not-allowed flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    <AiOutlineLeft />
                    Previous
                  </button>
                ) : (
                  <button
                    onClick={() => getAllTemplates(prvurl)}
                    className="bg-pr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    <AiOutlineLeft />
                    Previous
                  </button>
                )}
                {allwaitinglist.length !== 10 ? (
                  <button
                    disabled
                    className="bg-pr cursor-not-allowed  flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    Next <AiOutlineRight />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      getAllTemplates(nxturl);
                    }}
                    className="bg-pr   flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    Next <AiOutlineRight />
                  </button>
                )}
              </div>
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-sr cursor-pointer"
                />
              </div>
              <Form>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control value={title} disabled />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Content</Form.Label>
                  <Form.Control value={body} as="textarea" disabled rows={6} />
                </Form.Group>

                <div className="flex w-full items-center justify-end gap-2">
                  <Button
                    onClick={() => setEdit(false)}
                    variant="primary"
                    className="mt-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setEdit(false);
                      publish();
                    }}
                    variant="primary"
                    className="mt-2"
                  >
                    Publish
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="Success Stories">
          <div className="grid w-full grid-cols-4 gap-2 mb-2">
            <Form.Select
              size="sm"
              onChange={(e) => setLangcode(e.target.value)}
            >
              <option value="en">English </option>
              <option value="de">German </option>
              <option value="fr">French</option>
              <option value="it">Italien</option>
              <option value="no">Norwegian</option>
              <option value="ar">عربي</option>
              <option value="sv">Swedish</option>
              <option value="da">Danish</option>
              <option value="tr">Turkish</option>
              <option value="nl">Dutch</option>
              <option value="es">Spanish</option>
            </Form.Select>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                id="date"
                label="Start Date"
                type="date"
                onChange={(e) =>
                  setStartDate(
                    e.target.value === ""
                      ? ""
                      : `&StartDate=${e.target.value.slice(0, 10)}`
                  )
                }
                onReset={() => setStartDate("")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TextField
                id="date"
                label="End Date"
                type="date"
                onChange={(e) =>
                  setEndDate(
                    e.target.value === ""
                      ? ""
                      : `&EndDate=${e.target.value.slice(0, 10)}`
                  )
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
            <div className="flex grid-cols-3 gap-1">
              <Form.Control
                className="col-span-2"
                onChange={(e) =>
                  setSearchtext(
                    e.target.value === "" ? "" : `&SearchText=${e.target.value}`
                  )
                }
              />

              <button
                onClick={() =>
                  getSearchStories(
                    `${url}SuccessStory/search?LangCode=${langcode}${searchtext}${startDate}${endDate}`
                  )
                }
                className="bg-pr hover:bg-sr rounded-sm text-white px-3"
              >
                <AiOutlineSearch />
              </button>
            </div>
          </div>
          <>
            {searchList.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Created Date</th>
                      <th>LangCode</th>
                      <th>Title</th>
                      <th>Approved</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchList.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.createddate.slice(0, 10)}</td>
                        <td>{item.langcode}</td>

                        <td>{item.title}</td>

                        <td>{item.approved ? "Yes" : "No"}</td>

                        <td className="flex items-center justify-center gap-2">
                          <AiOutlineDelete
                            onClick={() =>
                              deleteTemplate(item.successstoriesid)
                            }
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                          <RiEditBoxLine
                            onClick={() => {
                              setShow(true);
                              readTemplate(item.successstoriesid);
                            }}
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-white w-full bg-sr h-24 flex items-center justify-center text-xl font-medium">
                No Search Result
              </div>
            )}
            {searchList.length !== 0 && (
              <div className="float-right flex items-center gap-4 mb-3">
                {prvurl === null ? (
                  <button
                    disabled
                    className="bg-pr cursor-not-allowed flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    <AiOutlineLeft />
                    Previous
                  </button>
                ) : (
                  <button
                    onClick={() => getAllTemplates(prvurl)}
                    className="bg-pr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    <AiOutlineLeft />
                    Previous
                  </button>
                )}
                {allwaitinglist.length !== 10 ? (
                  <button
                    disabled
                    className="bg-pr cursor-not-allowed  flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    Next <AiOutlineRight />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      getAllTemplates(nxturl);
                    }}
                    className="bg-pr   flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    Next <AiOutlineRight />
                  </button>
                )}
              </div>
            )}
          </>
          {show && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-sr cursor-pointer"
                />
              </div>
              <Form>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control value={title} disabled />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Content</Form.Label>
                  <Form.Control value={body} as="textarea" disabled rows={6} />
                </Form.Group>

                <div className="flex w-full items-center justify-end gap-2">
                  <Button
                    onClick={() => setEdit(false)}
                    variant="primary"
                    className="mt-2"
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default SuccesStories;
