import Select from "react-select";
import { useTranslation } from "react-i18next";

const AutocompleteSelectBasic = ({
  countryorigin,
  setCountryorigin,
  formdata,
}) => {
  const { t } = useTranslation();

  const options = formdata?.country?.map((item, ind) => ({
    value: item.translistid,
    label: item.name,
    key: ind,
  }));

  return (
    <Select
      className="mb-3 font-medium text-sr text-sm "
      placeholder={t("Select")}
      options={options}
      value={options?.find((option) => option?.value === countryorigin)}
      onChange={(selectedOption) => setCountryorigin(selectedOption?.value)}
      isClearable
      styles={{
        control: (provided) => ({
          ...provided,
        }),
      }}
    />
  );
};

export default AutocompleteSelectBasic;
