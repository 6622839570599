import Cookies from "js-cookie";
import React, { useState, useEffect, useContext } from "react";
import { url, errorHandler } from "../../../api/Api";
import { MainContent } from "../../../App";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import Spinner from "../../../components/Spinner";
import PeopleCard from "./../peopleActivity/PeopleCard";
import { ToastContainer } from "react-toastify";
import ActivityNoteMessage from "../../ActivityNoteMessage";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Blocklist = () => {
  const [response, setResponse] = useState(null);
  const { t } = useTranslation();
  const { changenow, language } = useContext(MainContent);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [nexturl, setNexturl] = useState();
  const [prvurl, setPrvurl] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getMyBloackedProfiles = (uri) => {
    let getheders = new Headers();
    getheders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    getheders.append("Accept-Language", language || "en");

    const getRequestOptions = {
      method: "GET",
      headers: getheders,
      redirect: "follow",
    };
    fetch(uri, getRequestOptions)
      .then((response) => {
        errorHandler(response);
        setResponse(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        saveProfileIds(result.data);
        setPrvurl(result.previouspage);
        setNexturl(result.nextpage);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getMyBloackedProfiles(`${url}profile/getblocked`);
    setReload(false);
  }, [changenow, reload, language]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Activity_Blocked_Profiles_List")}
        language={language}
      />
      <ToastContainer />
      {response !== null ? (
        <div>
          <div className=" w-full flex items-center justify-center ">
            <h1 className="text-center my-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Activity_Blocked_Profiles_List")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <>
            {data.length > 0 ? (
              <>
                {/* <div className="flex flex-wrap gap-2 justify-center items-center p-4"> */}
                <div className="my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto">
                  {data.map((item, ind) => (
                    <PeopleCard
                      key={ind}
                      item={item}
                      cardProfileId={item.profileid}
                      isBlocked={true}
                      setReload={setReload}
                      displayusername={item.displayusername}
                    ></PeopleCard>
                  ))}
                </div>
                <div className="pb-20">
                  <div
                    className={
                      language !== "ar"
                        ? "float-right flex items-center gap-4"
                        : "float-left flex items-center gap-4"
                    }
                  >
                    {prvurl !== null || nexturl !== null ? (
                      <>
                        {prvurl !== null ? (
                          <button
                            onClick={() => getMyBloackedProfiles(prvurl)}
                            className="bg-sr text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Previous")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="bg-sr text-xs px-3 py-2 cursor-not-allowed rounded text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Previous")}
                          </button>
                        )}
                        {nexturl !== null ? (
                          <button
                            onClick={() => getMyBloackedProfiles(nexturl)}
                            className="bg-sr text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="bg-sr text-xs px-3 py-2 rounded text-white cursor-not-allowed transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-white rounded-xl shadow-md text-black flex py-5">
                {/* <h1 className="ml-8"> {t("No_Data_Found")}</h1> */}
                <h1 className={language === "ar" ? "mr-8" : "ml-8"}>
                  {" "}
                  {t("Blocked_Profiles_NoData")}
                </h1>
              </div>
            )}
          </>
        </div>
      ) : (
        <Spinner/>
      )}
      <ActivityNoteMessage
        Section_Title={t("Note")}
        paddingBotton={" pb-2"}
      ></ActivityNoteMessage>
    </>
  );
};

export default Blocklist;
