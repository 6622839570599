import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { errorHandler, url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  AiOutlineDelete,
  AiFillFolder,
  AiOutlineClose,
  AiFillCloseCircle,
  AiOutlineRight,
  AiFillFolderOpen,
  AiOutlineLeft,
} from "react-icons/ai";
import { FiArchive } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import { ImBoxRemove } from "react-icons/im";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { BsBlockquoteRight, BsBlockquoteLeft, BsCheckLg } from "react-icons/bs";
import fetchHandler from "../../../../api/fetchHandler";
import PaginationButton from "../Helpers/PaginationButton";
import { Link } from "react-router-dom";

const ContactAdmin = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [langcode, setLangcode] = useState("");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [read, setRead] = useState(false);
  const [text, setText] = useState("");
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [undread, setUndread] = useState([]);
  const [message, setMessage] = useState("");
  const [hasBeenRepliedTo, setHasBeenRepliedTo] = useState(false);
  const [archivedme, setArchivedme] = useState([]);
  const [reload, setReload] = useState(false);
  const [userData, setUserData] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [getSelected, setGetSelected] = useState("unread");
  const [content, setContent] = useState("");

  const getAllCorrespondences = (url) => {
    fetchHandler(url, "GET").then((result) => {
      if (result.data) {
        setAllwaitinglist(result.data);
      }
    });
  };
  const getUnreadContact = (url) => {
    fetchHandler(url, "GET").then((result) => {
      setUndread(result.data);
      setNxturl(result.nextpage);
      setPrvurl(result.previouspage);
    });
  };

  useEffect(() => {
    setLoad(false);
    getUnreadContact(`${url}Correspondence/${getSelected}`);
    getAllCorrespondences(`${url}CorrespondenceTemplate/all?LangCode=en`);
  }, [changnow, load]);

  const getarchived = (url) => {
    fetchHandler(url, "GET").then((result) => {
      setEdit(false);
      if (result.data) {
        setArchivedme(result.data);
      } else {
        setArchivedme([]);
        showToast(result);
      }
      setNxturl(result.nextpage);
      setPrvurl(result.previouspage);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setReload(false);
    getarchived(`${url}Correspondence/archivedlist`);
  }, [reload]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0].message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Content-Type", "application/json");

  const deleteCorrespondence = (id) => {
    let raw = JSON.stringify({
      correspondenceId: id,
    });
    fetchHandler(`${url}Correspondence/delete`, "DELETE", raw, myHeaders).then(
      (result) => {
        setEdit(false);
        showToast(result);
      }
    );
  };
  const sendArchive = (id) => {
    let raw = JSON.stringify({
      correspondenceId: id,
    });
    fetchHandler(`${url}Correspondence/archive`, "PATCH", raw, myHeaders).then(
      (result) => {
        setReload(true);
        showToast(result);
      }
    );
  };
  const removearchive = (id) => {
    let raw = JSON.stringify({
      correspondenceId: id,
    });
    fetchHandler(
      `${url}Correspondence/removefromarchive`,
      "PATCH",
      raw,
      myHeaders
    ).then(() => {
      setReload(true);
    });
  };
  const Markreply = (id) => {
    let raw = JSON.stringify({
      correspondenceId: id,
    });
    fetchHandler(
      `${url}Correspondence/repliedto`,
      "PATCH",
      raw,
      myHeaders
    ).then((result) => {
      showToast(result);
    });
  };
  const MarkRead = (id) => {
    let raw = JSON.stringify({
      correspondenceId: id,
    });

    fetchHandler(
      `${url}Correspondence/markasread`,
      "PATCH",
      raw,
      myHeaders
    ).then((result) => {
      showToast(result);
    });
  };

  const readCorrespondence = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}CorrespondenceTemplate?CorrespondenceTemplateId=${id}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setLangcode(result.data.langcode);
        setTitle(result.data.title);
        setContent(` 
        ${result.data.header} 
        ${result.data.body} 
        ${result.data.tail}`);
      });
  };
  const readContactMessage = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}Correspondence?CorrespondenceId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setRead(true);
        setUserData(result.data);
        setMessage(result.data.text);
        setHasBeenRepliedTo(result.data.isrepliedto);
      });
  };

  const reply = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      correspondenceId: userData.correspondenceid,
      title: title,
      text: content,
      correspondenceTypeId: userData.correspondencetypeid,
      deviceId: userData.devicetypeid,
      langCode: userData.langcode || langcode,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}Correspondence/reply`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        if (result.success) {
          setTitle("");
          setContent("");
        }
        showToast(result);
      });
  };

  const handleStartDateChange = (selectedStartDate) => {
    if (selectedStartDate) {
      let formattedDate = selectedStartDate.toISOString().split("T")[0];
      setStartDate("&startdate=" + formattedDate);
    }
  };

  const handleEndDateChange = (selectedEndDate) => {
    if (selectedEndDate) {
      let formattedDate = selectedEndDate.toISOString().split("T")[0];
      setEndDate("&enddate=" + formattedDate);
    }
  };

  return (
    <div className="px-4 ">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Contact
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="All">
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <span className="font-extrabold">Filter By</span>
              </FormLabel>
              <RadioGroup
                onChange={(e) => {
                  getUnreadContact(`${url}Correspondence/${e.target.value}`);
                  setGetSelected(e.target.value);
                }}
                defaultValue="unread"
                row
                aria-label="gender"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="unread"
                  control={<Radio />}
                  label="Unread"
                />
                <FormControlLabel
                  value="notrepliedto"
                  control={<Radio />}
                  label="No Reply"
                />
                <FormControlLabel value="all" control={<Radio />} label="All" />
              </RadioGroup>
            </FormControl>
          </div>
          <>
            {undread.length > 0 ? (
              <Table bordered striped hover responsive="md">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>ProfileId/Email</th>
                    <th>C-Type</th>
                    <th>D-Type</th>
                    <th>IsRead</th>
                    <th>IsRepliedTo</th>
                    <th>LangCode</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {undread.map((item, ind) => (
                      <tr key={item.correspondenceid}>
                        <td>{item.createddate.slice(0, 10)}</td>
                        <td>{item.title}</td>
                        <td>{item.profileid ? item.profileid : item.email}</td>
                        <td> {item.correspondencetypename}</td>
                        <td> {item.devicetypename}</td>
                        <td>
                          <span
                            className={
                              item.isread ? "text-green-500" : "text-red-500"
                            }
                          >
                            {item.isread ? "YES" : "NO"}
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              item.isrepliedto
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          >
                            {item.isrepliedto ? "YES" : "NO"}
                          </span>
                        </td>
                        <td> {item.langcode}</td>
                        <td className="flex items-center justify-center gap-2">
                          {!item.isread ? (
                            <AiFillFolder
                              onClick={() => MarkRead(item.correspondenceid)}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          ) : (
                            <AiFillFolderOpen
                              className="h-6 w-6 text-pr cursor-pointer"
                              title="Ticked open"
                            />
                          )}
                          {!item.isrepliedto ? (
                            <BsCheckLg
                              onClick={() => Markreply(item.correspondenceid)}
                              className="h-6 w-6 text-pr cursor-pointer"
                              title="Mark as reply to"
                            />
                          ) : (
                            <AiOutlineClose
                              className="h-6 w-6 text-pr cursor-pointer"
                              title="Ticket closed"
                            />
                          )}
                          <FiArchive
                            onClick={() => sendArchive(item.correspondenceid)}
                            className="h-6 w-6 text-pr cursor-pointer"
                            title="Move to archive"
                          />
                          <FaRegEye
                            onClick={() => {
                              setEdit(true);
                              readContactMessage(item.correspondenceid);
                            }}
                            title="View and answer"
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                          <AiOutlineDelete
                            onClick={() =>
                              deleteCorrespondence(item.correspondenceid)
                            }
                            className="h-6 w-6 text-pr cursor-pointer"
                            title="Delete"
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </Table>
            ) : (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                No unread contact message
              </div>
            )}
            {undread?.length > 0 && (prvurl !== null || nxturl !== null) && (
              <div className="float-right flex items-center gap-4 mb-3">
                <PaginationButton
                  onClick={() => getUnreadContact(prvurl)}
                  disabled={prvurl === null}
                  text="Previous"
                  icon={<AiOutlineLeft />}
                />
                <PaginationButton
                  onClick={() => getUnreadContact(nxturl)}
                  disabled={nxturl === null}
                  text="Next"
                  icon={<AiOutlineRight />}
                />
              </div>
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <div
                className={
                  hasBeenRepliedTo
                    ? " text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md"
                    : ""
                }
              >
                <span
                  className={
                    hasBeenRepliedTo
                      ? "text-green-500 text-2xl font-extrabold"
                      : ""
                  }
                >
                  {hasBeenRepliedTo
                    ? "FYI: You have already responded to this correspondence"
                    : ""}
                </span>
              </div>
              <Form.Group className="my-3" controlId="formGridState">
                <Form.Control value={message} disabled as="textarea" rows={7} />
              </Form.Group>
              <div className="flex items-center gap-3 w-1/2 mb-3">
                <Form.Select
                  size="sm"
                  value={langcode}
                  onChange={(e) => {
                    getAllCorrespondences(
                      `${url}CorrespondenceTemplate/all?LangCode=${e.target.value}`
                    );
                    setLangcode(e.target.value);
                  }}
                >
                  <option value="en">English </option>
                  <option value="de">German </option>
                  <option value="fr">French</option>
                  <option value="it">Italien</option>
                  <option value="no">Norwegian</option>
                  <option value="ar">عربي</option>
                  <option value="sv">Swedish</option>
                  <option value="da">Danish</option>
                  <option value="tr">Turkish</option>
                  <option value="nl">Dutch</option>
                  <option value="es">Spanish</option>
                </Form.Select>
                <Form.Control
                  placeholder="Search Here "
                  onChange={(e) => setText(e.target.value)}
                />
                {text !== null ? (
                  <button
                    onClick={() =>
                      getAllCorrespondences(
                        `${url}CorrespondenceTemplate/all?LangCode=${langcode}&FreeText=${text}`
                      )
                    }
                    className="px-3 py-1 rounded-md bg-sr hover:bg-pr text-white"
                    type="submit"
                  >
                    Search
                  </button>
                ) : (
                  <button
                    className="px-3 py-1 rounded-md bg-gray-500 text-white "
                    type="submit"
                  >
                    Search
                  </button>
                )}
              </div>
              <div className="my-2 bg-white rounded-md w-full divide-y border p-3 h-52 overflow-y-scroll">
                {allwaitinglist.length !== 0 && (
                  <>
                    {allwaitinglist.map((item, ind) => (
                      <div
                        className="w-full flex items-center border-b  text-gray-900 px-4 rounded-md justify-between py-2 "
                        key={item.correspondencetemplateid}
                      >
                        {/* <div>{item.title}</div> */}
                        <Link
                          onClick={() => {
                            setRead(true);
                            readCorrespondence(item.correspondencetemplateid);
                          }}
                          className=" w-full font-bold px-4 py-2 rounded-md text-gray-700 hover:text-green-700 hover:font-bold "
                        >
                          {item.title}
                        </Link>

                        <div className="flex items-center justify-center gap-2">
                          <FaRegEye
                            onClick={() => {
                              setRead(true);
                              readCorrespondence(item.correspondencetemplateid);
                            }}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {read && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    reply();
                    window.scrollTo(0, 0);
                  }}
                >
                  <Form.Group className="my-3" controlId="formGridAddress2">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      autoFocus
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <div className="h-32 bg-gray-200 text-gray-600 overflow-y-scroll my-2 w-full p-2 flex flex-col">
                    <textarea
                      value={content}
                      required
                      onChange={(e) => setContent(e.target.value)}
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                  <div className="w-full flex items-center justify-end">
                    <button
                      type="submit"
                      className="px-3 py-2 rounded-md bg-sr hover:bg-pr text-white"
                    >
                      Send
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}
        </Tab>
        <Tab eventKey="achived" title="Archived">
          <>
            <form
              className="flex items-center gap-3 w-full mb-3 bg-white shadow-md rounded-md p-3"
              onSubmit={(e) => {
                e.preventDefault();
                getarchived(
                  `${url}Correspondence/search?${text}${startDate}${endDate}`
                );
              }}
            >
              <Form.Control
                required
                autoFocus
                placeholder="Search Here"
                className="w-52"
                onChange={(e) =>
                  setText(
                    e.target.value === "" ? "" : `FreeText=${e.target.value}`
                  )
                }
              />
              <div className="mt-3 flex items-center gap-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    defaultValue={dayjs(startDate)}
                    id="startdate"
                    label="Start Date"
                    selected={startDate}
                    // minDate={dayjs()}
                    onChange={handleStartDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    slotProps={{
                      textField: {
                        placeholder: "Start Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                  />

                  <DateTimePicker
                    label="End Date"
                    defaultValue={dayjs(endDate)}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    //  minDate={dayjs()}
                    slotProps={{
                      textField: {
                        placeholder: "End Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="flex gap-2">
                <button
                  type="submit"
                  className="px-3 py-1 rounded-md bg-sr hover:bg-pr text-white"
                >
                  Search
                </button>
              </div>
            </form>

            {archivedme.length > 0 ? (
              <Table bordered striped hover responsive="md">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>ProfileId/Email</th>
                    <th>C-Type</th>
                    <th>D-Type</th>
                    <th>IsRead</th>
                    <th>IsRepliedTo</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {archivedme.map((item, ind) => (
                    <tr key={item.correspondenceid}>
                      <td>{item.createddate.slice(0, 10)}</td>
                      <td>{item.title}</td>
                      <td>{item.profileid ? item.profileid : item.email}</td>
                      <td> {item.correspondencetypename}</td>
                      <td> {item.devicetypename}</td>
                      <td>
                        <span
                          className={
                            item.isread ? "text-green-500" : "text-red-500"
                          }
                        >
                          {item.isread ? "YES" : "NO"}
                        </span>
                      </td>
                      <td>
                        <span
                          className={
                            item.isrepliedto ? "text-green-500" : "text-red-500"
                          }
                        >
                          {item.isrepliedto ? "YES" : "NO"}
                        </span>
                      </td>
                      <td className="flex items-center justify-center gap-2">
                        <ImBoxRemove
                          onClick={() => removearchive(item.correspondenceid)}
                          className="h-6 w-6 text-pr cursor-pointer"
                          title="Remove from archive"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                No archived contacts found
              </div>
            )}

            {archivedme?.length > 0 && (prvurl !== null || nxturl !== null) && (
              <div className="float-right flex items-center gap-4 mb-3">
                <PaginationButton
                  onClick={() => getarchived(prvurl)}
                  disabled={prvurl === null}
                  text="Previous"
                  icon={<AiOutlineLeft />}
                />
                <PaginationButton
                  onClick={() => getarchived(nxturl)}
                  disabled={nxturl === null}
                  text="Next"
                  icon={<AiOutlineRight />}
                />
              </div>
            )}
          </>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContactAdmin;
