import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { MainContent } from "../../../App";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { checkPasswordStrength } from "../../../utils/checkPasswordStrength";
import { customToasterStyle } from "../../../utils/constants";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FixedHeader from "../../../layout/FixedHeader";

const PasswordChange = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [currentpass, setCurrentpass] = useState("");
  const { language, changnow } = useContext(MainContent);
  const [newpass, setNewpass] = useState("");
  const [confirmPassword, setConfirmPassword] = useState({
    confirmNewPassword: "",
  });
  const [isValid, setValid] = useState(false);
  const [error, setError] = useState({ confirmNewPassword: "" });
  const [passwordTextPage, setPasswordTextPage] = useState(null);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const showToast = (result) => {
    if (result.success) {
      history("/change-password-receipt");
      setCurrentpass("");
      setNewpass("");
    } else {
      toast.error(result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };
  const changePass = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      currentpassword: currentpass,
      newpassword: newpass,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}identity/changepassword`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };

  const getContactReceipt = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=48&LangCode=${language}`,

      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })

      .then((result) => setPasswordTextPage(result.data));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getContactReceipt();
  }, [language, changnow]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("focus").focus();
    }, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const validate = () => {
    return (
      newpass.length & confirmPassword.confirmNewPassword.length &&
      newpass === confirmPassword.confirmNewPassword
    );
  };

  useEffect(() => {
    const isValid = validate();
    setValid(isValid);
  }, [newpass, confirmPassword.confirmNewPassword]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setConfirmPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      if (!value) {
        stateObj[name] = "Enter_Confirm_Password";
      } else if (newpass && value !== newpass) {
        stateObj[name] = "Confirm_Password_Does_Not_Match";
      }

      return stateObj;
    });
  };

  const handlePassword = (event) => {
    const strength = checkPasswordStrength(event);
    setPassword(event);
    setMessage(strength);

    let verifiedCount = 0;
    if (strength === "Strong") {
      verifiedCount = 5;
    } else if (strength === "Medium") {
      verifiedCount = 2;
    }
    setProgress(`${(verifiedCount / 5) * 100}%`);
  };

  const strength = checkPasswordStrength(password);

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "#FF0054";
  };



    const handleMouseDown = (setShow) => (event) => {
      event.preventDefault();
      setShow(true);
    };

    const handleMouseUp = (setShow) => () => {
      setShow(false);
  };
  

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Change_Password")}
        language={language}
      />
      <div className="mt-2 flex items-center flex-col my-52">
        <ToastContainer />
        <div className=" w-full flex items-center mb-16 justify-center">
          <h1 className="text-center mt-9  text-sr font-bold sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Change_Password")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <div className="p-5 my-26 bg-white shadow-md rounded-md w-full">
          {passwordTextPage !== null && (
            // <div className="bg-white py-1 px-1 w-full  rounded-lg font-medium">
            <div
              className="pb-3"
              dangerouslySetInnerHTML={{ __html: passwordTextPage.text }}
            ></div>
            // </div>
          )}
          <div className=" w-full">
            {/* <p className="float-right flex text-gray-500">
            {t("PasswordStrength")}
          </p>{" "} */}
            <p
              className={`float-right text-green-500 ${
                language !== "ar" ? "float-right" : "float-left"
              }`}
            >
              {t(strength)}
            </p>
          </div>
          <div className="progress-bg">
            <div
              className="progress"
              style={{
                width: "100%",
                backgroundColor: getActiveColor(message),
              }}
            ></div>
          </div>
          <div className="my-3 grid grid-cols-1 items-center">
            <form onSubmit={(e) => changePass(e)}>
              <div className="relative flex">
                <input
                  autoComplete="off"
                  id="focus"
                  // type="password"
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentpass}
                  onChange={(e) => setCurrentpass(e.target.value)}
                  required
                  className="py-1 px-2 shadow-md rounded-md border outline-none my-1   w-full"
                  placeholder={t("Insert_Current_Password")}
                  style={{ direction: currentpass ? "ltr" : "" }}
                />
                {currentpass && (
                  <InputAdornment
                    position="end"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                  >
                    <IconButton
                      onMouseDown={handleMouseDown(setShowCurrentPassword)}
                      onMouseUp={handleMouseUp(setShowCurrentPassword)}
                      onTouchStart={handleMouseDown(setShowCurrentPassword)}
                      onTouchEnd={handleMouseUp(setShowCurrentPassword)}
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
              </div>
              <div className="relative flex">
                <input
                  autoComplete="off"
                  type={showPassword ? "text" : "password"}
                  // type="password"
                  name="password"
                  value={newpass}
                  style={{ direction: newpass ? "ltr" : "" }}
                  onChange={(e) => {
                    setNewpass(e.target.value);
                    handlePassword(e.target.value);
                  }}
                  required
                  className="py-1 px-2 shadow-md rounded-md border outline-none my-1  w-full"
                  placeholder={t("Insert_New_Password")}
                />
                {newpass && (
                  <InputAdornment
                    position="end"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                  >
                    <IconButton
                      onMouseDown={handleMouseDown(setShowPassword)}
                      onMouseUp={handleMouseUp(setShowPassword)}
                      onTouchStart={handleMouseDown(setShowPassword)}
                      onTouchEnd={handleMouseUp(setShowPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
              </div>
              <div className="relative flex">
                <input
                  autoComplete="off"
                  //type="password"
                  type={showConfirmPassword ? "text" : "password"}
                  style={{
                    direction: confirmPassword.confirmNewPassword ? "ltr" : "",
                  }}
                  name="confirmNewPassword"
                  value={confirmPassword.confirmNewPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                  required
                  className="py-1 px-2 shadow-md rounded-md border
            outline-none my-1 w-full"
                  placeholder={t("Confirm_New_Password")}
                />
                {confirmPassword.confirmNewPassword &&(
                <InputAdornment
                  position="end"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2"
                >
                  <IconButton
                    onMouseDown={handleMouseDown(setShowConfirmPassword)}
                    onMouseUp={handleMouseUp(setShowConfirmPassword)}
                    onTouchStart={handleMouseDown(setShowConfirmPassword)}
                    onTouchEnd={handleMouseUp(setShowConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  </InputAdornment>
                  )}
              </div>
              {error.confirmNewPassword && (
                <span className="text-red-600">
                  {t(error.confirmNewPassword)}
                </span>
              )}

              <div className="w-full flex items-center justify-center md:justify-end mt-3">
                <button
                  type="submit"
                  className={
                    "px-2 py-2 font-bold border text-md rounded-md text-white w-150 " +
                    (isValid ? "bg-sr   hover:bg-pr" : "bg-gray-400")
                  }
                  disabled={!isValid}
                >
                  {t("Change_Password")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
