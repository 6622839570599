import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi";
import { useNavigate } from "react-router";

const ConfirmationMessage = ({ language, userid, email, token }) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchEmailConfirmation = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Bearer ${Cookies.get("accessToken")}`
        );
        myHeaders.append("Accept-Language", language || "en");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(
          `${url}identity/changeemailconfirm?UserId=${userid}&Email=${email}&Token=${token}&LangCode=${language}`,
          requestOptions
        );

        errorHandler(response);
        const result = await response.json();

        if (result.success) {
          history("/mailchangesuccess");
        } else {
          setErrorMessage(result.errors[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEmailConfirmation();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="items-center justify-center text-center mt-2 mb-8 bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium">
      <div className="w-full h-full items-center justify-center mb-4">
        {errorMessage &&
          errorMessage !== null &&
          errorMessage !== undefined && (
            <BiErrorCircle className="text-6xl w-full text-red-500 text-center" />
          )}
        <p className="py-2 text-sm text-black">
          {errorMessage &&
            errorMessage !== null &&
            errorMessage !== undefined && (
              <>
                {t("Change_Account_Email_Failed")}
                <div className="text-red-700 font-bold mt-2">
                  {errorMessage}
                </div>
              </>
            )}
        </p>
      </div>
    </div>
  );
};

export default ConfirmationMessage;
