import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Multiselect from "multiselect-react-dropdown";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";

function SportsHarbits({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload,language } = useContext(MainContent);
  const { profilesports } = myown_data?.data || {};
  const [sports, setSports] = useState(profilesports || []);

  const handleSaveSports = () => {
    const body = JSON.stringify(sports);

    HANDLE_API_REQUEST.POST(endpoints.SAVE_SPORTS, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      });
  };
  let filter4 = [];

  if (sports.length !== 0 && profileData !== null) {
    sports.forEach((item) => {
      let newarr = profileData?.sportattitude?.filter(
        (ind) => ind.translistid === item.sportattitudeid
      );
      filter4 = [...filter4, newarr[0]];
    });
  }
  const savesports = (arr) => {
    let newarr = [];
    arr.map((item) => newarr.push({ sportattitudeid: item.translistid }));
    setSports(newarr);
  };

  return (
    <Accordion.Item
      className="my-2 rounded-md bg-white shadow-md"
      eventKey="10"
    >
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center capitalize  w-full">
          {t("Sport_Attitude")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        {filter4.length !== 0 ? (
          <Multiselect
            className={`form-select form-select-md mb-3 mt-2 py-2 ${
              language === "ar" ? "text-right " : ""
            } ${filter4.length === 0 ? "bg-red-100" : "bg-green-100 "}`}
            required
            options={profileData.sportattitude}
            selectionLimit="5"
            selectedValues={filter4}
            displayValue="name"
            placeholder={t("Select")}
            onSelect={(e) => savesports(e)}
            onRemove={(e) => savesports(e)}
          />
        ) : (
          <Multiselect
            className={`form-select form-select-md mb-3 mt-2 py-2 ${
              language === "ar" ? "text-right " : ""
            } ${sports.length === 0 ? "bg-red-100" : "bg-green-100 "}`}
            required
            options={profileData.sportattitude}
            selectionLimit="5"
            displayValue="name"
            placeholder={t("Select")}
            onSelect={(e) => savesports(e)}
            onRemove={(e) => savesports(e)}
          />
        )}
        <div className="w-full items-end flex justify-end">
          <button
            type="button"
            onClick={handleSaveSports}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default SportsHarbits;
