import React, { memo, useState } from "react";
import { NavLink } from "react-router-dom";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import { useTranslation } from "react-i18next";

const Header = ({
  activeProfileId,
  inboxes,
  isChatSelected,
  activeChatUsername,
  activeChatImage,
  gender,
  available,
}) => {
    const { t } = useTranslation();
  // const [hasMessages, setHasMessages] = useState(isChatSelected);
  const imageOnError = (event) => {
    event.currentTarget.src = gender ? noimage : muslimf;
  };

  return !available ? (
    <div className="header_center">
      {inboxes.length !== 0 && (
        <div className="inner">
          {isChatSelected ? (
            <img
              src={`${process.env.REACT_APP_IMAGE}${activeChatImage}`}
              alt=""
              onError={imageOnError}
              onContextMenu={(e) => e.preventDefault()}
            />
          ) : (
            ""
          )}
          <div className="name">
            {isChatSelected ? (
              <div>
                <h3
                  className="text-center "
                  style={{ direction: "ltr", color: "#aaa" }}
                >
                  {activeChatUsername && activeChatUsername.trim() !== ""
                    ? activeChatUsername
                    : t("NotAvailable")}
                </h3>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="header_center">
      <NavLink to={"/profile/" + activeProfileId}>
        {inboxes.length !== 0 && (
          <div className="inner">
            {isChatSelected ? (
              <img
                src={`${process.env.REACT_APP_IMAGE}${activeChatImage}`}
                alt=""
                onError={imageOnError}
                onContextMenu={(e) => e.preventDefault()}
              />
            ) : (
              ""
            )}
            <div className="name">
              {isChatSelected ? (
                <div>
                  <h3 className="text-center" style={{ direction: "ltr" }}>
                    {activeChatUsername && activeChatUsername.trim() !== ""
                      ? activeChatUsername
                      : t("NotAvailable")}
                  </h3>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </NavLink>
    </div>
  );
};
export default memo(Header);
