import React, { useEffect } from "react";
import IdleTimer from "react-idle-timer";
import { errorHandler, url } from "../api/Api";
import Cookies from "js-cookie";
const Checkonline = () => {
  const active = () => {
    setTimer(timer + 1);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${url}profile/saveactivitydate`, requestOptions).then((response) =>
      errorHandler(response)
    );
  };

  const [start, setStart] = React.useState(true);
  const [timer, setTimer] = React.useState(0);

  // useEffect(() => {
  //   if (start === true) {
  //     setTimeout(() => active(), 110000)
  //   }
  // }, [timer, start])

  useEffect(() => {
    if (start === true) {
      const timeoutId = setTimeout(() => active(), 110000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [timer, start]);

  useEffect(() => {
    active();
  }, []);

  return (
    <>
      <IdleTimer
        startOnMount={true}
        timeout={120000}
        onActive={() => {
          setStart(true);
          active();
        }}
        onIdle={() => setStart(false)}
      />
    </>
  );
};

export default Checkonline;
