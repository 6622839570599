import React, { Fragment, useContext, useState, useEffect } from "react";
import { Dialog, Transition, Menu } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { FiSettings, FiMail } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { MainContent } from "../App";
import muslimf from "../images/avatar_female.jpg";
import noimage from "../images/avatar_male.jpg";
import { FaUserCircle, FaUserEdit, FaUserFriends } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import Badge from "@mui/material/Badge";
import {
  MdAutoDelete,
  MdPhotoCamera,
  MdEmail,
  MdOutlinePermContactCalendar,
  MdNotifications,
  MdGraphicEq,
} from "react-icons/md";
import { SiOpenaccess } from "react-icons/si";
import { HiOutlineSearch } from "react-icons/hi";
import { RiLockPasswordFill, RiDashboardLine } from "react-icons/ri";
import { BiSitemap } from "react-icons/bi";
import "./footer.css";

const Sidebar = () => {
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const {
    changnow,
    setBasicshow,
    setAdvancesho,
    language,
    myown_data,
    newMessage,
  } = useContext(MainContent);
  const [profilepic, setProfilepic] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const imageOnError = (event) => {
    event.currentTarget.src = myown_data?.data?.profileattributes?.gender
      ? noimage
      : muslimf;
  };

  useEffect(() => {
    let isMounted = true;
    const getOwnData = () => {
      if (!myown_data.data.albumcontainer) {
        setProfilepic([]);
        return;
      }

      const pictures = myown_data.data.albumcontainer.pictures;
      if (!pictures.length) {
        setProfilepic([]);
        return;
      }

      const profilepic = pictures.filter(
        (item) =>
          item.ismicroimage === true &&
          item.isprimary === true &&
          item.isthumbnail === false
      );
      if (isMounted) {
        setProfilepic(profilepic);
      }
    };

    getOwnData();

    return () => {
      isMounted = false; // Set the mounted state to false when the component is unmounted
    };
  }, [changnow, language]);

  const { open, setOpen } = useContext(MainContent);

  return (
    <>
      <div className="hidden sm:block">
        {open ? (
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 "
              onClose={setOpen}
              style={{ zIndex: "999999" }}
            >
              <div className="absolute inset-0 ">
                <div
                  className={
                    "pointer-events-none fixed inset-y-0 " +
                    (language === "ar" ? "right-0" : "left-0") +
                    " flex w-16"
                  }
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <div className="pointer-events-auto w-screen max-w-md">
                      <div
                        style={{ zIndex: "999999" }}
                        className=" relative flex h-full flex-col bg-sr text-white shadow-xl"
                      >
                        <div className="flex-1  py-6  sm:px-6">
                          <div className="flex items-start justify-between">
                            <div className=" flex h-7 items-center justify-center w-full">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon
                                  className="h-6 w-6 animate-bounce text-white duration-100 "
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>

                          <div className="mt-8">
                            <nav className="grid grid-cols-1 gap-5">
                              <>
                                <Menu
                                  as="div"
                                  className=" relative w-full flex items-center justify-center"
                                >
                                  <NavLink
                                    to="/chat/active"
                                    onClick={() => setOpen(false)}
                                  >
                                    <Badge
                                      color="secondary"
                                      badgeContent={
                                        newMessage > 99 ? "99+" : newMessage
                                      }
                                    >
                                      <FiMail className="w-7 h-7 text-pr hover:text-pr cursor-pointer" />
                                    </Badge>
                                  </NavLink>
                                </Menu>
                              </>
                              <>
                                <Menu
                                  as="div"
                                  className=" relative flex items-center justify-center"
                                >
                                  <Menu.Button className="flex text-sm rounded-full">
                                    <HiOutlineSearch className="w-7 h-7 text-pr hover:text-pr cursor-pointer" />
                                  </Menu.Button>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      style={{
                                        zIndex: "999999",
                                        top: "0px",
                                        direction:
                                          language === "ar" ? "rtl" : "ltr",
                                      }}
                                      className={
                                        "origin-top-right absolute  mt-2 " +
                                        (language === "ar"
                                          ? "right-8"
                                          : "left-8") +
                                        " w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                      }
                                    >
                                      <Menu.Item style={{ width: "100%" }}>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/basic"
                                          onClick={() => {
                                            setBasicshow(true);
                                            localStorage.removeItem("basic");
                                            setOpen(false);
                                          }}
                                        >
                                          {t("Search_Basic")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/advance"
                                          onClick={() => {
                                            setAdvancesho(true);
                                            localStorage.removeItem("advance");
                                            setOpen(false);
                                          }}
                                        >
                                          {t("Search_Advanced")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/profilename"
                                          onClick={() => {
                                            localStorage.removeItem("name");
                                            setOpen(false);
                                            localStorage.removeItem("stext");
                                          }}
                                        >
                                          {t("Search_By_Profile_Name")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/memberid"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("Search_By_Profile_ID")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/top10"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("Top_Ten_Profiles")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/latest"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("Latest_Profiles")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/search/online"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("Online_Users")}
                                        </NavLink>
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </>
                              <>
                                <Menu
                                  as="div"
                                  className=" relative flex items-center justify-center"
                                >
                                  <Menu.Button className="flex text-sm rounded-full">
                                    <MdGraphicEq className="w-7 h-7 text-pr hover:text-pr cursor-pointer" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      style={{
                                        zIndex: "999999",
                                        top: "0px",
                                        direction:
                                          language === "ar" ? "rtl" : "ltr",
                                      }}
                                      className={
                                        "origin-top-right absolute  mt-2 " +
                                        (language === "ar"
                                          ? "right-8"
                                          : "left-8") +
                                        " w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                      }
                                    >
                                      <>
                                        <Menu.Item style={{ width: "100%" }}>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/likedme"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t("Activity_Liked_Me")}
                                          </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/favoriteme"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t("Activity_I_Am_Their_Favorite")}
                                          </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/myviewers"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t("Activity_Viewed_My_Profile")}
                                          </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/mylikes"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t("Activity_My_Likes")}
                                          </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/myfavorite"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t("My_Favorite")}
                                          </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/myviewed"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t("Activity_Profile_I_Viewed")}
                                          </NavLink>
                                        </Menu.Item>
                                        <Menu.Item>
                                          <NavLink
                                            end
                                            className={(navData) =>
                                              navData.isActive
                                                ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                                : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                            }
                                            style={{ width: "100%" }}
                                            to="/activity/blocklist"
                                            onClick={() => setOpen(false)}
                                          >
                                            {t(
                                              "Activity_Blocked_Profiles_List"
                                            )}
                                          </NavLink>
                                        </Menu.Item>
                                      </>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </>
                              <>
                                <Menu
                                  as="div"
                                  className=" relative flex items-center justify-center"
                                >
                                  <Menu.Button className="flex text-sm rounded-full">
                                    <FaUserFriends className="w-7 h-7 text-pr hover:text-pr cursor-pointer" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      style={{
                                        right: "0px",
                                        zIndex: "999999",
                                        direction:
                                          language === "ar" ? "rtl" : "ltr",
                                      }}
                                      className={
                                        "origin-top-right absolute  mt-2 " +
                                        (language === "ar"
                                          ? "right-8"
                                          : "left-8") +
                                        " w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                      }
                                    >
                                      <Menu.Item style={{ width: "100%" }}>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/matches/mymatches"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("My_Matches")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/matches/filter"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("Filter")}
                                        </NavLink>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <NavLink
                                          end
                                          className={(navData) =>
                                            navData.isActive
                                              ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white "
                                              : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                                          }
                                          style={{ width: "100%" }}
                                          to="/matches/matches-setting"
                                          onClick={() => setOpen(false)}
                                        >
                                          {t("Matches_Settings")}
                                        </NavLink>
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </>
                              <div className=" flex items-center justify-center w-full">
                                <Link
                                  to="/dashboard"
                                  onClick={() => setOpen(false)}
                                  className="w-7 h-7  mt-2 text-pr hover:text-pr cursor-pointer"
                                >
                                  {" "}
                                  <RiDashboardLine className="w-7 h-7 text-pr hover:text-pr cursor-pointer" />
                                </Link>
                              </div>

                              <Menu
                                as="div"
                                className="relative flex items-center justify-center w-full"
                              >
                                <div>
                                  <Menu.Button
                                    className="flex text-sm rounded-full w-9"
                                    style={{ marginLeft: "-3px" }}
                                  >
                                    {profilepic?.length !== 0 ? (
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE}${profilepic[0].path}`}
                                        alt=""
                                        className="h-9 w-12 rounded-full cursor-pointer border-2 border-pr"
                                        style={{ objectFit: "cover" }}
                                        onError={imageOnError}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={
                                          myown_data?.data?.profileattributes
                                            ?.gender
                                            ? noimage
                                            : muslimf
                                        }
                                        className="h-9 w-12 rounded-full cursor-pointer border-2 border-pr"
                                        style={{ objectFit: "cover" }}
                                        alt=""
                                        onError={imageOnError}
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    )}{" "}
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    style={{
                                      zIndex: "999999",
                                      top: "0px",
                                      direction:
                                        language === "ar" ? "rtl" : "ltr",
                                    }}
                                    className={
                                      "origin-top-right absolute  mt-2 " +
                                      (language === "ar"
                                        ? "right-8"
                                        : "left-8") +
                                      " w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    }
                                  >
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/profile-settings/view"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full "
                                          )}
                                        >
                                          <FaUserCircle />{" "}
                                          {t("Profile_Settings_View_Profile")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/profile-settings/edit"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <FaUserEdit />
                                          {t("Profile_Settings_Edit_Profile")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/profile-settings/delete"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <MdAutoDelete />
                                          {t("Profile_Settings_Delete_Profile")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/profile-settings/photos"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <MdPhotoCamera />
                                          {t("Profile_Photos")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/profile-settings/image-access"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <SiOpenaccess />
                                          {t("Profile_Settings_Images_Access")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                              <Menu
                                as="div"
                                className=" relative flex items-center justify-center w-full"
                              >
                                <div>
                                  <Menu.Button className="flex text-sm rounded-full">
                                    <div className="text-3xl cursor-pointer text-pr">
                                      <FiSettings />
                                    </div>
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    style={{
                                      zIndex: "999999",
                                      top: "0px",
                                      direction:
                                        language === "ar" ? "rtl" : "ltr",
                                    }}
                                    className={
                                      "origin-top-right absolute  mt-2 " +
                                      (language === "ar"
                                        ? "right-8"
                                        : "left-8") +
                                      " w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    }
                                  >
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/memberssettings/email"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full "
                                          )}
                                        >
                                          <MdEmail />{" "}
                                          {t(
                                            "Member_Settings_Change_Email_Address"
                                          )}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/memberssettings/password"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <RiLockPasswordFill />
                                          {t("Member_Settings_Change_Password")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/memberssettings/notification"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <MdNotifications />
                                          {t("Notifications_Settings_Short")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/memberssettings/site-settings"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <BiSitemap />
                                          {t("Site_Settings")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/memberssettings/contact"
                                          onClick={() => {
                                            localStorage.removeItem("cid");
                                            localStorage.removeItem("contact");
                                            setOpen(false);
                                          }}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <MdOutlinePermContactCalendar />
                                          {t("Contact")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/payment"
                                          onClick={() => setOpen(false)}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                                          )}
                                        >
                                          <GiArmorUpgrade />
                                          {t("Membership")}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        ) : (
          <div
            className={
              "fixed " +
              (language === "ar" ? "right-0 top-2/4" : "left-0 top-2/4")
            }
            style={{ zIndex: "999999" }}
          >
            {scrollPosition > 100 && (
              <div
                style={{ zIndex: "999999" }}
                onClick={() => setOpen(true)}
                className="h-12 w-12 z-50 bg-sr relative flex items-center justify-center border-2 border-pr  duration-300 ease-linear rounded-full cursor-pointer"
              >
                {language === "ar" ? (
                  <BsChevronDoubleRight className=" text-white h-6 w-6 animate-pulse duration-1000 ease-linear" />
                ) : (
                  <BsChevronDoubleLeft className=" text-white h-6 w-6 animate-pulse duration-1000 ease-linear" />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
