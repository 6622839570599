import React, { useContext, useEffect, useState } from "react";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { VscThreeBars } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Cart from "../card/Cart";
import Cookies from "js-cookie";
import { errorHandler, url } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import FixedHeader from "../../../layout/FixedHeader";
const MemberId = () => {
  const { t } = useTranslation();
  const { grid, setGrid, language } = useContext(MainContent);
  const [searchProfileId, setSearchProfileId] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [error, setError] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getProfileByName = (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      profilematchciterias: {
        SearchProfileId: searchProfileId,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}profile/search`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
        } else {
          setSearchResult([]);
        }
      });
  };

  const handleValidation = () => {
    let isValid = true;

    if (searchProfileId.length === 0) {
      setError("searchProfileIdErrorMessage");
      isValid = false;
    } else {
      setError("");
    }
    return isValid;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let timeoutId = setTimeout(() => {
      document.getElementById("focus").focus();
    }, 10);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Search_By_Profile_ID")}
        language={language}
      />
      <div>
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Search_By_Profile_ID")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        <div className="mb-3">
          <form
            onSubmit={(e) => getProfileByName(e)}
            className="p-3 bg-white shadow-md w-full rounded-md"
            noValidate="noValidate"
          >
            <label htmlFor="focus" className=" text-sm font-medium text-sr">
              {t("Search_By_Profile_ID")}
            </label>
            {error.length > 0 && (
              <span
                className={`text-red-600 ${
                  language === "ar" ? " mr-5" : " ml-5"
                }`}
              >
                {t(error)}
              </span>
            )}
            <div className="flex items-center gap-2 mt-2">
              <input
                type="text"
                className="form-control w-80"
                id="focus"
                required
                onChange={(e) => setSearchProfileId(e.target.value)}
                autoComplete="off"
              />

              <button
                type="submit"
                className="text-white px-3 text-md font-bold rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer"
              >
                {t("Search")}
              </button>
            </div>
          </form>
        </div>
        <div className="my-3">
          {searchResult !== null ? (
            <>
              {searchResult.length !== 0 ? (
                <>
                  {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
                  {t("Search_Result")}
                </h1> */}

                  <SectionSeparator
                    Section_Title={t("Search_Result")}
                  ></SectionSeparator>
                  <div className="flex items-center justify-end gap-3 mt-0">
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        //className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {searchResult.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                </>
              ) : (
                <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                  {t("No_Result_Found")}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MemberId;
