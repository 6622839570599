import endpoints, { HANDLE_API_REQUEST } from "./EndPoints";

export const handleNotificationState = async (
  notificationstypeid,
  schedulerid,
  active,
  language
) => {
  const body = JSON.stringify({
    notificationstypeid,
    schedulerid,
    active,
  });

  return await HANDLE_API_REQUEST.POST(
    endpoints.ACTIVATED_NOTIFICATION,
    body,
    null,
    language
  ).then((response) => {
    return response.json();
  });
};
