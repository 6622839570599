import React from "react";
import { useTranslation } from "react-i18next";

const LoadMoreMessages = ({
  Section_Title,
  paddingBotton,
  paddingTop,
  LoadMoreHandler,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`relative flex ${paddingTop ? paddingTop : " pt-5 "} ${
        paddingBotton ? paddingBotton : " pb-5 "
      } items-center`}
    >
      <div className="flex-grow border-t border-gray-400"></div>
      {/* <button
        // onClick={LoadMoreHandler}
        className="bg-gray-400 hover:bg-sr text-white py-1 px-3 w-32 rounded-md flex-shrink mx-4"
        //className="flex-shrink mx-4 text-black bg-gray-400"
      > */}
      <span className="flex-shrink mx-4 font-bold text-gray-500 ">
        {t(Section_Title)}
      </span>
      {/* </button> */}
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  );
};

export default LoadMoreMessages;
