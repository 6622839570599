import { errorHandler } from "./Api";
export const getAdviceListRequest = async (language, accessToken) => {
  const res = await fetch(`${process.env.REACT_APP_URI}matrimonialAdvice`, {
    method: "GET",
    headers: new Headers({
      "Content-type": "application/json",
      "Accept-Language": language || "en",
      Authorization: `Bearer ${accessToken}`,
    }),
  });
  errorHandler(res);
  const data = await res.json();
  return data;
};
