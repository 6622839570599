import Cookies from "js-cookie";
import { errorHandler, profileHandler } from "./Api";

const fetchHandler = (url, requestType, body, header) => {
  let myHeaders;
  if (header) {
    myHeaders = header;
  } else {
    myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append(
      "Accept-Language",
      localStorage.getItem("i18nextLng") || "en"
    );
  }

  // if request option has not any body
  const requestOptionsWithoutBOdy = {
    method: requestType,
    headers: myHeaders,
    redirect: "follow",
  };

  // if request option has body
  const requestOptionsWithBOdy = {
    method: requestType,
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };

  // abort control

  const abortController = new AbortController();
  const signal = abortController.signal;

  // main fetch
  try {
    function callfetch() {
      const returnOutput = () => {
        return fetch(
          url,
          body ? requestOptionsWithBOdy : requestOptionsWithoutBOdy
        )
          .then((response) => {
            if (url.includes("profile?ProfileId=")) {
              profileHandler(response);
            } else {
              errorHandler(response);
            }
            return response.json();
          })
          .then((result) => {
            return result;
          });
      };
      const data = returnOutput()
        .then((res) => res)
        .then((result) => result);
      return data;
    }
    const fetchData = callfetch();

    if (!signal.aborted) {
      return fetchData;
    }
  } catch (e) {
    window.location.href = "/error";
  }
};

export default fetchHandler;
