import Cookies from "js-cookie";
import moment from "moment";
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollableFeed from "react-scrollable-feed";
import * as bs from "react-icons/bs";
import Spinner from "../../components/Spinner";
import { MainContent } from "../../App";
import {
  signalrWebsocket,
  sendRemoveSocketConnectionFromChatEvent,
  joinChat,
  sendTypingEvent,
  newMessageEvent,
} from "../../utils/socketUtils";
import { AiOutlineSend } from "react-icons/ai";
import { MdOutlineMailLock } from "react-icons/md";
import nochat from "../../images/nochat.png";
import selectchat from "../../images/selectchat.png";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import { NavLink, Link } from "react-router-dom";
import { GetInboxMessageRequest } from "../../api/getInboxMessageRequest";
import { sendMessageRequest } from "../../api/sendMessageRequest";
import { useToastHandle } from "../../utils/toast";
import { errorHandler } from "../../api/Api";
import LoadMoreMessages from "./LoadMoreMessages";
import { HiPencilSquare } from "react-icons/hi2";

export default function MessagesSection({
  activeChatMobile,
  inboxes,
  activeChat,
  own_image,
  isPaidMember,
  isArchived,
  subscExpiryObject,
  disableBox,
  inboxid,
  avatarimageurl,
  userName,
  currentProfileId,
  activeProfileId,
  own_gender,
  chatState,
  refreshMessages,
  setRefreshMessages,
  setInboxMessageTotalRecords,
  setRefreshProfileInboxList,
  isCurrentProfileApproved,
  isCurrentProfileAvailable,
  isMarkedForDeletion,
  setActiveChatMobile,
}) {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);
  const history = useNavigate();
  const { toastHandle } = useToastHandle();
  const accessToken = Cookies.get("accessToken");
  const [typing, setTyping] = useState(false);
  const [receiverId, setReceiverId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [hasReachedTheTop, setHasReachedTheTop] = useState(false);
  const [hasnewMessagesArrived, setHasNewMessagesArrived] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [error, setError] = useState("");
  const [newMessageText, setNewMessageText] = useState("");
  const hasMoreRef = useRef(false);
  const messagesRef = useRef();
  const messageListRef = useRef(null);
  const textareaRef = useRef();
  const lastMessageRef = useRef(null);
  const pageNumberRef = useRef("1");

  const validateMessage = (e) => {
    const inputValue = e.target.value;
    if (!inputValue.trim() || inputValue.trim().length === 0) {
      setError("Message_must_contain_at_least_1_character");
    } else {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
      setError("");
    }
  };

  const getCurrentState = useMemo(() => {
    const currentState = chatState.toLowerCase();
    if (currentState === "active") {
      return "No_Chat_Found_Inbox";
    } else if (currentState === "archived") {
      return "No_Chat_Found_archive";
    } else if (currentState === "deleted") {
      return "No_Chat_Found_Trash";
    } else {
      return "No_Chat_Found_Default";
    }
  }, [chatState]);

  const handleScroll = async () => {
    const { scrollHeight, scrollTop } = messagesRef.current.wrapperRef.current;

    if (scrollTop === 0 && hasMoreRef.current && messages.length > 0) {
      setRefreshMessages(true);
      messagesRef.current.wrapperRef.current.scrollTop = 200; // TODO:
    }
    if (scrollTop === 0 && !hasMoreRef.current && messages.length >= 10) {
      setHasReachedTheTop(true);
    }
  };

  const imageOnError = (event) => {
    event.currentTarget.src = inboxes[activeChat]?.gender ? noimage : muslimf;
  };

  const ownImageOnError = (event) => {
    event.currentTarget.src = own_gender ? noimage : muslimf;
  };

  const invokeTypingEvent = async () => {
    await sendTypingEvent(inboxid, currentProfileId, userName);
  };

  const JoinSelectedChat = async () => {
    await joinChat(inboxid, currentProfileId, userName);
  };

  const NewMessageReceived = () => {
    if (signalrWebsocket) {
      signalrWebsocket.on("NewMessageReceived", async (message) => {
        if (message.inboxId === inboxid) {
          setHasNewMessagesArrived(true);
        }
      });
    }
  };

  function scrollToLastMessage(event) {
    event.preventDefault();
    event.stopPropagation();
    const lastMessageElement = lastMessageRef.current;
    if (lastMessageElement) {
      const timeoutId = setTimeout(() => {
        lastMessageElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }

  const handleChangeInput = (target) => {
    const ele = target.currentTarget;
    ele.style.height = "auto";

    // Update the textarea's height to match the scrollHeight
    ele.style.height = ele.scrollHeight + "px";

    // Check if the content has been removed (e.g., by pressing backspace)
    if (ele.scrollTop > 0) {
      ele.style.height = ele.scrollHeight - ele.scrollTop + "px";
    }
  };

  // useEffect( () => handleInput() , [newMessageText] );

  useEffect(() => {
    if (chatMessages && chatMessages.data !== undefined) {
      if (
        pageNumberRef.current !== "1" &&
        inboxes[activeChat]?.inbox?.inboxid === inboxid
      ) {
        setMessages((prevRecords) => [...chatMessages.data, ...prevRecords]);
      } else {
        setMessages([...chatMessages.data]);
      }

      setInboxMessageTotalRecords(chatMessages?.data?.length);

      if (
        chatMessages.nextpage &&
        inboxes[activeChat]?.inbox?.inboxid === inboxid
      ) {
        pageNumberRef.current = chatMessages.nextpage.match(
          /PaginationQuery\.pageNumber=(\d+)/
        )[1];
      } else {
        pageNumberRef.current = "1";
      }

      hasMoreRef.current = chatMessages.nextpage ? true : false;
      setInboxMessageTotalRecords(chatMessages?.data?.length);
    }
  }, [chatMessages]);

  const getAllMessages = async () => {
    try {
      if (inboxid === undefined || inboxid === null) {
        resetChatMessageRecords();
      } else {
        const combinedParams = await buildCombinedParams();
        const data = await GetInboxMessageRequest(combinedParams, accessToken);

        if (data) {
          setChatMessages(data);
        }
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const buildCombinedParams = async () => {
    const pageNumberParam = `&PaginationQuery.pageNumber=${pageNumberRef.current}`;
    const pageSizeParam = `&PaginationQuery.pageSize=10`;

    return `${inboxid}&status=${chatState}${pageNumberParam}${pageSizeParam}`;
  };

  const updateChatMessages = async () => {
    pageNumberRef.current = "1";
    const combinedParams = await buildCombinedParams();
    const data = await GetInboxMessageRequest(combinedParams, accessToken);

    if (data) {
      setMessages(data.data);
      setRefreshProfileInboxList(true);
    }
  };
  const newMessageArrived = async () => {
    await updateChatMessages();
  };

  function resetChatMessageRecords() {
    setMessages([]);
  }

  const sendNewMessage = async () => {
    try {
      const data = await sendMessageRequest(
        { newMessage: newMessageText, activeProfileId },
        accessToken
      );

      if (data.success) {
        newMessageEvent({
          inboxid,
          currentProfileId,
          userName,
          activeProfileId,
        });
        await updateChatMessages();
        setNewMessageText("");
        toastHandle(data, t("Message_Sent_Successfully"));
      } else {
        if (data.status === 429) {
          history("/too-many-requests");
        }
        if (data.message !== null && data.status === undefined) {
          toastHandle(data, data.message);
        } else {
          if (newMessageText.trim().length > 0) {
            errorHandler(data);
          }
        }
      }
    } catch (err) {
      setRefreshMessages(false);
      errorHandler(err);
    }
  };

  const handleMessage = (e) => {
    setNewMessageText(e.target.value);
  };
  // Display a message to the user indicating that the last message has been reached.
  useEffect(() => {
    if (!hasMoreRef.current && messages.length >= 10) {
      setIsVisible(true);

      const hideTimeout = setTimeout(() => {
        setHasReachedTheTop(false);
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(hideTimeout);
    }
  }, [hasReachedTheTop]);

  //Triggered when new messages are received from ChatHub.
  useEffect(() => {
    const handleFocus = async () => {
      if (hasnewMessagesArrived) {
        await newMessageArrived();
        const timeoutId = setTimeout(() => {
          if (textareaRef.current) {
            textareaRef.current.focus();
          }
        }, 100);
        setHasNewMessagesArrived(false);
        return () => clearTimeout(timeoutId);
      }
    };
    handleFocus();
  }, [hasnewMessagesArrived]);

  // initialize chat event
  useEffect(() => {
    pageNumberRef.current = "1";

    let typingTimeoutId;

    const handleUserIsTyping = (chatId, callerId) => {
      if (typing) return;
      if (inboxid === chatId && activeProfileId === callerId) {
        setReceiverId(callerId);
        setTyping(true);
        typingTimeoutId = setTimeout(() => {
          setTyping(false);
        }, 2000);
      } else {
        setReceiverId();
      }
    };

    if (inboxid) {
      JoinSelectedChat();
      NewMessageReceived();
      signalrWebsocket?.on("UserIsTyping", handleUserIsTyping);

      if (textareaRef.current) {
        textareaRef.current.focus();
      }

      setError("");

      return () => {
        sendRemoveSocketConnectionFromChatEvent(
          inboxid,
          currentProfileId,
          userName
        );
        signalrWebsocket.off("NewMessageReceived");
        signalrWebsocket.off("UserIsTyping", handleUserIsTyping);
        clearTimeout(typingTimeoutId);
      };
    }
  }, [inboxid]);

  //Load selected chat messages
  useEffect(() => {
    if (refreshMessages) {
      const loadChatMessages = async () => {
        await getAllMessages();
      };
      setRefreshProfileInboxList(true);
      loadChatMessages();
      setRefreshMessages(false);
    }
  }, [refreshMessages]);

  function shouldTextAreaBeDisabled(condition) {
    return (
      disableBox ||
      !isCurrentProfileApproved ||
      !isCurrentProfileAvailable ||
      isMarkedForDeletion
    );
  }

  return (
    <div
      className={
        activeChatMobile ? "chat_section active_chat_section" : "chat_section"
      }
      style={inboxes.length === 0 ? { width: "100%" } : {}}
    >
      {/* top bar */}
      {!hasMoreRef.current && messages.length >= 7 && isVisible ? (
        <div className="bg-black text-yellow-400  w-full h-10 rounded-md shadow-md flex items-center justify-center border-dashed border-2 border-yellow-400">
          <span className="animate-pulse">
            {t("There_are_no_more_messages_to_show")}
          </span>
        </div>
      ) : (
        <div className="w-full h-10">
          {hasMoreRef.current &&
          !isVisible &&
          messages.length > 0 &&
          inboxid !== undefined ? (
            <>
              <LoadMoreMessages
                Section_Title={"ScrollMouseUp"}
                paddingTop={"mt-1"}
                paddingBotton={"mb-0"}
              />
            </>
          ) : (
            ""
          )}
          &nbsp;
        </div>
      )}

      {/* message container */}
      <div
        id="chat-message-container"
        className="chat_message_wrapper position-relative"
      >
        {/* If messages not available */}
        <div>
          {" "}
          {messages.length === 0 && (
            <h3
              style={{
                fontWeight: "700",
                fontSize: "1rem",
                textAlign: "center",
                margin: "auto",
                //  marginTop: "20%",
              }}
            >
              {inboxes.length === 0 ? (
                <>
                  <div className="flex justify-center mb-5 mt-12">
                    <img
                      className="w-56 border-0 border-sr h-56 rounded-2xl"
                      src={nochat}
                      onContextMenu={(e) => e.preventDefault()}
                      alt=""
                    />
                  </div>
                  {t(getCurrentState)}
                </>
              ) : (
                <>
                  {inboxid === undefined || inboxid === null ? (
                    !activeChatMobile ? (
                      <>
                        <div className=" flex flex-col items-center  justify-center mb-5 ">
                          <img
                            className="w-42 border-0 border-sr h-42 rounded-2xl mb-10"
                            src={selectchat}
                            onContextMenu={(e) => e.preventDefault()}
                            alt=""
                          />
                          <div>
                            {t("Click_On_The_Chat_To_Start_A_Conversation")}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center mb-5">
                          <Link onClick={() => setActiveChatMobile(false)}>
                            <img
                              className="w-42 border-0 border-sr h-42 rounded-2xl"
                              src={selectchat}
                              onContextMenu={(e) => e.preventDefault()}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="flex justify-center mb-5">
                          <Link onClick={() => setActiveChatMobile(false)}>
                            <div>
                              {t("Click_On_The_Chat_To_Start_A_Conversation")}
                            </div>
                          </Link>
                        </div>
                      </>
                    )
                  ) : !isPaidMember &&
                    inboxes[activeChat]?.totalunread > 0 &&
                    inboxid ? (
                    ""
                  ) : (
                 <Spinner/>
                  )}
                </>
              )}
            </h3>
          )}
        </div>

        {/* Message list */}
        <ul
          ref={messageListRef}
          style={{
            position: "relative",
            top: "2px",
            height: "500px",
          }}
        >
          <ScrollableFeed onScroll={handleScroll} ref={messagesRef}>
            {/* <ScrollableFeed ref={messagesRef}> */}
            {Array.isArray(messages) &&
              messages?.map((data, i) => {
                return (
                  inboxes?.length > 0 && (
                    <li
                      key={data.messageid}
                      style={
                        messages[i]?.profileid !==
                        inboxes[activeChat]?.profileid
                          ? { flexDirection: "row-reverse" }
                          : {}
                      }
                      ref={i === messages.length - 1 ? lastMessageRef : null}
                    >
                      {messages[i]?.profileid ===
                      inboxes[activeChat]?.profileid ? (
                        <img
                          src={
                            inboxes[activeChat]?.imageurl
                              ? `${process.env.REACT_APP_IMAGE}${inboxes[activeChat].imageurl}`
                              : { imageOnError }
                          }
                          alt=""
                          onError={imageOnError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      ) : own_image.length > 0 ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE}${own_image[0].path}`}
                          className="h-12 w-12 rounded-full  border-0 border-sr"
                          style={{ objectFit: "cover" }}
                          alt=""
                          onError={ownImageOnError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_IMAGE}${avatarimageurl}`}
                          className="h-12 w-12 rounded-full  border-0 border-sr"
                          style={{ objectFit: "cover" }}
                          alt=""
                          onError={imageOnError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      )}
                      <div
                        className={
                          messages[i]?.profileid !==
                          inboxes[activeChat]?.profileid
                            ? "user_info grayBg borderTopRight "
                            : `user_info greenBg borderTopLeft ${
                                !isPaidMember &&
                                (!messages[i].hasbeenread ||
                                  messages[i].createddate > subscExpiryObject)
                                  ? "bg-red-900"
                                  : ""
                              }`
                        }
                      >
                        <h3
                          className={
                            messages[i]?.profileid !==
                            inboxes[activeChat]?.profileid
                              ? "lightBlack"
                              : "grayColor"
                          }
                          style={{
                            fontSize: ".7rem",
                            fontStyle: "italic",
                          }}
                        >
                          {messages[i]?.profileid ===
                          inboxes[activeChat]?.profileid
                            ? moment(messages[i].createddate).format(
                                "MMM Do YYYY, h:mm a"
                              )
                            : moment(messages[i].createddate).format(
                                "MMM Do YYYY, h:mm a"
                              )}
                        </h3>
                        <h4
                          className={
                            messages[i]?.profileid !==
                            inboxes[activeChat]?.profileid
                              ? "user_message "
                              : "user_message white"
                          }
                        >
                          {messages[i].message}
                        </h4>
                      </div>
                      <div className="seen_ticks ">
                        {messages[i]?.profileid !==
                        inboxes[activeChat]?.profileid ? (
                          messages[i].hasbeenread ? (
                            <span>
                              <bs.BsCheckAll style={{ color: "	#4FB6EC" }} />
                            </span>
                          ) : (
                            <span>
                              <bs.BsCheck2 style={{ color: "#555" }} />
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  )
                );
              })}
            {!isPaidMember &&
              inboxes[activeChat]?.totalunread > 0 &&
              inboxid && (
                <>
                  <div
                    className="user_message w-72 mr-6 mb-6"
                    style={{ flexDirection: "row-reverse" }}
                  >
                    <Link
                      to="/payment"
                      className="text-center transition duration-300 ease-linear hover:bg-pr rounded bg-sr text-white py-2 px-2 shadow-md flex flex-col items-center gap-1 cursor-pointer justify-center"
                    >
                      <MdOutlineMailLock className="text-3xl text-red-600 animate-pulse" />
                      <p>{t("Please_Upgrade_To_See_MessageSection")}</p>
                    </Link>
                  </div>
                </>
              )}
          </ScrollableFeed>
        </ul>
      </div>

      {isArchived && messages.length > 0 && !inboxes[activeChat]?.isblocked && (
        <div className="unarchive_chat">
          <div className="heading">
            <span className="text-white">{t("Only visible to you")}</span>
          </div>
          <span>
            {t("You choose to Archie this conversation.If you wish to send")}{" "}
            <strong className="text-white">
              {inboxes[activeChat]?.username}
            </strong>{" "}
            {t("a message, Please Unarchive the conversation first.")}
          </span>
        </div>
      )}
      {!isPaidMember && inboxid && (
        <>
          <div
            className="user_message w-72 mr-6 mb-6 "
            style={{ flexDirection: "row-reverse" }}
          >
            <Link
              to="/payment"
              className="text-center transition duration-300 ease-linear hover:bg-pr rounded bg-sr text-white py-2 px-2 shadow-md flex flex-col items-center gap-1 cursor-pointer justify-center"
            >
              <HiPencilSquare className="text-3xl text-red-600 animate-bounce" />
              <p>{t("Please_Upgrade_To_Write_MessageSection")}</p>
            </Link>
          </div>
        </>
      )}
      {!isArchived && inboxes.length > 0
        ? messages.length > 0 &&
          !inboxes[activeChat]?.isblocked &&
          inboxes[activeChat]?.available &&
          isCurrentProfileApproved &&
          isCurrentProfileAvailable &&
          !isMarkedForDeletion &&
          chatState !== "Deleted" && (
            <>
              <div className="message_box d-block">
                {/* typing effect... */}
                {typing && activeProfileId === receiverId ? (
                  <div
                    className={
                      " w-full " + (language === "ar" ? " right-6 " : "left-6")
                    }
                    style={{
                      width: "100px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "row",
                      // position: "absolute",
                      // bottom: "50px",
                      // left: "25px",
                    }}
                  >
                    <img
                      className="typeingImage rounded-full "
                      src={
                        inboxes[activeChat].imageurl
                          ? `${process.env.REACT_APP_IMAGE}${inboxes[activeChat].imageurl}`
                          : { imageOnError }
                      }
                      alt=""
                      style={{
                        width: "2.6rem",
                        height: "2.6rem",
                      }}
                      onError={imageOnError}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <img alt="giphy" src="/giphy.gif" />
                  </div>
                ) : (
                  <></>
                )}
                {isPaidMember && inboxid && (
                  <textarea
                    ref={textareaRef}
                    onChange={(e) => {
                      validateMessage(e);
                      handleMessage(e);
                      invokeTypingEvent();
                    }}
                    onInput={handleChangeInput}
                    id="autoresizing"
                    value={newMessageText}
                    maxLength="4000"
                    cols="25"
                    disabled={shouldTextAreaBeDisabled()}
                    placeholder={t("Write_Your_Message_Here")}
                    className="form-control w-100"
                  ></textarea>
                )}
              </div>
              {isPaidMember && inboxid && (
                <div
                  className="text-center text-xs mt-1"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  {" "}
                  {t(error)}
                </div>
              )}
            </>
          )
        : ""}
      {inboxes[activeChat] !== undefined &&
        messages.length > 0 &&
        inboxes.length > 0 &&
        (inboxes[activeChat]?.isblocked ||
          !inboxes[activeChat]?.available ||
          !isCurrentProfileApproved ||
          !isCurrentProfileAvailable ||
          isMarkedForDeletion) &&
        chatState === "Active" && (
          // <div className="message_box px-5">
          <div
            // className="w-full  py-3 px-2 bg-sr shadow-2xl rounded-lg text-yellow-200 font-extrabold text-center"
            className="text-center transition duration-300 ease-linear rounded bg-sr text-white py-2 px-2 shadow-md flex flex-col items-center gap-1  justify-center"
          >
            {!inboxes[activeChat]?.available ? (
              t("Member_Is_Not_Available_At_The_Moment")
            ) : inboxes[activeChat]?.isblocked ? (
              <NavLink
                to={"/activity/blocklist"}
                className="hover:text-yellow-400 cursor-pointer"
              >
                {t("This_Member_Is_Blocked_By_You")}
              </NavLink>
            ) : !isCurrentProfileApproved ? (
              t("Profile_Must_Be_Approved_To_Send_Messages")
            ) : isMarkedForDeletion ? (
              t("ProfileMarkedForDeletion_Message")
            ) : (
              t("Profile_Must_Be_Active_To_Send_Messages")
            )}
          </div>
          // </div>
        )}

      {inboxes.length > 0 &&
        messages.length > 0 &&
        !inboxes[activeChat]?.isblocked &&
        inboxes[activeChat]?.available &&
        isCurrentProfileApproved &&
        isCurrentProfileAvailable &&
        !isMarkedForDeletion &&
        isPaidMember && (
          <div className="flex justify-between mt-1">
            {!disableBox && !isArchived && chatState !== "Deleted" ? (
              <div
                className={
                  language === "ar"
                    ? " text-right text-end "
                    : "text-start text-left "
                }
              >
                <p className="w-full text-green-400 font-bold">
                  {newMessageText.length} / 4000
                </p>
              </div>
            ) : (
              ""
            )}

            <div
              className="send-btn"
              style={
                language === "ar"
                  ? { left: "5%", right: "85%" }
                  : { right: "5%" }
              }
            >
              {isArchived || !isPaidMember || chatState === "Deleted" ? (
                ""
              ) : (
                <button
                  onClick={async (e) => {
                    if (newMessageText.length > 0) {
                      document.getElementById("autoresizing").style.height =
                        "auto";
                      await sendNewMessage();
                      scrollToLastMessage(e);
                      textareaRef?.current?.focus();
                    } else {
                      setError("Message_must_contain_at_least_1_character");
                    }
                  }}
                  disabled={newMessageText.length === 0}
                  style={{
                    color:
                      isArchived || !isPaidMember || newMessageText.length === 0
                        ? "#aaa"
                        : "#4CAF50",
                    cursor:
                      isArchived || !isPaidMember || newMessageText.length === 0
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  {t("Send")}
                  <AiOutlineSend
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : ""
                    }
                  />
                </button>
              )}
            </div>
          </div>
        )}
    </div>
  );
}
