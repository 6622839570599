import React, { useContext, useState, useEffect } from "react";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { VscThreeBars, VscSearch } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Cart from "../card/Cart";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
//import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";
//import Radio from "../../profileSettings/pSettingsPages/Radio";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const ProfileName = () => {
  const { grid, setGrid, changnow, language, currentGender } =
    useContext(MainContent);
  const [name, setName] = useState("");
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [pagenumber, setPagenumber] = useState();
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  //const [gender, setGender] = useState("any");
  const [error, setError] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const { t } = useTranslation();
  const showFrontPageData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //let selectedGender = gender === "any" ? "" : "?gender=" + gender;
    let selectedGender = currentGender === false ? "male" : "female";
    fetch(
      `${url}profile/getfrontpageprofiles?gender=${selectedGender}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFontpageProfiles(result.data);
      });
  };

  const handleValidation = () => {
    let isValid = true;

    if (name.length === 0) {
      setError("nameErrorMessage");
      isValid = false;
    } else {
      setError("");
    }
    return isValid;
  };

  const getProfileByName = (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    showFrontPageData();
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: name,
        gender: !currentGender,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    localStorage.setItem("name", raw);
    localStorage.setItem("stext", name);
    fetch(`${url}profile/search`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
          setPagenumber(result.pagenumber);
        } else {
          setSearchResult([]);
          saveProfileIds(null);
        }
      });
  };

  const prev = (crl) => {
    window.scrollTo(0, 0);
    showFrontPageData();
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: name,
        paginationquery: {
          pagenumber: pagenumber + 1,
          pagesize: 10,
        },
      },
    });

    localStorage.setItem("name", raw);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
          setPagenumber(result.pagenumber);
        } else {
          setSearchResult([]);
          saveProfileIds(null);
        }
      });
  };

  const next = (crl) => {
    window.scrollTo(0, 0);
    showFrontPageData();
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: name,
        paginationquery: {
          pagenumber: pagenumber - 1,
          pagesize: 10,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    localStorage.setItem("name", raw);
    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
          setPagenumber(result.pagenumber);
        } else {
          setSearchResult([]);
          saveProfileIds(null);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("name")) {
      showFrontPageData();
      const n = localStorage.getItem("stext");
      setName(n);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept-Language", language || "en");

      const raw = localStorage.getItem("name");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(`${url}profile/search`, requestOptions)
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((result) => {
          if (result.data.length !== 0) {
            setSearchResult(result.data);
            saveProfileIds(result.data);
            setNxturl(result.nextpage);
            setPrvurl(result.previouspage);
            setPagenumber(result.pagenumber);
          } else {
            setSearchResult([]);
            saveProfileIds(null);
          }
        });
    }
  }, [changnow, language]);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      document.getElementById("focus").focus();
    }, 10);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleSearch = () => {
    setError("");
    setName("");
    //setGender("any");
    setSearchResult(null);
    saveProfileIds(null);
    setNxturl(null);
    setPrvurl(null);
    setPagenumber(null);
    setFontpageProfiles([]);
    window.scrollTo(0, 0);
  };

  // const handleGenderChange = (event) => {
  //   setGender(event.target.value);
  // };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Search_By_Profile_Name")}
        language={language}
      />
      <div>
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Search_By_Profile_Name")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        <div className="pb-3">
          <form
            onSubmit={(e) => getProfileByName(e)}
            className="p-3 bg-white shadow-md w-full rounded-md flex flex-wrap gap-2"
            noValidate="noValidate"
          >
            <div className="w-full">
              <label htmlFor="focus" className=" text-sm font-medium text-sr ">
                {t("Search_By_Profile_Name")}
              </label>
              {error.length > 0 && (
                <span
                  className={`text-red-600 ${
                    language === "ar" ? " mr-5" : " ml-5"
                  }`}
                >
                  {t(error)}
                </span>
              )}
            </div>
            <div className="flex items-center gap-2">
              <input
                type="text"
                id="focus"
                className="form-control w-80"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                name="profile-name"
              />{" "}
            </div>

            {/* <FormControl component="fieldset">
              <RadioGroup
                row
                value={gender}
                onChange={handleGenderChange}
                name="gender"
              >
                <FormControlLabel
                  control={<Radio val={"any"} />}
                  label={t("Any")}
                />
                <FormControlLabel
                  control={<Radio val={"female"} />}
                  label={t("Female")}
                />
                <FormControlLabel
                  control={<Radio val={"male"} />}
                  label={t("Male")}
                />
              </RadioGroup>
            </FormControl> */}
            <button
              type="submit"
              className="text-white px-3 text-md font-bold rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer mr-4"
            >
              {t("Search")}
            </button>
          </form>
        </div>

        <div className="py-3">
          {searchResult !== null ? (
            <>
              {searchResult.length !== 0 ? (
                <>
                  {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
                  {t("Search_Result")}
                </h1> */}
                  <SectionSeparator
                    Section_Title={t("Search_Result")}
                  ></SectionSeparator>
                  <div className="flex items-center justify-end gap-3 mt-0">
                    <div>
                      <VscSearch
                        onClick={handleSearch}
                        className="cursor-pointer text-4xl text-sr"
                      />
                    </div>
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {searchResult.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                  <div className="w-full flex mt-14 items-center justify-end gap-5 pb-5">
                    {prvurl !== null || nxturl !== null ? (
                      <>
                        {prvurl !== null ? (
                          <button
                            onClick={() => next(prvurl)}
                            className="px-3 py-2 bg-pr text-white rounded-md hover:bg-sr"
                          >
                            {t("Previous")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="px-3 py-2 bg-pr cursor-not-allowed text-white rounded-md hover:bg-sr"
                          >
                            {t("Previous")}
                          </button>
                        )}
                        {searchResult.length !== 10 ? (
                          <button
                            disabled
                            className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        ) : (
                          <button
                            onClick={() => prev(nxturl)}
                            className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                  {t("No_Result_Found")}
                </div>
              )}
            </>
          ) : null}
          {fontpageProfiles.length !== 0 && (
            <>
              <SectionSeparator
                Section_Title={t("Recommended_Profiles")}
              ></SectionSeparator>
              {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
              {t("Recommended_Profiles")}
            </h1> */}
              <div className="flex items-center justify-end gap-3 mt-3">
                <div>
                  <VscSearch
                    onClick={handleSearch}
                    className="cursor-pointer text-4xl text-sr"
                  />
                </div>
                <div>
                  <BsFillGrid3X3GapFill
                    onClick={() => setGrid(true)}
                    // className="cursor-pointer text-3xl text-sr"
                    className={`cursor-pointer text-3xl  ${
                      grid ? "text-gray-400" : "text-sr"
                    }`}
                  />
                </div>
                <div>
                  <VscThreeBars
                    onClick={() => setGrid(false)}
                    // className="cursor-pointer text-4xl text-sr"
                    className={`cursor-pointer text-3xl  ${
                      !grid ? "text-gray-400" : "text-sr"
                    }`}
                  />
                </div>
              </div>
              <div
                className={
                  grid
                    ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  gap-2 items-center"
                    : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                }
              >
                {fontpageProfiles.map((item, ind) => (
                  <Cart key={ind} data={item} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileName;
