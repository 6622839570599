import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { RiEditBoxLine } from "react-icons/ri";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDelete,
  AiFillCloseCircle,
} from "react-icons/ai";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import PaginationButton from "../Helpers/PaginationButton";

const Tamplates = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [tail, setTail] = useState("");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [key, setKey] = useState("all");

  useEffect(() => {
    setEdit(false);
    setTitle("");
    setBody("");
    setTail("");
    setHeader("");
  }, [key]);

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
          showToast(result);
        }
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    getAllTemplates(
      `${url}CorrespondenceTemplate/search?LangCode=${langcode}&CorrespondenceTypeId=1${text}`
    );
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0].message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const showDeleteToast = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0].message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      langCode: langcode,
      correspondenceTypeId: 1,
      title: title,
      header: header,
      body: body,
      tail: tail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}CorrespondenceTemplate/create`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setTitle("");
          setBody("");
          setTail("");
          setHeader("");
        }
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };

  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      correspondencetemplateid: data.correspondencetemplateid,
      langCode: langcode,
      correspondenceTypeId: 1,
      title: title,
      header: header,
      body: body,
      tail: tail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}CorrespondenceTemplate/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        showToast(result);
      });
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      correspondenceTemplateId: id,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}CorrespondenceTemplate/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        getAllTemplates(
          `${url}CorrespondenceTemplate/search?LangCode=${langcode}&CorrespondenceTypeId=1${text}`
        );
        showDeleteToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}CorrespondenceTemplate?CorrespondenceTemplateId=${id}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setLangcode(result.data.langcode);
        setTitle(result.data.title);
        setHeader(result.data.header);
        setBody(result.data.body);
        setTail(result.data.tail);
      });
  };

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Templates
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        <Tab eventKey="all" title="All">
          <>
            <div className="flex items-center gap-3 w-1/2 mb-3 bg-white p-3 rounded-md shadow-md">
              <Form.Select
                size="md"
                className=" text-sr font-medium"
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option>Select Language </option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Control
                autoFocus
                placeholder="Search Here"
                onChange={(e) =>
                  setText(
                    e.target.value === "" ? "" : `&FreeText=${e.target.value}`
                  )
                }
              />

              <button
                onClick={() =>
                  getAllTemplates(
                    `${url}CorrespondenceTemplate/search?LangCode=${langcode}&CorrespondenceTypeId=1${text}`
                  )
                }
                className="px-3 py-2 text-sm rounded-md bg-sr text-white hover:bg-pr"
                type="submit"
              >
                Search
              </button>
            </div>
            <>
              {allwaitinglist.length !== 0 ? (
                <>
                  <Table bordered striped hover responsive="md">
                    <thead>
                      <tr>
                        <th>Template ID</th>
                        <th>Language</th>
                        <th>Title</th>
                        <th>Used</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        <>
                          {allwaitinglist.map((item, ind) => (
                            <tr key={item.correspondencetemplateid}>
                              <td>{item.correspondencetemplateid}</td>
                              <td>{item.langcode}</td>
                              <td>{item.title}</td>
                              <td>{item.numberoftimeused}</td>

                              <td className="flex items-center justify-center gap-2">
                                <AiOutlineDelete
                                  onClick={() =>
                                    deleteTemplate(
                                      item.correspondencetemplateid
                                    )
                                  }
                                  className="h-6 w-6 text-pr cursor-pointer"
                                />
                                <RiEditBoxLine
                                  onClick={() => {
                                    setEdit(true);
                                    readTemplate(item.correspondencetemplateid);
                                  }}
                                  className="h-6 w-6 text-pr cursor-pointer"
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                    </tbody>
                  </Table>
                  {allwaitinglist?.length > 0 &&
                    (prvurl !== null || nxturl !== null) && (
                      <div className="float-right flex items-center gap-4 mb-3">
                        <PaginationButton
                          onClick={() => getAllTemplates(prvurl)}
                          disabled={prvurl === null}
                          text="Previous"
                          icon={<AiOutlineLeft />}
                        />
                        <PaginationButton
                          onClick={() => getAllTemplates(nxturl)}
                          disabled={nxturl === null}
                          text="Next"
                          icon={<AiOutlineRight />}
                        />
                      </div>
                    )}
                </>
              ) : (
                <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                  No template found
                </div>
              )}
            </>
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <Form.Label>
                <strong>Language</strong>
              </Form.Label>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <Form.Select
                  size="md"
                  value={langcode}
                  onChange={(e) => setLangcode(e.target.value)}
                >
                  <option value="en">English </option>
                  <option value="de">German </option>
                  <option value="fr">French</option>
                  <option value="it">Italien</option>
                  <option value="no">Norwegian</option>
                  <option value="ar">عربي</option>
                  <option value="sv">Swedish</option>
                  <option value="da">Danish</option>
                  <option value="tr">Turkish</option>
                  <option value="nl">Dutch</option>
                  <option value="es">Spanish</option>
                </Form.Select>

                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>
                    <strong>Title</strong>
                  </Form.Label>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formGridCity">
                  <Form.Label>
                    <strong>Header</strong>
                    <span className="ml-2 text-green-700">Email Header</span>
                  </Form.Label>
                  <Form.Control
                    value={header}
                    onChange={(e) => setHeader(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>
                    <strong>Body</strong>
                    <span className="  ml-2 text-green-700">Email Body</span>
                  </Form.Label>
                  <Form.Control
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formGridZip">
                  <Form.Label>
                    <strong>Tail</strong>
                    <span className=" ml-2 text-green-700">Email Footer</span>
                  </Form.Label>
                  <Form.Control
                    value={tail}
                    onChange={(e) => setTail(e.target.value)}
                  />
                </Form.Group>
                <button
                  className="mt-2 px-2 rounded-md py-2 text-sm bg-sr hover:bg-pr text-white"
                  type="submit"
                >
                  Update
                </button>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="Create">
          <form onSubmit={(e) => createTemplate(e)}>
            <Form.Select
              size="md"
              required
              className=" text-sr font-medium"
              onChange={(e) => setLangcode(e.target.value)}
            >
              <option value="">Select Language</option>
              <option value="en">English </option>
              <option value="de">German </option>
              <option value="fr">French</option>
              <option value="it">Italien</option>
              <option value="no">Norwegian</option>
              <option value="ar">عربي</option>
              <option value="sv">Swedish</option>
              <option value="da">Danish</option>
              <option value="tr">Turkish</option>
              <option value="nl">Dutch</option>
              <option value="es">Spanish</option>
            </Form.Select>

            <Form.Group className="my-3" controlId="formGridAddress2">
              <Form.Label>
                <strong>Title</strong>
              </Form.Label>
              <Form.Control
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="my-3" controlId="formGridCity">
              <Form.Label>
                {" "}
                <strong>Header</strong>
                <span className="ml-2 text-green-700">Email Header</span>
              </Form.Label>
              <Form.Control
                required
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="my-3" controlId="formGridState">
              <Form.Label>
                {" "}
                <strong>Body</strong>
                <span className="  ml-2 text-green-700">Email Body</span>
              </Form.Label>
              <Form.Control
                required
                value={body}
                as="textarea"
                onChange={(e) => setBody(e.target.value)}
                rows={3}
              />
            </Form.Group>

            <Form.Group className="my-3" controlId="formGridZip">
              <Form.Label>
                {" "}
                <strong>Tail</strong>
                <span className=" ml-2 text-green-700">Email Footer</span>
              </Form.Label>
              <Form.Control
                required
                value={tail}
                onChange={(e) => setTail(e.target.value)}
              />
            </Form.Group>
            <button
              className="mt-2 px-3 py-2 rounded-md text-white bg-sr text-sm hover:bg-pr"
              type="submit"
            >
              Save
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Tamplates;
