import Checkbox from "@mui/material/Checkbox";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { VscThreeBars, VscSearch } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Cookies from "js-cookie";
import { url } from "../../../api/Api";
import { indigo } from "@mui/material/colors";
import Multiselect from "multiselect-react-dropdown";
import AutocompleteSelectBasic from "../../common/AutocompleteSelectBasic";
import Location from "../../profileSettings/pSettingsPages/Location";
import Cart from "../card/Cart";
import Spinner from "../../../components/Spinner";
import fetchHandler from "../../../api/fetchHandler";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Basic = ({ basicshow, setBasicshow }) => {
  const { t } = useTranslation();
  const { grid, setGrid, language, changnow, myown_data } =
    useContext(MainContent);
  const [formdata, setFormdata] = useState([]);
  const [lastActivityData, setLastActivityData] = useState([]);
  const [sortListData, setSortListData] = useState([]);
  const [maxage, setMaxage] = useState(99);
  const [age, setAge] = useState([]);
  const [minage, setMinage] = useState(18);
  const [lastactivity, setLastactivity] = useState(1);
  const [hasphoto, setHasphoto] = useState(false);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [sortby, setSortby] = useState(2);
  const [cityName, setCityName] = useState("");
  const [country, setCountry] = useState([]);
  const [countryorigin, setCountryorigin] = useState(0);
  const [searchResult, setSearchResult] = useState(null);
  const [prvurl, setPrvurl] = useState(null);
  const [nexturl, setNexturl] = useState();
  const [checkgender, setCheckgender] = useState(false);
  const [pagenumber, setPagenumber] = useState(0);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Accept-Language", language || "en");

  useEffect(() => {
    return () => {
      setCountry([]);
      setLongitude("");
      setLatitude("");
      setCityName("");
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getDataFromServer = async () => {
      // get profile data

      fetchHandler(
        `${url}metadata/lookups?LookupOptions=Profile`,
        "GET",
        undefined,
        myHeaders
      ).then((result) => {
        setFormdata(result);
      });
    };
    const getAge = async () => {
      fetchHandler(
        `${url}metadata/singletype?LookupSingleTypeOptions=Age`,
        "GET"
      ).then((result) => {
        setAge(result.age);
      });
    };
    const getLastActivity = async () => {
      fetchHandler(
        `${url}metadata/singletype?LookupSingleTypeOptions=LastActivity`,
        "GET",
        undefined,
        myHeaders
      ).then((result) => {
        setLastActivityData(result.lastactivity);
      });
    };
    const getSortResults = async () => {
      fetchHandler(
        `${url}metadata/singletype?LookupSingleTypeOptions=SortResults`,
        "GET",
        undefined,
        myHeaders
      ).then((result) => {
        setSortListData(result.sortresults);
      });
    };

    const checklocal = () => {
      if (localStorage.getItem("basic")) {
        setBasicshow(false);
        let myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${Cookies.get("accessToken")}`
        );

        myHeaders.append("Accept-Language", language || "en");
        myHeaders.append("Content-Type", "application/json");
        const raw = localStorage.getItem("basic");

        fetchHandler(`${url}profile/search`, "POST", raw, myHeaders).then(
          (result) => {
            if (result.data.length !== 0) {
              setSearchResult(result.data);
              saveProfileIds(result.data);
              setNexturl(result.nextpage);
              setPrvurl(result.previouspage);
              setPagenumber(result.pagenumber);
            } else {
              setSearchResult(null);
              saveProfileIds(null);
            }
          }
        );
      }
    };
    getSortResults();
    getLastActivity();
    getAge();
    checklocal();
    getDataFromServer();
  }, [language, changnow]);

  const showFontPageData = (gender) => {
    let selectedGender =
      gender === true || gender === "true" ? "male" : "female";
    const apiUrl = `${url}profile/getfrontpageprofiles?gender=${selectedGender}&SeekingMinAge=${minage}&SeekingMaxAge=${maxage}`;

    fetchHandler(apiUrl, "GET").then((result) => {
      setFontpageProfiles(result.data);
    });
  };

  useEffect(() => {
    if (basicshow === true) {
      setPagenumber(0);
    }
  }, [basicshow]);

  useEffect(() => {
    setCheckgender(!myown_data.data.profileattributes.gender);
  }, [myown_data.data.profileattributes.gender]);

  // select country
  const selectallcountry = (e) => {
    setCountry((prevCountry) => [
      //...prevCountry,
      ...e.map((item) => item.translistid),
    ]);
  };

  const handleSearch = () => {
    setBasicshow(true);
    window.scrollTo(0, 0);
  };

  // const selectallcountry = (e) => {
  //   const makearray = (item) => {
  //     setCountry([...country, item]);
  //   };
  //   e.map((item) => makearray(item.translistid));
  // };

  // basic search
  const SearchHere = (crnurl, calc) => {
    localStorage.removeItem("enableShowNextProfile");
    localStorage.removeItem("enableShowPreviousProfile");

    window.scrollTo(0, 0);
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      profilematchciterias: {
        gender: checkgender,
        agemin: minage,
        agemax: maxage,
        lastactivityid: lastactivity,
        sortbyid: sortby,
        hasphoto: hasphoto,
        countries: country,
        paginationquery: {
          pagenumber: calc ? pagenumber + 1 : pagenumber - 1,
          pagesize: 10,
        },
        location: {
          gpslongitude: longitude,
          gpslatitude: latitude,
          alternatecityname: cityName,
          countryoforiginid: countryorigin,
        },
      },
    });

    localStorage.setItem("basic", raw);
    setCountry([]);
    setLongitude("");
    setLatitude("");
    setCityName("");

    fetchHandler(crnurl, "POST", raw, myHeaders).then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNexturl(result.nextpage);
        setPrvurl(result.previouspage);
        setPagenumber(result.pagenumber);
      } else {
        setSearchResult([]);
        saveProfileIds(null);
      }
    });
    showFontPageData(checkgender);
  };

  const inputListStyles = `form-select form-select-md mb-3 mt-2 py-2 ${
    language === "ar" ? "text-right" : ""
  }`;

  const handleCheckboxChange = (e) => {
    setHasphoto(e.target.checked);
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Basic_Search")}
        language={language}
      />
      <div className="min-h-screen ">
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Basic_Search")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        {basicshow ? (
          <>
            <form className="grid grid-cols-1 h-auto lg:grid-cols-3 gap-4  text-sr font-medium">
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="im" className="form-label">
                  {t("I_Am_A")}
                </label>
                <select
                  className="form-select form-select-md mb-3 text-sr font-medium text-sm"
                  aria-label=".form-select-lg example"
                  id="gender"
                  value={checkgender}
                  disabled={true}
                  onChange={(e) => {
                    setCheckgender(e.target.value);
                  }}
                >
                  <option value={false}>{t("Male")}</option>
                  <option value={true}>{t("Female")}</option>
                </select>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="ProfileLookingFor" className="form-label">
                  {t("Profile_Looking_For")}
                </label>
                <select
                  className="form-select form-select-md mb-3 text-sr font-medium text-sm"
                  aria-label=".form-select-lg example"
                  id="ProfileLookingFor"
                  value={checkgender}
                  disabled={true}
                  onChange={(e) => {
                    setCheckgender(e.target.value);
                  }}
                >
                  <option value={true}>{t("Male")}</option>
                  <option value={false}> {t("Female")}</option>
                </select>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div>
                    <p className="pb-2">{t("Minimum_Age")}</p>
                    <select
                      className="form-select form-select-md mb-3 text-sr font-medium text-sm"
                      aria-label=".form-select-lg example"
                      id="MinimumAge"
                      onChange={(e) => setMinage(e.target.value)}
                      value={minage}
                    >
                      {age.length !== 0 &&
                        age.map((item, ind) => (
                          <option key={ind} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <p className="pb-2">{t("Maximum_Age")}</p>
                    <select
                      className="form-select form-select-md mb-3 text-sr font-medium text-sm"
                      aria-label=".form-select-lg example"
                      id="MaximumAge"
                      onChange={(e) => setMaxage(e.target.value)}
                      value={maxage}
                    >
                      {age.length !== 0 &&
                        age.map((item, ind) => (
                          <option key={ind} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="LastActive" className="form-label">
                  {t("Last_Active")}
                </label>
                <select
                  className="form-select form-select-md mb-3 text-sr font-medium text-sm"
                  aria-label=".form-select-lg example"
                  id="LastActive"
                  value={lastactivity}
                  onChange={(e) => setLastactivity(e.target.value)}
                >
                  {lastActivityData.map((item, ind) => (
                    <option key={ind} value={item.translistid}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col pt-3 bg-white px-4 py-1 shadow-md rounded-md">
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t("Has_Photo")}
                </label>
                <div>
                  <Checkbox
                    // onChange={(e) => setHasphoto(e.target.checked)}
                    onChange={handleCheckboxChange}
                    checked={hasphoto}
                    sx={{
                      color: indigo[900],
                      "&.Mui-checked": {
                        color: indigo[900],
                      },
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="SortListBy" className="form-label">
                  {t("Sort_List_By")}
                </label>
                <select
                  className="form-select form-select-md mb-3 text-sr font-medium text-sm"
                  aria-label=".form-select-lg example"
                  id="SortListBy"
                  value={sortby}
                  onChange={(e) => setSortby(e.target.value)}
                >
                  {sortListData.map((item, ind) => (
                    <option key={ind} value={item.translistid}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="im" className="form-label">
                  {t("Country_Of_Origin")}
                </label>
                <AutocompleteSelectBasic
                  countryorigin={countryorigin}
                  setCountryorigin={setCountryorigin}
                  formdata={formdata}
                />
              </div>
              <div className=" bg-white px-4 py-1 shadow-md rounded-md">
                <h2 className="text-sr text-base font-medium pb-1 border-b mb-1">
                  {t("CountryOfResidence")}
                </h2>

                <div className=" mt-2 bg-white">
                  <Multiselect
                    // className="bg-white py-2"
                    className={inputListStyles}
                    options={formdata.country}
                    selectionLimit="5"
                    displayValue="name"
                    placeholder={t("Select")}
                    onSelect={(e) => selectallcountry(e)}
                  />
                </div>
              </div>

              <div className="bg-white px-4 py-1 shadow-md rounded-md">
                <h2 className="text-sr text-base font-medium pb-1 border-b">
                  {t("Location_City")}
                </h2>
                <div className="mb-3 py-2">
                  <Location
                    setLongitude={(value) => setLongitude(value)}
                    setLatitude={(value) => setLatitude(value)}
                    setCityName={(value) => setCityName(value)}
                    savedAddress={""}
                    useColors={false}
                  />
                </div>
              </div>
            </form>
            <div className="w-full my-3 flex items-center justify-end text-white">
              <button
                onClick={() => {
                  SearchHere(
                    `${url}profile/search?pageNumber=1&pageSize=10`,
                    true
                  );
                  setBasicshow(false);
                }}
                className="px-3 py-2 bg-sr text-md font-bold hover:bg-pr rounded-md"
              >
                {t("Search")}
              </button>
            </div>
          </>
        ) : (
          <>
            {searchResult !== null ? (
              <>
                {searchResult?.length !== 0 ? (
                  <>
                    {/* <h1 className="text-3xl mt-3 font-medium text-sr">
                    {t("Search_Result")}
                  </h1> */}
                    <SectionSeparator
                      Section_Title={t("Search_Result")}
                    ></SectionSeparator>
                    <div className="flex items-center justify-end gap-3 mt-0">
                      <div>
                        <VscSearch
                          onClick={handleSearch}
                          className="cursor-pointer text-4xl text-sr"
                        />
                      </div>
                      <div>
                        <BsFillGrid3X3GapFill
                          onClick={() => setGrid(true)}
                          className={`cursor-pointer text-3xl  ${
                            grid ? "text-gray-400" : "text-sr"
                          }`}
                        />
                      </div>
                      <div>
                        <VscThreeBars
                          onClick={() => setGrid(false)}
                          // className="cursor-pointer text-4xl text-sr"
                          className={`cursor-pointer text-3xl  ${
                            !grid ? "text-gray-400" : "text-sr"
                          }`}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        grid
                          ? "my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto"
                          : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                      }
                    >
                      {searchResult.map((item, ind) => (
                        <Cart key={ind} data={item} />
                      ))}
                    </div>

                    <div className="w-full flex mt-14 items-center justify-end gap-3 pb-5">
                      {prvurl !== null || nexturl !== null ? (
                        <>
                          {prvurl !== null ? (
                            <button
                              onClick={() => SearchHere(prvurl, false)}
                              className="px-3 py-2 bg-pr text-white rounded-md hover:bg-sr"
                            >
                              {t("Previous")}
                            </button>
                          ) : (
                            <button
                              disabled
                              className="px-3 py-2 bg-pr cursor-not-allowed text-white rounded-md hover:bg-sr"
                            >
                              {t("Previous")}
                            </button>
                          )}
                          {searchResult.length !== 10 ? (
                            <button
                              disabled
                              className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                            >
                              {t("Next")}
                            </button>
                          ) : (
                            <button
                              onClick={() => SearchHere(nexturl, true)}
                              className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                            >
                              {t("Next")}
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                    <p> {t("No_Search_Result_Found")}</p>
                  </div>
                )}
                {fontpageProfiles.length !== 0 && (
                  <>
                    <SectionSeparator
                      Section_Title={t("Recommended_Profiles")}
                    ></SectionSeparator>
                    <div className="flex items-center justify-end gap-3 mt-3">
                      <div>
                        <VscSearch
                          onClick={handleSearch}
                          className="cursor-pointer text-4xl text-sr"
                        />
                      </div>
                      <div>
                        <BsFillGrid3X3GapFill
                          onClick={() => setGrid(true)}
                          // className="cursor-pointer text-3xl text-sr"
                          className={`cursor-pointer text-3xl  ${
                            grid ? "text-gray-400" : "text-sr"
                          }`}
                        />
                      </div>
                      <div>
                        <VscThreeBars
                          onClick={() => setGrid(false)}
                          // className="cursor-pointer text-4xl text-sr"
                          className={`cursor-pointer text-3xl  ${
                            !grid ? "text-gray-400" : "text-sr"
                          }`}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        grid
                          ? "my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto"
                          : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                      }
                    >
                      {fontpageProfiles.map((item, ind) => (
                        <Cart key={ind} data={item} />
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
            <Spinner/>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Basic;
