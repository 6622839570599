import { errorHandler } from "./Api";
export const getInboxRequest = async (value, accessToken) => {
  const res = await fetch(`${process.env.REACT_APP_URI}inbox?Status=${value}`, {
    method: "GET",
    headers: new Headers({
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
  });
  errorHandler(res);
  const data = await res.json();

  return data;
};
