import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ timeInSeconds, onTimeout }) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(timeInSeconds);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer;

    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      onTimeout && onTimeout();
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft, onTimeout]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="bg-white p-4 rounded-md text-center mt-4 mb-4">
      <p>{t("Time_Left_To_Log_You_Out")}</p>
      <div className="text-3xl font-bold text-red-700">
        {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
      </div>
    </div>
  );
};

export default CountdownTimer;
