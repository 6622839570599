import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { url, errorHandler } from "../../../../api/Api";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";

const Scheduler = () => {
  const showToast = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const diactivateIncactive = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(`${url}Scheduler/deactivateinactiveprofiles`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => showToast(result));
  };
  const cleanup = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}Scheduler/cleanup`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => showToast(result));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-4">
      <ToastContainer />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Mail Queue
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <h1 className="text-4xl text-gray-700 font-medium">System Scheduler</h1>
      <p className="text-sm text-gray-500 pt-3 capitalize">
        Those Schedulers Run Automatically, but there's might be times when we
        need to run them manually
      </p>
      <div className="mt-4 flex flex-col gap-3 w-60">
        <button
          onClick={cleanup}
          className="px-4 py-3 bg-sr hover:bg-pr text-white rounded-sm"
        >
          Cleanup Deleted Messages
        </button>
        <button
          onClick={diactivateIncactive}
          className="px-4 py-3 bg-pr hover:bg-sr text-white rounded-sm"
        >
          Deactivate Inactive Profiles
        </button>
      </div>
    </div>
  );
};

export default Scheduler;
