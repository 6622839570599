import { useContext } from "react";
import { MainContent } from "./../App";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { customToasterStyle } from "../utils/constants";

export const useToastHandle = () => {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);

  const toastHandle = (result, message) => {
    if (result.success) {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(message || t("Message_Not_sent"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  return { toastHandle };
};
