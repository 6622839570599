import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import { useTranslation } from "react-i18next";
import { MainContent } from "../../App";

function ProfilePhoto(props) {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);
  const { albumcontainer, gender, profilepicture } = props || {};

  const imageOnError = (event) => {
    event.currentTarget.src = gender ? noimage : muslimf;
  };

  const imageSrc = gender ? noimage : muslimf;

  const handleClick = () => {
    if (
      !props?.hasaccessalreadybeenrequested &&
      props.isCurrentProfileApproved &&
      props.isCurrentProfileAvailable &&
      !props.isMarkedForDeletion
    ) {
      props.handleButtonClick(props.profileid);
    } else {
      props.profileNotActiveOrNotApprovedHandler();
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {" "}
        <h6 className={language === "ar" ? "py-2 text-right w-full" : ""}>
          <span className="font-bold">{t("Main_Profile_Picture")}</span>
        </h6>{" "}
      </Modal.Header>
      <Modal.Body>
        <div>
          {albumcontainer !== null ? (
            albumcontainer.pictures.length !== 0 && (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                className=" cursor-pointer rounded-md rounded-bl-none rounded-br-none"
                src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                onContextMenu={(e) => e.preventDefault()}
                alt=""
                onError={imageOnError}
              />
            )
          ) : (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              className="rounded-full cursor-pointer rounded-bl-none rounded-br-none"
              src={imageSrc}
              onContextMenu={(e) => e.preventDefault()}
              alt=""
              onError={imageOnError}
            />
          )}

          <div>
            {props.ispicturesprivate === true &&
              props.hascurrentuseraccesstoviewcardimages === false &&
              (!props.block ? (
                <div
                  onClick={handleClick}
                  style={{
                    zIndex: "6000",
                    textAlign: "center",
                  }}
                  className={
                    props.hasaccessalreadybeenrequested
                      ? " px-2 py-2  bottom-0 start-0 bg-gray-500 text-white text-sm w-100 rounded-br-md rounded-bl-md"
                      : "px-2 py-2  bottom-0 start-0 hover:bg-sr cursor-pointer  text-white text-sm w-100 bg-pr rounded-br-md rounded-bl-md"
                  }
                >
                  {props.hasaccessalreadybeenrequested
                    ? t("Request_Already_Sent")
                    : t("Ask_About_My_Photo")}
                </div>
              ) : (
                <div
                  title={t("Blocked")}
                  style={{
                    zIndex: "6000",
                    textAlign: "center",
                  }}
                  className={
                    props.hasaccessalreadybeenrequested
                      ? " px-2 py-2  bottom-0 start-0 bg-gray-500 text-white text-sm w-100 rounded-br-md rounded-bl-md cursor-not-allowed"
                      : "px-2 py-2  bottom-0 start-0 hover:bg-sr  text-white text-sm w-100 bg-pr rounded-br-md rounded-bl-md cursor-not-allowed"
                  }
                >
                  {props.hasaccessalreadybeenrequested
                    ? t("Request_Already_Sent")
                    : t("Ask_About_My_Photo")}
                </div>
              ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProfilePhoto;
