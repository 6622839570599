export function checkPasswordStrength(password) {
  const strengthChecks = {
    length: 0,
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  };

  strengthChecks.length = password.length >= 8 ? true : false;
  strengthChecks.hasUpperCase =
    /[A-Z]+/.test(password) && strengthChecks.length;
  strengthChecks.hasLowerCase =
    /[a-z]+/.test(password) && strengthChecks.length;
  strengthChecks.hasDigit = /[0-9]+/.test(password) && strengthChecks.length;
  strengthChecks.hasSpecialChar =
    /[^A-Za-z0-9]+/.test(password) && strengthChecks.length;

  let verifiedList = Object.values(strengthChecks).filter((value) => value);

  let strength =
    verifiedList.length === 5
      ? "Strong"
      : verifiedList.length >= 2
      ? "Medium"
      : "Weak";

  return strength;
}
