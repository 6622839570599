import React, { useState, useContext, useEffect } from "react";
import ProfileNavbar from "../../layout/ProfileNavbar";
import fetchHandler from "../../api/fetchHandler";
import { errorHandler, url } from "../../api/Api";
import { MainContent } from "../../App";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import FixedHeader from "../../layout/FixedHeader";
import { HiOutlinePhotograph } from "react-icons/hi";
import { AiOutlineUsergroupAdd} from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { MdOutlineNotificationsNone } from "react-icons/md";
import CompleProfileCard from "../profile/card/CompleProfileCard";

const WelcomePage = () => {
  const { t } = useTranslation();
  const { language, changnow } = useContext(MainContent);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [language, changnow]);

  useEffect(() => {
    const markWelcomeMessageAsShown = async () => {
      try {
        await fetchHandler(
          `${url}profile/markwelcomemessageshownforcurrentprofile`,
          "PATCH"
        );
        // Optionally handle success if needed
      } catch (error) {
        // Handle errors if necessary
        console.error("Error marking welcome message as shown:", error);
      }
    };

    markWelcomeMessageAsShown();
  }, []);

  return (
    <>
      <div className="container mt-24 bg-blue-50 lg:mt-16 min-h-screen lg:pt-10  h-full rounded mb-20">
        <div className=" w-full px-2">
          <ProfileNavbar />
          <div className=" mt-4">
            <FixedHeader
              scrollPosition={scrollPosition}
              headerText={t("Welcome_New_User_Message")}
              language={language}
            />
            <div className="mt-2 flex items-center flex-col my-502">
              <div className=" w-full flex items-center mb-16 justify-center ">
                <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
                  <BsBlockquoteLeft className="text-sr" />
                  {t("Welcome_New_User_Message")}
                  <BsBlockquoteRight className="text-sr" />
                </h1>
              </div>

              <div className="p-3 bg-white rounded-md shadow-md  w-full">
                <div className="bg-white py-5 px-5 w-full rounded-lg font-medium">
                  <p>{t("WelcomeMessage")}</p>
                  {/* <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 mt-4">
                    <Link
                      to="/profile-settings/edit"
                      className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300 rounded flex-1 mx-2"
                    >
                      <FaUserEdit
                        className={`h-5 w-5 ${
                          language === "ar" ? "ml-1" : "mr-1"
                        } `}
                      />
                      {t("Complete_Your_Profile")}{" "}
                      {language === "ar" ? (
                        <AiOutlineDoubleLeft className="icon" />
                      ) : (
                        <AiOutlineDoubleRight className="icon" />
                      )}
                    </Link>
                    <Link
                      to="/matches/matches-setting"
                      className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300 rounded flex-1 mx-2"
                    >
                      <AiOutlineUsergroupAdd
                        className={`h-5 w-5 ${
                          language === "ar" ? "ml-1" : "mr-1"
                        } `}
                      />
                      {t("SetYourMatchCriteria")}{" "}
                      {language === "ar" ? (
                        <AiOutlineDoubleLeft className="icon" />
                      ) : (
                        <AiOutlineDoubleRight className="icon" />
                      )}
                    </Link>
                    <Link
                      to="/memberssettings/notification"
                      className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300 rounded flex-1 mx-2"
                    >
                      <MdOutlineNotificationsNone
                        className={`h-5 w-5 ${
                          language === "ar" ? "ml-1" : "mr-1"
                        } `}
                      />
                      {t("Notifications_Settings")}{" "}
                      {language === "ar" ? (
                        <AiOutlineDoubleLeft className="icon" />
                      ) : (
                        <AiOutlineDoubleRight className="icon" />
                      )}
                    </Link>
                  </div> */}
                </div>
                {/* <div className="w-full">
                  <div className="flex flex-col sm:flex-row px-5"></div>
                </div> */}
                {/* <div className="bg-white py-1 px-5 w-full  rounded-lg font-medium"> */}
                {/* <p>{t("WelcomeProfileImageHeader")} </p> */}
                {/* <Link
                    to="/profile-settings/photos"
                    className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded mt-4"
                  >
                    <HiOutlinePhotograph
                      className={`h-5 w-5 ${
                        language === "ar" ? "ml-1" : "mr-1"
                      } `}
                    />
                    {t("SetYourProfilePicture")}{" "}
                    {language === "ar" ? (
                      <AiOutlineDoubleLeft className="icon" />
                    ) : (
                      <AiOutlineDoubleRight className="icon" />
                    )}
                  </Link> */}

                {/* <div className="mt-8 text-lg text-gray-600 ">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                      {t("HowMatchingWorks")}{" "}
                    </h2>
                    <p className="font-medium">{t("WelcomeMatchingHeader")} </p>
                    <ul
                      className={`list-disc mt-4 text-${
                        language === "ar" ? "right" : "left"
                      } text-sm`}
                    >
                      <li className="mb-2">{t("WelcomeMatchingOption1")}</li>
                      <li className="mb-2">{t("WelcomeMatchingOption2")}</li>
                      <li className="mb-2">{t("WelcomeMatchingOption3")}</li>
                      <li className="mb-2">{t("WelcomeMatchingOption4")}</li>
                      <li className="mb-2">{t("WelcomeMatchingOption5")}</li>
                    </ul>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className=" mx-auto px-1">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
            <CompleProfileCard
              title={t("CompleteYourProfile_Card")}
              description={t("CompleteYourProfile_Card_Text")}
              linkText={t("Continue")}
              linkUrl="/profile-settings/edit"
              language={language}
              icon={FaUserEdit}
            />
            <CompleProfileCard
              title={t("SetYourProfilePicture")}
              description={t("CompleteYourProfile_Card_UploadImage")}
              linkText={t("Continue")}
              linkUrl="/profile-settings/photos"
              language={language}
              icon={HiOutlinePhotograph}
            />
            <CompleProfileCard
              title={t("Define_Match_Criteria")}
              description={t("CompleteYourProfile_Card_MatchSettings")}
              linkText={t("Continue")}
              linkUrl="/matches/matches-setting"
              language={language}
              icon={AiOutlineUsergroupAdd}
            />
            <CompleProfileCard
              title={t("CompleteYourProfile_Card_notification")}
              description={t("CompleteYourProfile_Card_NotificationSettings")}
              linkText={t("Continue")}
              linkUrl="/memberssettings/notification"
              language={language}
              icon={MdOutlineNotificationsNone}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
