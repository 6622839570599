import React, { useEffect, useState, useContext } from "react";
import { MainContent } from "../../../App";
import { useTranslation } from "react-i18next";
import { url, errorHandler } from "../../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { customToasterStyle } from "../../../utils/constants";
import FixedHeader from "../../../layout/FixedHeader";

const PrivateContact = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { language, changnow } = useContext(MainContent);
  const [contactpage, setContactpage] = useState(null);
  const [contactData, setContactData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [contactid, setContactid] = useState(0);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [deviceId, setDeviceId] = useState(0);
  const [cid, setCid] = useState(0);
  const [update, setUpdate] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getContactPage = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=44&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })

      .then((result) => setContactpage(result.data));
  };

  const contactType = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=CorrespondenceType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })

      .then((result) => setContactData(result.correspondencetype));
  };
  const DeviceType = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=DeviceType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })

      .then((result) => setDeviceData(result.devicetype));
  };
  const showToast = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };
  const contactUs = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      Text: text,
      correspondenceTypeId: contactid,
      title: title,
      deviceTypeId: deviceId,
      langcode: language || "en",
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}Correspondence/create`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          localStorage.setItem("cid", result.identifier);
          localStorage.setItem("contact", raw);
          history("/contact-receipt");
        }
        showToast(result);
      });
  };
  const updateContact = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      correspondenceid: cid,
      Text: text,
      correspondenceTypeId: contactid,
      title: title,
      deviceTypeId: deviceId,
      langcode: language || "en",
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}Correspondence/update`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          localStorage.setItem("cid", result.identifier);
          localStorage.setItem("contact", raw);
          history("/contact-receipt");
        }
        showToast(result);
      });
  };

  const getLocalData = () => {
    if (localStorage.getItem("cid")) {
      setCid(localStorage.getItem("cid"));
      setUpdate(true);
    }
    if (localStorage.getItem("contact")) {
      const contactDataL = localStorage.getItem("contact");
      const cJson = JSON.parse(contactDataL);
      setTitle(cJson.title);
      setText(cJson.Text);
      setContactid(cJson.correspondenceTypeId);
      setDeviceId(cJson.deviceTypeId);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getLocalData();
    DeviceType();
    contactType();
    getContactPage();
  }, [language, changnow]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {contactData.length !== 0 ? (
        <div className="min-h-screen">
          <div className=" w-full flex items-center mb-0 justify-center ">
            <h1 className="text-center mt-9  text-sr font-medium  sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Contact")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <FixedHeader
            scrollPosition={scrollPosition}
            headerText={t("Contact")}
            language={language}
          />
          <ToastContainer />
          <div className="p-3 bg-white rounded-md shadow-md  w-full mt-4">
            {contactpage !== null && (
              <div className="pt-1">
                <div
                  className=" py-2 px-5 w-full  font-normal"
                  dangerouslySetInnerHTML={{ __html: contactpage.text }}
                ></div>
              </div>
            )}
            <div className="pt-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  update ? updateContact() : contactUs();
                }}
              >
                <h6 className=" text-sr font-bold text-sm">
                  {t("Subject")} <span className="ml-1 text-red-500">*</span>
                </h6>
                <select
                  value={contactid}
                  onChange={(e) => setContactid(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      t("Select_A_Value_from_Topic_List")
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  className="p-2 shadow-md font-medium text-sr rounded-md text-sm bg-white  mt-2 outline-none py-1 px-2 border  my-1 w-full"
                >
                  <option value="">{t("Select_Contact_Subject")}</option>
                  {contactData.map((item, ind) => (
                    <option value={item.translistid} key={ind}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <h6 className=" text-sr font-bold text-sm pt-3">
                  {t("Device_Type")}
                  <span className="ml-1 text-red-500">*</span>
                </h6>
                <select
                  value={deviceId}
                  onChange={(e) => setDeviceId(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      t("Select_A_Value_from_Device_List")
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  className="p-2 shadow-md font-medium text-sr rounded-md text-sm bg-white mt-2 outline-none py-1 px-2 border  my-1 w-full"
                >
                  <option value="">{t("Select_Device_Type")}</option>
                  {deviceData.map((item, ind) => (
                    <option value={item.translistid} key={ind}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <h6 className=" text-sr font-bold text-sm pt-3">
                  {t("Title")} <span className="ml-1 text-red-500">*</span>
                </h6>
                <input
                  autoFocus
                  maxLength="100"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className=" px-2 mt-2 w-full bg-white rounded-md py-2 shadow-md outline-none border my-1 "
                  placeholder={t("Subject_Title")}
                  type="text"
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      t("The_Subject_Field_Is_Mandatory")
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                <h6 className=" text-sr font-bold text-sm pt-3">
                  {t("Message_Content")}{" "}
                  <span className="ml-1 text-red-500">*</span>
                </h6>
                <textarea
                  maxLength="4000"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      t("The_content_Field_Is_Mandatory")
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  className=" px-2 mt-2 w-full bg-white rounded-md py-2 shadow-md outline-none border my-1"
                  placeholder={t("Content")}
                  cols="30"
                  rows="5"
                ></textarea>
                <div className="flex items-center justify-between ">
                  <p className=" text-green-500 font-bold text-md">
                    {text.length} / 4000
                  </p>
                  <button
                    type="submit"
                    className="px-2 py-2 rounded-md bg-sr text-white hover:bg-pr text-md font-bold  mt-2"
                  >
                    {update ? t("Update") : t("Submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
       <Spinner/>
      )}
    </>
  );
};

export default PrivateContact;
