import React, { useEffect, useState, useContext } from "react";
import { url, errorHandler } from "../../api/Api";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { MainContent } from "../../App";
import { AiOutlineInfoCircle } from "react-icons/ai";

const ChangeEmailReceipt = () => {
  const [hmtl, setHmtl] = useState(null);
  const { language, isAuthenticated } = useContext(MainContent);
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    //
    fetch(
      `${url}sitepage/pagetype?PageTypeId=50&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setHmtl(result.data.text));
  }, [language]);

     useEffect(() => {
       window.scrollTo(0, 0);
     }, []);
  
  return (
    <div className="container mt-16 lg:pt-10 min-h-screen mb-4">
      <div className="container">
        <div className="mb-2">{isAuthenticated && <ProfileNavbar />}</div>

        <div className="ml-2 mr-2 ">
          <div className="items-center justify-center text-center mt-2 mb-8 bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium ">
            <div className="w-full h-full flex items-center justify-center mb-4">
              <AiOutlineInfoCircle className="text-6xl text-yellow-500 text-center" />
            </div>
            <div>
              {hmtl !== null && (
                <div dangerouslySetInnerHTML={{ __html: hmtl }}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmailReceipt;
