import { errorHandler } from "./Api";
export const BlockUserRequest = async ({ blockedProfileId }, accessToken) => {
  const res = await fetch(`${process.env.REACT_APP_URI}profile/block`, {
    method: "POST",
    headers: new Headers({
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({
      blockedProfileId,
    }),
  });

  errorHandler(res);
  const data = await res.json();
  return data;
};
