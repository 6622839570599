import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { Accordion } from "react-bootstrap";
import { FaRegStickyNote, FaRegEye } from "react-icons/fa";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { GiElectric } from "react-icons/gi";
import { RiRefund2Line } from "react-icons/ri";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import fetchHandler from "../../../../api/fetchHandler";
import PaginationButton from "../Helpers/PaginationButton";

const AllPayments = ({ ismanager }) => {
  const { changnow, setFooterShow } = useContext(MainContent);
  const [allPaymentlist, setAllPaymentlist] = useState([]);
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [text, setText] = useState(null);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentPeriod, setPaymentPeriod] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [currency, setCurrency] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isrefund, setIsrefund] = useState("");
  const [isrequestRefund, setIsrequestRefund] = useState("");
  const [pamentData, setPamentData] = useState(null);
  const [singleRefund, setSingleRefund] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState("");

  const getAllPayments = (url) => {
    fetchHandler(url, "GET").then((result) => {
      if (result.data) {
        setAllPaymentlist(result.data);
      } else {
        setAllPaymentlist([]);
      }
      setNxturl(result.nextpage);
      setPrvurl(result.previouspage);
    });
  };
  const getSinglePayment = (id) => {
    fetchHandler(`${url}Payment?PaymentId=${id}`, "GET").then((result) => {
      setData(result.data);
    });
  };

  const getSingleRefund = (id) => {
    fetchHandler(`${url}refund?PaymentId=${id}`, "GET").then((result) => {
      if (result.data !== null) {
        setSingleRefund(result.data);
      }
    });
  };

  const paymentMeta = () => {
    fetchHandler(`${url}metadata/lookups?LookupOptions=Payment`, "GET").then(
      (result) => {
        setPamentData(result);
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    paymentMeta();
    getAllPayments(`${url}Payment/all?PageNumber=1&PageSize=10`);
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Content-Type", "application/json");

  const deletePayment = (id) => {
    let raw = JSON.stringify({
      paymentId: id,
    });

    fetchHandler(`${url}Payment/delete`, "DELETE", raw, myHeaders).then(
      (result) => {
        setEdit(false);
        showToast(result);
      }
    );
  };
  const handlePayment = (id, url) => {
    const raw = JSON.stringify({
      paymentid: id,
    });
    fetchHandler(url, "POST", raw, myHeaders).then((result) => {
      setEdit(false);
      showToast(result);
    });
  };

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Payment
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="Payments">
          <>
            <div className="flex items-center gap-3 w-1/2 mb-3 bg-white shadow-md rounded-md p-3">
              <Form.Control
                autoFocus
                placeholder="Profile id"
                onChange={(e) => {
                  setText(e.target.value);
                  e.target.value === "" &&
                    getAllPayments(
                      `${url}Payment/all?PageNumber=1&PageSize=10`
                    );
                }}
              />
              {text !== null ? (
                <button
                  onClick={() => {
                    getAllPayments(`${url}Payment/search?ProfileId=${text}`);
                    setEdit(false);
                  }}
                  className="px-3 py-2 text-sm rounded-md bg-sr hover:bg-pr text-white"
                  type="submit"
                >
                  Search
                </button>
              ) : (
                <button
                  className="px-3 py-2 text-sm rounded-md bg-sr hover:bg-pr text-white"
                  type="submit"
                >
                  Search
                </button>
              )}
            </div>
            <div className="w-full my-3">
              <Accordion>
                <Accordion.Item eventKey="0" className=" shadow-md">
                  <Accordion.Header>Advance Search</Accordion.Header>
                  <Accordion.Body>
                    <div className="mt-3 ml-12 grid grid-cols-3 gap-10">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TextField
                          id="date"
                          label="Start Date"
                          type="date"
                          sx={{ width: 250 }}
                          onChange={(e) =>
                            setStartDate(`StartDate=${e.target.value}`)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TextField
                          id="date"
                          label="End Date"
                          type="date"
                          sx={{ width: 250 }}
                          onChange={(e) =>
                            setEndDate(`&EndDate=${e.target.value}`)
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </LocalizationProvider>

                      {pamentData !== null && (
                        <>
                          <Form.Select
                            onChange={(e) =>
                              setPaymentMethod(
                                parseInt(e.target.value) === 0
                                  ? ""
                                  : `&PaymentMethodId=${e.target.value}`
                              )
                            }
                            size="sm"
                          >
                            <option value={0}>Select Payment Method</option>
                            {pamentData.paymentmethod.map((item, ind) => (
                              <option key={ind} value={item.translistid}>
                                {item.name}{" "}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Select
                            onChange={(e) =>
                              setPaymentPeriod(
                                parseInt(e.target.value) === 0
                                  ? ""
                                  : `&PaymentPeriodId=${e.target.value}`
                              )
                            }
                            size="sm"
                          >
                            <option value={0}>Select Payment Period</option>
                            {pamentData.paymentperiodtype.map((item, ind) => (
                              <option key={ind} value={item.translistid}>
                                {item.name}{" "}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Select
                            onChange={(e) =>
                              setPaymentStatus(
                                parseInt(e.target.value) === 0
                                  ? ""
                                  : `&PaymentStatusId=${e.target.value}`
                              )
                            }
                            size="sm"
                          >
                            <option value={0}>Select Payement Status</option>
                            {pamentData.paymentstatus.length !== 0 &&
                              pamentData.paymentstatus.map((item, ind) => (
                                <option key={ind} value={item.translistid}>
                                  {item.name}{" "}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Select
                            onChange={(e) =>
                              setCurrency(
                                parseInt(e.target.value) === 0
                                  ? ""
                                  : `&PaymentCurrencyId=${e.target.value}`
                              )
                            }
                            size="sm"
                          >
                            <option value={0}>Select Currency</option>
                            {pamentData.currencytype.map((item, ind) => (
                              <option key={ind} value={item.translistid}>
                                {item.name}{" "}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      )}
                      <div className="flex items-center justify-center gap-8">
                        <div className="flex items-center gap-2">
                          <input
                            onChange={(e) =>
                              setIsrefund(
                                e.target.checked
                                  ? `&IsRefunded=${e.target.checked}`
                                  : ""
                              )
                            }
                            type="checkbox"
                            name="refund"
                          />
                          <label htmlFor="refund">Is Refunded</label>
                        </div>
                        <div className="flex items-center gap-2">
                          <input
                            onChange={(e) => {
                              setIsrequestRefund(
                                e.target.checked
                                  ? `&IsRefundRequest=${e.target.checked}`
                                  : ""
                              );
                            }}
                            type="checkbox"
                            name="refund"
                          />
                          <label htmlFor="refund">Is Refund Request</label>
                        </div>
                      </div>
                      <div className="flex items-center w-full justify-start">
                        <button
                          className=" px-2 py-2 rounded-md text-sm text-white bg-sr hover:bg-pr"
                          onClick={() => {
                            setEdit(false);
                            getAllPayments(
                              `${url}Payment/search?${startDate}${endDate}${paymentMethod}${paymentPeriod}${currency}${paymentStatus}${isrefund}${isrequestRefund}`
                            );
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            {allPaymentlist.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Username</th>
                      <th>Period</th>
                      <th>Pay-Method</th>
                      <th>Sub-type</th>
                      <th>Pay-status</th>
                      <th>Amount</th>
                      <th>Corrency</th>
                      <th>RequestRefund</th>
                      <th>Refunded</th>
                      <th>Refund status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {allPaymentlist.map((item, ind) => (
                        <tr key={ind}>
                          <td>
                            {item.paymentdate.slice(0, 10)}
                            {item.note !== "" && (
                              <FaRegStickyNote className="text-pr ml-2" />
                            )}
                          </td>
                          <td>
                            <Link
                              onClick={() => setFooterShow(true)}
                              to={"/profile/" + item.profileid}
                            >
                              {item.displayusername}
                            </Link>
                          </td>
                          <td>{item.paymentperiodname}</td>
                          <td>{item.paymentmethodname}</td>
                          <td>{item.subscriptiontypename}</td>
                          <td>{item.paymentstatusname}</td>
                          <td>{item.amount}</td>
                          <td>{item.paymentcurrencyname}</td>
                          <td>
                            {item.isrefundrequest && (
                              <GiElectric className="text-yellow-500 h-8 w-8" />
                            )}
                          </td>
                          <td>
                            {item.isrefundrequest
                              ? item.isrefunded
                                ? "Yes"
                                : "No"
                              : ""}{" "}
                          </td>
                          <td>{item.refundstatusname}</td>

                          <td>
                            <FaRegEye
                              onClick={() => {
                                setEdit(true);
                                getSinglePayment(item.paymentid);
                                getSingleRefund(item.paymentid);
                                setSelectedUserName(item.displayusername);
                              }}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                            {!ismanager && (
                              <AiOutlineDelete
                                onClick={() => deletePayment(item.paymentid)}
                                className="h-6 w-6 text-pr cursor-pointer"
                              />
                            )}
                            {ismanager && (
                              <RiRefund2Line
                                onClick={() =>
                                  handlePayment(
                                    item.paymentid,
                                    `${url}refund/activaterefundrequest`
                                  )
                                }
                                className="h-6 w-6 text-pr cursor-pointer"
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </Table>
                <div className="float-right flex items-center gap-4 mb-3">
                  <div>
                    {allPaymentlist?.length !== 0 &&
                      (prvurl !== null || nxturl !== null) && (
                        <div className="float-right flex items-center gap-4 mb-3">
                          <PaginationButton
                            onClick={() => getAllPayments(prvurl)}
                            disabled={prvurl === null}
                            text="Previous"
                            icon={<AiOutlineLeft />}
                          />
                          <PaginationButton
                            onClick={() => getAllPayments(nxturl)}
                            disabled={nxturl === null}
                            text="Next"
                            icon={<AiOutlineRight />}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </>
            ) : (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                No payments found for the selected criteria
              </div>
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              {data.length !== 0 && (
                <div className=" w-full h-full">
                  <div className="bg-white rounded-md text-sr font-medium flex flex-col  h-full w-full p-2">
                    <h1 className=" text-2xl pt-3 pl-3">Payment Details</h1>
                    <div className="my-2 mx-3">
                      <p className="capitalize">
                        Username -{" "}
                        <span className="font-bold text-green-600">
                          {" "}
                          {selectedUserName}
                        </span>
                      </p>
                      <p className="capitalize">
                        amount -{" "}
                        <span className="font-bold text-green-600">
                          {" "}
                          {data.amount}
                        </span>
                        <span className="font-bold  text-gray-400">
                          {" "}
                          {data.paymentcurrencyname}
                        </span>
                      </p>
                      <p className="capitalize">
                        isrefunded - {data.isrefunded ? "Yes" : "No"}
                      </p>
                      <p className="capitalize">
                        isrefundrequest - {data.isrefundrequest ? "Yes" : "No"}
                      </p>
                      <p className="capitalize">
                        refundstatusname - {data.refundstatusname}
                      </p>
                      <p className="capitalize">note - {data.note}</p>
                      <p className="capitalize">
                        paymentcurrencyname - {data.paymentcurrencyname}
                      </p>
                      <p className="capitalize">
                        paymentdate - {data.paymentdate}
                      </p>
                      <p className="capitalize">paymentid - {data.paymentid}</p>
                      <p className="capitalize">
                        subscriptiontypename - {data.subscriptiontypename}
                      </p>
                      <p className="capitalize">
                        paymentmethodname - {data.paymentmethodname}
                      </p>
                      <p className="capitalize">
                        paymentperiodname - {data.paymentperiodname}
                      </p>
                      <p className="capitalize">
                        paymentstatusname - {data.paymentstatusname}
                      </p>
                      <p className="capitalize">profileid - {data.profileid}</p>
                      <p className="capitalize">
                        transactionid - {data.transactionid}
                      </p>
                    </div>

                    <>
                      {!ismanager && (
                        <>
                          <div className="flex items-center justify-between w-full px-3 py-5">
                            <div>
                              {data.refundstatusid === 2 && (
                                <button
                                  onClick={() => {
                                    handlePayment(
                                      data.paymentid,
                                      `${url}refund/cancel`
                                    );
                                  }}
                                  className="px-3 py-2 rounded-md bg-pr text-white hover:bg-sr"
                                >
                                  Cancel refund request
                                </button>
                              )}
                            </div>
                            <>
                              {data.isrefundrequest === true &&
                                data.refundstatusid !== 4 && (
                                  <>
                                    {data.isrefunded === true ? (
                                      <div className="flex items-center justify-end gap-3">
                                        <button className="px-3 py-2 rounded-md bg-gray-600 text-white ">
                                          Reject refund request
                                        </button>
                                        <button className="px-3 py-2 rounded-md bg-gray-600 text-white ">
                                          Issue for a refund
                                        </button>
                                      </div>
                                    ) : (
                                      <>
                                        {data.refundstatusid === 2 ||
                                        data.refundstatusid === 5 ? (
                                          <div className="flex items-center justify-end gap-3">
                                            <button className="px-3 py-2 rounded-md bg-gray-600 text-white ">
                                              Reject refund request
                                            </button>
                                            <button className="px-3 py-2 rounded-md bg-gray-600 text-white ">
                                              Issue for a refund
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="flex items-center justify-end gap-3">
                                            {data.refundstatusid !== 3 && (
                                              <button
                                                onClick={() => {
                                                  handlePayment(
                                                    data.paymentid,
                                                    `${url}refund/reject`
                                                  );
                                                }}
                                                className="px-3 py-2 rounded-md bg-pr text-white hover:bg-sr"
                                              >
                                                Reject refund request
                                              </button>
                                            )}
                                            {data.refundstatusid !== 3 && (
                                              <button
                                                onClick={() => {
                                                  handlePayment(
                                                    data.paymentid,
                                                    `${url}refund/issue`
                                                  );
                                                }}
                                                className="px-3 py-2 rounded-md bg-pr text-white hover:bg-sr"
                                              >
                                                Issue for a refund
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          </div>
                        </>
                      )}
                    </>
                  </div>

                  {singleRefund.length !== 0 && (
                    <div className="bg-gray-200 w-full py-3 px-6 text-gray-700">
                      <h1 className="text-xl text-gray-800">Refund Details</h1>
                      <p className="capitalize">
                        amount - {singleRefund.amount}
                      </p>
                      <p className="capitalize">
                        Refund Date - {singleRefund.refunddate}
                      </p>
                      <p className="capitalize">
                        total days Refunded - {singleRefund.totaldaysrefunded}
                      </p>
                      <p className="capitalize">
                        Is refunded - {singleRefund.isrefunded}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default AllPayments;
