import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import ProfileNavbar from "./../layout/ProfileNavbar";
import { useTranslation } from "react-i18next";
import { MainContent } from "./../App";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import FixedHeader from "./../layout/FixedHeader";

const PaymentCancel = () => {
  const { isAuthenticated, language } = useContext(MainContent);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-16 lg:pt-10 min-h-screen pb-5">
      <div className="container">
        {isAuthenticated && (
          <div className="mb-2 ">
            <ProfileNavbar />
          </div>
        )}
        <FixedHeader
          scrollPosition={scrollPosition}
          headerText={t("PaymentCancelled_Header")}
          language={language}
        />
        <div className="bg-white py-5 px-1 w-full shadow-md rounded-lg font-medium ">
          <div className="flex place-content-center mb-4 h3 text-yellow-400">
            <MdCancel size={92} />
          </div>
          <h1 className=" text-red-700 text-3xl  text-center mb-5 font-extrabold">
            {t("PaymentCancelled_Header")}
          </h1>

          <div className="px-5 lg:px-20 text-gray-500 font-medium text-center mb-5">
            {t("PaymentCancelled_Message")}
          </div>
          <div className="w-full flex flex-col sm:flex-row gap-1 justify-center items-center">
            <Link
              to="/"
              className=" px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
            >
              {t("Back_To_Home")}
            </Link>
            <Link
              to="/payment"
              className=" px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
            >
              {t("Back_To_PaymentPage")}
            </Link>
            <Link
              to="/memberssettings/contact"
              className=" px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
            >
              {t("Contact")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancel;
