import React from "react";
import { useTranslation } from "react-i18next";
import SectionSeparator from "./SectionSeparator";

const ActivityNoteMessage = ({ Section_Title, paddingBotton, paddingTop }) => {
  const { t } = useTranslation();
  return (
    <div className="pb-5">
      <SectionSeparator
        Section_Title={Section_Title}
        paddingBotton={paddingBotton}
        paddingTop={paddingTop}
      ></SectionSeparator>
      <p className="text-center text-gray-400">
        {t("Only_Active_And_Approved_Profiles_Are_Visible")}
      </p>
    </div>
  );
};

export default ActivityNoteMessage;
