import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CompleteProfileReminder = ({ isProfileCompleted, hasProfileImage }) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const [showReminder, setShowReminder] = useState(false); // Initialize as false initially

  const handlePictureUpload = () => {
    history("/profile-settings/photos");
    setShowReminder(false);
  };

  const handleCompleteProfile = () => {
    history("/profile-settings/edit");
    setShowReminder(false);
  };

  useEffect(() => {
    const reminderTimeout = setTimeout(() => {
      if (!isProfileCompleted || !hasProfileImage) {
        const lastReminderDate = localStorage.getItem("reminderDate");
        const today = new Date().toISOString().split("T")[0]; // Get today's date

        if (lastReminderDate !== today) {
          let title = "";
          let text = "";
          let confirmButtonText = "";

          if (!hasProfileImage) {
            title = t("Reminder_UploadProfilePictureTitle");
            text = t("Reminder_UploadProfilePictureText");
            confirmButtonText = t("Reminder_UploadProfilePictureButton");
          } else if (!isProfileCompleted) {
            title = t("Reminder_CompleteYourProfileTitle");
            text = t("Reminder_CompleteYourProfileText");
            confirmButtonText = t("Reminder_CompleteYourProfileButton");
          }

          Swal.fire({
            title,
            text,
            icon: "warning",
            confirmButtonText,
            showCancelButton: true,
            cancelButtonText: t("Later"),
            confirmButtonColor: "#032E42",
            cancelButtonColor: "#808080",
          }).then((result) => {
            if (result.isConfirmed) {
              if (!hasProfileImage) {
                handlePictureUpload();
              } else if (!isProfileCompleted) {
                handleCompleteProfile();
              }
            } else {
              setShowReminder(false); // Dismiss the reminder
            }
          });

          localStorage.setItem("reminderDate", today);
        }
      }
    }, 3000); // 3 seconds delay

    return () => clearTimeout(reminderTimeout); // Cleanup timeout on component unmount
  }, [isProfileCompleted, hasProfileImage, history, t]); // Only necessary dependencies

  return null; // This component doesn't render any UI directly
};

export default CompleteProfileReminder;
