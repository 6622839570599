import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import Redio from "../Radio";
import { MainContent } from "../../../../App";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { CgGenderFemale } from "react-icons/cg";
import { CgGenderMale } from "react-icons/cg";

function BasicInfomation({ age }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload, language } =
    useContext(MainContent);
  const username = myown_data?.data?.profileattributes?.username;

  const [gender, setGender] = useState(
    myown_data?.data.profileattributes?.gender
  );
  const [minage, setMinage] = useState(
    myown_data?.profileattributes?.seekingminage !== 0
      ? myown_data?.data?.profileattributes?.seekingminage
      : 18
  );
  const [maxage, setMaxage] = useState(
    myown_data?.data?.profileattributes?.seekingmaxage !== 0
      ? myown_data?.data?.profileattributes?.seekingmaxage
      : 99
  );

  const saveGender = () => {
    const body = JSON.stringify({
      gender: gender,
      seekingminage: minage,
      seekingmaxage: maxage,
    });
    HANDLE_API_REQUEST.PATCH(endpoints.CHANGE_GENDER, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showtoast(result);
        result?.success && setFreshReload(true);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <h1 className="text-xl text-sr font-medium border-b pb-2">
        {t("Your_Basics")}
      </h1>

      <div className="grid grid-cols-1 rounded-md lg:grid-cols-2 bg-white  shadow-md p-3 border-2">
        <div className="pt-4 flex items-center gap-3  lg:justify-start flex-wrap">
          <label
            htmlFor="firstname"
            className="form-label text-sm font-medium text-sr"
          >
            {t("User_Name")}
          </label>
          <input
            type="text"
            className="form-control w-80"
            id="lastname"
            disabled
            value={username}
          />
        </div>
        <div className="pt-4">
          <h2 className="text-sr text-sm font-medium pb-1 border-b">
            {t("Gender")}
          </h2>
          <div className="flex items-center w-full gap-2 mt-2">
            <FormControl component="fieldset">
              {gender === "true" || gender === true ? (
                <CgGenderMale className="text-7xl text-sr" />
              ) : (
                <CgGenderFemale className="text-7xl text-pr" />
              )}
              {/* <RadioGroup
                row
                name="row-radio-buttons-group"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className={`${language === "ar" ? "ml-2" : ""}`}
                
              >
                <FormControlLabel
                  control={<Redio val={true} />}
                  label={t("Male")}
                  disabled
                />
                <FormControlLabel
                  control={<Redio val={false} />}
                  label={t("Female")}
                  disabled
                />
              </RadioGroup> */}
            </FormControl>
            <div className="grid grid-cols-2 gap-4 items-end">
              <div>
                <p className="pb-2 text-xs text-sr font-medium">
                  {t("Looking_For_Minimum_Age")}
                </p>
                <select
                  className="form-select form-select-md mb-3"
                  aria-label=".form-select-lg example"
                  id="im"
                  onChange={(e) => setMinage(e.target.value)}
                  value={minage}
                >
                  {age?.length !== 0 &&
                    age?.map((item, ind) => (
                      <option key={ind} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <p className="pb-2 text-xs text-sr font-medium">
                  {t("Looking_For_Maximum_Age")}
                </p>
                <select
                  className="form-select form-select-md mb-3"
                  aria-label=".form-select-lg example"
                  id="im"
                  onChange={(e) => setMaxage(e.target.value)}
                  value={maxage}
                >
                  {age?.length !== 0 &&
                    age?.map((item, ind) => (
                      <option key={ind} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-end">
            <button
              type="button"
              onClick={saveGender}
              className="text-white px-3 mt-1 ml-4   rounded py-2 bg-sr hover:bg-pr text-md font-bold transition ease-linear duration-300 cursor-pointer"
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInfomation;
