import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDelete,
  AiFillCloseCircle,
  AiOutlineCheckCircle,
  AiFillCheckCircle,
} from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import PaginationButton from "../Helpers/PaginationButton";
import Swal from "sweetalert2";

const Sitepage = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [langcode, setLangCode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [lettertype, setLettertype] = useState([]);
  const [letterid, setLetterid] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [site, setSite] = useState([]);
  const [idsite, setIdsite] = useState(1);
  const [idsiteEdit, setIdsiteEdit] = useState(1);
  const [markPublish, setMarkPublish] = useState(false);
  const [useMobileVersion, setUseMobileVersion] = useState(false);
  const [createTitle, setCreateTitle] = useState("");
  const [createbody, setCreatebody] = useState("");
  const [letteridEdit, setLetteridEdit] = useState("");
  const [langcodeEdit, setLangCodeEdit] = useState("en");
  const [errorMessage, setErrorMessage] = useState("");
  const currentDate = new Date();

  const handleConfirmation = (id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this page? This action cannot be undone.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#032E42",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTemplate(id);
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire({
      //     title: "Cancelled",
      //     text: "",
      //     confirmButtonText: "Ok",
      //     confirmButtonColor: "#032E42",
      //     icon: "error",
      //   });
      // }
    });
  };

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setAllwaitinglist([]);
        if (result.data?.length !== 0) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };

  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=PageType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setLettertype(result.pagetype));
  };
  const getsite = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=Site`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setSite(result.site));
  };

  useEffect(() => {
    setLoad(false);
    getlettertype();
    getsite();
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createTemplate = (e) => {
    e.preventDefault();

    if (startDate === null) {
      setErrorMessage("Start Date is required");
      return;
    } else {
      setErrorMessage();
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      title: createTitle,
      text: createbody,
      langCode: langcode,
      startDate: startDate,
      endDate: endDate,
      pageTypeId: letterid,
      siteId: idsite,
      isPublished: markPublish,
      useMobileVersion: useMobileVersion,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}sitepage/add`, requestOptions)
      .then((response) => {
        if (response.ok) {
          setTitle("");
          setKey("all");
          getAllTemplates(
            `${url}sitepage/sitepages?PageType=${letterid}&SiteId=${idsite}&LangCode=${langcode}`
          );
        }
        return response.json();
      })
      .then((result) => showToast(result));
  };

  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      sitePageId: data.sitepageid,
      title: title,
      text: createbody,
      langCode: langcodeEdit,
      startDate: startDate,
      endDate: endDate,
      pagetypeid: letteridEdit,
      siteId: idsiteEdit,
      isPublished: markPublish,
      useMobileVersion: useMobileVersion,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}sitepage/update`, requestOptions)
      .then((response) => {
        if (response.ok) {
          setEdit(false);
        }
        return response.json();
      })
      .then((result) => {
        showToast(result);
        getAllTemplates(
          `${url}sitepage/sitepages?PageType=${letterid}&SiteId=${idsite}&LangCode=${langcode}`
        );
      });
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      sitepageid: id,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}sitepage/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        showToast(result);
        getAllTemplates(
          `${url}sitepage/sitepages?PageType=${letterid}&SiteId=${idsite}&LangCode=${langcode}`
        );
      });
  };
  const publish = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      sitePageId: id,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}sitepage/publish`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}sitepage?SitePageId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setLangCodeEdit(result.data.langcode);
        setTitle(result.data.title);
        setLetteridEdit(result.data.pagetypeid);
        setCreatebody(result.data.text);
        setStartDate(result.data.startdate);
        setEndDate(result.data.enddate);
        setIdsiteEdit(result.data.siteid);
        setMarkPublish(result.data.ispublished);
        setUseMobileVersion(result.data.usemobileversion);
      });
  };
  const [key, setKey] = useState("all");
  useEffect(() => {
    window.scrollTo(0, 0);

    if (key === "create") {
      //   setLangCode("");
      setTitle("");
      setLetterid("");
      setCreateTitle("");
      setCreatebody("");
      setCreatebody("");
      setStartDate(dayjs());
      setEndDate(null);
    } else {
      setEdit(false);
    }
  }, [key]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Site Pages
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        <Tab eventKey="all" title="Edit">
          <div className="mb-2 grid grid-cols-1 lg:grid-cols-3 gap-3">
            <Form.Select
              value={langcode}
              size="sm"
              onChange={(e) => {
                e.target.value !== "" && setLangCode(e.target.value);
                e.target.value !== ""
                  ? getAllTemplates(
                      `${url}sitepage/sitepages?PageType=${letterid}&SiteId=${idsite}&LangCode=${e.target.value}`
                    )
                  : setAllwaitinglist([]);

                setEdit(false);
              }}
            >
              <option value="">Select Language</option>
              <option value="en">English </option>
              <option value="de">German </option>
              <option value="fr">French</option>
              <option value="it">Italien</option>
              <option value="no">Norwegian</option>
              <option value="ar">عربي</option>
              <option value="sv">Swedish</option>
              <option value="da">Danish</option>
              <option value="tr">Turkish</option>
              <option value="nl">Dutch</option>
              <option value="es">Spanish</option>
            </Form.Select>
            <Form.Select
              value={letterid}
              size="sm"
              onChange={(e) => {
                e.target.value !== ""
                  ? getAllTemplates(
                      `${url}sitepage/sitepages?PageType=${e.target.value}&SiteId=${idsite}&LangCode=${langcode}`
                    )
                  : setAllwaitinglist([]);
                e.target.value !== "" && setLetterid(e.target.value);
                setEdit(false);
              }}
            >
              <option value="">Select Page type</option>
              {lettertype?.length !== 0 && (
                <>
                  {lettertype.map((item, ind) => (
                    <option key={item.translistid} value={item.translistid}>
                      {item.name}
                    </option>
                  ))}
                </>
              )}
            </Form.Select>
          </div>
          <>
            {allwaitinglist?.length !== 0 && lettertype?.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Page ID</th>
                      <th>Title</th>
                      <th>Language</th>
                      <th>Page type</th>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Status</th>
                      <th>Mobile</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist?.map((item, ind) => (
                      <tr key={item.langcode}>
                        <td>{item.sitepageid}</td>
                        <td>{item.title}</td>
                        <td>{item.langcode}</td>
                        <td>
                          {
                            lettertype.filter(
                              (i) => i.translistid === item.pagetypeid
                            )[0].name
                          }
                        </td>
                        <td>{item.startdate && item.startdate.slice(0, 10)}</td>
                        <td>{item.enddate && item.enddate.slice(0, 10)}</td>
                        <td>
                          <span
                            className={`text-${
                              item.ispublished &&
                              (item.enddate === null ||
                                new Date(item.enddate) > currentDate)
                                ? "green"
                                : "red"
                            }-500 font-bold`}
                          >
                            {item.ispublished &&
                            (item.enddate === null ||
                              new Date(item.enddate) > currentDate)
                              ? "Online"
                              : "Offline"}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`text-${
                              item.usemobileversion === true ? "green" : "red"
                            }-500 font-bold`}
                          >
                            {item.usemobileversion === true ? "Yes" : "No"}
                          </span>
                        </td>
                        <td className="flex items-center justify-center gap-2">
                          {item.ispublished ? (
                            <AiFillCheckCircle className="h-6 w-6 text-pr" />
                          ) : (
                            <AiOutlineCheckCircle
                              onClick={() => publish(item.sitepageid)}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          )}
                          {item.ispublished ? (
                            <AiOutlineDelete className="h-6 w-6 text-gray-500 " />
                          ) : (
                            <AiOutlineDelete
                              onClick={() =>
                                handleConfirmation(item.sitepageid)
                              }
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          )}
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.sitepageid);
                            }}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                <p>The selected category doesn't have any pages</p>
              </div>
            )}
            {allwaitinglist?.length !== 0 &&
              (prvurl !== null || nxturl !== null) && (
                <div className="float-right flex items-center gap-4 mb-3">
                  <PaginationButton
                    onClick={() => getAllTemplates(prvurl)}
                    disabled={prvurl === null}
                    text="Previous"
                    icon={<AiOutlineLeft />}
                  />
                  <PaginationButton
                    onClick={() => getAllTemplates(nxturl)}
                    disabled={nxturl === null}
                    text="Next"
                    icon={<AiOutlineRight />}
                  />
                </div>
              )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className="grid w-full grid-cols-1 gap-2">
                  <Form.Select
                    size="sm"
                    value={langcodeEdit}
                    disabled
                    onChange={(e) => setLangCodeEdit(e.target.value)}
                  >
                    <option value="en">English </option>
                    <option value="de">German </option>
                    <option value="fr">French</option>
                    <option value="it">Italien</option>
                    <option value="no">Norwegian</option>
                    <option value="ar">عربي</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="tr">Turkish</option>
                    <option value="nl">Dutch</option>
                    <option value="es">Spanish</option>
                  </Form.Select>
                  <Form.Select
                    size="sm"
                    value={letteridEdit}
                    disabled
                    onChange={(e) => setLetteridEdit(e.target.value)}
                  >
                    {lettertype?.length !== 0 && (
                      <>
                        {lettertype.map((item, ind) => (
                          <option
                            key={item.translistid}
                            value={item.translistid}
                          >
                            {item.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </div>
                <div className="my-3 flex items-center gap-10">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      defaultValue={dayjs(startDate)}
                      id="startdate"
                      label="Start Date"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      dateFormat="YYYY-MM-DD HH:mm:ss"
                      slotProps={{
                        textField: {
                          placeholder: "Start Date",
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                    />
                    <DateTimePicker
                      label="End Date"
                      defaultValue={dayjs(endDate)}
                      selected={endDate}
                      onChange={handleEndDateChange}
                      dateFormat="YYYY-MM-DD HH:mm:ss"
                      slotProps={{
                        textField: {
                          placeholder: "End Date",
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Content</Form.Label>
                  <Form.Control
                    required
                    value={createbody}
                    onChange={(e) => setCreatebody(e.target.value)}
                    as="textarea"
                    rows={6}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    checked={markPublish}
                    onChange={(e) => setMarkPublish(e.target.checked)}
                    type="checkbox"
                    label="Publish"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="formBasicCheckboxMobile"
                >
                  <Form.Check
                    disabled={true}
                    checked={useMobileVersion}
                    //    onChange={(e) => setUseMobileVersion(e.target.checked)}
                    type="checkbox"
                    label="For mobile devices"
                  />
                </Form.Group>
                <div className="flex space-x-4">
                  <button
                    className="rounded-md text-white px-2 py-2 bg-sr text-sm"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    className="rounded-md text-white px-2 py-2 bg-pr text-sm"
                    type="button"
                    onClick={(e) => setEdit(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="Create">
          <form onSubmit={(e) => createTemplate(e)}>
            <div className="grid w-full grid-cols-1 gap-2">
              <Form.Select
                required
                size="sm"
                value={langcode}
                onChange={(e) => setLangCode(e.target.value)}
              >
                <option value="">Select Language </option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                value={letterid}
                required
                size="sm"
                onChange={(e) => setLetterid(e.target.value)}
              >
                <option value="">Select page type </option>
                {lettertype?.length !== 0 && (
                  <>
                    {lettertype.map((item, ind) => (
                      <option key={item.translistid} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
            </div>
            <div className="my-3 flex items-center gap-10">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  required
                  defaultValue={dayjs(startDate)}
                  id="startdate"
                  label="Start Date"
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="YYYY-MM-DD HH:mm:ss"
                  slotProps={{
                    textField: {
                      placeholder: "Start Date",
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                />
                {errorMessage !== "" && (
                  <div className="text-red-600">{errorMessage}</div>
                )}
                <DateTimePicker
                  label="End Date"
                  defaultValue={dayjs(endDate)}
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="YYYY-MM-DD HH:mm:ss"
                  slotProps={{
                    textField: {
                      placeholder: "End Date",
                      InputLabelProps: {
                        shrink: true,
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <Form.Group className="my-3" controlId="formGridAddress2">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                value={createTitle}
                onChange={(e) => setCreateTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="formGridState">
              <Form.Label>Html</Form.Label>
              <Form.Control
                required
                value={createbody}
                as="textarea"
                onChange={(e) => setCreatebody(e.target.value)}
                rows={6}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                onChange={(e) => setMarkPublish(e.target.checked)}
                type="checkbox"
                label="Publish"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckboxMobile">
              <Form.Check
                checked={useMobileVersion}
                onChange={(e) => setUseMobileVersion(e.target.checked)}
                type="checkbox"
                label="For mobile devices"
              />
            </Form.Group>

            <button
              className="mt-2 px-3 py-2 rounded-md bg-sr text-white text-sm"
              type="submit"
            >
              Save
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Sitepage;
