import React, { useState, useContext, useEffect } from "react";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import NotificationsList from "./NotificationsList";
import { errorHandler, url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import fetchHandler from "../../../../api/fetchHandler";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SectionSeparator from "../../../SectionSeparator";
import FixedHeader from "../../../../layout/FixedHeader";
import DynamicButton from "./../../../../components/DynamicButton";

const Notifications = () => {
  const {
    changnow,
    language,
    setFreshReload,
    myown_data,
    setPushNotificationEnabled,
  } = useContext(MainContent);
  const [text, setText] = useState("");
  const [pushIsActivated, setPushIsActivated] = useState(
    myown_data.data.profileattributes.pushnotificationenabled || true
  );
  const [profileNotifications] = useState([]);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isProfileContentComplete, setIsProfileContentComplete] = useState(
    myown_data?.data?.profilecontent !== null ||
      myown_data?.data?.profilecontentdraft !== null
  );
  const [profileComplet, setProfileComplet] = useState(
    myown_data.data.profileattributes.profilepercentcomplete
  );

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getnotificationPage = () => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=31&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setText(result.data.text);
        }
      });
  };

  useEffect(() => {
    if (myown_data?.data?.profileattributes) {
      setPushIsActivated(
        myown_data.data.profileattributes.pushnotificationenabled
      );
    }
    getnotificationPage();
  }, [changnow, language]);

  const handlePushNotification = (checked) => {
    fetchHandler(
      `${url}profile/enabledisableprofilepushnotification`,
      "PATCH"
    ).then((result) => {
      setPushIsActivated(checked);
      setFreshReload(true);
      setPushNotificationEnabled(checked);
    });
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Notifications_Settings")}
        language={language}
      />
      <div className=" w-full mb-12">
        <div className="w-full flex flex-col lg:flex-row items-center gap-2 mb-10">
          <div className=" flex items-center justify-center flex-grow ">
            <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl  lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Notifications_Settings")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          {(isProfileContentComplete === false ||  profileComplet < 60) && (
              <div className="flex justify-center w-full lg:w-auto lg:ml-auto mt-2 lg:mt-0">
                <DynamicButton />
              </div>
            )}
        </div>
        <div className="ml-5">
          <div className="bg-gray-200 py-2 px-5 w-full shadow-2xl rounded-lg font-medium">
            <div
              className="py-4 px-4"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
        </div>
        <div>
          <NotificationsList notifications={profileNotifications} />
        </div>
        <div className="ml-5 py-3">
          <div>
            {" "}
            <SectionSeparator
              Section_Title={t("Important_Notice")}
              paddingTop={"mt-2"}
              paddingBotton={"mb-3"}
            ></SectionSeparator>
          </div>
          <div className="bg-gray-200 py-2 px-5 w-full shadow-2xl rounded-lg font-medium">
            {t("PushNotificationHeaderMessage")}
          </div>
        </div>
        <div className="flex flex-col gap-2 pl-5">
          <div className="w-full py-1">
            <div
              className={` shadow-md py-3 px-2 rounded-md items-center ${
                pushIsActivated ? "bg-green-500" : "bg-pr"
              }`}
            >
              <div
                className={`flex flex-row items-center ${
                  language ? "gap-3" : "gap-1"
                }  pl-1`}
              >
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => {
                        handlePushNotification(e.target.checked);
                      }}
                      checked={pushIsActivated}
                    />
                  }
                />
                <span className="font-bold">
                  {`${
                    pushIsActivated
                      ? t("PushNotificationEnabled")
                      : t("PushNotificationDisabled")
                  }`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
