import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n.js";
import "./index.css";
import Spinner from "./components/Spinner";
import ReactGA from "react-ga4";

ReactGA.initialize("G-EP53E1WRVW");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
