import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { RiEditBoxLine } from "react-icons/ri";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineClose,
  AiOutlineCheck,
  AiOutlineDelete,
  AiFillCloseCircle,
} from "react-icons/ai";

import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import PaginationButton from "../Helpers/PaginationButton";

const Faq = ({ ismanager }) => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [faqid, setFaqid] = useState(1);
  const [body, setBody] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [metadata, setMetadata] = useState([]);
  const [issmart, setIssmart] = useState(false);
  const [faqCategory, setFaqCategory] = useState("");
  const [key, setKey] = useState("all");

  useEffect(() => {
    window.scrollTo(0, 0);
    setEdit(false);
    setTitle("");
    setBody("");
  }, [key]);

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setAllwaitinglist(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      });
  };
  const getMeatadata = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=FAQCategory`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setMetadata(result.faqcategory);
        // setAllwaitinglist(result.data)
        // setNxturl(result.nextpage)
        // setPrvurl(result.previouspage)
      });
  };
  useEffect(() => {
    setLoad(false);
    getMeatadata();
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [ispublic, setIspublic] = useState(false);
  const createTemplate = (e) => {
    e.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      title: title,
      text: body,
      isSmartDeviceRelated: issmart,
      faqCategoryId: faqCategory,
      langCode: langcode,
      ispublic: ispublic,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}faq/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setTitle("");
          setBody("");
        }
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      faqId: faqid,
      title: title,
      text: body,
      isSmartDeviceRelated: issmart,
      faqCategoryId: faqCategory,
      langCode: langcode,
      ispublic: ispublic,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}faq/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
        setTitle("");
        setBody("");
      });
  };
  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      faqId: id,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}faq/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        getAllTemplates(
          `${url}faq/all?FAQCategoryId=${faqCategory}&LangCode=${langcode}`
        );
        showToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}faq?FAQId=${id} `, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setFaqid(result.data.faqid);
        setLangcode(result.data.langcode);
        setTitle(result.data.title);
        setBody(result.data.text);
        setIssmart(result.data.issmartdevicerelated);
        setFaqCategory(result.data.faqcategoryid);
        setIspublic(result.data.ispublic);
      });
  };
  const enableDisable = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      faqid: id,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}faq/approvedisapprove`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        getAllTemplates(
          `${url}faq/all?FAQCategoryId=${faqCategory}&LangCode=${langcode}`
        );
      });
  };

  useEffect(() => {
    if (key === "all") {
      getAllTemplates(
        `${url}faq/all?FAQCategoryId=${faqCategory}&LangCode=${langcode}`
      );
    }
  }, [key]);

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          FAQ
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey={!ismanager ? "all" : "create"}
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        {!ismanager && (
          <Tab eventKey="create" title="Create">
            <>
              <form onSubmit={(e) => createTemplate(e)}>
                <div className="flex items-center gap-3 w-1/2 mb-3">
                  <Form.Select
                    size="md"
                    required
                    onChange={(e) => setLangcode(e.target.value)}
                  >
                    <option value="">Select Language</option>
                    <option value="en">English </option>
                    <option value="de">German </option>
                    <option value="fr">French</option>
                    <option value="it">Italien</option>
                    <option value="no">Norwegian</option>
                    <option value="ar">عربي</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="tr">Turkish</option>
                    <option value="nl">Dutch</option>
                    <option value="es">Spanish</option>
                  </Form.Select>
                  <Form.Select
                    required
                    size="md"
                    value={faqCategory}
                    onChange={(e) => setFaqCategory(e.target.value)}
                  >
                    <option value="">Select Faqcategory</option>
                    {metadata.length !== 0 &&
                      metadata.map((item, ind) => (
                        <option key={item.translistid} value={item.translistid}>
                          {item.name}{" "}
                        </option>
                      ))}
                  </Form.Select>
                </div>
                <div className="my-2 flex items-center flex-wrap gap-10">
                  <div className=" flex items-center gap-2">
                    <input
                      type="checkbox"
                      onChange={(e) => setIssmart(e.target.checked)}
                    />{" "}
                    <p>Is related to smart device</p>
                  </div>
                  <div className=" flex items-center gap-2">
                    <input
                      type="checkbox"
                      onChange={(e) => setIspublic(e.target.checked)}
                    />
                    <p>Is public</p>
                  </div>
                </div>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    autoFocus
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Body</Form.Label>
                  <Form.Control
                    required
                    value={body}
                    as="textarea"
                    onChange={(e) => setBody(e.target.value)}
                    rows={3}
                  />
                </Form.Group>
                <button
                  className="mt-2 px-3 py-2 rounded-md bg-sr  hover:bg-pr text-white text-sm"
                  type="submit"
                >
                  Save
                </button>
              </form>
            </>
          </Tab>
        )}
        <Tab eventKey="all" title="Edit">
          <div className="flex items-center gap-3 w-1/2 mb-3">
            <Form.Select
              size="md"
              onChange={(e) => setLangcode(e.target.value)}
            >
              <option>Select Language</option>
              <option value="en">English </option>
              <option value="de">German </option>
              <option value="fr">French</option>
              <option value="it">Italien</option>
              <option value="no">Norwegian</option>
              <option value="ar">عربي</option>
              <option value="sv">Swedish</option>
              <option value="da">Danish</option>
              <option value="tr">Turkish</option>
              <option value="nl">Dutch</option>
              <option value="es">Spanish</option>
            </Form.Select>
            <Form.Select
              size="md"
              onChange={(e) =>
                setFaqCategory(
                  e.target.value == 0 ? "" : `&FAQCategoryId=${e.target.value}`
                )
              }
            >
              <option value={0}>Select FaqCategory</option>
              {metadata.length !== 0 &&
                metadata.map((item, ind) => (
                  <option key={item.translistid} value={item.translistid}>
                    {item.name}{" "}
                  </option>
                ))}
            </Form.Select>
            <button
              className="px-3 py-2 rounded-md bg-sr  hover:bg-pr text-white text-sm"
              onClick={() =>
                getAllTemplates(
                  `${url}faq/all?LangCode=${langcode}${faqCategory}`
                )
              }
            >
              Search
            </button>
          </div>
          {allwaitinglist.length !== 0 ? (
            <Table bordered striped hover responsive="md">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Language</th>
                  <th>Category</th>
                  <th>Title</th>
                  <th>Counter</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allwaitinglist.map((item, ind) => (
                  <tr key={item.faqcategoryid}>
                    <td>{item.faqid}</td>
                    <td>{item.langcode}</td>
                    <td>{item.faqcategoryid}</td>
                    <td>{item.title}</td>
                    <td>{item.counter}</td>

                    <td className="flex items-center justify-center gap-2">
                      {!ismanager && (
                        <>
                          {!item.approved ? (
                            <AiOutlineClose
                              onClick={() => enableDisable(item.faqid)}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          ) : (
                            <AiOutlineCheck
                              onClick={() => enableDisable(item.faqid)}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          )}
                          <AiOutlineDelete
                            onClick={() => deleteTemplate(item.faqid)}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        </>
                      )}
                      <RiEditBoxLine
                        onClick={() => {
                          setEdit(true);
                          readTemplate(item.faqid);
                        }}
                        className="h-6 w-6 text-pr cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
              No faq found
            </div>
          )}
          {/* {allwaitinglist.length !== 0 && (
            <div className="float-right flex items-center gap-4 mb-3">
              {prvurl === null ? (
                <button
                  disabled
                  className="bg-sr cursor-not-allowed flex text-xs items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                >
                  Previous
                </button>
              ) : (
                <button
                  onClick={() => getAllTemplates(prvurl)}
                  className="bg-sr flex items-center gap-1 text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                >
                  Previous
                </button>
              )}
              {allwaitinglist.length !== 12 ? (
                <button
                  disabled
                  className="bg-sr cursor-not-allowed  flex text-xs items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={() => {
                    getAllTemplates(nxturl);
                  }}
                  className="bg-sr   flex items-center gap-1 text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                >
                  Next
                </button>
              )}
            </div>
          )} */}
          {allwaitinglist?.length > 0 &&
            (prvurl !== null || nxturl !== null) && (
              <div className="float-right flex items-center gap-4 mb-3">
                <PaginationButton
                  onClick={() => getAllTemplates(prvurl)}
                  disabled={prvurl === null}
                  text="Previous"
                  icon={<AiOutlineLeft />}
                />
                <PaginationButton
                  onClick={() => getAllTemplates(nxturl)}
                  disabled={nxturl === null}
                  text="Next"
                  icon={<AiOutlineRight />}
                />
              </div>
            )}

          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className=" flex items-center flex-wrap gap-10">
                  <div className="flex items-center gap-2">
                    <input
                      checked={issmart}
                      type="checkbox"
                      onChange={(e) => setIssmart(e.target.checked)}
                    />{" "}
                    <p>Is related to smart device</p>
                  </div>
                  <div className=" flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={ispublic}
                      onChange={(e) => setIspublic(e.target.checked)}
                    />
                    <p>Is public</p>
                  </div>
                </div>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Body</Form.Label>
                  <Form.Control
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                {!ismanager && (
                  <button
                    className="mt-2 px-2 py-2 rounded-md bg-sr hover:bg-pr text-white text-xs"
                    type="submit"
                  >
                    Update
                  </button>
                )}
              </Form>
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default Faq;
