import Cookies from "js-cookie";
import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";

import { url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineLeft,
  AiOutlineRight,
} from "react-icons/ai";

import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  BsBlockquoteRight,
  BsBlockquoteLeft,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import fetchHandler from "../../../../api/fetchHandler";
import PaginationButton from "../Helpers/PaginationButton";
import moment from "moment";

const Profiles = () => {
  const [pagenumber, setPagenumber] = useState();
  const [load, setLoad] = useState(false);
  const { changnow, setFooterShow } = useContext(MainContent);
  const [page, setPage] = useState(1);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [name, setName] = useState("");
  const [searchMade, setSearchMade] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [waitimage, setWaitimage] = useState([]);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [nxtpro, setNxtpro] = useState(null);
  const [prvpro, setPrvpro] = useState(null);
  const [draftList, setDraftList] = useState([]);
  const [dnext, setDnext] = useState(null);
  const [dprv, setDprv] = useState(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [aboutme, setAboutme] = useState("");
  const [lookingfor, setLookingfor] = useState("");
  const [selectUserName, setSelectUserName] = useState("");
  const [did, setDid] = useState("");

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept-Language", "en");
  const getWaitlist = (url) => {
    fetchHandler(url, "GET").then((result) => {
      if (result.data.length !== 0) {
        setAllwaitinglist(result.data);
      } else {
        setAllwaitinglist([]);
      }
      setNxtpro(result.nextpage);
      setPrvpro(result.previouspage);
    });
  };
  const GetWaitingPhoto = () => {
    fetchHandler(`${url}image/watingforapproval`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setWaitimage(result.data);
      } else {
        setWaitimage([]);
      }
    });
  };
  const draft = (url) => {
    fetchHandler(url, "GET").then((result) => {
      setDraftList(result.data);
      setDnext(result.nextpage);
      setDprv(result.previouspage);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    GetWaitingPhoto();
    if (localStorage.getItem("adminName")) {
      setName(localStorage.getItem("aname"));

      const raw = localStorage.getItem("adminName");

      fetchHandler(`${url}profile/search`, "POST", raw, myHeaders).then(
        (result) => {
          if (result.data.length !== 0) {
            setPagenumber(result.pagenumber);
            setSearchResult(result.data);
            setNxturl(result.nextpage);
            setPrvurl(result.previouspage);
          } else {
            setSearchResult([]);
          }
        }
      );
    }
    draft(`${url}profile/getawaitingcontentdrafts`);
    getWaitlist(`${url}profile/getwaiting?PageNumber=1&PageSize=10`);
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleProfiles = (id, url) => {
    const raw = JSON.stringify({
      profileId: id,
    });
    fetchHandler(url, "PATCH", raw, myHeaders).then((result) => {
      showToast(result);
    });
  };

  const handlephoto = (id, cid, url) => {
    const raw = JSON.stringify({
      containerId: cid,
      pictureId: id,
    });
    fetchHandler(url, "PATCH", raw, myHeaders).then((result) => {
      // setLoad(true);
      showToast(result);
    });
  };

  const getProfileByName = (e) => {
    setSearchMade(true);
    e.preventDefault();
    const raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: name,
      },
    });

    localStorage.setItem("adminName", raw);
    localStorage.setItem("aname", name);
    fetchHandler(`${url}profile/search`, "POST", raw, myHeaders).then(
      (result) => {
        if (result.data.length !== 0) {
          setPagenumber(result.pagenumber);
          setSearchResult(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
        } else {
          setSearchResult([]);
        }
      }
    );
  };

  const next = (url) => {
    let raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: name,
        paginationquery: {
          pagenumber: pagenumber + 1,
          pagesize: 10,
        },
      },
    });
    localStorage.setItem("adminName", raw);
    fetchHandler(url, "POST", raw, myHeaders).then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        setPagenumber(result.pagenumber);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult([]);
      }
    });
  };

  const prev = (url) => {
    let raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: name,
        paginationquery: {
          pagenumber: pagenumber - 1,
          pagesize: 10,
        },
      },
    });
    localStorage.setItem("adminName", raw);
    fetchHandler(url, "POST", raw, myHeaders).then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        setPagenumber(result.pagenumber);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult([]);
      }
    });
  };

  const readDraft = (id) => {
    fetchHandler(
      `${url}profile/getcontentdraftbyid?ProfileId=${id}`,
      "GET"
    ).then((result) => {
      setTitle(result.data.title);
      setAboutme(result.data.aboutmetext);
      setLookingfor(result.data.lookingfortext);
      setSelectUserName(result.data.displayusername);
      setDid(result.data.profilecontentdraftid);
    });
  };

  const approveOrReject = (url) => {
    let raw = JSON.stringify({
      profileId: did,
    });
    fetchHandler(url, "PATCH", raw, myHeaders).then((result) => {
      showToast(result);
      setShow(false);
    });
  };

  return (
    <>
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Profiles
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <div className="grid grid-cols-2 lg:grid-cols-3 mb-3 p-3 rounded-md ">
        <button
          onClick={() => {
            setPage(1);
            localStorage.removeItem("adminName");
          }}
          className={
            page === 1
              ? "bg-sr text-white px-3 py-2  border"
              : "text-pr border px-3 py-2  hover:bg-sr hover:text-white"
          }
        >
          Profiles Approval
        </button>
        <button
          onClick={() => setPage(2)}
          className={
            page === 2
              ? "bg-sr text-white px-3 py-2  border"
              : "text-pr border px-3 py-2  hover:bg-sr hover:text-white"
          }
        >
          Image Approval{" "}
          {waitimage.length !== 0 && (
            <span className="bg-pr text-white px-2 py-1 rounded-full">
              {waitimage.length}
            </span>
          )}
        </button>
        <button
          onClick={() => setPage(4)}
          className={
            page === 4
              ? "bg-sr text-white px-3 py-2  border"
              : "text-pr border px-3 py-2  hover:bg-sr hover:text-white"
          }
        >
          Content Draft Approval
        </button>
      </div>
      <div className="p-3">
        {page === 1 && (
          <>
            <>
              <div>
                <form
                  className="pl-4 w-full bg-white rounded-md p-3  shadow-md "
                  onSubmit={(e) => getProfileByName(e)}
                >
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label text-xl text-gray-700"
                  >
                    <span className="font-bold">
                      Search within approved profiles by profileId or name
                    </span>
                  </label>
                  <div className="w-80 flex items-center gap-2">
                    <input
                      type="text"
                      autoFocus
                      className="form-control w-80"
                      id="exampleFormControlInput1"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        e.target.value === "" && setSearchResult([]);
                      }}
                    />
                    <button
                      type="submit"
                      className="text-white px-3  rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer"
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="my-4">
                {searchResult.length > 0 ? (
                  <>
                    <h3 className="mt-4 mb-2 text-gray-600 ml-2 flex items-center gap-2 font-extrabold">
                      Search Result{" "}
                      <BsFillArrowDownCircleFill className="text-pr" />
                    </h3>
                    <div className="grid grid-cols-5 my-1 divide-x-2 font-bold border-2 border-gray-700 py-2">
                      <div className="text-left col-span-2">Profile ID</div>

                      <div className="text-left">Username</div>
                      <div className="text-center">Age</div>
                      <div className="text-center">Subscription type</div>
                    </div>
                    {searchResult.map((item, ind) => (
                      <div
                        key={item.profileid}
                        className="grid grid-cols-5 my-1 divide-x border py-2"
                      >
                        <div className="text-left col-span-2 text-blue-700">
                          <Link
                            onClick={() => setFooterShow(true)}
                            to={"/profile/" + item.profileid}
                          >
                            {item.profileid}
                          </Link>
                        </div>
                        <div className="text-left" style={{ direction: "ltr" }}>
                          {item.displayusername}
                        </div>
                        <div className="text-center">{item.age}</div>
                        <div className="text-center">
                          {item.subscriptiontype}
                        </div>
                      </div>
                    ))}
                    <div>
                      {searchResult?.length !== 0 &&
                        (prvurl !== null || nxturl !== null) && (
                          <div className="float-right flex items-center gap-4 mb-3">
                            <PaginationButton
                              onClick={() => prev(prvurl)}
                              disabled={prvurl === null}
                              text="Previous"
                              icon={<AiOutlineLeft />}
                            />
                            <PaginationButton
                              onClick={() => next(nxturl)}
                              disabled={nxturl === null}
                              text="Next"
                              icon={<AiOutlineRight />}
                            />
                          </div>
                        )}
                    </div>
                  </>
                ) : name !== "" && searchMade ? (
                  <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                    <p> No results found</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
            <>
              <h3 className="mt-16 mb-2 text-gray-600 ml-2 border-t-4 pt-3 flex items-center gap-2 font-extrabold">
                Profiles waiting for Approval{" "}
                <BsFillArrowDownCircleFill className="text-pr" />
              </h3>
              {allwaitinglist.length > 0 ? (
                <>
                  <div className="grid grid-cols-6 my-1 divide-x-2 text-gray-600  w-full border-gray-700 border-2 py-2 font-bold">
                    <div className="text-left">Profile ID</div>
                    <div className="text-left">Username</div>
                    <div className="text-left">Gender</div>
                    <div className="text-center">Age</div>
                    <div className="text-center">Created date</div>
                    <div className="text-center">Actions</div>
                  </div>
                  {allwaitinglist.map((item, ind) => (
                    <div
                      key={ind}
                      className="grid grid-cols-6 my-1 divide-x w-full border py-2"
                    >
                      <div className="text-left">
                        {item.profileattributes.profileattributesid}
                      </div>
                      <div className="text-left">
                        {item.profileattributes.displayusername}
                      </div>
                      <div className="text-left">
                        {item.profileattributes.gender ? "Male" : "Female"}
                      </div>
                      <div className="text-center">
                        {item.profileattributes.age}
                      </div>
                      <div className="text-center">
                        {moment(item.profileattributes.createddate).format(
                          "DD-MM-YYYY"
                        )}
                      </div>
                      <div className="flex items-center gap-3 justify-center">
                        <AiFillCheckCircle
                          onClick={() =>
                            handleProfiles(
                              item.profileid,
                              `${url}profile/approve`
                            )
                          }
                          className="w-6 h-6 text-pr cursor-pointer "
                        />
                        <AiFillCloseCircle
                          onClick={() =>
                            handleProfiles(
                              item.profileid,
                              `${url}profile/reject`
                            )
                          }
                          className="w-6 h-6 text-sr cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
                  <div>
                    {allwaitinglist?.length > 0 &&
                      (prvpro !== null || nxtpro !== null) && (
                        <div className="float-right flex items-center gap-4 mb-3">
                          <PaginationButton
                            onClick={() => getWaitlist(prvpro)}
                            disabled={prvpro === null}
                            text="Previous"
                            icon={<AiOutlineLeft />}
                          />
                          <PaginationButton
                            onClick={() => getWaitlist(nxtpro)}
                            disabled={nxtpro === null}
                            text="Next"
                            icon={<AiOutlineRight />}
                          />
                        </div>
                      )}
                  </div>
                </>
              ) : (
                <div className="text-white w-full bg-sr h-24 flex items-center justify-center text-xl font-medium">
                  No profiles waiting for Approval
                </div>
              )}
            </>
          </>
        )}
        {page === 2 && (
          <>
            {waitimage.length !== 0 ? (
              <div className="grid grid-cols-5 gap-3">
                {waitimage.map((item, ind) => (
                  <div
                    key={ind}
                    className="w-full bg-gray-600 shadow-xl rounded-md  "
                  >
                    <img
                      src={`${process.env.REACT_APP_IMAGE}${item.path}`}
                      alt=""
                      className="h-full w-full object-cover "
                      // style={{ objectFit: "cover" }}
                      onError={(event) => (event.target.style.display = "none")}
                    />
                    <div className="py-2 w-full flex items-cente border-t px-2 justify-between bg-gray-700 shadow-xl rounded-md ">
                      <AiFillCheckCircle
                        onClick={() =>
                          handlephoto(
                            item.pictureid,
                            item.albumcontainerid,
                            `${url}image/approve`
                          )
                        }
                        className="w-6 h-6 text-green-400 cursor-pointer "
                      />
                      <span className="font-bold text-white text-xl">
                        PN: {item.profilenumber}
                      </span>
                      <AiFillCloseCircle
                        onClick={() =>
                          handlephoto(
                            item.pictureid,
                            item.albumcontainerid,
                            `${url}image/decline`
                          )
                        }
                        className="w-6 h-6 text-red-600 cursor-pointer "
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                <p>No Image Available for Approval</p>
              </div>
            )}
          </>
        )}
        {page === 4 && (
          <>
            <>
              {draftList.length !== 0 ? (
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Title</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {draftList.map((item, ind) => (
                        <tr key={item.profilecontentdraftid}>
                          <td style={{ direction: "ltr" }}>
                            {item.displayusername}
                          </td>
                          <td>{item.title}</td>

                          <td className="flex  gap-2">
                            <FaRegEye
                              onClick={() => {
                                setShow(true);
                                readDraft(item.profilecontentdraftid);
                              }}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  </tbody>
                </Table>
              ) : (
                <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                  <p> No Approval Content Available</p>
                </div>
              )}
              <div>
                {draftList?.length > 0 && (dprv !== null || dnext !== null) && (
                  <div className="float-right flex items-center gap-4 mb-3">
                    <PaginationButton
                      onClick={() => draft(dprv)}
                      disabled={dprv === null}
                      text="Previous"
                      icon={<AiOutlineLeft />}
                    />
                    <PaginationButton
                      onClick={() => draft(dnext)}
                      disabled={dnext === null}
                      text="Next"
                      icon={<AiOutlineRight />}
                    />
                  </div>
                )}
              </div>
            </>
            {show && (
              <div className="mb-4 mt-3">
                <div className="w-full flex items-center justify-end mb-3 mt-4 pr-4">
                  <AiFillCloseCircle
                    onClick={() => {
                      setShow(false);
                    }}
                    className="h-8 w-8 text-pr cursor-pointer"
                  />
                </div>
                <div className="w-full ml-6 font-extrabold">
                  Username:{" "}
                  <span className="ml-4 text-green-600">{selectUserName}</span>
                </div>
                <div className="px-4">
                  <div className="w-full  px-2 py-1 mt-3 h-10 overflow-y-scroll border bg-gray-100 text-gray-600">
                    {title}
                  </div>
                  <div className="w-full p-3 mt-3 h-40 overflow-y-scroll border bg-gray-100 text-gray-600">
                    {aboutme}
                  </div>
                  <div className="w-full p-3 mt-3 h-40 overflow-y-scroll border bg-gray-100 text-gray-600">
                    {lookingfor}
                  </div>
                  <div className="flex items-center justify-end gap-3 mt-2">
                    <button
                      onClick={() =>
                        approveOrReject(`${url}profile/draft/approve`)
                      }
                      className="bg-sr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr font-bold"
                    >
                      Approve
                    </button>

                    <button
                      onClick={() =>
                        approveOrReject(`${url}profile/draft/reject`)
                      }
                      className="bg-sr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr font-bold"
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Profiles;
