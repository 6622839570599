import React, { useContext } from "react";
import { MainContent } from "../../../App";
import { useParams } from "react-router-dom";
import ProfileNavbar from "../../../layout/ProfileNavbar";
import ConfirmationMessage from "./ConfirmationMessage";

const ChangeEmailChange = () => {
  const { language, isAuthenticated } = useContext(MainContent);
  const { userid, email, token } = useParams();

  return (
    <div className="container mt-16 lg:pt-10 min-h-screen mb-4">
      <div className="container">
        {isAuthenticated && <ProfileNavbar />}
        <div className="ml-2 mr-2">
          {/* Render ConfirmationMessage component */}
          <ConfirmationMessage
            language={language}
            userid={userid}
            email={email}
            token={token}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeEmailChange;
