import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { MainContent } from "../../../../App";

function TravellingHarbits({ profileData }) {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload } = useContext(MainContent);
  const { profiletravlingattitudes } = myown_data?.data || {};

  const [traveling, setTraveling] = useState(
    profiletravlingattitudes?.length !== 0
      ? profiletravlingattitudes[0]?.travlingattitudeid
      : null
  );

  const handleSaveTraveling = () => {
    //if (traveling !== null && traveling !== "") {

    const body = JSON.stringify([
      {
        travlingattitudeid: traveling ? traveling : 0,
      },
    ]);

    HANDLE_API_REQUEST.POST(endpoints.SAVE_TRAVELLING, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      })
      .catch((error) => console.log(error));
    // } else {
    //   showtoast("", "", "Please_Select_A_Value");
    // }
  };

  return (
    <Accordion.Item
      className="my-2 rounded-md bg-white shadow-md"
      eventKey="11"
    >
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center capitalize  w-full">
          {t("Travling_Attitude")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        <select
          className={`px-2 py-2 border w-full text-sr font-medium rounded-md ${
            traveling === "" || traveling === undefined || traveling === null
              ? "bg-red-100"
              : "bg-green-100 "
          }`}
          value={traveling}
          // defaultValue={"default"}
          onChange={(e) => setTraveling(e.target.value)}
          //  className="px-2 py-2 border w-full"
        >
          <option value="">{t("Select_Options")}</option>
          {profileData.travlingattitude.map((item, ind) => (
            <option key={ind} value={item.translistid}>
              {item.name}
            </option>
          ))}
        </select>
        <div className="w-full items-end flex justify-end">
          <button
            type="button"
            onClick={handleSaveTraveling}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default TravellingHarbits;
