import React, { useEffect, useContext, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ProfileNavbar from "../../../layout/ProfileNavbar";
import FixedHeader from "../../../layout/FixedHeader";
import { MainContent } from "../../../App";
import { url } from "../../../api/Api";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { MdCancel } from "react-icons/md";
import { BsCheckCircleFill, BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import fetchHandler from "../../../api/fetchHandler";
import muslimf from "../../../images/avatar_female.jpg";
import noimage from "../../../images/avatar_male.jpg";
import SectionSeparator from "../../SectionSeparator";
import { GiArmorUpgrade } from "react-icons/gi";
import Swal from "sweetalert2";
import { customToasterStyle } from "../../../utils/constants";

const ImageAccess = () => {
  const { t } = useTranslation();
  const { changenow, language, isProfileImagesPublic, isMemberPlatinum } =
    useContext(MainContent);
  const [requestCount, setRequestCount] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [allowedacces, setAllowedacces] = useState([]);
  const [reload, setReload] = useState(false);
  const [requestedprofile, setRequestedprofile] = useState([]);
  const [reqhtml, setReqhtml] = useState();
  const [achtml, setAchtml] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const imageOnError = (event, item) => {
    event.currentTarget.src = item.gender ? noimage : muslimf;
  };

  const confirmRevokeHandler = (id, url, action) => {
    Swal.fire({
      title: t("Image_Access_Confirmation_Title"),
      html:
        action === "reject"
          ? t("Reject_Profile_Image_Access_Message")
          : t("Revoke_Profile_Image_Access_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText:
        action === "reject" ? t("Confirm_Reject") : t("Confirm_Revoke"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        //  blockProfile();
        disallow(id, url);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setRefresh(false);
    fetchHandler(`${url}profile/getwaitingdaccess`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setRequestCount(result.data);
      } else {
        setRequestCount([]);
      }
    });
  }, [changenow, refresh, language]);

  let headerWithKey = new Headers();
  headerWithKey.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
  const allowed = () => {
    fetchHandler(
      `${url}sitepage/pagetype?PageTypeId=38&LangCode=${language}`,
      "GET",
      undefined,
      headerWithKey
    ).then((result) => {
      if (result.data) {
        setAchtml(result.data.text);
      }
    });
  };

  const requested = () => {
    fetchHandler(
      `${url}sitepage/pagetype?PageTypeId=39&LangCode=${language}`,
      "GET",
      undefined,
      headerWithKey
    ).then((result) => {
      if (result.data) {
        setReqhtml(result.data.text);
      }
    });
  };

  useEffect(() => {
    setReload(false);
    fetchHandler(`${url}profile/getallowedaccess`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setAllowedacces(result.data);
      } else {
        setAllowedacces([]);
      }
    });
    allowed();
    requested();
  }, [changenow, reload, language]);

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept-Language", language || "en");

  const disallow = (id, url) => {
    let raw = JSON.stringify({
      profileid: id,
    });
    fetchHandler(url, "POST", raw, myHeaders).then((result) => {
      if (result.success) {
        setReload(true);
        setRefresh(true);
        Swal.fire({
          title: result.message,
          //text: result.message,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#032E42",
          icon: "success",
          timer: 4000,
        });
      } else {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: customToasterStyle(language),
        });
      }
    });
  };

  useEffect(() => {
    setReload(false);

    fetchHandler(`${url}profile/getwaitingdaccess`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setRequestedprofile(result.data);
      } else {
        setRequestedprofile([]);
      }
    });
  }, [changenow, reload, language]);

  const handleAllow = (id, url) => {
    let raw = JSON.stringify({
      profileid: id,
    });

    fetchHandler(url, "POST", raw, myHeaders).then((result) => {
      if (result.success) {
        setReload(!reload);
        setRefresh(true);
        Swal.fire({
          title: result.message,
          //text: result.message,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#032E42",
          icon: "success",
          timer: 4000,
        });
      } else {
        toast.error(result.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: customToasterStyle(language),
        });
      }
    });
  };

  return (
    <div className="container bg-blue-50 mt-24 lg:mt-16 lg:pt-10 min-h-screen">
      <ToastContainer />
      <div className=" w-full">
        <ProfileNavbar />
      </div>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Profile_Settings_Images_Access")}
        language={language}
      />
      <div className=" w-full pt-4">
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center mt-9  text-sr font-medium  sm:text-4xl md:text-4xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Profile_Settings_Images_Access")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        {isMemberPlatinum ? (
          <>
            <div className="mt-3 lg:mt-5 w-full">
              <Tabs
                defaultActiveKey="request"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab
                  eventKey="request"
                  title={
                    <div>
                      {t("Requested_Access_Waiting_For_Approval")}
                      {requestCount.length !== 0 && (
                        <span className="bg-pr ml-1 text-white px-1  rounded-full">
                          {requestCount.length}
                        </span>
                      )}
                    </div>
                  }
                >
                  <div className="py-4">
                    <div
                      className="w-full border-b pb-3"
                      dangerouslySetInnerHTML={{ __html: reqhtml }}
                    ></div>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2 mt-2">
                      {requestedprofile.length !== 0 && (
                        <>
                          {requestedprofile.map((item, ind) => (
                            <div
                              key={ind}
                              className="w-full rounded-sm border bg-white p-2 bottom-2 shadow-md   relative gap-2 "
                            >
                              <div className="flex items-center justify-center flex-col mb-3">
                                <Link to={"/profile/" + item.profileid}>
                                  {item.url !== "" ? (
                                    <img
                                      src={
                                        `${process.env.REACT_APP_IMAGE}` +
                                        item.url
                                      }
                                      alt=""
                                      className="h-64 w-64 object-cover "
                                      style={{ objectFit: "cover" }}
                                      onError={(event) =>
                                        imageOnError(event, item)
                                      }
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  ) : (
                                    <img
                                      src={item.gender ? noimage : muslimf}
                                      alt=""
                                      className="h-64 w-64 object-cover"
                                      style={{ objectFit: "cover" }}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  )}
                                  <h6
                                    style={{
                                      // marginLeft: "-10px",
                                      direction: "ltr",
                                    }}
                                    className="text-gray-600 text-center mt-2 font-bold"
                                  >
                                    {item.username}
                                  </h6>
                                </Link>
                              </div>
                              <div className="py-2 w-full flex items-center px-2 justify-between ">
                                <BsCheckCircleFill
                                  onClick={() =>
                                    handleAllow(
                                      item.profileid,
                                      `${url}profile/album/allow`
                                    )
                                  }
                                  className="cursor-pointer w-6 h-6  text-sr hover:text-pr"
                                  title={t("Approve_Request")}
                                />
                                <Link to={`/profile/${item.profileid}`}>
                                  {" "}
                                  <BsFillEyeFill
                                    className="cursor-pointer w-6 h-6 text-sr hover:text-pr"
                                    title={t("View_Profile")}
                                  />
                                </Link>
                                <div className="flex items-center gap-1 text-sr hover:text-pr">
                                  <MdCancel
                                    onClick={() => {
                                      confirmRevokeHandler(
                                        item.profileid,
                                        `${url}profile/album/disallow`,
                                        "reject"
                                      );
                                    }}
                                    title={t("Reject_Request")}
                                    className="cursor-pointer w-6 h-6  text-sr hover:text-pr"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    {requestedprofile.length === 0 && (
                      <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                        <h1>{t("No_one_has_been_approved")}</h1>
                      </div>
                    )}
                  </div>
                  <SectionSeparator
                    Section_Title={t("Note")}
                    paddingTop={"pt-2"}
                    paddingBotton={"pb-2"}
                  />
                  <p className="text-center text-gray-400 pb-5">
                    {t("Image_Access_Advice")}
                  </p>
                </Tab>
                <Tab
                  eventKey="access"
                  title={<div>{t("Has_Already_Access")}</div>}
                >
                  <div className="py-4">
                    <div
                      className="w-full border-b pb-3"
                      dangerouslySetInnerHTML={{ __html: achtml }}
                    ></div>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2 mt-2">
                      {allowedacces.length !== 0 && (
                        <>
                          {allowedacces.map((item, ind) => (
                            <div
                              key={ind}
                              className="w-full rounded-sm border bg-white p-2 bottom-2 shadow-md  relative gap-2"
                            >
                              <div className="flex items-center justify-center flex-col mb-3 ">
                                <Link to={"/profile/" + item.profileid}>
                                  {item.url !== "" ? (
                                    <img
                                      src={
                                        `${process.env.REACT_APP_IMAGE}` +
                                        item.url
                                      }
                                      alt=""
                                      className="h-64 w-64 object-cover"
                                      style={{ objectFit: "cover" }}
                                      onError={(event) =>
                                        imageOnError(event, item)
                                      }
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  ) : (
                                    <img
                                      src={item.gender ? noimage : muslimf}
                                      alt=""
                                      className="h-64 w-64 object-cover"
                                      style={{ objectFit: "cover" }}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  )}
                                  <h6
                                    style={{
                                      // marginLeft: "-10px",
                                      direction: "ltr",
                                    }}
                                    className="text-gray-600 text-center mt-0 font-bold"
                                  >
                                    {item.username}
                                  </h6>
                                </Link>
                              </div>
                              <div className="py-2 w-full flex items-cente border-t px-2 justify-between">
                                <div className="flex items-end w-full justify-end gap-1 text-sr">
                                  <MdCancel
                                    onClick={() => {
                                      confirmRevokeHandler(
                                        item.profileid,
                                        `${url}profile/album/disallow`,
                                        "revoke"
                                      );
                                    }}
                                    className="cursor-pointer w-6 h-6  text-sr hover:text-pr"
                                    title={t("Revoke_Request")}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    {allowedacces.length === 0 && (
                      <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                        <h1>{t("No_requests_have_been_made")}</h1>
                      </div>
                    )}
                  </div>
                  <SectionSeparator
                    Section_Title={t("Note")}
                    paddingTop={"pt-2"}
                    paddingBotton={"pb-2"}
                  />
                  <p className="text-center text-gray-400 pb-5">
                    {t("Image_Access_Revoke_Advice")}
                  </p>
                </Tab>
              </Tabs>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg mt-8">
              <p className="pb-4">{t("ProfileImageIsPublic")}</p>
              <Link
                to="/payment"
                className=" py-2 px-2 bg-pr shadow-md justify-center cursor-pointer flex items-center gap-1 transition ease-linear duration-300 hover:bg-sr text-center rounded text-white"
              >
                <p>{t("Upgrade_My_Subscription")} </p>
                <GiArmorUpgrade />
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageAccess;
