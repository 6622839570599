import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { url, errorHandler } from "../../api/Api";
import { useNavigate, useLocation } from "react-router-dom";

const PaymentCheck = () => {
  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const paymentId = searchParams.get("paymentId");
    const token = searchParams.get("token");
    const payerId = searchParams.get("PayerID");

    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(
      `${url}Payment/makepaypalpayment?paymentId=${paymentId}&token=${token}&PayerID=${payerId}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          history("/payment-success");
        } else {
          history("/payment-wrong");
        }
      });
  }, [location.search]);

  return (
    <div className="flex items-center justify-center h-screen">
      <Spinner animation="grow" variant="primary" />
    </div>
  );
};

export default PaymentCheck;
