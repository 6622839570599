import { errorHandler } from "./Api";
export const RestoreMessageRequest = async ({ inboxid }, accessToken) => {
  const res = await fetch(
    `${process.env.REACT_APP_URI}inbox/restore/messages`,
    {
      method: "POST",
      headers: new Headers({
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        inboxid,
      }),
    }
  );
  errorHandler(res);
  const data = await res.json();
  return data;
};
