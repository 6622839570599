import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import PaginationButton from "../Helpers/PaginationButton";

import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };
const SiteSettings = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [sitepage, setSitepage] = useState("");
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [lettertype, setLettertype] = useState([]);
  const [site, setSite] = useState([]);
  const [letterid, setLetterid] = useState("1");
  const [sitersetid, setSitersetid] = useState(1);

  const getAllTemplates = (url) => {
    setEdit(false);
    window.scrollTo(0, 0);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };

  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=CurrencyType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setLettertype(result.currencytype));
  };

  const getsitepage = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(`${url}sitepage/pagetype?PageTypeId=33&LangCode=en`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setSitepage(result?.data?.text);
        setTitle(result?.data?.title);
      });
  };
  const getsite = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=Site`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setSite(result.site));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getsite();
    setLoad(false);
    getlettertype();
    getAllTemplates(`${url}sitesetting/all?pageNumber=1&pageSize=10`);
    getsitepage();
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      sitesettingsid: sitersetid,
      defaultlangcode: langcode,
      defaultcurrencytypeid: letterid,
      isenabled: true,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}sitesetting/add`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => showToast(result));
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      sitesettingsid: data.sitesettingsid,
      defaultlangcode: langcode,
      defaultcurrencytypeid: letterid,
      isenabled: data.isenabled,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}sitesetting/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      });
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      sitesettingsid: id,
    });

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}siteSetting/remove`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        showToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}sitesetting?SiteSettingsId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setLangcode(result.data.defaultlangcode);
        setLetterid(result.data.defaultcurrencytypeid);
        setSitersetid(result.data.sitesettingsid);
      });
  };
  const enableDisable = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      sitesettingsid: id,
    });

    let requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}siteSetting/enable`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => showToast(result));
  };
  return (
    <div className="px-4">
      <ToastContainer />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Site Settings
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="Site List">
          <>
            {allwaitinglist.length !== 0 && lettertype.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      {/* <th>SiteId</th> */}
                      <th>Default Language</th>
                      <th>Default Currency</th>
                      <th>Enabled</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.sitesettingsid}</td>
                        <td>{item.defaultlangcode}</td>
                        <td>
                          {
                            lettertype.filter(
                              (i) =>
                                i.translistid === item.defaultcurrencytypeid
                            )[0].name
                          }
                        </td>
                        <td>{item.isenabled ? "true" : "false"}</td>
                        <td className="flex items-center justify-center gap-1">
                          <AiOutlineDelete
                            onClick={() => deleteTemplate(item.sitesettingsid)}
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.sitesettingsid);
                            }}
                            className="h-6 w-6 text-sr cursor-pointer"
                          />
                          <Switch
                            {...label}
                            color="success"
                            onChange={() => enableDisable(item.sitesettingsid)}
                            checked={item.isenabled}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-white w-full bg-sr h-24 flex items-center justify-center text-xl font-medium">
                No Search Result
              </div>
            )}
            {/* {allwaitinglist.length !== 0 && (
              <div className="float-right flex items-center gap-4 mb-3">
                {prvurl === null ? (
                  <button
                    disabled
                    className="bg-pr cursor-not-allowed flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    <AiOutlineLeft />
                    Previous
                  </button>
                ) : (
                  <button
                    onClick={() => getAllTemplates(prvurl)}
                    className="bg-pr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    <AiOutlineLeft />
                    Previous
                  </button>
                )}
                {allwaitinglist.length !== 10 ? (
                  <button
                    disabled
                    className="bg-pr cursor-not-allowed  flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    Next <AiOutlineRight />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      getAllTemplates(nxturl);
                    }}
                    className="bg-pr   flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                  >
                    Next <AiOutlineRight />
                  </button>
                )}
              </div>
            )} */}

            {allwaitinglist?.length > 0 &&
              (prvurl !== null || nxturl !== null) && (
                <div className="float-right flex items-center gap-4 mb-3">
                  <PaginationButton
                    onClick={() => getAllTemplates(prvurl)}
                    disabled={prvurl === null}
                    text="Previous"
                    icon={<AiOutlineLeft />}
                  />
                  <PaginationButton
                    onClick={() => getAllTemplates(nxturl)}
                    disabled={nxturl === null}
                    text="Next"
                    icon={<AiOutlineRight />}
                  />
                </div>
              )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-sr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className="grid w-full grid-cols-1 gap-2">
                  {/* <Form.Select
                    value={sitersetid}
                    onChange={(e) => setSitersetid(e.target.value)}
                    size="sm"
                  >
                    <option>Select Site</option>
                    {site.map((item) => (
                      <option key={item} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select> */}
                  <Form.Select
                    size="sm"
                    value={langcode}
                    onChange={(e) => setLangcode(e.target.value)}
                  >
                    <option value="en">English </option>
                    <option value="de">German </option>
                    <option value="fr">French</option>
                    <option value="it">Italien</option>
                    <option value="no">Norwegian</option>
                    <option value="ar">عربي</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="tr">Turkish</option>
                    <option value="nl">Duch</option>
                    <option value="es">Spanish</option>
                  </Form.Select>
                  <Form.Select
                    size="sm"
                    value={letterid}
                    onChange={(e) => setLetterid(e.target.value)}
                  >
                    {lettertype.length !== 0 && (
                      <>
                        {lettertype.map((item, ind) => (
                          <option key={ind} value={item.translistid}>
                            {item.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </div>

                <div className="flex justify-end w-full">
                  <Button
                    variant="primary"
                    className="mt-2 bg-sr hover:bg-pr "
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="New">
          <form onSubmit={(e) => createTemplate(e)}>
            <div className="py-6 text-gray-600">
              <h1 className="text-4xl capitalize">{title}</h1>
              <p className="text-sm text-gray-400 mt-2">{sitepage}</p>
            </div>
            <div className="grid grid-cols-1 gap-2  w-full mb-3">
              {/* <Form.Select
                onChange={(e) => setSitersetid(e.target.value)}
                size="sm"
              >
                <option>Select Site</option>
                {site.map((item) => (
                  <option key={item} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </Form.Select> */}
              <Form.Select
                size="sm"
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option>Select Language </option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                size="sm"
                onChange={(e) => setLetterid(e.target.value)}
              >
                <option>Select Currency </option>
                {lettertype.length !== 0 && (
                  <>
                    {lettertype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
            </div>

            <button
              className="mt-2 px-3 py-2 rounded-md text-sm text-white bg-sr hover:bg-pr"
              type="submit"
            >
              Save
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default SiteSettings;
