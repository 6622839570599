import React, { useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import { MainContent } from "../../../App";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { customToasterStyle } from "../../../utils/constants";
import FixedHeader from "../../../layout/FixedHeader";

const EmailChange = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { language, changnow, ownerEmail } = useContext(MainContent);
  const [emailpage, setEmailpage] = useState(null);
  const [newemail, setNewemail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [error, setError] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("focus").focus();
    }, 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const getContactReceipt = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=43&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })

      .then((result) => setEmailpage(result.data));
  };

  const showToast = (result) => {
    if (result.success) {
      history("/change-email-receipt");
    } else {
      toast.error(result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const handleValidation = () => {
    let isValid = true;

    if (!newemail) {
      setError("emailErrorMessage");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(newemail)) {
      setError("emailErrorInvalidMessage");
      isValid = false;
    } else if (!confirmEmail) {
      setError("Confirm_Email_IsRequired");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(confirmEmail)) {
      setError("Enter_Confirm_Email");
      isValid = false;
    } else if (newemail !== confirmEmail) {
      setError("Confirm_Email_Does_Not_Match");
      isValid = false;
    } else if (newemail === ownerEmail) {
      setError("Email_Address_Must_Be_Different_From_The_Current_One");
      isValid = false;
    } else {
      setError("");
    }

    return isValid;
  };

  const changeEmail = (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      email: newemail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}identity/changeemail`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getContactReceipt();
  }, [language, changnow]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Change_Email_Address")}
        language={language}
      />
      <div className="mt-2 flex items-center flex-col my-502">
        <div className=" w-full flex items-center mb-16 justify-center ">
          <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Change_Email_Address")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        <div className="p-3 bg-white rounded-md shadow-md  w-full">
          <div className="bg-white py-5 px-5 w-full  rounded-lg font-medium">
            {emailpage !== null && (
              <div dangerouslySetInnerHTML={{ __html: emailpage.text }}></div>
            )}
          </div>
          <div className="w-full">
            <div className="flex flex-col sm:flex-row px-5">
              <span className="text-gray-300 font-bold mb-2 sm:mb-0 mr-1">
                {t("CurrentEmail_Address")}:
              </span>
              <span className="text-green-700 font-bold">{ownerEmail}</span>
            </div>
          </div>
          <form
            className="my-3 px-5"
            onSubmit={(e) => changeEmail(e)}
            noValidate="noValidate"
          >
            <input
              autoComplete="off"
              id="focus"
              type="email"
              onChange={({ target }) => {
                setNewemail(target.value);
              }}
              required
              className="py-1 px-2 border shadow-md outline-none rounded-md my-1 w-full"
              placeholder={t("Insert_New_Mail")}
              style={{ direction: newemail ? "ltr" : "" }}
            />
            <input
              autoComplete="off"
              type="email"
              name="confirmChangeEmail"
              onChange={({ target }) => {
                setConfirmEmail(target.value);
              }}
              required
              className="py-1 px-2 border shadow-md outline-none rounded-md my-1 w-full"
              placeholder={t("Confirm_New_Mail_PlaceHolder")}
              style={{ direction: confirmEmail ? "ltr" : "" }}
            />
            {error && <span className="text-red-600">{t(error)}</span>}

            <div className="flex justify-center md:justify-end text-center">
              <button
                type="submit"
                className={
                  "px-2 py-2 mt-2 border text-md rounded-md font-bold text-white w-150 bg-sr hover:bg-pr"
                }
              >
                {t("Submit_ChangeEmail")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmailChange;
