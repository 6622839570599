import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineExclamationCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const DynamicButton = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const handleClick = () => {
    history("/welcomepage");
  };

  return (
    <button
      onClick={handleClick}
      // className="text-lg px-6 py-3 bg-red-500 text-white rounded-md shadow-md transform transition-transform active:translate-y-1 active:shadow-none"
      // className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition"
      // className="flex items-center px-4 py-2 bg-yellow-400 text-yellow-900 rounded-md shadow-md hover:bg-yellow-500 hover:text-white transition"
      className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 hover:text-white transition font-bold"
    >
      <AiOutlineExclamationCircle className="mr-2 text-lg lg:text-xl font-bold text-yellow-300" />{" "}
      {t("Complete_Your_Profile")}
    </button>
  );
};

export default DynamicButton;
