import { MainContent } from "../App";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export function useTimeFunction() {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);
  return function time_ago(time) {
    switch (typeof time) {
      case "number":
        break;
      case "string":
        time = +new Date(time);
        break;
      case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, t("seconds"), 1], // 60
      [120, t("1 minute ago"), t("1 minute from now")], // 60*2
      [3600, t("minutes"), 60], // 60*60, 60
      [7200, t("1 hour ago"), t("1 hour from now")], // 60*60*2
      [86400, t("hours"), 3600], // 60*60*24, 60*60
      [172800, t("Yesterday"), t("Tomorrow")], // 60*60*24*2
      [604800, t("days"), 86400], // 60*60*24*7, 60*60*24
      [1209600, t("Last week"), t("Next week")], // 60*60*24*7*4*2
      [2419200, t("weeks"), 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, t("Last month"), t("Next month")], // 60*60*24*7*4*2
      [29030400, t("months"), 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, t("Last year"), t("Next year")], // 60*60*24*7*4*12*2
      [2903040000, t("years"), 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, t("Last century"), t("Next century")], // 60*60*24*7*4*12*100*2
      [58060800000, t("centuries"), 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = t("ago"),
      list_choice = 1;

    if (seconds === 0) {
      return t("Just now");
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = t("from now");
      list_choice = 2;
    }
    var i = 0,
      format;
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == "string") return format[list_choice];
        else
          return language === "ar" || language === "fr"
            ? token + " " + Math.floor(seconds / format[2]) + " " + format[1]
            : Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
      }
    return time;
  };
}
