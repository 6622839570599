import React, { useEffect } from "react";
import heart from "../images/heart.gif";

const Spinner = ({ timeoutDuration = 15000, toppx = "mt-[20vh]" }) => {
  useEffect(() => {
    // Set a timeout to reload the page if loading takes longer than the specified duration
    const timer = setTimeout(() => {
      window.location.reload();
    }, timeoutDuration);

    // Clear the timer if the component unmounts before the timeout
    return () => clearTimeout(timer);
  }, [timeoutDuration]);

  return (
    <div
      // className="flex justify-center min-h-screen mt-[20vh]"
      className={`flex justify-center min-h-screen ${toppx}`}
      role="status"
      aria-label="Loading"
    >
      <img
        src={heart}
        className="h-52 rounded-full"
        alt="Loading spinner"
        onContextMenu={(e) => e.preventDefault()}
        onError={(event) => (event.target.style.display = "none")}
        style={{
          position: "relative",
          zIndex: 99999999999999,
          marginTop: "50px",
        }} // Optional: Set z-index here too
      />
    </div>
  );
};

export default Spinner;
