import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { MainContent } from "../../../../App";
import ProfileNavbar from "../../../../layout/ProfileNavbar";
import Spinner from "../../../../components/Spinner";
import { useGetAge, useGetProfileData } from "../../../../hooks/CustomHooks";
import BasicInfomation from "./BasicInfomation";
import ProfileInformation from "./ProfileInformation";
import YourLocation from "./YourLocation";
import Appearance from "./Appearance";
import Persolnality from "./Persolnality";
import SpokenLanguages from "./SpokenLanguages";
import LifeStyles from "./LifeStyles";
import BackgroundAndCulturalValues from "./BackgroundAndCulturalValues";
import SportsHarbits from "./SportsHarbits";
import TravellingHarbits from "./TravellingHarbits";
import Hobbies from "./Hobbies";
import FixedHeader from "../../../../layout/FixedHeader";
import DynamicButton from "./../../../../components/DynamicButton";

const ProfileEdit = () => {
  const { t } = useTranslation();
  const { language, changnow, myown_data } = useContext(MainContent);
  const [refresh, setRefresh] = useState(false);
  const { data: profileData } = useGetProfileData(language);
  const { data: ageData } = useGetAge(language) || {};
  const { age } = ageData || {};
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isProfileContentComplete, setIsProfileContentComplete] = useState(
    myown_data?.data?.profilecontent !== null ||
      myown_data?.data?.profilecontentdraft !== null
  );
 const [profileComplet, setProfileComplet] = useState(
   myown_data.data.profileattributes.profilepercentcomplete || 0
 );

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setRefresh(false);
  }, [language, changnow, refresh]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Profile_Settings_Edit_Profile")}
        language={language}
      />

      <div className=" bg-blue-50">
        <>
          <ToastContainer />
          <div className="container mt-24 lg:mt-16 lg:pt-10 min-h-screen pb-12">
            <div className=" w-full px-2">
              <ProfileNavbar />
            </div>

            {profileData !== null ? (
              <>
                <div className="w-full flex flex-col lg:flex-row items-center gap-2 mb-10">
                  <div className=" flex items-center justify-center flex-grow">
                    <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
                      <BsBlockquoteLeft className="text-sr" />
                      {t("Profile_Settings_Edit_Profile")}
                      <BsBlockquoteRight className="text-sr" />
                    </h1>
                  </div>
                  {(isProfileContentComplete === false || profileComplet < 60) && (
                      <div className="flex justify-center w-full lg:w-auto lg:ml-auto mt-2 lg:mt-0">
                        <DynamicButton />
                      </div>
                    )}
                </div>
                <div className=" px-0 lg:px-12">
                  <div className=" p-0 lg:p-8">
                    <div className="mt-5 pt-2 ">
                      <BasicInfomation {...{ age }} />
                      <Accordion className="mt-5" defaultActiveKey="1">
                        <ProfileInformation />
                        <YourLocation profileData={profileData} />
                        <Appearance profileData={profileData} />
                        <Persolnality profileData={profileData} />
                        <SpokenLanguages profileData={profileData} />
                        <LifeStyles profileData={profileData} />
                        <BackgroundAndCulturalValues
                          profileData={profileData}
                        />
                        <SportsHarbits profileData={profileData} />
                        <TravellingHarbits profileData={profileData} />
                        <Hobbies profileData={profileData} />
                      </Accordion>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <Spinner/>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default ProfileEdit;
