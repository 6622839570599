import { indigo } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import * as React from 'react';

export default function Redio({val}) {
  return (
    <div>
      <Radio
       value={val}
        sx={{
          color: indigo[900],
          '&.Mui-checked': {
            color: indigo[900],
          },
        }}
      />
    </div>
  );
}
