import React, { useContext, useState, useEffect } from "react";

import { BiFemale, BiMale } from "react-icons/bi";
import { VscThreeBars } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Cart from "../card/Cart";
import { url } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import fetchHandler from "../../../api/fetchHandler";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Online = () => {
  const { t } = useTranslation();
  const { gender } = useParams();
  //const navigate = useNavigate();

  const { grid, setGrid, changnow, language, myown_data } =
    useContext(MainContent);
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  const showFontPageData = () => {
    fetchHandler(`${url}profile/getfrontpageprofiles`, "GET").then((result) => {
      setFontpageProfiles(result.data);
    });
  };
  const prev = (crl) => {
    fetchHandler(crl, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult(null);
        saveProfileIds(null);
      }
    });
    window.scrollTo(0, 0);
    showFontPageData();
  };
  const next = (crl) => {
    fetchHandler(crl, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult(null);
        saveProfileIds(null);
      }
    });
    window.scrollTo(0, 0);
    showFontPageData();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    showFontPageData();

    //let selectedGender = gender === undefined || gender === null ? "" : `?gender=${gender}`;
    //etchHandler(`${url}profile/online${selectedGender}`, "GET").then(
    fetchHandler(`${url}profile/online`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult(null);
        saveProfileIds(null);
      }
    });
  }, [changnow, language, gender]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Online_Users")}
        language={language}
      />
      <div>
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Online_Users")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        <div className="mt-3 pb-3">
          {searchResult !== null ? (
            <>
              {searchResult.length !== 0 ? (
                <>
                  <div className="flex items-center justify-end gap-3 mt-3">
                    {/* {gender?.toLowerCase() === "male" && (
                      <div>
                        <NavLink to={"/search/online"}>
                          <BiFemale className="cursor-pointer text-3xl text-pr" />
                        </NavLink>
                      </div>
                    )}
                    {gender?.toLowerCase() === "female" && (
                      <div>
                        <NavLink to={"/search/online"}>
                          <BiMale className="cursor-pointer text-3xl text-pr" />
                        </NavLink>
                      </div>
                    )} */}
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {searchResult.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                  <div className="w-full flex mt-14 items-center justify-end gap-5">
                    {prvurl !== null || nxturl !== null ? (
                      <>
                        {prvurl !== null ? (
                          <button
                            onClick={() => prev(prvurl)}
                            className="px-3 py-2 bg-pr text-white rounded-md hover:bg-sr"
                          >
                            {t("Previous")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="px-3 py-2 bg-pr cursor-not-allowed text-white rounded-md hover:bg-sr"
                          >
                            {t("Previous")}
                          </button>
                        )}
                        {searchResult.length !== 10 ? (
                          <button
                            disabled
                            className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        ) : (
                          <button
                            onClick={() => next(nxturl)}
                            className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                  <p>{t("No_Data_Found")}</p>
                </div>
              )}

              {fontpageProfiles.length !== 0 && (
                <>
                  {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
                  {t("Recommended_Profiles")}
                </h1> */}
                  <SectionSeparator
                    Section_Title={t("Recommended_Profiles")}
                  ></SectionSeparator>
                  <div className="flex items-center justify-end gap-3 mt-3">
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-1 justify-center gap-2 items-center"
                    }
                  >
                    {fontpageProfiles.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg sm:text-md lg:text-lg ">
              {myown_data.data.profileattributes.gender ? (
                <p>{t("No_Female_Is_Found_Online")}</p>
              ) : (
                <p>{t("No_Male_Is_Found_Online")}</p>
              )}
              {/* <p>{t("No_One_Is_Found_Online")}</p> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Online;
