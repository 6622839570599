import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { url } from "../api/Api";
import { MainContent } from "../App";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Policy = (props) => {
  const { t } = useTranslation();
  const [sitepagedata, setSitepagedata] = useState("");
  const { language } = useContext(MainContent);
  const navigate = useNavigate();
  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${url}sitepage/pagetype?PageTypeId=12&LangCode=${language}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.data !== null) {
          setSitepagedata(result.data.text);
        } else {
          setSitepagedata("");
        }
      })
      .catch((err) => {
        props.setLoginshow(false);
        navigate("/error");
      });
  }, [language]);
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className
      >
        <Modal.Header>
          <div
            className={
              language === "ar"
                ? "w-full flex items-center justify-end px-2 font-extrabold"
                : "w-full flex items-center justify-start px-2 font-extrabold"
            }
          >
            {t("Privacy_Policy")}
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "540px",
            overflowY: "auto",
          }}
        >
          {/* <p>{sitepagedata}</p> */}

          <div
            className="w-full bg-white shadow-md p-3 rounded-md"
            dangerouslySetInnerHTML={{ __html: sitepagedata }}
          ></div>
        </Modal.Body>
        <div
          className={
            language === "ar"
              ? "w-full flex items-center justify-start px-2 py-2"
              : "w-full flex py-2 items-center justify-end px-2"
          }
        >
          <Button className="bg-sr hover:bg-pr" onClick={props.onHide}>
            {t("Close")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Policy;
