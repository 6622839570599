import React, { useEffect, useState, useContext } from "react";
import { url } from "../api/Api";
import { MainContent } from "../App";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import fetchHandler from "../api/fetchHandler";
import ProfileNavbar from "./../layout/ProfileNavbar";
import { FaUserSlash } from "react-icons/fa";
import FixedHeader from "./../layout/FixedHeader";

const ProfileNotFound = () => {
  const { t } = useTranslation();
  const [html, setHtml] = useState(null);
  const { language, isAuthenticated } = useContext(MainContent);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    fetchHandler(
      `${url}sitepage/pagetype?PageTypeId=30&LangCode=${language}`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      if (result.data) {
        setHtml(result.data);
      }
    });
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" min-h-screen">
      <div className=" w-full lg:mt-20 pt-2 ">
        <div className="container">
          <div
            className={
              isAuthenticated ? "mb-2 px-2 mt-3" : "mb-2 px-2 mt-24 lg:mt-4"
            }
          >
            {isAuthenticated && (
              <div className="mb-2 ">
                <ProfileNavbar />
              </div>
            )}
            <FixedHeader
              scrollPosition={scrollPosition}
              headerText={t("Profile_Not_Found")}
              language={language}
            />
            <div className="bg-white py-5 px-1 w-full shadow-md rounded-lg font-medium ">
              <h1 className=" text-9xl text-sr text-center flex justify-center items-center">
                <FaUserSlash className=" text-sr" text-center />
              </h1>
              <div className="bg-white py-5 px-1 w-full rounded-lg font-medium ">
                <div className="px-5 lg:px-20 text-sr font-medium text-center border-0">
                  {html !== null && (
                    <div
                      className={`px-0 lg:px-20 text-sr font-medium ${
                        language === "ar" ? "text-right" : "text-left"
                      }`}
                      dangerouslySetInnerHTML={{ __html: html.text }}
                    ></div>
                  )}
                </div>
                <div className="text-center  mt-8">
                  <Link
                    to="/"
                    className=" px-4 py-2 mt-2 rounded-3xl bg-sr hover:bg-pr text-white "
                  >
                    {t("Back_To_Home")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNotFound;
