import Cookies from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import { url, errorHandler } from "../../api/Api";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { MainContent } from "../../App";
import { useNavigate } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";

const ChagePassReceipt = () => {
  const [hmtl, setHmtl] = useState(null);
  const { language, isAuthenticated, setisAuthenticated } =
    useContext(MainContent);
  const history = useNavigate();

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=49&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setHmtl(result.data.text));
  }, [language]);

  useEffect(() => {
    let timeoutId;
    if (isAuthenticated) {
      timeoutId = setTimeout(() => {
        removeTokensAndlogOut();
      }, 10000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const removeTokensAndlogOut = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      refreshToken: `${Cookies.get("refreshToken")}`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}identity/signout`, requestOptions);
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    setisAuthenticated(false);
   // localStorage.setItem("isAuthenticated", false);
    history("/");
    Cookies.remove("location");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-16 lg:pt-10 min-h-screen">
      <div className="container">
        <div className="mb-2">{isAuthenticated && <ProfileNavbar />}</div>
        <div className="ml-2 mr-2 ">
          <div className="items-center justify-center text-center mt-2 mb-8 bg-white py-5 px-5 w-full shadow-md rounded-lg font-medium ">
            <div className="flex place-content-center mb-4 h3">
              <BsCheckCircleFill className="text-green-600 text-6xl" />
            </div>
            <div>
              {hmtl !== null && (
                <div dangerouslySetInnerHTML={{ __html: hmtl }}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChagePassReceipt;
