import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainContent } from "../App";
import { errorHandler } from "../api/Api";
import HtmlContent from "../components/HtmlContent";
import endpoints, { HANDLE_API_REQUEST } from "../api/EndPoints";
import { FaExclamationCircle } from "react-icons/fa";
import FixedHeader from "../layout/FixedHeader";

const ConfirmEmailMsg = () => {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);
  const [html, sethtml] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    HANDLE_API_REQUEST.GET(
      endpoints.CONFIRM_EMAIL_MSG(language),
      null,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => sethtml(result.data.text));
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("MailConfirmationRequest")}
        language={language}
      />
      <div className=" min-h-screen">
        <div className=" w-full mt-20 pt-3 ">
          <div className="container">
            <div className="ml-2 mr-2 ">
              <div className="items-center justify-center text-center mt-2 mb-8 bg-white  py-5 px-1 w-full shadow-md rounded-lg font-medium ">
                <div className="w-full h-full flex items-center justify-center mb-4">
                  <FaExclamationCircle className="text-yellow-400 text-6xl" />
                </div>
                {html !== null && <HtmlContent content={html} />}
                {/* <div className="flex gap-2 items-center justify-center "> */}
                <div className="w-full flex flex-col sm:flex-row gap-1 justify-center items-center mt-3">
                  <Link
                    to="/"
                    className="px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
                  >
                    {t("Back_To_Home")}
                  </Link>
                  <Link
                    to="/public-contact"
                    className=" px-4 py-2 rounded-md bg-sr hover:bg-pr text-white w-full sm:w-auto"
                  >
                    {t("Footer_Contact_Us")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmailMsg;
