import React from "react";
import { BsBlockquoteLeft, BsBlockquoteRight } from "react-icons/bs";

const FixedHeader = ({ scrollPosition, headerText, language }) => {
  return (
    <>
      {scrollPosition > 200 && (
        <div
          style={{ zIndex: "200" }}
          className="fixed top-20 left-0 h-15 w-full bg-pr shadow-md flex items-center justify-center lg:px-16"
        >
          <div className="flex mt-0 items-center justify-center h-20">
            <h1 className="font-medium sm:text-xl md:text-2xl lg:text-4xl  text-center mt-8  text-sr   flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {headerText}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default FixedHeader;
