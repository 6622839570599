import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { errorHandler, url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";

import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";

const HelpAdmin = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [lettertype, setLettertype] = useState([]);
  const [letterid, setLetterid] = useState(0);

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setAllwaitinglist(result.data);
        } else {
          showToast(result);
          setAllwaitinglist([]);
        }
      });
  };
  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=HelpType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setLettertype(result.helptype));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);
    getlettertype();
  }, [changnow, load]);
  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0].message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const createTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      helptypeid: letterid,
      key: title,
      text: body,
      langcode: langcode,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}help/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setTitle("");
          setBody("");
        }
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      })
      .catch();
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      helpid: letterid,
      helptypeid: data.helptypeid,
      key: title,
      text: body,
      langcode: langcode,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}help/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setEdit(false);
        }
        showToast(result);
      });
  };
  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      helpid: id,
    });

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}help/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        getAllTemplates(
          `${url}help/bytype?LangCode=${langcode}&HelpType=${letterid}`
        );
        showToast(result);
        setEdit(false);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}help/byId?HelpId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setLangcode(result.data.langcode);
        setLetterid(result.data.helpid);
        setTitle(result.data.key);
        setBody(result.data.text);
      });
  };

  return (
    <div className="px-4 ">
      <ToastContainer />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Help
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="Edit">
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                getAllTemplates(
                  `${url}help/bytype?LangCode=${langcode}&HelpType=${letterid}`
                );
              }}
              className="grid grid-cols-1 lg:grid-cols-3 gap-3  w-full mb-3"
            >
              <Form.Select
                size="md"
                required
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option>Select Language</option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                size="md"
                required
                onChange={(e) =>
                  setLetterid(e.target.value == 0 ? "" : e.target.value)
                }
              >
                <option value={0}>Select HelpType</option>
                {lettertype.length !== 0 && (
                  <>
                    {lettertype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
              <div>
                {letterid !== 0 ? (
                  <button
                    type="submit"
                    className="px-2 py-2 rounded-md bg-pr text-white"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    disabled
                    className="px-2 py-2 rounded-md bg-gray-400 text-white"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
            {allwaitinglist.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Key</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.key}</td>
                        <td className="flex items-center justify-center gap-2">
                          <AiOutlineDelete
                            onClick={() => deleteTemplate(item.helpid)}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.helpid);
                            }}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              "No search Result"
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className="grid w-full grid-cols-1 gap-2">
                  <Form.Select
                    size="md"
                    value={langcode}
                    disabled
                    onChange={(e) => setLangcode(e.target.value)}
                  >
                    <option value="en">English </option>
                    <option value="de">German </option>
                    <option value="fr">French</option>
                    <option value="it">Italien</option>
                    <option value="no">Norwegian</option>
                    <option value="ar">عربي</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="tr">Turkish</option>
                    <option value="nl">Dutch</option>
                    <option value="es">Spanish</option>
                  </Form.Select>
                  <Form.Select
                    size="md"
                    disabled
                    value={letterid}
                    onChange={(e) => setLetterid(e.target.value)}
                  >
                    {lettertype.length !== 0 && (
                      <>
                        {lettertype.map((item, ind) => (
                          <option key={ind} value={item.translistid}>
                            {item.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </div>

                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Help Key</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Help Content</Form.Label>
                  <Form.Control
                    value={body}
                    as="textarea"
                    onChange={(e) => setBody(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <Button variant="primary" className="mt-2" type="submit">
                  Update
                </Button>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="Create">
          <form onSubmit={(e) => createTemplate(e)}>
            <div className="grid grid-cols-1 gap-2  w-full mb-3">
              <Form.Select
                required
                size="md"
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option value="">Select Language</option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                required
                size="md"
                onChange={(e) => setLetterid(e.target.value)}
              >
                <option value="">Select HelpType</option>
                {lettertype.length !== 0 && (
                  <>
                    {lettertype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
            </div>
            <Form.Group className="my-3" controlId="formGridAddress2">
              <Form.Label>Alert Title</Form.Label>
              <Form.Control
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="formGridState">
              <Form.Label>Alert Content</Form.Label>
              <Form.Control
                value={body}
                required
                as="textarea"
                onChange={(e) => setBody(e.target.value)}
                rows={3}
              />
            </Form.Group>

            <button
              className="mt-2 px-3 py-2 text-sm text-white bg-sr rounded-md"
              type="submit"
            >
              Save
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default HelpAdmin;
