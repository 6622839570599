import { errorHandler } from "./Api";
export const sendMessageRequest = async (
  { newMessage, activeProfileId },
  accessToken
) => {
  const lang = localStorage.getItem("i18nextLng") || "en";
  const res = await fetch(`${process.env.REACT_APP_URI}inbox/newmessage`, {
    method: "POST",
    headers: new Headers({
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Accept-Language": lang || "en",
    }),
    body: JSON.stringify({
      body: newMessage,
      ToProfileId: activeProfileId,
    }),
  });

  errorHandler(res);
  const data = await res.json();

  return data;
};
