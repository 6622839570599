import React, { useContext } from "react";
import { MainContent } from "../../../../App";
import {
  useGetNotifications,
  useGetNotificationScheduler,
} from "../../../../hooks/CustomHooks";
import Notification from "./Notification";

const NotificationsList = () => {
  const { language } = useContext(MainContent);

  const { data: notifications } = useGetNotifications(language);
  const { data: schedulersData } = useGetNotificationScheduler(language);
  const { notificationscheduler: schedulers } = schedulersData || {};

  return (
    <div>
      <div className="flex flex-col gap-2 pl-5">
        {notifications?.data?.map((notification, index) => (
          <Notification
            key={index}
            notification={notification}
            schedulers={schedulers}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationsList;
