import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MainContent } from "../App";
import logo from "../images/logo.png";
import { AiFillCloseCircle } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Login from "../authentication/Login";
import Register from "../authentication/Register";
import Policy from "../authentication/Policy";
import Forgetpassword from "../authentication/Forgetpassword";
// import Flag from "react-world-flags";

const LoginOpen = (props) => {
  const [loginOrRegistration, setLoginOrRegistration] = useState(1);
  const { t } = useTranslation();
  const [forgetPass, setForgetPass] = useState(false);
  const { openReg, setOpenReg } = useContext(MainContent);

  useEffect(() => {
    if (openReg === true) {
      setLoginOrRegistration(2);
    }
  }, [openReg]);
  const { openReg: a, setModalShow, setLoginshow, ...modalProps } = props;
  return (
    <Modal
      {...modalProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        <div className=" w-full flex justify-end pr-3 pt-3">
          <AiFillCloseCircle
            onClick={() => {
              setLoginshow(false);
              setOpenReg(false);
            }}
            className="w-9 h-9 text-sr hover:text-pr cursor-pointer"
          />
        </div>
        <div className="grid grid-cols-2 lg:px-8">
          <div
            onClick={() => {
              setLoginOrRegistration(1);
              setForgetPass(false);
            }}
            className={
              loginOrRegistration === 1
                ? "border-b-2 border-pr cursor-pointer flex items-center justify-center py-2 text-pr font-medium text-xl"
                : "border-b-2 border-white cursor-pointer flex items-center justify-center py-2 text-sr font-medium text-xl"
            }
          >
            {t("Login")}
          </div>
          <div
            onClick={() => {
              setLoginOrRegistration(2);
              setForgetPass(false);
            }}
            className={
              loginOrRegistration === 2
                ? "border-b-2 border-pr cursor-pointer flex items-center justify-center py-2 text-pr font-medium text-xl"
                : "border-b-2 border-white cursor-pointer flex items-center justify-center py-2 text-sr font-medium text-xl"
            }
          >
            {t("Register")}
          </div>
        </div>
        <>
          {forgetPass === false ? (
            <>
              <>
                {loginOrRegistration === 1 && (
                  <Login
                    setForgetPass={setForgetPass}
                    setLoginOrRegistration={setLoginOrRegistration}
                  />
                )}
              </>
              <>
                {loginOrRegistration === 2 && (
                  <Register setModalShow={setModalShow} />
                )}
              </>
            </>
          ) : (
            <>
              <Forgetpassword
                setForgetPass={setForgetPass}
                setLoginshow={setLoginshow}
                setModalShow={setModalShow}
              />
            </>
          )}
        </>
      </div>
    </Modal>
  );
};

const Navbar = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [loginshow, setLoginshow] = useState(false);
  const location = useLocation();
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );

  const {
    changeDirection,
    changeDirectionsame,
    openReg,
    language,
    setOpenReg,
    isAuthenticated,
    setisAuthenticated,
    setMyown_data,
  } = useContext(MainContent);

  useEffect(() => {
    // setCurrentSelectedLanguage(localStorage.getItem("i18nextLng") || "en");
    setCurrentSelectedLanguage(language || "en");
  }, [language]);

  const removeTokens = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const raw = JSON.stringify({
      refreshToken: `${Cookies.get("refreshToken")}`,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}identity/signout`, requestOptions);
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isAuthenticated");
    setMyown_data(null);
    setisAuthenticated(false);
    //localStorage.setItem("isAuthenticated", false);
    history("/");
  };

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (openReg === true) {
      setLoginshow(true);
    }
  }, [openReg]);

  useEffect(() => {
    if (Cookies.get("location") && isAuthenticated === false) {
      setLoginshow(true);
    }

    if (isAuthenticated === true && Cookies.get("location")) {
      Cookies.remove("location");
    }
  }, [isAuthenticated]);

  return (
    <>
      <Policy
        show={modalShow}
        onHide={() => setModalShow(false)}
        setLoginshow={setLoginshow}
      />
      {isAuthenticated === false && (
        <LoginOpen
          show={loginshow}
          setModalShow={(value) => setModalShow(value)}
          setLoginshow={(value) => setLoginshow(value)}
          openReg={openReg ? true : false}
          onHide={() => {
            setLoginshow(false);
            setOpenReg(false);
          }}
        />
      )}

      <div
        data-position="fixed"
        style={{ zIndex: "1000" }}
        className={
          isAuthenticated
            ? "flex py-2 fixed top-0 left-0 w-full items-center justify-between bg-sr px-4 "
            : location.pathname === "/"
            ? "flex pt-3 absolute top-0 left-0  items-center justify-between w-full px-4"
            : "flex pt-3 fixed top-0 left-0 w-full  items-center bg-sr justify-between px-4"
        }
      >
        <div className="w-20 h-20">
          <Link to="/">
            <img
              src={logo}
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
              alt=""
              onError={(event) => (event.target.style.display = "none")}
              onContextMenu={(e) => e.preventDefault()}
            />
          </Link>
        </div>
        <div className="flex items-center gap-3">
          <select
            value={currentSelectedLanguage}
            onChange={(e) => {
              e.target.value !== "ar"
                ? changeDirectionsame(e.target.value)
                : changeDirection(e.target.value);
              localStorage.setItem("i18nextLng", e.target.value);
            }}
            className=" text-white pl-1 border-none outline-none text-sm bg-transparent w-50"
          >
            <option value="da" disabled={currentSelectedLanguage === "da"}>
              {t("Language_Danish")}
            </option>
            <option value="en" disabled={currentSelectedLanguage === "en"}>
              {t("Language_English")}
            </option>
            <option value="fr" disabled={currentSelectedLanguage === "fr"}>
              {t("Language_French")}
            </option>
            <option value="de" disabled={currentSelectedLanguage === "de"}>
              {t("Language_German")}
            </option>
            <option value="it" disabled={currentSelectedLanguage === "it"}>
              {t("Language_Italien")}
            </option>
            <option value="no" disabled={currentSelectedLanguage === "no"}>
              {t("Language_Norwegian")}
            </option>
            <option value="es" disabled={currentSelectedLanguage === "es"}>
              {t("Language_Spanish")}
            </option>
            <option value="sv" disabled={currentSelectedLanguage === "sv"}>
              {t("Language_Swedish")}
            </option>
            <option value="tr" disabled={currentSelectedLanguage === "tr"}>
              {t("Language_Turkish")}
            </option>
            <option value="nl" disabled={currentSelectedLanguage === "nl"}>
              {t("Language_Dutch")}
            </option>
            <option value="ar" disabled={currentSelectedLanguage === "ar"}>
              {t("Language_Arabic")}
            </option>
          </select>
          <ul className="navbar-navmb-2 mb-md-0 flex items-center gap-2">
            {isAuthenticated ? (
              <li
                onClick={removeTokens}
                className="bg-pr py-2 cursor-pointer font-medium  text-center w-24 text-xs rounded transition ease-linear duration-150 hover:bg-sr text-sr hover:text-pr"
              >
                {t("Logout")}
              </li>
            ) : (
              <div
                onClick={() => setLoginshow(true)}
                className="bg-pr py-2 cursor-pointer text-center w-24 font-medium text-xs rounded transition ease-linear duration-150 hover:bg-sr text-sr hover:text-pr"
              >
                {t("Login")}
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
