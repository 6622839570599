import React, { useContext, useEffect, useState } from "react";
import { MainContent } from "../App";
import {  errorHandler } from "../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../api/EndPoints";
const Confirmemail = () => {
  const { language } = useContext(MainContent);
  const [hmtl, setHmtl] = useState(null);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    HANDLE_API_REQUEST.GET(
      endpoints.CONFIRM_EMAIL(language),
      null,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setHmtl(result.data.text));
  }, [language]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <div className="container mt-28 lg:pt-10 flex items-center justify-center min-h-screen mb-4">
      <div>
        {hmtl !== null && (
          <div dangerouslySetInnerHTML={{ __html: hmtl }}></div>
        )}
      </div>
    </div>
  );
};

export default Confirmemail;
