import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { errorHandler, url } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import Swal from "sweetalert2";

const SystemAlert = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [alertTypeData, setAlertTypeData] = useState([]);
  const [alertTypeid, setAlertTypeid] = useState("");
  const [langcodeEdit, setLangcodeEdit] = useState("en");
  const [alertTypeidEdit, setAlertTypeidEdit] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState();
  const [loadAlertList, setLoadAlertList] = useState(true);
  const currentDate = new Date();

  const getAllTemplates = (url) => {
    setEdit(false);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setAllwaitinglist(result.data);
        } else {
          showToast(result);
          setAllwaitinglist([]);
        }
      });
  };
  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=SystemAlertType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setAlertTypeData(result.systemalerttype));
  };

  useEffect(() => {
    setLoad(false);
    getlettertype();
  }, [changnow, load]);

  useEffect(() => {
    if (
      loadAlertList ||
      langcode ||
      alertTypeid ||
      langcode === "" ||
      alertTypeid === ""
    ) {
      let aTypeid =
        alertTypeid === 0 || alertTypeid === "" || alertTypeid === undefined
          ? ""
          : `&SystemAlertTypeId=${alertTypeid}`;
      let searchUrl = `${url}systemalert/search?LangCode=${langcode}${text}${aTypeid}&SiteSettingsId=1&PaginationQuery.PageNumber=1&PaginationQuery.PageSize=10`;
      getAllTemplates(searchUrl);
      setLoadAlertList(false);
    }
  }, [loadAlertList, langcode, alertTypeid]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const errorMessage = result.errors[0] || "An error occurred";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      title: title,
      text: body,
      startdate: startDate,
      enddate: endDate,
      langcode: langcode,
      systemalerttypeid: alertTypeid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}systemalert/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setTitle("");
          setBody("");
        }
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      })
      .catch();
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      alertid: data.alertid,
      title: title,
      text: body,
      startdate: startDate,
      enddate: endDate,
      langcode: langcodeEdit,
      systemalerttypeid: alertTypeidEdit,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}systemalert/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      });
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      alertid: id,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}systemalert/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setLoadAlertList(true);
        showToast(result);
        setEdit(false);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}systemalert?AlertId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setLangcodeEdit(result.data.langcode);
        setTitle(result.data.title);
        setAlertTypeidEdit(result.data.systemalerttypeid);
        setBody(result.data.text);
        setStartDate(result.data.startdate);
        setEndDate(result.data.enddate);
      });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [key, setKey] = useState("all");

  useEffect(() => {
    window.scrollTo(0, 0);
    setEdit(false);
    if (key === "create") {
      setLangcode("");
      setAlertTypeid("");
      setTitle("");
      setBody("");
      setStartDate(dayjs());
      setEndDate(null);
    }
  }, [key]);

  const handleConfirmation = (id) => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to delete this alert? This action cannot be undone.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#032E42",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTemplate(id);
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire({
      //     title: "Cancelled",
      //     text: "",
      //     confirmButtonText: "Ok",
      //     confirmButtonColor: "#032E42",
      //     icon: "error",
      //   });
      // }
    });
  };

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          System Alert
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        <Tab eventKey="all" title="All">
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3  w-full mb-3">
              <Form.Select
                size="md"
                onChange={(e) => setLangcode(e.target.value)}
                value={langcode}
              >
                <option>Select Language</option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                size="md"
                value={alertTypeid}
                onChange={(e) => setAlertTypeid(e.target.value)}
              >
                <option value="">Select alert type</option>
                {alertTypeData.length !== 0 && (
                  <>
                    {alertTypeData.map((item, ind) => (
                      <option key={item.translistid} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
              <Form.Control
                placeholder="Search Here"
                onChange={(e) =>
                  setText(
                    e.target.value === "" ? "" : `&SearchText=${e.target.value}`
                  )
                }
              />

              {
                <button
                  onClick={() => {
                    setEdit(false);
                    let aTypeid =
                      alertTypeid === 0 ||
                      alertTypeid === "" ||
                      alertTypeid === undefined
                        ? ""
                        : `&SystemAlertTypeId=${alertTypeid}`;
                    getAllTemplates(
                      `${url}systemalert/search?LangCode=${langcode}${text}${aTypeid}&SiteSettingsId=1&PaginationQuery.PageNumber=1&PaginationQuery.PageSize=10`
                    );
                  }}
                  className="px-3 py-2 text-sm w-20 rounded-md  bg-sr text-white"
                  type="submit"
                >
                  Search
                </button>
              }
            </div>
            {allwaitinglist.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>Alert ID</th>
                      <th>Language</th>
                      <th>Title</th>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Alert type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={item.alertid}>
                        <td>{item.alertid}</td>
                        <td>{item.langcode}</td>
                        <td>{item.title}</td>

                        <td>{item.startdate.slice(0, 10)}</td>
                        <td>{item.enddate.slice(0, 10)}</td>
                        <td>{item.alerttypename}</td>
                        <td>
                          <span
                            className={`text-${
                              new Date(item.enddate) > currentDate
                                ? "green"
                                : "red"
                            }-500 font-bold`}
                          >
                            {new Date(item.enddate) > currentDate
                              ? "Online"
                              : "Offline"}
                          </span>
                        </td>
                        <td className="flex items-center justify-center gap-2">
                          <AiOutlineDelete
                            onClick={() => handleConfirmation(item.alertid)}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.alertid);
                            }}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : !edit ? (
              <>
                <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                  <p> The selected category doesn't have any alerts</p>
                </div>
              </>
            ) : (
              ""
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className="grid w-full grid-cols-1 gap-2">
                  <Form.Select
                    size="md"
                    value={langcodeEdit}
                    onChange={(e) => setLangcodeEdit(e.target.value)}
                  >
                    <option value="en">English </option>
                    <option value="de">German </option>
                    <option value="fr">French</option>
                    <option value="it">Italien</option>
                    <option value="no">Norwegian</option>
                    <option value="ar">عربي</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="tr">Turkish</option>
                    <option value="nl">Dutch</option>
                    <option value="es">Spanish</option>
                  </Form.Select>
                  <Form.Select
                    size="md"
                    value={alertTypeidEdit}
                    onChange={(e) => setAlertTypeidEdit(e.target.value)}
                  >
                    {alertTypeData.length !== 0 && (
                      <>
                        {alertTypeData.map((item, ind) => (
                          <option
                            key={item.translistid}
                            value={item.translistid}
                          >
                            {item.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </div>
                <div className="my-3 flex items-center gap-10">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      defaultValue={dayjs(startDate)}
                      id="startdate"
                      label="Start Date"
                      selected={startDate}
                      minDate={dayjs()}
                      onChange={handleStartDateChange}
                      dateFormat="YYYY-MM-DD HH:mm:ss"
                      slotProps={{
                        textField: {
                          placeholder: "Start Date",
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                    />

                    <DateTimePicker
                      label="End Date"
                      defaultValue={dayjs(endDate)}
                      selected={endDate}
                      onChange={handleEndDateChange}
                      dateFormat="YYYY-MM-DD HH:mm:ss"
                      minDate={dayjs()}
                      slotProps={{
                        textField: {
                          placeholder: "End Date",
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Content</Form.Label>
                  <Form.Control
                    value={body}
                    as="textarea"
                    onChange={(e) => setBody(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                {/* <Button variant="primary" className="mt-2" type="submit">
                
                  Update
                </Button> */}
                <div className="flex space-x-4">
                  <button
                    className="rounded-md text-white px-2 py-2 bg-sr text-sm"
                    type="submit"
                  >
                    Update
                  </button>
                  <button
                    className="rounded-md text-white px-2 py-2 bg-pr text-sm"
                    type="button"
                    onClick={(e) => setEdit(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="Create">
          <form onSubmit={(e) => createTemplate(e)}>
            <div className="grid grid-cols-1 gap-2  w-full mb-3">
              <Form.Select
                value={langcode}
                required
                size="md"
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option value="">Select Language</option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                required
                value={alertTypeid}
                size="md"
                onChange={(e) => setAlertTypeid(e.target.value)}
              >
                <option value="">Select alert type</option>
                {alertTypeData.length > 0 && (
                  <>
                    {alertTypeData.map((item, ind) => (
                      <option key={item.translistid} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
              <div className="my-3 flex items-center gap-10">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    defaultValue={dayjs(startDate)}
                    id="startdate"
                    label="Start Date"
                    selected={startDate}
                    minDate={dayjs()}
                    onChange={handleStartDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    slotProps={{
                      textField: {
                        placeholder: "Start Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                  />

                  <DateTimePicker
                    label="End Date"
                    defaultValue={dayjs(endDate)}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="YYYY-MM-DD HH:mm:ss"
                    minDate={dayjs()}
                    slotProps={{
                      textField: {
                        placeholder: "End Date",
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <Form.Group className="my-3" controlId="formGridAddress2">
              <Form.Label>Title</Form.Label>
              <Form.Control
                value={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="formGridState">
              <Form.Label>Content</Form.Label>
              <Form.Control
                value={body}
                required
                as="textarea"
                onChange={(e) => setBody(e.target.value)}
                rows={3}
              />
            </Form.Group>

            <button
              className="mt-2 px-3 py-2 text-white bg-sr rounded-md"
              type="submit"
            >
              Save
            </button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default SystemAlert;
