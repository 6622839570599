import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import Profiles from "./Pages/Profiles/Profiles";
import { RiBarChartHorizontalFill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { MainContent } from "../../App";
import AllPayments from "./Pages/allpayments/AllPayments";
import Tamplates from "./Pages/templates/Tamplates";
import ContactAdmin from "./Pages/Contact/ContactAdmin";
import Abusement from "./Pages/abusement/Abusement";
import Faq from "./Pages/faq/Faq";
import Promotion from "./Pages/promotion/Promotion";
import SystemLetters from "./Pages/Letters/SystemLetters";
import SystemAlert from "./Pages/alert/SystemAlert";
import Sitepage from "./Pages/SIte pages/Sitepage";
import SiteSettings from "./Pages/sitesettings/SiteSettings";
import SystemLogs from "./Pages/logs/SystemLogs";
import MailQueue from "./Pages/mailqueue/MailQueue";
import Scheduler from "./Pages/scheduler/Scheduler";
import SuccesStories from "./Pages/SuccessStories/SuccessStories";
import logo from "../../images/logo.png";
import HelpAdmin from "./Pages/help/HelpAdmin";

const Admin = () => {
  const { page } = useParams();
  const history = useNavigate();
  const { changnow, setFooterShow, myown_data } = useContext(MainContent);
  const [isadmin, setIsadmin] = useState(false);
  const [ismanager, setIsmanager] = useState();

  useEffect(() => {
    setFooterShow(false);
    if (myown_data.data.profileattributes) {
      if (myown_data.data.profileattributes.isadmin) {
        setIsadmin(myown_data.data.profileattributes.isadmin);
      } else if (myown_data.data.profileattributes.ismanager) {
        setIsmanager(myown_data.data.profileattributes.ismanager);
      } else {
        history("/");
      }
    }
  }, [changnow]);
  const [open, setOpen] = useState(true);
  return (
    <>
      {(isadmin || ismanager) && (
        <>
          <div className="bg-blue-50 min-h-screen">
            <div className="w-full ">
              <div className="relative  w-full">
                <div className="bg-sr fixed top-0 left-0 lg:px-0 px-3 z-50 w-full grid gap-x-1 grid-cols-3 items-center">
                  <div className=" w-full  flex items-center justify-between lg:justify-start gap-2">
                    {open === false && (
                      <RiBarChartHorizontalFill
                        onClick={() => setOpen(true)}
                        className=" text-white w-8 h-8 cursor-pointer"
                      />
                    )}
                    <img src={logo} className="w-14 h-14 lg:hidden" alt="" />
                    <img
                      src={logo}
                      className="w-20 h-20 hidden lg:block"
                      alt=""
                    />
                  </div>

                  <h1 className=" text-2xl lg:text-2xl text-white w-full col-span-2 lg:col-span-1 ">
                    ZAWAJ ADMINSTRATOR
                  </h1>
                  <h6 className="text-white w-full text-center">
                    Loggged in as :{" "}
                    <span className="text-pr">
                      {ismanager ? "Manager" : "Admin"}
                    </span>
                  </h6>
                </div>

                {open && (
                  <div
                    style={{ zIndex: "9954395990" }}
                    className=" min-h-screen top-0 fixed  bg-sr z-20 w-36 left-0 grid grid-cols-1 "
                  >
                    <div className=" flex items-center justify-center w-full h-full pt-12 pb-10">
                      <AiOutlineClose
                        onClick={() => setOpen(false)}
                        className=" text-white w-8 h-8 cursor-pointer"
                      />
                    </div>

                    <NavLink
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/"
                      end
                      onClick={() => setFooterShow(true)}
                    >
                      Dashboard
                    </NavLink>
                    <NavLink
                      end
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/profiles"
                      onClick={() => {
                        localStorage.removeItem("adminName");
                        setOpen(false);
                      }}
                    >
                      Profiles
                    </NavLink>
                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/payment"
                    >
                      Payment
                    </NavLink>
                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/contact"
                    >
                      Contact
                    </NavLink>
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/templates"
                      >
                        Templates
                      </NavLink>
                    )}
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/promotion"
                      >
                        Promotion
                      </NavLink>
                    )}

                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/faq"
                    >
                      FAQ
                    </NavLink>
                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/abusement"
                    >
                      Abusement
                    </NavLink>
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/system-letter"
                      >
                        System letter
                      </NavLink>
                    )}
                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/system-alert"
                    >
                      System Alert
                    </NavLink>
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/site-pages"
                      >
                        Site Pages
                      </NavLink>
                    )}
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/site-settings"
                      >
                        Site Settings
                      </NavLink>
                    )}
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/logs"
                      >
                        System Logs
                      </NavLink>
                    )}
                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/mail-queue"
                      >
                        Mail Queue
                      </NavLink>
                    )}

                    {!ismanager && (
                      <NavLink
                        onClick={() => setOpen(false)}
                        className={(navData) =>
                          navData.isActive
                            ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                            : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                        }
                        to="/admin/scheduler"
                      >
                        Scheduler
                      </NavLink>
                    )}
                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/success-stories"
                    >
                      Success Stories
                    </NavLink>
                    <NavLink
                      onClick={() => setOpen(false)}
                      className={(navData) =>
                        navData.isActive
                          ? "px-3  text-center text-gray-100 py-2 flex items-center justify-center bg-pr"
                          : "px-3  text-center text-gray-100 py-2 flex items-center justify-center"
                      }
                      to="/admin/help"
                    >
                      Help
                    </NavLink>
                  </div>
                )}
                <div
                  className={
                    open
                      ? " min-h-screen absolute top-40 left-0 w-full lg:pl-36 "
                      : " min-h-screen absolute top-40 left-0 w-full "
                  }
                >
                  {page === "profiles" && <Profiles />}
                  {page === "payment" && <AllPayments ismanager={ismanager} />}
                  {!ismanager && page === "templates" && <Tamplates />}
                  {page === "contact" && <ContactAdmin />}
                  {page === "abusement" && <Abusement />}
                  {page === "faq" && <Faq ismanager={ismanager} />}
                  {!ismanager && page === "promotion" && <Promotion />}
                  {page === "system-letter" && <SystemLetters />}
                  {page === "system-alert" && <SystemAlert />}
                  {page === "help" && <HelpAdmin />}
                  {!ismanager && page === "site-pages" && <Sitepage />}
                  {!ismanager && page === "site-settings" && <SiteSettings />}
                  {!ismanager && page === "logs" && <SystemLogs />}
                  {!ismanager && page === "mail-queue" && <MailQueue />}
                  {!ismanager && page === "scheduler" && <Scheduler />}
                  {page === "success-stories" && <SuccesStories />}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Admin;
