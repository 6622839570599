import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { url, errorHandler } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";

const PublicPage = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { language, isAuthenticated } = useContext(MainContent);
  const [text, setText] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { page } = useParams();

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getPage = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}sitepage/pagetype?PageTypeId=${page}&LangCode=${language}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        if (response.status === 400) {
          history("/error");
        } else {
          return response.json();
        }
      })
      .then((result) => {
        setText(
          result?.data ? result?.data?.text : t("Page_ID_not_found_Message")
        );
        //        setText(result.data ? result.data.text : t("NoDataAvailable"));
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();
  }, [language, page]);

  useEffect(() => {
    getPage();
  }, []);

  return (
    // <div
    //   className={`pt-1 container min-h-screen bg-blue-50 sm:mt-1 md:mt-1 lg:mt-24 xl:mt-24 2xl:mt-24 ${
    //     !isAuthenticated ? "mt-20" : ""
    //   }`}
    // >

    <div
      className={`pt-1 container min-h-screen bg-blue-50 ${
        !isAuthenticated ? "mt-20" : "lg:mt-24"
      }`}
    >
      {isAuthenticated && <ProfileNavbar />}
      {scrollPosition > 200 && (
        <div
          style={{ zIndex: "200" }}
          className="fixed top-20 left-0 h-15 w-full bg-pr shadow-md flex items-center justify-center lg:px-16"
        >
          <div className="flex mt-0 items-center justify-center h-20">
            <h1 className="text-center mt-8  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl  flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {(() => {
                if (page === "2") {
                  return t("About");
                } else if (page === "6") {
                  return t("Help");
                } else if (page === "19") {
                  return t("Visite_Our_Other_Site");
                } else if (page === "34") {
                  return t("Terms_Of_Use");
                } else if (page === "35") {
                  return t("Privacy_Statement");
                } else if (page === "36") {
                  return t("GDPR");
                } else if (page === "46") {
                  return t("Fatwa");
                } else {
                  return t("Page_ID_not_found_Header");
                }
              })()}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
        </div>
      )}
      <div className=" w-full flex items-center mb-0 justify-center ">
        <h1 className="text-center mt-8  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {(() => {
            if (page === "2") {
              return t("About");
            } else if (page === "6") {
              return t("Help");
            } else if (page === "19") {
              return t("Visite_Our_Other_Site");
            } else if (page === "34") {
              return t("Terms_Of_Use");
            } else if (page === "35") {
              return t("Privacy_Statement");
            } else if (page === "36") {
              return t("GDPR");
            } else if (page === "46") {
              return t("Fatwa");
            } else {
              return t("Page_ID_not_found_Header");
            }
          })()}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <div className="bg-white rounded-xl shadow-md text-black flex py-5 mt-8">
        <div className={`py-3 ${language === "ar" ? "mr-8" : "ml-8"}`}>
          {text !== null && (
            <div
              className="border-white pb-4"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicPage;
