import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { IoIosWarning } from "react-icons/io";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";

const MailQueue = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [load, setLoad] = useState(false);
  const [prvurl, setPrvurl] = useState();
  const [nxturl, setNxturl] = useState();
  const [show, setShow] = useState(false);

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
        }
        setPrvurl(result.previouspage);
        setNxturl(result.nextpage);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoad(false);

    getAllTemplates(`${url}mailqueue`);
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const alldelete = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${url}mailqueue/resetqueue`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
      });
  };

  return (
    <div className="px-4 ">
      <ToastContainer />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          Mail Queue
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        <Tab eventKey="all" title="Mail Queue">
          <>
            {allwaitinglist.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>CreateDate</th>
                      <th>Form</th>
                      <th>To</th>
                      <th>LangCode</th>
                      <th>Subject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.createddate.slice(0, 10)}</td>
                        <td>{item.from}</td>
                        <td>{item.to}</td>
                        <td>{item.langcode}</td>
                        <td>{item.subject}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-white w-full bg-sr h-24 flex items-center justify-center text-xl font-medium">
                No Mail Queue
              </div>
            )}
            {allwaitinglist.length !== 0 && (
              <div className=" w-full justify-between flex items-center gap-4 mb-3">
                <div></div>
                <div className="flex items-center gap-2">
                  {prvurl === null ? (
                    <button
                      disabled
                      className="bg-pr cursor-not-allowed flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                    >
                      <AiOutlineLeft />
                      Previous
                    </button>
                  ) : (
                    <button
                      onClick={() => getAllTemplates(prvurl)}
                      className="bg-pr flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                    >
                      <AiOutlineLeft />
                      Previous
                    </button>
                  )}
                  {allwaitinglist.length !== 10 ? (
                    <button
                      disabled
                      className="bg-pr cursor-not-allowed  flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                    >
                      Next <AiOutlineRight />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        getAllTemplates(nxturl);
                      }}
                      className="bg-pr   flex items-center gap-1 px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-blue-800"
                    >
                      Next <AiOutlineRight />
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
        </Tab>
        <Tab eventKey="create" title="Reset Queue">
          {show ? (
            <div className="w-full">
              <h1 className="text-2xl text-gray-500 flex items-center gap-1">
                <IoIosWarning className="text-yellow-400" />
                By Clicking On This Button all outgoing mails in the mail queue
                will be deleted
              </h1>
              <button
                onClick={alldelete}
                className="bg-pr px-4 py-2 mt-2 rounded-md text-white hover:bg-sr cursor-pointer"
              >
                Confirm Reset mail queue
              </button>
            </div>
          ) : (
            <div className="w-full">
              <button
                onClick={() => setShow(true)}
                className="bg-sr px-4 py-2 text-white rounded-md hover:bg-pr cursor-pointer"
              >
                Reset mail queue
              </button>
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default MailQueue;
