import Cookies from "js-cookie";
import endpoints, { HANDLE_API_REQUEST } from "../api/EndPoints";

export const url = process.env.REACT_APP_URI;

// export const errorHandler = (err) => {
//   if (err.status === 401 || err.status === 403) {
//     window.location.href = "/";
//     if (Cookies.get("accessToken")) {
//       Cookies.remove("accessToken");
//       Cookies.remove("refreshToken");
//     }
//     alert("401 errorHandler");
//   }

//   if (err.status === 404) {
//     alert("404 page not found");
//     if (window.location.pathname !== "/404") {
//       window.location.href = "/404";
//     } else {
//       console.log("Already on the 404 page");
//     }
//   }
//   if (
//     err.status !== 404 &&
//     err.status !== 403 &&
//     err.status !== 401 &&
//     err.status !== 429 &&
//     err.status !== 400 &&
//     err.status !== 200
//   ) {
//     console.log(err);
//     alert("Error");
//     window.location.href = "/error";
//   }
// };

export const errorHandler = async (err) => {
  const status = err.status;

  if ([401, 403].includes(status)) {
    await handleAuthError();
  } else if (status === 404) {
    handleNotFoundError();
  } else if (status === 429) {
    window.location.href = "/too-many-requests";
  } else if (![404, 403, 401, 429, 400, 200, 201, 204].includes(status)) {
    handleUnexpectedError(err);
  }
};

const handleAuthError = async () => {
  try {
    await refreshTokens();
    console.log("Tokens refreshed successfully.");
    // Redirect to the original location after refreshing tokens
    window.location.reload();
  } catch (error) {
    console.log("Token refresh failed:", error);
    removeAuthTokens();
    console.log("401 or 403 error. Redirecting to login.");
    redirectTo("/");
  }
};

const handleNotFoundError = () => {
  if (window.location.pathname !== "/404") {
    redirectTo("/404");
  } else {
    console.log("Already on the 404 page");
  }
};

const handleNoProfileFoundError = () => {
  if (window.location.pathname !== "/profile-notfound") {
    redirectTo("/profile-notfound");
  } else {
    console.log("Already in profile not found page");
  }
};

const handleUnexpectedError = (err) => {
  console.log(err);
  redirectTo("/error");
};

const removeAuthTokens = () => {
  if (Cookies.get("accessToken")) {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.set("isAuthenticated", false);
  }
};

const redirectTo = (path) => {
  window.location.href = path;
};

const refreshTokens = async () => {
  const token = Cookies.get("accessToken");
  const refreshToken = Cookies.get("refreshToken");

  if (!refreshToken || !token) {
    console.log("No refresh token or access token available");
    redirectTo("/");
  }

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
  myHeaders.append("Accept-Language", "en");

  const body = JSON.stringify({
    token: token,
    refreshToken: refreshToken,
  });

  const config = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };
  const response = await HANDLE_API_REQUEST.POST(
    endpoints.REFRESH_TOKEN,
    null,
    config
  );

  if (!response.ok) {
    console.log("Failed to refresh tokens");
  }

  const result = await response.json();

  if (result.succeeded) {
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    Cookies.set("accessToken", result.token, { expires: oneMonthFromNow });
    Cookies.set("refreshToken", result.refreshtoken, {
      expires: oneMonthFromNow,
    });
    Cookies.set("isAuthenticated", true, { expires: oneMonthFromNow });
  } else {
    console.log("Token refresh did not succeed");
    redirectTo("/");
  }
};

// export const profileHandler = (err) => {
//   if (err.status === 401 || err.status === 403) {
//     window.location.href = "/";
//     if (Cookies.get("accessToken")) {
//       Cookies.remove("accessToken");
//       Cookies.remove("refreshToken");
//     }
//     alert("401 profileHandler");
//   }

//   if (err.status === 404) {
//     window.location.href = "/profile-notfound";
//   }
//   if (err.status === 429) {
//     window.location.href = "/too-many-requests";
//   }
//   if (
//     err.status !== 404 &&
//     err.status !== 403 &&
//     err.status !== 401 &&
//     err.status !== 429 &&
//     err.status !== 200
//   ) {
//     window.location.href = "/error";
//   }
// };

export const profileHandler = async (err) => {
  const status = err.status;

  if ([401, 403].includes(status)) {
    await handleAuthError();
  } else if (status === 404) {
    handleNoProfileFoundError();
  } else if (status === 429) {
    window.location.href = "/too-many-requests";
  } else if (![404, 403, 401, 429, 400, 200, 201, 204].includes(status)) {
    handleUnexpectedError(err);
  }
};
