import React from "react";
import { useNavigate } from "react-router-dom";

import {
  AiOutlineDoubleRight,
  AiOutlineDoubleLeft,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { FaFilter, FaUserEdit } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { HiOutlinePhotograph } from "react-icons/hi";
import { MdOutlineNotificationsNone } from "react-icons/md";

const CompleProfileCard = ({
  title,
  description,
  linkText,
  linkUrl,
  language,
  icon: IconComponent,
}) => {
  const history = useNavigate();

  const handleClick = () => {
    history(linkUrl);
  };

  return (
    <div
      className="bg-white shadow-lg rounded-lg overflow-hidden m-1 flex flex-col justify-between h-full cursor-pointer hover:shadow-xl"
      onClick={handleClick}
    >
      <div className="p-4">
        <div
          //    className="flex items-center"
          className={`flex items-center ${
            language === "ar" ? " justify-start " : " mr-auto "
          }`}
        >
          {IconComponent && (
            <IconComponent className="text-pr mr-2 inline-block align-middle text-4xl"  />
          )}
          <h2 className="font-bold text-xl mb-2 mr-2">{title}</h2>
        </div>

        <p className="text-gray-700">{description}</p>
      </div>
      <div className="p-4 flex  justify-end">
        <button className="bg-sr hover:bg-pr text-white font-bold py-2 px-4   transition ease-linear duration-300  rounded golink uppercase ">
          {linkText}
          {language === "ar" ? (
            <AiOutlineDoubleLeft className="icon" />
          ) : (
            <AiOutlineDoubleRight className="icon" />
          )}
        </button>
      </div>
    </div>
  );
};

export default CompleProfileCard;
