import React from "react";

const HtmlContent = ({ content }) => (
  <div
    className="px-5 lg:px-20 text-sr font-medium text-center"
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

export default HtmlContent;
