export const MakeEmpRow = ({ gdata, mydata }) => {
  const filterdata = mydata.filter(
    (item) => item.translistid === gdata.languageid
  );
  return (
    <div className="text-white px-2 py-1 rounded-md bg-sr text-xs font-medium">
      {filterdata.length !== 0 && filterdata[0].name}
    </div>
  );
};
export const MakeEmpRow2 = ({ gdata, mydata }) => {
  const filterdata = mydata.filter(
    (item) => item.translistid === gdata.hobbiesid
  );
  return (
    <div className="text-white px-2 py-1 rounded-md bg-sr text-xs font-medium">
      {filterdata.length !== 0 && filterdata[0].name}
    </div>
  );
};
export const MakeSportRow = ({ gdata, mydata }) => {
  const filterdata = mydata.filter(
    (item) => item.translistid === gdata.sportattitudeid
  );
  return (
    <div className="text-white text-xs font-medium border px-2 py-1 text-center flex items-center justify-center bg-sr rounded-md">
      {filterdata.length !== 0 && filterdata[0].name}
    </div>
  );
};
