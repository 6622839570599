import React, { useContext } from "react";
import ProfileNavbar from "../../layout/ProfileNavbar";
import Advance from "./advance/Advance";
import Basic from "./basic/Basic";
import MemberId from "./MemberId/MemberId";
import "./profile.css";
import ProfileName from "./ProfileName/ProfileName";
import { useParams, useNavigate } from "react-router-dom";
import { MainContent } from "../../App";
import Topten from "./top10/Topten";
import Latest from "./latest/Latest";
import Online from "./online/Online";

const Search = () => {
  const { page } = useParams();

  const { basicshow, setBasicshow, setAdvancesho, advancesho } =
    useContext(MainContent);
  const history = useNavigate();
  return (
    <div className="container mt-24 lg:mt-16 lg:pt-10 min-h-screen bg-blue-50">
      <div className="container">
        <ProfileNavbar />
        <div>
          <div className="pt-4">
            <>
              {page === "basic" && (
                <Basic basicshow={basicshow} setBasicshow={setBasicshow} />
              )}
              {page === "advance" && (
                <Advance
                  advancesho={advancesho}
                  setAdvancesho={setAdvancesho}
                />
              )}
              {page === "profilename" && <ProfileName />}
              {page === "memberid" && <MemberId />}
              {page === "top10" && <Topten />}
              {page === "latest" && <Latest />}
              {page === "online" && <Online />}
              {[
                "basic",
                "advance",
                "profilename",
                "memberid",
                "top10",
                "latest",
                "online",
              ].indexOf(page) === -1 && history("/404")}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
