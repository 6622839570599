import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Multiselect from "multiselect-react-dropdown";
import { MainContent } from "../../../../App";
import { errorHandler } from "../../../../api/Api";
import endpoints, { HANDLE_API_REQUEST } from "../../../../api/EndPoints";
import { useGetAllHobbies } from "../../../../hooks/CustomHooks";

function Hobbies() {
  const { t } = useTranslation();
  const { showtoast, myown_data, setFreshReload, language } =
    useContext(MainContent);
  const { data: hobbiesData } = useGetAllHobbies(language);
  //const [usersHobbies] = useState(myown_data?.data?.profilehobbies || []);
  const [hobbies, setHobbies] = useState(
    myown_data?.data?.profilehobbies || []
  );

  const saveHobbies = (arr) => {
    let newarr = [];
    arr.map((item) => newarr.push({ hobbiesid: item.translistid }));
    setHobbies(newarr);
  };

  let filter1 = [];
  if (hobbies.length !== 0 && hobbiesData?.hobbies?.length !== 0) {
    hobbies.forEach((item) => {
      let newarr = hobbiesData?.hobbies?.filter(
        (ind) => ind.translistid === item.hobbiesid
      );
      filter1 = newarr?.length > 0 && [...filter1, newarr[0]];
    });
  }

  const handleSaveHobbies = () => {
    const body = JSON.stringify(hobbies);
    HANDLE_API_REQUEST.POST(endpoints.SAVE_HOBBIES, body)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFreshReload(true);
        showtoast(result);
      });
  };

  return (
    <Accordion.Item className="my-2 rounded-md bg-white shadow-md" eventKey="9">
      <Accordion.Header>
        {" "}
        <h1 className="text-xl text-sr font-medium pb-2 flex items-center  w-full">
          {t("Hobbies")}
        </h1>
      </Accordion.Header>
      <Accordion.Body>
        {filter1.length !== 0 ? (
          <Multiselect
            className={`form-select form-select-md mb-3 mt-2 py-2 ${
              language === "ar" ? "text-right " : ""
            }${filter1.length === 0 ? "bg-red-100" : "bg-green-100 "}`}
            required
            options={hobbiesData?.hobbies}
            selectionLimit="10"
            selectedValues={filter1}
            displayValue="name"
            placeholder={t("Select")}
            onSelect={(e) => saveHobbies(e)}
            onRemove={(e) => saveHobbies(e)}
          />
        ) : (
          <Multiselect
            className={`form-select form-select-md mb-3 mt-2 py-2 ${
              language === "ar" ? "text-right " : ""
            }  ${hobbies.length === 0 ? " bg-red-100" : " bg-green-100 "}`}
            required
            options={hobbiesData?.hobbies}
            selectionLimit="10"
            displayValue="name"
            placeholder={t("Select")}
            onSelect={(e) => saveHobbies(e)}
            onRemove={(e) => saveHobbies(e)}
          />
        )}
        <div className="w-full items-end flex justify-end">
          <button
            type="button"
            onClick={handleSaveHobbies}
            className="text-white px-3 mt-4 rounded py-2 bg-sr text-md font-bold hover:bg-pr transition ease-linear duration-300 cursor-pointer"
          >
            {t("Save")}
          </button>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default Hobbies;
