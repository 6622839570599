import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../../api/Api";
import { MainContent } from "../../../../App";
import { Tabs, Tab, Form, Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineDelete, AiFillCloseCircle } from "react-icons/ai";
import { RiEditBoxLine } from "react-icons/ri";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
const SystemLetters = () => {
  const { changnow } = useContext(MainContent);
  const [allwaitinglist, setAllwaitinglist] = useState([]);
  const [title, setTitle] = useState("");
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [langcode, setLangcode] = useState("en");
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [text, setText] = useState("");
  const [lettertype, setLettertype] = useState([]);
  const [messagetype, setMessagetype] = useState([]);
  const [letterid, setLetterid] = useState("");
  const [messagid, setMessagid] = useState("");
  const [gender, setGender] = useState("M");

  const getAllTemplates = (url) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setAllwaitinglist(result.data);
        } else {
          setAllwaitinglist([]);
          // showToast(result)
        }
      });
  };

  const getlettertype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=LettersType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setLettertype(result.letterstype));
  };
  const getMessagetype = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept-Language", "en");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${url}metadata/singletype?LookupSingleTypeOptions=MessageType`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => setMessagetype(result.messagetype));
  };

  const [key, setKey] = useState("all");

  useEffect(() => {
    window.scrollTo(0, 0);
    setTitle("");
    setBody("");
    setEdit(false);
    setHeader("");
  }, [key]);

  useEffect(() => {
    setLoad(false);
    getMessagetype();
    getlettertype();
  }, [changnow, load]);

  const showToast = (result) => {
    if (result.success) {
      setLoad(true);
      toast.success(result.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    
    let raw = JSON.stringify({
      title: title,
      text: body,
      siteSettingsId: 1,
      langCode: langcode,
      systemLetterTypeId: letterid,
      htmlFileTemplatePath: header,
      messagetypeid: messagid,
      targetgender: gender,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}systemletter/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setTitle("");
          setBody("");
          setHeader("");
        }
        return response.json();
      })
      .then((result) => showToast(result));
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      letterid: data.letterid,
      title: title,
      text: body,
      langcode: langcode,
      htmlfiletemplatepath: header,
      systemlettertypeid: letterid,
      messagetypeid: messagid,
      targetgender: gender,
    });

    let requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}systemletter/update`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        showToast(result);
        setEdit(false);
      });
  };

  const deleteTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      letterid: id,
    });

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}systemletter/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setEdit(false);
        getAllTemplates(
          `${url}systemletter/search?SearchText=${text}&LangCode=${langcode}&SystemLetterTypeId=${letterid}&MessageTypeId=${messagid}&SiteSettingsId=1&PaginationQuery.PageNumber=1&PaginationQuery.PageSize=10&TargetGender=${gender}`
        );
        showToast(result);
      });
  };
  const readTemplate = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}systemletter?LetterId=${id}`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        setLangcode(result.data.langcode);
        setTitle(result.data.title);
        setMessagid(result.data.messagetypeid);
        setLetterid(result.data.systemlettertypeid);
        setGender(result.data.targetgender);
        setHeader(result.data.htmlfiletemplatepath);
        setBody(result.data.text);
      });
  };

  return (
    <div className="px-4">
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-2  text-sr font-medium text-4xl flex items-center gap-2">
          <BsBlockquoteLeft className="text-sr" />
          System Letter
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      <Tabs
        defaultActiveKey="all"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="my-3"
      >
        <Tab eventKey="all" title="All">
          <>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3  w-full mb-3">
              <Form.Select
                size="md"
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option>Select Language</option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                size="md"
                onChange={(e) =>
                  setLetterid(
                    e.target.value === 0
                      ? ""
                      : `&SystemLetterTypeId=${e.target.value}`
                  )
                }
              >
                <option value={0}>Select Lettertype</option>
                {lettertype.length !== 0 && (
                  <>
                    {lettertype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
              <Form.Select
                size="md"
                onChange={(e) =>
                  setMessagid(
                    e.target.value === 0
                      ? ""
                      : `&MessageTypeId=${e.target.value}`
                  )
                }
              >
                <option value={0}>Select Messagetype</option>
                {messagetype.length !== 0 && (
                  <>
                    {messagetype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>

              <FormControl component="fieldset">
                <div className="flex items-center gap-1">
                  <FormLabel component="legend">Target Gender</FormLabel>
                  <RadioGroup
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                  >
                    <div className="flex items-center gap-1">
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio />}
                        label="Female"
                      />
                    </div>
                  </RadioGroup>
                </div>
              </FormControl>

              <Form.Control
                autoFocus
                placeholder="Search Here"
                onChange={(e) =>
                  setText(
                    e.target.value === "" ? "" : `&SearchText=${e.target.value}`
                  )
                }
              />

              <button
                onClick={() =>
                  getAllTemplates(
                    `${url}systemletter/search?LangCode=${langcode}${text}${letterid}${messagid}&SiteSettingsId=1&PaginationQuery.PageNumber=1&PaginationQuery.PageSize=10&TargetGender=${gender}`
                  )
                }
                className="px-3 py-1 rounded-md w-24 bg-sr hover:bg-pr text-white"
                type="submit"
              >
                Search
              </button>
            </div>
            {allwaitinglist.length !== 0 ? (
              <>
                <Table bordered striped hover responsive="md">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Language</th>
                      <th>Title</th>
                      <th>Gender</th>
                      <th>L-Type</th>
                      <th>M-Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allwaitinglist.map((item, ind) => (
                      <tr key={ind}>
                        <td>{item.letterid}</td>
                        <td>{item.langcode}</td>
                        <td>{item.title}</td>
                        <td>{item.targetgender}</td>
                        <td>{item.systemlettertypename}</td>
                        <td>{item.messagetypename}</td>

                        <td className="flex items-center justify-center gap-2">
                          {item.issystemmessage ? (
                            <AiOutlineDelete className="h-6 w-6 text-gray-500 " />
                          ) : (
                            <AiOutlineDelete
                              onClick={() => deleteTemplate(item.letterid)}
                              className="h-6 w-6 text-pr cursor-pointer"
                            />
                          )}
                          <RiEditBoxLine
                            onClick={() => {
                              setEdit(true);
                              readTemplate(item.letterid);
                            }}
                            className="h-6 w-6 text-pr cursor-pointer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <div className=" text-gray-700 w-full bg-white h-24 flex items-center justify-center rounded-md shadow-md">
                No system letter found
              </div>
            )}
          </>
          {edit && (
            <div className="mb-4 mt-3">
              <div className="w-full flex items-center justify-end mb-3 mt-4">
                <AiFillCloseCircle
                  onClick={() => {
                    setEdit(false);
                    setTitle("");
                    setBody("");
                    setHeader("");
                  }}
                  className="h-8 w-8 text-pr cursor-pointer"
                />
              </div>
              <Form onSubmit={(e) => updateTemplate(e)}>
                <div className="grid w-full grid-cols-1 gap-2">
                  <Form.Select
                    size="md"
                    disabled
                    value={langcode}
                    onChange={(e) => setLangcode(e.target.value)}
                  >
                    <option value="en">English </option>
                    <option value="de">German </option>
                    <option value="fr">French</option>
                    <option value="it">Italien</option>
                    <option value="no">Norwegian</option>
                    <option value="ar">عربي</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="tr">Turkish</option>
                    <option value="nl">Dutch</option>
                    <option value="es">Spanish</option>
                  </Form.Select>
                  <Form.Select
                    size="md"
                    disabled
                    value={letterid}
                    onChange={(e) => setLetterid(e.target.value)}
                  >
                    {lettertype.length !== 0 && (
                      <>
                        {lettertype.map((item, ind) => (
                          <option key={ind} value={item.translistid}>
                            {item.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                  <Form.Select
                    size="md"
                    disabled
                    value={messagid}
                    onChange={(e) => setMessagid(e.target.value)}
                  >
                    {messagetype.length !== 0 && (
                      <>
                        {messagetype.map((item, ind) => (
                          <option key={ind} value={item.translistid}>
                            {item.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </div>
                <Form.Group className="my-3" controlId="formGridAddress2">
                  <Form.Label>Letter Title</Form.Label>
                  <Form.Control
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="formGridState">
                  <Form.Label>Letter Content</Form.Label>
                  <Form.Control
                    required
                    value={body}
                    as="textarea"
                    onChange={(e) => setBody(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <Form.Group className="my-3" controlId="formGridCity">
                  <Form.Label>Html Template Path</Form.Label>
                  <Form.Control
                    required
                    value={header}
                    onChange={(e) => setHeader(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  className="mt-2 bg-sr hover:bg-pr"
                  type="submit"
                >
                  Update
                </Button>
              </Form>
            </div>
          )}
        </Tab>
        <Tab eventKey="create" title="Create">
          <form onSubmit={(e) => createTemplate(e)}>
            <div className="grid grid-cols-1 gap-2  w-full mb-3">
              <Form.Select
                size="md"
                required
                onChange={(e) => setLangcode(e.target.value)}
              >
                <option value="">Select Language</option>
                <option value="en">English </option>
                <option value="de">German </option>
                <option value="fr">French</option>
                <option value="it">Italien</option>
                <option value="no">Norwegian</option>
                <option value="ar">عربي</option>
                <option value="sv">Swedish</option>
                <option value="da">Danish</option>
                <option value="tr">Turkish</option>
                <option value="nl">Dutch</option>
                <option value="es">Spanish</option>
              </Form.Select>
              <Form.Select
                required
                size="md"
                onChange={(e) => setLetterid(e.target.value)}
              >
                <option value="">Select LetterTypeId</option>
                {lettertype.length !== 0 && (
                  <>
                    {lettertype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
              <Form.Select
                required
                size="md"
                onChange={(e) => setMessagid(e.target.value)}
              >
                <option value="">Select MessageType</option>
                {messagetype.length !== 0 && (
                  <>
                    {messagetype.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>

              <FormControl component="fieldset">
                <div className="flex items-center w-80 gap-1">
                  <FormLabel component="legend">Target Gender</FormLabel>
                  <RadioGroup
                    defaultValue="M"
                    onChange={(e) => setGender(e.target.value)}
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                  >
                    <div className="flex items-center gap-1">
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio />}
                        label="Female"
                      />
                    </div>
                  </RadioGroup>
                </div>
              </FormControl>
            </div>
            <Form.Group className="my-3" controlId="formGridAddress2">
              <Form.Label>Letter Title</Form.Label>
              <Form.Control
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="formGridState">
              <Form.Label>Letter Content</Form.Label>
              <Form.Control
                required
                value={body}
                as="textarea"
                onChange={(e) => setBody(e.target.value)}
                rows={3}
              />
            </Form.Group>

            <Form.Group className="my-3" controlId="formGridCity">
              <Form.Label>Html Template Path</Form.Label>
              <Form.Control
                required
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" className="mt-2" type="submit">
              Save
            </Button>
          </form>
        </Tab>
      </Tabs>
    </div>
  );
};

export default SystemLetters;
