import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import Redio from "./Radio";
const EditItem = ({ title, data, icon, handleSelect, value }) => {
  return (
    <div>
      <div className="pt-4">
        <h2 className="text-sr text-lg font-medium pb-1 border-b flex items-center">
          {title}{" "}
          {icon && <BsInfoCircle className="ml-3 p-1 text-sr cursor-pointer" />}
        </h2>
        <FormControl component="fieldset" className="w-full">
          <RadioGroup
            value={value}
            className={`rounded-md ${value ? "bg-green-100 " : "bg-red-100 "}`}
            onChange={(e) => handleSelect(e.target.value)}
            row
            aria-label="haircolor"
            name="row-radio-buttons-group"
          >
            <>
              <>
                {data?.map((item) => (
                  <FormControlLabel
                    key={item.translistid}
                    control={<Redio val={item.translistid} />}
                    label={item?.name}
                  />
                ))}
              </>
            </>
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default EditItem;
